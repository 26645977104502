import React from 'react';
import CloseIcon from '@eg/elements/Icons/CloseIcon';
import './ClosingIcon.scss';
import LoaderIcon from '@eg/elements/Icons/LoaderIcon';
import classNames from 'classnames';
import { ClosingIconProps } from './ClosingIcon.types';

export const ClosingIcon: React.FC<ClosingIconProps> = ({
  onDelete,
  isBeingDeleted,
  isDeleteActionBlocked,
}: ClosingIconProps) => {
  function getCloseIconClassNames() {
    return classNames({
      'closing-icon closing-icon--enabled': !isDeleteActionBlocked,
      'closing-icon': isDeleteActionBlocked,
    });
  }

  if (isBeingDeleted) {
    return <LoaderIcon spinning className={'closing-icon'} />;
  } else {
    return (
      <CloseIcon
        className={`${getCloseIconClassNames()}`}
        onClick={() => (!isDeleteActionBlocked ? onDelete() : null)}
      />
    );
  }
};
