import { RootState } from 'store/store.interface';
import { personsSelectors } from 'entities/persons';

const editedPerson = (state: RootState) => {
  const editedPersonId = state.ui.personManagement.editedPersonId;
  if (editedPersonId) {
    return personsSelectors.getPersonById(state, editedPersonId);
  }
};

const isPersonMgmtActionBlocked = (state: RootState) =>
  state.ui.personManagement.isPersonMgmtActionBlocked;

export const personManagementSelectors = {
  editedPerson,
  isPersonMgmtActionBlocked,
};
