import React from 'react';
import Chip from '@eg/elements/Chip';
import { DeletableChipProps } from './DeletableChip.types';
import './DeletableChip.scss';
import { ClosingIcon } from '../ClosingIcon';

export const DeletableChip: React.FC<DeletableChipProps> = ({
  onDelete,
  value,
  isBeingDeleted,
  isDeleteActionBlocked,
  children,
  ...props
}: DeletableChipProps) => {
  return (
    <div className={'deletable-chip'}>
      <Chip {...props}>
        {children}
        <ClosingIcon
          {...{ isBeingDeleted, isDeleteActionBlocked }}
          onDelete={() => onDelete(value)}
        />
      </Chip>
    </div>
  );
};
