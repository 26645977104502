import { connect } from 'react-redux';
import { contractDetailsSelectors } from 'entities/contractDetails';
import { offerActions } from 'entities/offer';
import { personManagementSelectors } from '../../state';
import { personsSelectors } from 'entities/persons';
import { RoleBadges as RoleBadgesComponent } from './RoleBadges';
import {
  RoleBadgesDispatchProps,
  RoleBadgesOwnProps,
  RoleBadgesStateProps,
} from './RoleBadges.types';
import { RootState } from 'store/store.interface';

const makeMapStateToProps = (state: RootState, { personId }: RoleBadgesOwnProps) => {
  const personRoleSelector = personsSelectors.getPersonRoles(personId);

  return (state: RootState): RoleBadgesStateProps => ({
    roles: personRoleSelector(state),
    insuranceOwnerIsEdwinInitialized: personsSelectors.insuranceOwnerIsEdwinInitialized(state),
    isDisabled: personManagementSelectors.isPersonMgmtActionBlocked(state),
    maxInsuredPersonsNumberReached: personsSelectors.isMaxInsuredPersonsNumberReached(state),
    personBirthDate: personsSelectors.getBirthdate(state, personId),
    hasAssociationMemberData: contractDetailsSelectors.hasAssociationMemberData(state),
  });
};

const mapDispatchToProps: RoleBadgesDispatchProps = {
  toggleRole: offerActions.toggleRole,
};

export const RoleBadges = connect(makeMapStateToProps, mapDispatchToProps)(RoleBadgesComponent);
