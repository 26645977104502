import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'store/store.interface';
import { ProcessingFlowComponent } from './ProcessingFlow.component';
import {
  ProcessingFlowDispatchProps,
  ProcessingFlowStateProps,
  ProcessingFlowProps,
} from './ProcessingFlow.types';
import { offerActions, OfferFields, offerSelectors, ProcessingOfferFields } from 'entities/offer';

const mapStateToProps = (state: RootState): ProcessingFlowStateProps => ({
  consultationProtocolId: offerSelectors.getConsultationProtocolId(state),
  variantId: offerSelectors.getSelectedVariantId(state),
  applicationDate: offerSelectors.getVariantField(
    state,
    offerSelectors.getSelectedVariantId(state),
    ProcessingOfferFields.applicationDate
  ),
  approvalNote: offerSelectors.getApprovalNote(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ProcessingFlowDispatchProps => ({
  updateApplicationDate: (value: string, variantId: string) =>
    dispatch(
      offerActions.updateVariants({
        value,
        personId: '',
        variantId,
        fieldId: ProcessingOfferFields.applicationDate,
      })
    ),
  updateConsultationProtocolId: (value: string) =>
    dispatch(offerActions.updateOfferBasicData(value, OfferFields.consultationProtocolId)),
  updateApprovalNote: (value: string) =>
    dispatch(offerActions.updateOfferBasicData(value, ProcessingOfferFields.approvalNote)),
});

const mergeProps = (
  propsFromState: ProcessingFlowStateProps,
  propsFromDispatch: ProcessingFlowDispatchProps
): ProcessingFlowProps => {
  return {
    ...propsFromState,
    ...propsFromDispatch,
    updateApplicationDate: (value: string) => {
      propsFromDispatch.updateApplicationDate(value, propsFromState.variantId);
    },
  };
};

export const ProcessingFlow = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ProcessingFlowComponent);
