import React from 'react';
import { BENEFICIARY_FIELDS } from './BeneficiaryManagement.constants';
import { BeneficiaryManagement as BeneficiaryManagementContainer } from './BeneficiaryManagement.container';
import { BeneficiaryManagementOwnProps } from './BeneficiaryManagement.types';

export const BeneficiaryManagement: React.FC<BeneficiaryManagementOwnProps> = ({
  insuredPersonId,
  additionalFormFields,
  personHeaderInfo,
}) => {
  return (
    <BeneficiaryManagementContainer
      insuredPersonId={insuredPersonId}
      showRoleBadges={false}
      enablePDSValidation={false}
      enableCopyAddress={false}
      personsLimitLabel={'Anzahl der BB:'}
      visibleFields={BENEFICIARY_FIELDS}
      additionalFormFields={additionalFormFields}
      personHeaderInfo={personHeaderInfo}
    />
  );
};
