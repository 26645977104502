import React from 'react';
import { Checkbox, Currency, Select } from '../fields';

import { PackageFields } from 'entities/offer';

export const packagesConfig = {
  [PackageFields['000500.insuranceSum']]: {
    label: {
      text: 'Kapitalleistung bei Invalidität - Versicherungssumme',
      tooltipText: (
        <>
          <h3>Kapitalleistung bei Invalidität</h3>
          <p>
            Mit dieser einmaligen Kapitalleistung können Sie <b>unfallbedingte Folgekosten</b>{' '}
            auffangen. So können Sie z. B. eine barrierefreie Wohnung finanzieren.
          </p>
          <p>Die Versicherungssumme sollte:</p>
          <ul>
            <li>Pauschal mindestens 100.000 € Kapitalleistung oder</li>
            <li>Das drei- bis sechsfache der Jahresnettoeinnahmen der versicherten Person</li>
          </ul>
          <p>
            Die <b>Höhe der Leistung</b> nach einem Unfall hängt ab von
          </p>
          <ul>
            <li>der vereinbarten Versicherungssumme und</li>
            <li>dem Grad der Invalidität.</li>
          </ul>
          Die ERGO Unfallversicherung leistet ab jedem messbaren Invaliditätsgrad.
        </>
      ),
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000500.insuranceSum'],
        forceDecimals: true,
      },
    },
  },
  [PackageFields['000500.model']]: {
    label: {
      text: 'Leistung bei einer Invalidität ab 70%',
      tooltipText: (
        <>
          <div>
            <p>
              <b>
                Mit Progression 600 % wird bei voller Invalidität die 6-fache Leistung ausgezahlt
              </b>
            </p>
            <p>
              Wählen Sie einen Wert zwischen 10.000 und 300.000 €. Die Versicherungssumme sollte:
            </p>
            <ul>
              <li> Pauschal mindestens 100.000 € Kapitalleistung oder </li>
              <li> Das drei- bis sechsfache der Jahresnettoeinnahmen der versicherten Person</li>
            </ul>
            <p>Die ERGO Unfallversicherung leistet ab jedem messbaren Invaliditätsgrad.</p>{' '}
            <p>
              <b>Das Problem:</b> Bei <b>höheren Invaliditätsgraden</b> steigt der Kapitalbedarf{' '}
              <b>überproportional</b> an.
            </p>
            <p>
              <b>Die Lösung:</b> Ab einem Invaliditätsgrad von 25 % <b>erhöht sich die Leistung</b>{' '}
              mit der Progression stufenlos bis zu 600 % der Versicherungssumme bei einem
              Invaliditätsgrad von 100 %. Bereits ab einem Invaliditätsgrad von 70 % erhalten Sie
              die <b>volle Leistung, also die sechsfache Leistung</b>.
            </p>
            <p>
              <b>Beispiele bei 100.000 € Versicherungssumme</b>
            </p>
            <ul>
              <li>
                Bei <b>30 %</b> Invalidität: 100.000 € x <b>45 %</b> = 45.000 € Kapitalleistung
              </li>
              <li>
                Bei <b>50 %</b> Invalidität: 100.000 € x <b>125 %</b> = 125.000 € Kapitalleistung
              </li>
              <li>
                Bei <b>70 %</b> Invalidität: 100.000 € x <b>600 %</b> = 600.000 € Kapitalleistung
              </li>
              <li>
                Bei <b>100 %</b> Invalidität: 100.000 € x <b>600 %</b> = 600.000 € Kapitalleistung
              </li>
            </ul>
          </div>
          <div>
            <p>
              <b>
                Mit Progression 300 % wird bei voller Invalidität die 3-fache Leistung ausgezahlt
              </b>
            </p>
            <p>
              Wählen Sie einen Wert zwischen 10.000 und 300.000 €. Die Versicherungssumme sollte:
            </p>
            <ul>
              <li>Pauschal mindestens 100.000 € Kapitalleistung oder</li>
              <li>Das drei- bis sechsfache der Jahresnettoeinnahmen der versicherten Person</li>
            </ul>
            <p>Die ERGO Unfallversicherung leistet ab jedem messbaren Invaliditätsgrad.</p>
            <p>
              <b> Das Problem:</b> Bei <b> höheren Invaliditätsgraden</b> steigt der Kapitalbedarf{' '}
              <b> überproportional</b> an.
            </p>
            <p>
              <b> Die Lösung:</b> Ab einem Invaliditätsgrad von 25 % <b>erhöht sich die Leistung</b>{' '}
              mit der Progression stufenlos bis zu 300 % der Versicherungssumme bei einem
              Invaliditätsgrad von 100 %. Bereits ab einem Invaliditätsgrad von 70 % erhalten Sie
              die <b>volle Leistung, also die dreifache Leistung.</b>
            </p>
            <p>
              <b>Beispiele bei 100.000 € Versicherungssumme</b>
            </p>
            <ul>
              <li>
                Bei <b>30 %</b> Invalidität: 100.000 € x <b>40 %</b> = 40.000 € Kapitalleistung
              </li>
              <li>
                Bei <b>50 %</b> Invalidität: 100.000 € x <b>100 %</b> = 100.000 € Kapitalleistung
              </li>
              <li>
                Bei <b>70 %</b> Invalidität: 100.000 € x <b>300 %</b> = 300.000 € Kapitalleistung
              </li>
              <li>
                Bei <b>100 %</b> Invalidität: 100.000 € x <b>300 %</b> = 300.000 € Kapitalleistung
              </li>
            </ul>
          </div>
        </>
      ),
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000500.model'],
        readOnly: true,
      },
    },
  },
  [PackageFields['000961.insuranceSum']]: {
    label: {
      text: 'Kosten für kosmetische Operationen',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000961.insuranceSum'],
      },
    },
  },
  [PackageFields['000961.model']]: {
    label: {
      text: 'Kosten für kosmetische Operationen',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['000961.model'],
      },
    },
  },
  [PackageFields['000962.insuranceSum']]: {
    label: {
      text: 'Rettungs- und Bergungskosten',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000962.insuranceSum'],
      },
    },
  },
  [PackageFields['000962.model']]: {
    label: {
      text: 'Rettungs- und Bergungskosten',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['000962.model'],
      },
    },
  },
  [PackageFields['000583.insuranceSum']]: {
    label: {
      text: 'Beratungsleistung',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000583.insuranceSum'],
      },
    },
  },
  [PackageFields['000583.model']]: {
    label: {
      text: 'Beratungsleistung',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['000583.model'],
      },
    },
  },
  [PackageFields['000565.insuranceSum']]: {
    label: {
      text: 'Unfall Rente',
      tooltipText: (
        <>
          <p>
            Mit der Unfall-Rente oder Unfall-Rente Plus kann die versicherte Person{' '}
            <b>laufende Kosten</b> decken und <b>Extra-Ausgaben</b> bewältigen – z. B. für Kredite
            (Auto, Möbel, Haus). So bleibt die Lebensqualität dauerhaft abgesichert.
          </p>
          <p>
            <b>Rente</b>
          </p>
          <p>
            Eine <b>monatliche Rente</b> in Höhe der vereinbarten Versicherungssumme erhalten Sie ab
            einem unfallbedingten Invaliditätsgrad von 50 %. Und zwar grundsätzlich{' '}
            <b>bis zum Lebensende.</b>
          </p>
        </>
      ),
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000565.insuranceSum'],
      },
    },
  },
  [PackageFields['000565.model']]: {
    label: {
      text: 'Unfall Rentenmodell',
      tooltipText: (
        <>
          <h3>
            <b>Unfall-Rente Plus</b>
          </h3>
          <p>
            Geben Sie einen Wert zwischen 250 und 3.000 € ein. Bereits ab 35 % unfallbedingtem
            Invaliditätsgrad erhalten Sie eine lebenslange monatliche Rente (normalerweise erst ab
            50 %).
          </p>
          <p>
            Bei höheren unfallbedingten Invaliditätsgraden steigt der Rentenbedarf{' '}
            <b>überproportional</b> an:
          </p>
          <ul>
            <li>
              Ab <b>35 %</b> Invalidität: <b>50 %</b> der Versicherungssumme
            </li>
            <li>
              Ab <b>50 %</b> Invalidität: <b>100 %</b> der Versicherungssumme
            </li>
            <li>
              Ab <b>90 %</b> Invalidität: <b>200 %</b> der Versicherungssumme
            </li>
          </ul>
        </>
      ),
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['000565.model'],
      },
    },
  },
  [PackageFields['person-info.member-tax']]: {
    label: {
      text: 'Gliedertaxe Plus',
      tooltipText: (
        <p>
          Mit der Gliedertaxe Plus ist im Ernstfall eine höhere Kapitalleistung bei Invalidität
          möglich. Auch auf die Unfall-Rente und Unfall-Hilfe können sich die höheren
          Invaliditätsgrade positiv auswirken. Sind durch einen Unfall mehrere Körperteile oder
          Sinnesorgane dauerhaft betroffen, werden die ermittelten Invaliditätsgrade
          zusammengerechnet.
        </p>
      ),
    },
    component: {
      Component: Checkbox,
      props: {
        field: PackageFields['person-info.member-tax'],
      },
    },
  },
  [PackageFields['000501.insuranceSum']]: {
    label: {
      text: 'Todesfallleistung',
      tooltipText: (
        <>
          <h3>
            <b>Todesfallleistung</b>
          </h3>
          <p>
            Geben Sie einen Wert zwischen 1.000 und 250.000 € ein. Stirbt die versicherte Person
            unfallbedingt <b>innerhalb eines Jahres</b> nach dem Unfallereignis, zahlt die ERGO
            Unfallversicherung die vereinbarte Versicherungssumme an die Erben bzw.
            Bezugsberechtigten aus.
          </p>
        </>
      ),
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000501.insuranceSum'],
      },
    },
  },
  [PackageFields['injury-benefit.group']]: {
    label: {
      text: 'Verletzungsgeld',
      tooltipText: (
        <>
          <p>
            Für bestimmte <b>unfallbedingte Verletzungen</b> zahlt die ERGO Unfallversicherung ein
            Verletzungsgeld.
          </p>
          <h3>
            <b>Verletzungsgeld</b>
          </h3>
          <ul>
            <li>
              <b>100 €</b> z. B. bei Bruch von Finger, Zehe, Rippe, Nasenbein
            </li>
            <li>
              <b>500 €</b> z. B. bei Bruch von Hand, Fuß, Arm, Bein
            </li>
            <li>
              <b>1.000 €</b> z. B. bei Schädelhirntrauma ab 3. Grad
            </li>
          </ul>
          <h3>
            <b>Verletzungsgeld Plus</b>
          </h3>
          <ul>
            <li>
              <b>300 €</b> z. B. bei Bruch von Finger, Zehe, Rippe, Nasenbein
            </li>
            <li>
              <b>1.500 €</b> z. B. bei Bruch von Hand, Fuß, Arm, Bein
            </li>
            <li>
              <b>3.000 €</b> z. B. bei Schädelhirntraume ab 3. Grad
            </li>
          </ul>
        </>
      ),
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['injury-benefit.group'],
      },
    },
  },
  [PackageFields['000750.insuranceSum']]: {
    label: {
      text: 'Krankenhaus-Tagegeld',
      tooltipText: (
        <>
          <h3>
            <b>Krankenhaus-Tagegeld</b>
          </h3>
          <p>
            Geben Sie einen Wert zwischen 5 und 150 € ein. Bei einem unfallbedingten{' '}
            <b>stationären Krankenhausaufenthalt</b> zahlt die ERGO Unfallversicherung für jeden Tag
            das vereinbarte Krankenhaus-Tagegeld. Der vereinbarte Tagessatz wird{' '}
            <b>ab dem 4. Tag verdoppelt</b>, im Ausland bereits ab dem 1. Tag. Bei einer{' '}
            <b>ambulanten chirurgischen Operation</b> im Inland erhalten Sie <b>5 Tagessätze</b>, im
            Ausland das Doppelte.
          </p>
        </>
      ),
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000750.insuranceSum'],
      },
    },
  },
  [PackageFields['000567.insuranceSum']]: {
    label: {
      text: 'Rooming-in-Leistungen',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000567.insuranceSum'],
      },
    },
  },
  [PackageFields['000567.model']]: {
    label: {
      text: 'Rooming-in-Leistungen',
      tooltipText:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['000567.model'],
      },
    },
  },
  [PackageFields['000966.insuranceSum']]: {
    label: {
      text: 'Kids-Topschutz-Rente',
      tooltipText: (
        <>
          <h3>
            <b>Kids-Topschutz</b>
          </h3>
          <p>Geben Sie einen Wert zwischen 250 und 1.500 € ein.</p>
          <p>
            Mit dem Kids-Topschutz erhält das versicherte Kind eine{' '}
            <b>
              monatliche Rente bei Unfall und Krankheit bei einem Grad der Behinderung von
              mindestens 50.
            </b>
          </p>
          <p>
            Der Kids-Topschutz endet zum Ablauf des Versicherungsjahrs, in dem das versicherte Kind
            seinen <b>20. Geburtstag</b> hat. Dazu ist keine Kündigung nötig.
          </p>
        </>
      ),
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000966.insuranceSum'],
      },
    },
  },
  [PackageFields['accident-assistance.group']]: {
    label: {
      text: 'Unfall-Hilfe',
      tooltipText: (
        <>
          <p>Rundum-sorglos-Service mit umfangreichen Assistenzleistungen:</p>
          <h3>
            <b>Unfall-Assistenzleistung</b>
          </h3>
          <p>
            <b>Unfall-Hilfe Haushalts- und Pflegeleistungen</b> (z. B. Begleitung bei Arzt- oder
            Behördengängen, Wohnungsreinigung, Menüservice, individuelle Grundpflege) bis zu{' '}
            <b>einen Monat</b> lang, für Verbandsmitglieder bis zu 2 Monate.
          </p>
          <ul>
            <li>
              <b>Fahrdienste</b> (z. B. zur bzw. von der Krankengymnastik, Reha-Maßnahme und
              ambulanten Weiterbehandlung) <b>bis zu 500 €</b>
            </li>
            <li>
              <b>Fitness und Therapien</b> (z. B. Unterstützung durch einen persönlichen Reha- oder
              Personal Trainer, Trauma-Therapie) <b>bis zu 500 €</b>
            </li>
            <li>
              <b>Rehabilitation</b> (z. B. Hilfsmittel, Reha- und Umbaumaßnahmen, Unterstützung und
              Kostenübernahme bei der beruflichen Wiedereingliederung oder Umschulung){' '}
              <b>bis zu 50.000 €</b>
            </li>
          </ul>
          <h3>
            <b>Unfall-Assistenzleistung Unfall-Hilfe Plus</b>
          </h3>
          <p>
            <b>Haushalts- und Pflegeleistungen</b> (z. B. Begleitung bei Arzt- oder Behördengängen,
            Wohnungsreinigung, Menüservice, individuelle Grundpflege) bis zu <b>9 Monate</b> lang,
            für Verbandsmitglieder bis zu 12 Monate.
          </p>
          <ul>
            <li>
              <b>Fahrdienste</b> (z. B. zur bzw. von der Krankengymnastik, Reha-Maßnahme und
              ambulanten Weiterbehandlung) <b>bis zu 2.500 €</b>
            </li>
            <li>
              <b>Fitness und Therapien</b> (z. B. Unterstützung durch einen persönlichen Reha- oder
              Personal Trainer, Trauma-Therapie) <b>bis zu 2.500 €</b>
            </li>
            <li>
              <b>Rehabilitation</b> (z. B. Hilfsmittel, Reha- und Umbaumaßnahmen, Unterstützung und
              Kostenübernahme bei der beruflichen Wiedereingliederung oder Umschulung){' '}
              <b>bis zu 150.000 €</b>
            </li>
          </ul>
          <p>
            Unsere Leistungen erbringt die ERGO Unfallversicherung ausschließlich in Deutschland.
          </p>
        </>
      ),
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['accident-assistance.group'],
      },
    },
  },
  [PackageFields['000969.selected']]: {
    label: {
      text: 'Business-Hilfe',
      tooltipText: (
        <>
          <h3>Business-Hilfe</h3>
          <p>
            Als versicherter <b>Selbstständiger</b> bekommen Sie nach einem Unfall diese Hilfen:
          </p>
          <ul>
            <li>
              <b>Fahrdienste für Sie</b> als versicherten Selbstständigen für berufliche Zwecke{' '}
              <b>(bis zu 2.500 €)</b> und <b>für Ihre Mitarbeiter (bis zu 2.500 €)</b> – insgesamt
              max. <b>5.000 €</b>
            </li>
            <li>
              <b>Business-Unterstützung:</b> Zeit- bzw. Leiharbeitskräfte, Übergangsmanagement,
              Kostenerstattung für behindertengerechten Umbau im beruflichen Umfeld{' '}
              <b>bis zu 25.000 €</b>
            </li>
          </ul>
          <p>
            Unsere Leistungen erbringt die ERGO Unfallversicherung ausschließlich in Deutschland.
          </p>
        </>
      ),
    },
    component: {
      Component: Checkbox,
      props: {
        field: PackageFields['000969.selected'],
      },
    },
  },
  [PackageFields['000963.model']]: {
    label: {
      text: 'Business-Tagegeld',
      tooltipText: (
        <>
          <h3>
            <b>Business-Tagegeld</b>
          </h3>
          <p>
            Geben Sie einen Wert zwischen 5 und 150 € ein. Sind Sie als versicherter Selbständiger
            unfallbedingt <b>in Ihrer Arbeitsfähigkeit beeinträchtigt</b>, zahlt die ERGO
            Unfallversicherung <b>ab dem 8. Tag/ab dem 15. Tag</b> ein Tagegeld für die vereinbarte
            Dauer (max. bis zum 365. Tag). Das Tagegeld berechnet sich nach der vereinbarten
            Versicherungssumme und dem ärztlich festgestellten Grad der Beeinträchtigung.
          </p>
        </>
      ),
    },
    component: {
      Component: Select,
      props: {
        field: PackageFields['000963.model'],
      },
    },
  },
  [PackageFields['000963.insuranceSum']]: {
    label: {
      text: 'Business-Tagegeldhöhe',
    },
    component: {
      Component: Currency,
      props: {
        field: PackageFields['000963.insuranceSum'],
      },
    },
  },
};
