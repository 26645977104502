import { OfferActions, OfferActionsTypes } from 'entities/offer';
import { ProcessingFlowState } from './processing.flow.types';

const initialState: ProcessingFlowState = {
  isAdding: false,
  isDeleting: false,
  currentlyDeletedApprovalClause: '',
  currentlyAddedApprovalClause: '',
};

export const processingFlowReducer = (
  state: ProcessingFlowState = initialState,
  action: OfferActions
): ProcessingFlowState => {
  switch (action.type) {
    case OfferActionsTypes.ADD_APPROVAL_CLAUSE_TRIGGER:
      return {
        ...state,
        isAdding: true,
        currentlyAddedApprovalClause: action.payload,
      };
    case OfferActionsTypes.ADD_APPROVAL_CLAUSE_SUCCESS:
    case OfferActionsTypes.ADD_APPROVAL_CLAUSE_FAILURE:
      return {
        ...state,
        isAdding: false,
        currentlyAddedApprovalClause: '',
      };
    case OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_TRIGGER:
      return {
        ...state,
        isDeleting: true,
        currentlyDeletedApprovalClause: action.payload,
      };
    case OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_SUCCESS:
    case OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_FAILURE:
      return {
        ...state,
        isDeleting: false,
        currentlyDeletedApprovalClause: '',
      };

    default:
      return { ...state };
  }
};
