import { connect } from 'react-redux';
import { partial } from 'lodash';
import { Select as SelectComponent } from './Select';
import {
  SelectOwnProps,
  SelectDispatchProps,
  SelectStateProps,
  SelectMergeProps,
} from './Select.types';
import { mapDispatchToProps } from '../fields.helpers';
import { offerSelectors, TableFields } from 'entities/offer';
import { RootState } from 'store/store.interface';

const makeFieldMapState = (state: RootState, ownProps: SelectOwnProps<TableFields>) => {
  const { personId, variantId, field } = ownProps;
  const fieldSelector = offerSelectors.getFieldOrEmptyPlaceholder(personId!, variantId!, field);

  return (state: RootState): SelectStateProps => fieldSelector(state);
};

const mergeProps = (
  stateProps: SelectStateProps,
  dispatchProps: SelectDispatchProps,
  ownProps: SelectOwnProps
): SelectMergeProps => {
  const { setCorrupted, updateVariant, ...restDispatchProps } = dispatchProps;
  return {
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    setCorrupted: partial(
      setCorrupted,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
    updateVariant: partial(
      updateVariant,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
  };
};

export const Select = connect(makeFieldMapState, mapDispatchToProps, mergeProps)(SelectComponent);
