import { isNumber, isString } from 'lodash';
import React from 'react';
import { offerHelper } from 'entities/offer';

interface InputFieldProps<Value> {
  value?: Value;
  stepRange?: number;
}

export const useFieldStepRangeValidation = <Value>(
  element: React.ReactNode,
  stepRange?: number
): boolean => {
  if (React.isValidElement(element)) {
    const { value } = element.props as InputFieldProps<Value>;
    const asFloat = isString(value) ? parseFloat(value) : NaN;
    const isValidValue = !isNaN(asFloat);
    const isValidStepRange = isNumber(stepRange) && stepRange !== 0;

    if (!isValidValue || !isValidStepRange) {
      return true;
    }

    return offerHelper.hasValidStepRange(asFloat, stepRange || '');
  }

  return true;
};
