import { of } from 'rxjs';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap } from 'rxjs/operators';
import { difference, map, uniq } from 'lodash';
import { ActionByType, makeApiCall, buildEndpoint, ENDPOINTS } from 'services';
import { RootState } from 'store/store.interface';
import { OfferActions, OfferDto, offerSelectors } from 'entities/offer';
import { Person, PersonsActions, personsActions, personsSelectors } from 'entities/persons';
import {
  beneficiaryManagementActions,
  BeneficiaryManagementActions,
} from '../BeneficairyManagement/state/beneficiary.management.actions';
import {
  BeneficiaryActionTypes,
  BeneficiaryActions,
  beneficiaryActions,
} from './beneficiary.actions';
import { AjaxResponse } from 'rxjs/internal/observable/dom/AjaxObservable';

type CreateBeneficiaryTrigger = ActionByType<
  BeneficiaryActions,
  BeneficiaryActionTypes.CREATE_BENEFICIARY_TRIGGER
>;

const addBeneficiaryOrShowErrorsForFailure = (
  offer: OfferDto,
  insuredPersonId: string,
  person: Person
): Array<BeneficiaryActions | BeneficiaryManagementActions | PersonsActions> =>
  person
    ? [
        personsActions.addPerson(person),
        beneficiaryManagementActions.setEditedPerson(insuredPersonId!, person),
        beneficiaryActions.createBeneficiary.success({
          offer,
          insuredPersonId,
        }),
      ]
    : [
        beneficiaryActions.createBeneficiary.success({
          offer,
          insuredPersonId,
        }),
      ];

export const onCreateBeneficiaryRequest: Epic<
  BeneficiaryActions | BeneficiaryManagementActions | PersonsActions,
  BeneficiaryActions | BeneficiaryManagementActions | PersonsActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(BeneficiaryActionTypes.CREATE_BENEFICIARY_TRIGGER),
    mergeMap((action) => {
      const businessId = offerSelectors.getBusinessId(state$.value);
      const { insuredPersonId } = (action as CreateBeneficiaryTrigger).payload;

      return makeApiCall({
        method: 'post',
        url: buildEndpoint(ENDPOINTS.addBeneficiary, { businessId, insuredPersonId }),
        body: {},
      }).pipe(
        mergeMap((data) => {
          const {
            response: { offer, person },
          } = data;
          return addBeneficiaryOrShowErrorsForFailure(offer, insuredPersonId, person);
        }),
        catchError((error: AjaxError) =>
          of(
            beneficiaryActions.createBeneficiary.failure(error, {
              insuredPersonId,
            })
          )
        )
      );
    })
  );

type RemoveBeneficiariesTrigger = ActionByType<
  BeneficiaryActions,
  BeneficiaryActionTypes.REMOVE_BENEFICIARIES_TRIGGER
>;

export const onDeleteBeneficiariesRequest: Epic<
  BeneficiaryActions | BeneficiaryManagementActions | PersonsActions | OfferActions,
  BeneficiaryActions | BeneficiaryManagementActions | PersonsActions | OfferActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(BeneficiaryActionTypes.REMOVE_BENEFICIARIES_TRIGGER),
    mergeMap((action) => {
      const businessId = offerSelectors.getBusinessId(state$.value);
      const { personId } = (action as RemoveBeneficiariesTrigger).payload;

      return makeApiCall({
        method: 'delete',
        url: buildEndpoint(ENDPOINTS.deleteBeneficiaries, { businessId, personId }),
      }).pipe(
        mergeMap((data) => {
          const { response } = data;
          const personIds = personsSelectors.getPersonIds(state$.value);
          const personIdsInOffer = uniq(map(response, 'personId'));
          const personIdsToRemove = difference(personIds, personIdsInOffer);
          const arrayOfActions = personIdsToRemove.map((insuredPersonId: string) =>
            personsActions.removePerson.success({ personId: insuredPersonId })
          );

          return [...arrayOfActions, beneficiaryActions.removeBeneficiaries.success({ personId })];
        }),
        catchError((error: AjaxError) => of(beneficiaryActions.removeBeneficiaries.failure(error)))
      );
    })
  );

type UpdateBeneficiaryShareTrigger = ActionByType<
  BeneficiaryActions,
  BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_TRIGGER
>;

export const onUpdateBeneficiaryShareRequest: Epic<
  BeneficiaryActions | BeneficiaryManagementActions | PersonsActions | OfferActions,
  BeneficiaryActions | BeneficiaryManagementActions | PersonsActions | OfferActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_TRIGGER),
    mergeMap((action) => {
      const businessId = offerSelectors.getBusinessId(state$.value);
      const { personId, share } = (action as UpdateBeneficiaryShareTrigger).payload;

      return makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateBeneficiaryShare, { businessId, personId }),
        body: { share },
      }).pipe(
        mergeMap((res: AjaxResponse) => [
          beneficiaryActions.updateBeneficiarySuccess(res.response.person),
          beneficiaryActions.updateBeneficiaryShare.success(res.response),
        ]),
        catchError((error: AjaxError) =>
          of(beneficiaryActions.updateBeneficiaryShare.failure(error))
        )
      );
    })
  );

export const beneficiaryEpics = combineEpics(
  onCreateBeneficiaryRequest,
  onDeleteBeneficiariesRequest,
  onUpdateBeneficiaryShareRequest
);
