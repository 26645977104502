import * as React from 'react';
import { ElementTypes, InteractiveSectionComponent, Section, ValuesMap } from '../models';
import {
    SectionBackground,
    StyledConfirmationCheckbox,
    StyledConfirmationRowWrapper,
    StyledFormRow,
    StyledH4,
    StyledRadioGroupRow
} from './CssOverrides';
import Checkbox from '@eg/elements/Checkbox';
import Radio from '@eg/elements/Radio';

export interface SectionComponentProps {
    section: Section;
    values?: ValuesMap;
    disabled?: boolean;
    onValueChangedHandler?: (key: string, value: string) => void;
}

export default class SectionComponent extends React.Component<SectionComponentProps, {}> {
    render() {
        const { section } = this.props;
        return this._backgroundWrapper(section);
    }

    // tslint:disable-next-line:no-any
    private _handleCheckChange(e: React.FormEvent<HTMLInputElement>) {
        if (this.props.onValueChangedHandler) {
            this.props.onValueChangedHandler(e.currentTarget.name, String(e.currentTarget.checked));
        }
    }

    private _handleRadioChange(e: React.FormEvent<HTMLInputElement>) {
        if (this.props.onValueChangedHandler) {
            this.props.onValueChangedHandler(e.currentTarget.name, e.currentTarget.value);
        }
    }

    private _backgroundWrapper = (section: Section) => {
        return <SectionBackground>{this._renderElements(section)}</SectionBackground>;
    };

    private _renderElements(section: Section) {
        switch (section.elementType) {
            case ElementTypes.MULTISELECT: {
                return this._renderMultiselect(section);
            }
            case ElementTypes.SELECT: {
                return this._renderSelect(section);
            }
            case ElementTypes.CONFIRMATION: {
                return this._renderConfirmation(section);
            }
            case ElementTypes.TEXTBLOCKHEADER: {
                return this._renderTextblockheader(section);
            }
            default:
                throw new Error(section.heading.display + ': UNKNOWN ELEMENT TYPE >' + section.elementType + '<');
        }
    }

    private _renderMultiselect(section: Section) {
        if (!section.elements) {
            return;
        }
        return (
            <StyledFormRow label={section.heading.display} description={section.hint && section.hint.display}>
                {section.elements.map(element => (
                    <Checkbox
                        data-test-id={this._generateDataId('CHECKBOX', element.backendName)}
                        disabled={this.props.disabled}
                        key={`${element.backendName}-${element.display}`}
                        label={element.display}
                        value={element.display}
                        name={element.backendName}
                        defaultChecked={this.props.values && this.props.values.get(element.backendName) === 'true'}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => this._handleCheckChange(e)}
                    />
                ))}
            </StyledFormRow>
        );
    }

    private _renderSelect(section: Section) {
        if (!section.elements) {
            return;
        }

        let selected;
        section.elements.forEach(ele => {
            if (this.props.values) {
                selected = String(this.props.values.get(ele.backendName));
            }
        });
        // tslint:disable-next-line:no-any
        const elements: Array<any> = [];

        section.elements.forEach(ele =>
            elements.push(
                <Radio
                    data-test-id={this._generateDataId('RADIO', ele.backendName + '_' + ele.allowedValue)}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => this._handleRadioChange(e)}
                    key={`${ele.display}-${ele.backendName}`}
                    label={ele.display}
                    disabled={this.props.disabled}
                    value={ele.allowedValue || ele.display}
                />
            )
        );

        return (
            <StyledRadioGroupRow
                label={section.heading.display}
                description={section.hint && section.hint.display}
                name={section.elements[0].backendName}
                defaultValue={'' + selected}
            >
                {elements}
            </StyledRadioGroupRow>
        );
    }

    private _renderConfirmation(section: Section) {
        if (!section.elements) {
            return;
        }
        const confirmationCheckbox = section.elements[0];
        return (
            <StyledConfirmationRowWrapper
                label={this._renderConfirmationCheckbox(confirmationCheckbox, section.heading.display)}
                description={section.hint && section.hint.display}
            />
        );
    }

    private _renderConfirmationCheckbox(element: InteractiveSectionComponent, label: string) {
        return (
            <StyledConfirmationCheckbox
                data-test-id={this._generateDataId('CHECKBOX', element.backendName)}
                key={`${element.display}-${element.backendName}`}
                label={label}
                value={element.backendName}
                name={element.backendName}
                disabled={this.props.disabled}
                defaultChecked={this.props.values && this.props.values.get(element.backendName) === 'true'}
                onChange={(e: React.FormEvent<HTMLInputElement>) => this._handleCheckChange(e)}
            />
        );
    }

    private _renderTextblockheader(section: Section) {
        return <StyledH4>{section.heading.display}</StyledH4>;
    }

    private _generateDataId(elementType: string, elementName: string) {
        return elementType + '_' + elementName + '_IDD';
    }
}
