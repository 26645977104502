import React, { useState, useCallback, useEffect } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import Input from '@eg/elements/Input';
import { ReplacementContractProps } from './ReplacementContract.types';
import { Field } from 'components';
import './ReplacementContract.scss';
import BaseSelect from '@eg/elements/Select';
import { CurrencyInput } from '../../../ComparisonTable/components/fields/Currency/components';
import {
  CURRENT_CONTRACT_PREMIUM_REGEX,
  PAYMENT_PERIOD_OPTIONS,
  POLICY_NUMBER_REGEX,
  TEXTS,
} from './ReplacementContract.const';

export const ReplacementContract = ({
  hasData,
  isEnabled,
  isLoading,
  policyNumber,
  currentContractPremium,
  paymentMethod,
  setEnabled,
  deleteReplacementContract,
  updateReplacementContract,
}: ReplacementContractProps) => {
  const [policyNumberState, setPolicyNumberState] = useState(policyNumber);
  const [isPolicyNumberTouchedState, setIsPolicyNumberTouchedState] = useState(false);
  const [policyNumberErrorMessage, setPolicyNumberErrorMessage] = useState('');

  const [currentContractPremiumState, setCurrentContractPremiumState] =
    useState(currentContractPremium);
  const [isCurrentContractPremiumTouchedState, setIsCurrentContractPremiumTouchedState] =
    useState(false);
  const [currentContractPremiumErrorMessage, setCurrentContractPremiumErrorMessage] = useState('');

  const [paymentMethodState, setPaymentMethodState] = useState(paymentMethod);
  const [isPaymentMethodTouchedState, setIsPaymentMethodTouchedState] = useState(false);

  useEffect(() => {
    setPolicyNumberState(policyNumber);
  }, [policyNumber]);

  useEffect(() => {
    setPaymentMethodState(paymentMethod);
    setIsPolicyNumberTouchedState(false);
  }, [paymentMethod]);

  useEffect(() => {
    setCurrentContractPremiumState(currentContractPremium);
    setIsCurrentContractPremiumTouchedState(false);
  }, [currentContractPremium]);

  const onCheckboxChangeHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;
      setEnabled(isChecked);

      if (!isChecked && hasData) {
        deleteReplacementContract();
      }
    },
    [hasData, setEnabled]
  );

  const onChangePolicyNumberHandler = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.toUpperCase();

    setPolicyNumberState(value);
    setIsPolicyNumberTouchedState(false);

    const isValid = POLICY_NUMBER_REGEX.test(value);
    const validationMessage = !isValid
      ? TEXTS.REPLACEMENT_CONTRACT.POLICY_NUMBER.ERROR_MESSAGE
      : '';

    setPolicyNumberErrorMessage(validationMessage);
  }, []);

  const onBlurPolicyNumberHandler = useCallback(() => {
    if (!isPolicyNumberTouchedState) {
      setIsPolicyNumberTouchedState(true);
    }

    submitForm();
  }, [isPolicyNumberTouchedState, policyNumberState]);

  const onChangeCurrentContractPremiumHandler = useCallback((value: string | undefined) => {
    value = value ? value : '';

    setCurrentContractPremiumState(value);
    setIsCurrentContractPremiumTouchedState(false);

    const isValid = CURRENT_CONTRACT_PREMIUM_REGEX.test(value);
    const validationMessage = !isValid
      ? TEXTS.REPLACEMENT_CONTRACT.CURRENT_CONTRACT_PREMIUM.ERROR_MESSAGE
      : '';

    setCurrentContractPremiumErrorMessage(validationMessage);
  }, []);

  const onBlurCurrentContractPremiumHandler = useCallback(() => {
    if (!isCurrentContractPremiumTouchedState) {
      setIsCurrentContractPremiumTouchedState(true);
    }

    submitForm();
  }, [isCurrentContractPremiumTouchedState, currentContractPremiumState]);

  const submitForm = useCallback(() => {
    if (
      policyNumber === policyNumberState &&
      currentContractPremium === currentContractPremiumState &&
      paymentMethod === paymentMethodState
    ) {
      return;
    }

    const isPolicyNumberValid = Boolean(policyNumberState) && !Boolean(policyNumberErrorMessage);
    const isCurrentContractPremiumStateValid =
      Boolean(currentContractPremiumState) && !Boolean(currentContractPremiumErrorMessage);
    const isPaymentMethodValid = Boolean(paymentMethodState);

    const isSubmittable =
      isPolicyNumberValid && isCurrentContractPremiumStateValid && isPaymentMethodValid;

    if (isSubmittable) {
      const formattedCurrentContractPremiumState = currentContractPremiumState.replace(',', '.');

      updateReplacementContract(
        policyNumberState,
        formattedCurrentContractPremiumState,
        paymentMethodState
      );
    }
  }, [policyNumberState, currentContractPremiumState, paymentMethodState]);

  const onChangePaymentMethodHandler = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setPaymentMethodState(event.currentTarget.value);
      setIsPaymentMethodTouchedState(false);
    },
    [policyNumberState, currentContractPremiumState, paymentMethodState, updateReplacementContract]
  );

  const onBlurPaymentMethodHandler = useCallback(() => {
    if (!isPolicyNumberTouchedState) {
      setIsPaymentMethodTouchedState(true);
    }

    submitForm();
  }, [isPaymentMethodTouchedState, paymentMethodState]);

  const policyNumberErrors = policyNumberState === '' ? [] : [policyNumberErrorMessage];
  const currentContractPremiumErrors =
    currentContractPremiumState === '' ? [] : [currentContractPremiumErrorMessage];

  return (
    <div className='replacement-contract esc_grid__wrapper'>
      <div className='replacement-contract__checkbox esc_col esc_col-4'>
        <Field>
          <Checkbox
            onChange={onCheckboxChangeHandler}
            checked={isEnabled}
            disabled={isLoading}
            label={TEXTS.REPLACEMENT_CONTRACT.CHECKBOX.LABEL}
          />
        </Field>
      </div>

      {isEnabled && (
        <div className='replacement-contract__data esc_col esc_col-8'>
          <Field
            label={TEXTS.REPLACEMENT_CONTRACT.POLICY_NUMBER.LABEL}
            required={isPolicyNumberTouchedState}
            errors={policyNumberErrors}
          >
            <Input
              type='text'
              disabled={isLoading}
              required={isEnabled}
              value={policyNumberState}
              onChange={onChangePolicyNumberHandler}
              onBlur={onBlurPolicyNumberHandler}
            />
          </Field>

          <Field
            label={TEXTS.REPLACEMENT_CONTRACT.CURRENT_CONTRACT_PREMIUM.LABEL}
            required={isCurrentContractPremiumTouchedState}
            errors={currentContractPremiumErrors}
          >
            <CurrencyInput
              value={currentContractPremiumState}
              inputStateValue={currentContractPremiumState}
              disabled={isLoading}
              forceDecimals
              isDecimalStep
              onValueChange={onChangeCurrentContractPremiumHandler}
              onBlur={onBlurCurrentContractPremiumHandler}
              stepRange={2}
            />
          </Field>

          <Field
            label={TEXTS.REPLACEMENT_CONTRACT.PAYMENT_METHOD.LABEL}
            required={isPaymentMethodTouchedState}
          >
            <BaseSelect
              disabled={isLoading}
              required={isEnabled}
              value={paymentMethodState}
              onChange={onChangePaymentMethodHandler}
              onBlur={onBlurPaymentMethodHandler}
            >
              {!paymentMethod && (
                <option key={TEXTS.DEFAULT_SELECTED_OPTION.KEY} value={''}>
                  {TEXTS.DEFAULT_SELECTED_OPTION.LABEL}
                </option>
              )}

              {PAYMENT_PERIOD_OPTIONS.map((paymentPeriod) => (
                <option key={paymentPeriod.value} value={paymentPeriod.value}>
                  {paymentPeriod.label}
                </option>
              ))}
            </BaseSelect>
          </Field>
        </div>
      )}
    </div>
  );
};
