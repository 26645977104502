import { PersonProfession } from 'entities/persons';
import { buildEndpoint, ENDPOINTS } from 'services/endpoint.builder.service';
import { makeApiCall } from 'services';
import { map } from 'rxjs/operators';

const getSearchProfessionUrl = (searchProfession: string) =>
  buildEndpoint(ENDPOINTS.searchProfession, {
    searchProfession: encodeURI(searchProfession.trim()),
  });

export const fetchProfessions = async (search: string): Promise<PersonProfession[]> => {
  const response: PersonProfession[] = await makeApiCall({
    url: getSearchProfessionUrl(search),
  })
    .pipe(map(({ response }) => response))
    .toPromise();

  return response;
};
