import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { beneficiaryActions } from '../../../state/beneficiary.actions';
import { beneficiaryManagementSelectors } from '../../state/beneficiary.management.selectors';
import { InheritShareComponent } from './inherit-share.component';
import {
  InheritShareDispatchProps,
  InheritShareOwnProps,
  InheritShareStateProps,
} from './inherit-share.types';

const mapStateToProps = (
  state: RootState,
  ownProps: InheritShareOwnProps
): InheritShareStateProps => ({
  isLoading: offerSelectors.isLoading(state),
  editedPerson: beneficiaryManagementSelectors.editedPerson(state, ownProps.insuredPersonId)!,
});

const mapDispatchToProps = (dispatch: Dispatch): InheritShareDispatchProps => ({
  updateBeneficiaryShare: (personId: string, share: number) =>
    dispatch(beneficiaryActions.updateBeneficiaryShare.trigger({ personId, share })),
});

export const InheritShare = connect(mapStateToProps, mapDispatchToProps)(InheritShareComponent);
