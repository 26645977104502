import React, { useState, useEffect } from 'react';
import { DatePicker } from '@eg/elements/DatePicker';
import { format, parse } from 'date-fns';
import { DatePickerProps } from './DatePicker.types';
import { EmptyField, Field } from 'components';
import './DatePicker.css';
import { offerHelper } from 'entities/offer';

export const DatePickerComponent: React.FC<DatePickerProps> = ({
  value = '',
  required = false,
  corrupted,
  disabled,
  errors,
  minDate,
  maxDate,
  setCorrupted,
  updateVariant,
  field,
  personId,
  variantId,
}: DatePickerProps) => {
  const fieldId = offerHelper.generateComponsedKeyForFrontendValidationByComposedId(
    personId,
    variantId,
    field
  );
  const [selectedDate, setSelectedDate] = useState<Date | string>(
    value && parse(value, 'yyyy-MM-dd', new Date())
  );

  useEffect(() => {
    if (corrupted && value) {
      setSelectedDate(parse(value, 'yyyy-MM-dd', new Date()));
      setCorrupted(false);
    }
  }, [corrupted, setCorrupted, value]);

  useEffect(() => {
    if (value) {
      setSelectedDate(parse(value, 'yyyy-MM-dd', new Date()));
    }
  }, [value]);

  if (!value) {
    return <EmptyField />;
  }

  return (
    <Field className='date-picker' errors={errors} required={required} id={fieldId}>
      <DatePicker
        disabled={disabled}
        valueDate={selectedDate}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        onChange={(e, date) => {
          if (date && date instanceof Date) {
            setSelectedDate(date);
            updateVariant(format(date, 'yyyy-MM-dd'));
          }
        }}
      />
    </Field>
  );
};
