import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { PaymentData as PaymentDataComponent } from './PaymentData';
import { paymentDataActions, paymentDataSelectors } from '../../entities/paymentData';

import { PaymentDataStateProps, PaymentDataDispatchProps } from './PaymentData.types';
import { RootState } from '../../store/store.interface';
import { UpdatePaymentDataDto } from '../../entities/paymentData/payment.data.types';

const mapStateToProps = (state: RootState): PaymentDataStateProps => ({
  hasError: paymentDataSelectors.hasError(state),
  iban: paymentDataSelectors.getIban(state),
  isLoading: paymentDataSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch): PaymentDataDispatchProps => ({
  updateIban: (iban: string) =>
    dispatch(paymentDataActions.updatePaymentData.trigger({ iban } as UpdatePaymentDataDto)),
});

export const PaymentDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentDataComponent);
