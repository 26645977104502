import { Dispatch } from 'redux';
import type { OutputSelector } from 'reselect';
import { FieldsProps, FieldDispatchProps } from './Fields.types';
import { RootState } from 'store/store.interface';
import { GenericFieldValue, offerActions, TableFields, VariantField } from 'entities/offer';

type Selector = (
  personId: string,
  variantId: string,
  fieldId: TableFields
) => OutputSelector<any, any, (res: VariantField) => any>;

type FieldSelector<Field> = (field: Field) => any;

export const makeFieldMapState =
  <S extends Selector = Selector>(selector: S) =>
  (fieldSelector: FieldSelector<ReturnType<ReturnType<S>>>) =>
  (state: RootState, ownProps: FieldsProps<TableFields>) => {
    const { personId, variantId, field } = ownProps;
    const fieldSelectorInstance = selector(personId!, variantId!, field);

    return (state: RootState) => fieldSelector(fieldSelectorInstance(state as never));
  };

export const mapDispatchToProps = <F extends GenericFieldValue = GenericFieldValue>(
  dispatch: Dispatch
): FieldDispatchProps<F> => ({
  setCorrupted: (personId: string, variantId: string, fieldId: TableFields, isCorrupted: boolean) =>
    dispatch(offerActions.setFieldCorrupted(personId, variantId, fieldId, isCorrupted)),
  updateVariant: (
    personId: string,
    variantId: string,
    fieldId: TableFields,
    value: GenericFieldValue
  ) =>
    dispatch(
      offerActions.updateVariants({
        personId,
        variantId,
        fieldId,
        value,
      })
    ),
});
