import React from 'react';

import { Field } from 'components';
import { StaticProps } from './Static.types';
import { offerHelper } from 'entities/offer';

export const Static: React.FC<StaticProps> = ({
  value = '',
  label = '',
  errors,
  field,
  personId,
  variantId,
}: StaticProps) => {
  const fieldId = offerHelper.generateComponsedKeyForFrontendValidationByComposedId(
    personId,
    variantId,
    field
  );
  return (
    <Field errors={errors} id={fieldId}>
      <>{`${value} ${label}`.trim()}</>
    </Field>
  );
};
