import * as React from 'react';
import styled from 'styled-components';

export interface HeadWrapperProps {
    className?: string;
    children?: React.ReactChild;
}

const HeadWrapper: React.StatelessComponent<HeadWrapperProps> = props => (
    <div className={props.className}>{props.children}</div>
);

// tslint:disable-next-line:no-any
export const FormHeadWrapper: any = styled(HeadWrapper)``;

export interface LegendWrapperProps {
    className?: string;
    children?: React.ReactChild;
}

const FormLegendWrapperUnstyled: React.StatelessComponent<LegendWrapperProps> = props => (
    <div className={props.className}>{props.children}</div>
);

// tslint:disable-next-line:no-any
export const FormLegendWrapper: any = styled(FormLegendWrapperUnstyled)``;

export interface FormWrapperProps {
    className?: string;
    children: React.ReactNode;
}

// tslint:disable-next-line:no-any
export const FormWrapper: React.StatelessComponent<FormWrapperProps> = props => (
    <div className={props.className} id="idd-form-wrapper">
        {props.children}
    </div>
);

export default FormWrapper;
