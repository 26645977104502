import { connect } from 'react-redux';
import { PackagesTable as PackagesTableComponent } from './PackagesTable';
import { PackagesTableOwnProps, PackagesTableStateProps } from './PackagesTable.types';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';

const mapStateToProps = (
  state: RootState,
  ownProps: PackagesTableOwnProps
): PackagesTableStateProps => ({
  fieldsIds: offerSelectors.getCommonFieldsIdsForPackages(state, ownProps.personId),
  isOfferLoading: offerSelectors.isLoading(state),
  mapOfPackageIdByComposedId: offerSelectors.getMapOfPackageIdByComposedId(state),
  variantsIds: offerSelectors.getVariantsIds(state),
});

export const PackagesTable = connect(mapStateToProps)(PackagesTableComponent);
