import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import BaseSelect from '@eg/elements/Select';

import { SelectProps } from './Select.types';
import { Field, EmptyField } from 'components';
import { offerHelper } from 'entities/offer';

export const Select: React.FC<SelectProps> = ({
  disabled,
  corrupted,
  errors,
  mandatory,
  options = [],
  setCorrupted,
  updateVariant,
  value,
  field,
  personId,
  variantId,
}) => {
  const fieldId = offerHelper.generateComponsedKeyForFrontendValidationByComposedId(
    personId,
    variantId,
    field
  );
  const [selectedValue, setSelectedValue] = useState(value);
  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const {
        target: { value: eventValue },
      } = event;
      setSelectedValue(eventValue);
      updateVariant(eventValue);
    },
    [setSelectedValue, updateVariant]
  );

  useEffect(() => {
    if (corrupted) {
      setSelectedValue(value);
      setCorrupted(false);
    }
  }, [corrupted, value, setCorrupted]);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Field errors={errors} required={mandatory} id={fieldId}>
      {options.length ? (
        <BaseSelect value={selectedValue} disabled={disabled} onChange={onChangeHandler}>
          {!selectedValue && <option disabled value='' />}

          {options.map(({ label, value: optionValue }) => (
            <option key={`baseSelect.${label}.${optionValue}`} value={optionValue}>
              {label}
            </option>
          ))}
        </BaseSelect>
      ) : (
        <>
          <EmptyField />
        </>
      )}
    </Field>
  );
};
