import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { offerActions, offerSelectors, ShippingWayTypes } from 'entities/offer';
import { personsSelectors } from 'entities/persons';
import { RootState } from 'store/store.interface';
import { ShippingWay as ShippingWayComponent } from './ShippingWay';
import { ShippingWayStateProps, ShippingWayDispatchProps } from './ShippingWay.types';

const mapStateToProps = (state: RootState): ShippingWayStateProps => ({
  email: personsSelectors.getInsuranceOwnerEmail(state),
  isLoading: offerSelectors.isLoading(state),
  type: offerSelectors.getShippingWay(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ShippingWayDispatchProps => ({
  updateShippingWay: (type: ShippingWayTypes | null, email: string | null) =>
    dispatch(
      offerActions.updateShippingWay.trigger({
        type,
        email,
      })
    ),
});

export const ShippingWayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingWayComponent);
