import React from 'react';
import Button from '@eg/elements/Button';
import OrdersIcon from '@eg/elements/Icons/OrdersIcon';
import { CopyButtonProps } from './CopyButton.types';
import styles from './CopyButton.module.scss';

export const CopyButton: React.FC<CopyButtonProps> = ({
  isDisabled,
  isHidden,
  copyFieldsOfFirstInsuredPerson,
  personId,
  errorMessage,
}) => {
  const onClick = () => {
    copyFieldsOfFirstInsuredPerson(personId);
  };

  if (isHidden) return null;

  return (
    <div className={styles.container}>
      <Button onClick={onClick} variant='text-link' iconLeft={OrdersIcon} disabled={isDisabled}>
        Leistungen der 1. versicherten Person übernehmen
      </Button>
      <div className={styles.errorMessage}>{errorMessage}</div>
    </div>
  );
};
