import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import FormSection from '@eg/elements/FormSection';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import Radio from '@eg/elements/Radio';
import { Loading } from 'components';
import { ShippingWayProps } from './ShippingWay.types';
import { ShippingWayTypes } from 'entities/offer';
import { Details } from '@eg/elements/Details';

export const ShippingWay: React.FC<ShippingWayProps> = ({
  email,
  isLoading,
  updateShippingWay,
  type,
}: ShippingWayProps) => {
  const [shippingWayValue, setShippingWayValue] = useState<ShippingWayTypes | string>(type || '');
  const onChangeShippingWay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const shippingWayType = event.target.value as ShippingWayTypes;
      const emailToSend = email || null;
      setShippingWayValue(shippingWayType);
      updateShippingWay(shippingWayType, emailToSend);
    },
    [email, updateShippingWay]
  );

  useEffect(() => {
    if (!isLoading) {
      setShippingWayValue(type || '');
    }
  }, [isLoading, setShippingWayValue, type]);

  return (
    <Details className='comparison-table__details' summary='Versandart' isInitiallyOpen>
      <FormSection>
        <Loading loading={isLoading}>
          <RadioGroupRow
            label='Versandsweg des schriftlichen Angebots'
            name='shippingWay'
            value={shippingWayValue}
            onChange={onChangeShippingWay}
          >
            <Radio value={ShippingWayTypes.EMAIL} label={ShippingWayTypes.EMAIL} />
            <Radio value={ShippingWayTypes.POST} label={ShippingWayTypes.POST} />
          </RadioGroupRow>
        </Loading>
      </FormSection>
    </Details>
  );
};
