import { connect } from 'react-redux';
import { StickySidebar as StickySidebarComponent } from './StickySidebar';
import { RootState } from 'store/store.interface';
import { offerSelectors } from 'entities/offer';
import { StickySidebarProps } from './StickySidebar.types';

const mapStateToProps = (state: RootState): StickySidebarProps => ({
  isProcessing: offerSelectors.isProcessing(state),
});

export const StickySidebar = connect(mapStateToProps)(StickySidebarComponent);
