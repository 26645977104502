import React from 'react';
import { get } from 'lodash';
import { offerHelper, PackageFields } from 'entities/offer';
import { EmptyField } from 'components';
import { packagesConfig } from './PackagesTable.config';
import { PackagesTableProps } from './PackagesTable.types';
import { Table } from '../Table';
import './PackagesTable.css';
import { CopyButton } from './components';

export const PackagesTable: React.FC<PackagesTableProps> = ({
  fieldsIds,
  isOfferLoading,
  mapOfPackageIdByComposedId,
  personId,
  variantsIds,
}: PackagesTableProps) => {
  if (!variantsIds.length || !fieldsIds.length) {
    return null;
  }

  return (
    <div className='packages-table'>
      <Table>
        <Table.Labels>
          {fieldsIds.map((fieldId: PackageFields) => {
            if (!packagesConfig.hasOwnProperty(fieldId)) {
              return null;
            }

            const labelProps = get(packagesConfig, [fieldId, 'label']);
            return (
              <Table.Label key={`packages.table.label.${personId}.${fieldId}`} {...labelProps} />
            );
          })}
        </Table.Labels>
        {variantsIds.map((variantId: string) => (
          <Table.Variant
            key={`table.variant.${personId}.${variantId}`}
            personId={personId}
            variantId={variantId}
            loading={isOfferLoading}
          >
            {fieldsIds.map((fieldId: PackageFields) => {
              const hasFieldConfigured = packagesConfig.hasOwnProperty(fieldId);
              const hasPackageData = get(
                mapOfPackageIdByComposedId,
                offerHelper.generateComponsedKeyForPackagesByIds(personId, variantId, fieldId)
              );
              if (!hasFieldConfigured || !hasPackageData) {
                return (
                  <EmptyField
                    key={`packages.table.component.${personId}.${variantId}.${fieldId}`}
                  />
                );
              }

              const { Component, props } = get(packagesConfig, [fieldId, 'component']);
              return (
                <Component
                  key={`packages.table.component.${personId}.${variantId}.${fieldId}`}
                  {...props}
                />
              );
            })}
          </Table.Variant>
        ))}
      </Table>
      <CopyButton personId={personId} />
    </div>
  );
};
