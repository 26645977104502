import { useMemo } from 'react';

export const useFormatCurrency = <Value extends string>(value: Value) => {
  return useMemo(() => {
    const asFloat = parseFloat(value);

    if (isNaN(asFloat)) {
      return '- €';
    }

    const roundedFloat = Math.round(asFloat * 100) / 100;
    return `${new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
    }).format(roundedFloat)} €`;
  }, [value]);
};
