import { combineEpics, Epic, ofType } from 'redux-observable';
import { filter, map } from 'rxjs/operators';
import { PersonsActions, PersonsActionTypes, personsSelectors } from 'entities/persons';

import { ActionByType } from 'services';
import { RootState } from 'store/store.interface';
import { personManagementActions, PersonManagementActions } from './person.management.actions';
import { personManagementSelectors } from './person.management.selectors';

type RemovePersonSuccess = ActionByType<PersonsActions, PersonsActionTypes.REMOVE_PERSON_SUCCESS>;

export const onDeletePersonSuccess: Epic<
  PersonsActions | PersonManagementActions,
  PersonsActions | PersonManagementActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(PersonsActionTypes.REMOVE_PERSON_SUCCESS),
    filter((action) => {
      const personId = (action as RemovePersonSuccess).payload.personId;
      const editedPerson = personManagementSelectors.editedPerson(state$.value);
      return editedPerson?.personId === personId;
    }),
    map((action) => {
      const personId = (action as RemovePersonSuccess).payload.personId;
      const [firstPersonIdToSelect] = personsSelectors
        .getPersonIds(state$.value)
        .filter((pId) => pId !== personId);

      const selectedPerson = personsSelectors.getPersonById(state$.value, firstPersonIdToSelect);

      return personManagementActions.setEditedPerson(selectedPerson);
    })
  );

export const personManagementEpics = combineEpics(onDeletePersonSuccess);
