import { createSelector } from 'reselect';
import { RootState } from 'store/store.interface';

const error = (state: RootState) => state.entities.contractDetails.error;

const hasError = (state: RootState) => error(state) !== null;

const isLoading = (state: RootState) => state.entities.contractDetails.loading;

const getReplacementRequestNumber = (state: RootState) =>
  state.entities.contractDetails.replacementRequest.replacementRequestNumber;

const isReplacementRequestEnabled = (state: RootState) =>
  state.entities.contractDetails.replacementRequest.enabled;

const hasReplacementRequestData = createSelector(
  getReplacementRequestNumber,
  (replacementRequestNumber) => !!replacementRequestNumber
);

const getAssociationOptions = (state: RootState) =>
  state.entities.contractDetails.associationMember.association.options || [];

const getAssociationSuggestions = createSelector(getAssociationOptions, (options) =>
  options.map((option) => `${option.name}`)
);

const getSelectedAssociationNr = (state: RootState) =>
  state.entities.contractDetails.associationMember.association.selectedNr;

const getSelectedAssociationName = createSelector(
  getSelectedAssociationNr,
  getAssociationOptions,
  (associationNr, options) => options.find((option) => option.nr === associationNr)?.name || ''
);

const getMembershipNumber = (state: RootState) =>
  state.entities.contractDetails.associationMember.membershipNumber;

const isAssociationMembershipEnabled = (state: RootState) =>
  state.entities.contractDetails.associationMember.enabled;

const hasAssociationMemberData = createSelector(
  getSelectedAssociationNr,
  getMembershipNumber,
  (selectedAssociationNr, membershipNumber) => !!selectedAssociationNr && !!membershipNumber
);

const isReplacementContractEnabled = (state: RootState) =>
  state.entities.contractDetails.replacementContract.enabled;

const getSelectedPolicyNumber = (state: RootState) =>
  state.entities.contractDetails.replacementContract.policyNumber;

const getSelectedCurrentContractPremium = (state: RootState) =>
  state.entities.contractDetails.replacementContract.currentContractPremium;

const getSelectedPaymentMethod = (state: RootState) =>
  state.entities.contractDetails.replacementContract.paymentMethod;

const hasReplacementContractData = createSelector(
  getSelectedPolicyNumber,
  getSelectedCurrentContractPremium,
  getSelectedPaymentMethod,
  (policyNumber, currentContractPremium, paymentMethod) =>
    Boolean(paymentMethod) && Boolean(currentContractPremium) && Boolean(paymentMethod)
);

const hasEnabledAnyCheckbox = createSelector(
  isAssociationMembershipEnabled,
  isReplacementRequestEnabled,
  isReplacementContractEnabled,
  (isAMEnabled, isRREnabled, isRCEnabled) => isAMEnabled || isRREnabled || isRCEnabled
);

export const contractDetailsSelectors = {
  error,
  getAssociationOptions,
  getAssociationSuggestions,
  getMembershipNumber,
  getReplacementRequestNumber,
  getSelectedAssociationNr,
  getSelectedAssociationName,
  hasAssociationMemberData,
  hasEnabledAnyCheckbox,
  hasError,
  hasReplacementRequestData,
  isLoading,
  isAssociationMembershipEnabled,
  isReplacementRequestEnabled,
  isReplacementContractEnabled,
  getSelectedPolicyNumber,
  getSelectedCurrentContractPremium,
  getSelectedPaymentMethod,
  hasReplacementContractData,
};
