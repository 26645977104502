import { isNil } from 'lodash';
import React, { ReactElement } from 'react';

interface InputFieldProps<Value> {
  value?: Value;
  checked?: boolean;
  valueDate?: Date;
}

export const useFieldValidation = <Value>(element: React.ReactNode): boolean => {
  if (React.isValidElement(element)) {
    const { value, valueDate, checked } = (element as ReactElement).props as InputFieldProps<Value>;

    return !isNil(value || valueDate || checked);
  }

  return false;
};
