import * as React from 'react';

import MessageBox from '@eg/elements/MessageBox';

export interface ValidationErrorComponentProps {
    validationErrors: Array<string> | null;
}
const ValidationErrorComponent = (props: ValidationErrorComponentProps) => {
    return (
        <MessageBox type={'error'}>
            <ul>
                {props.validationErrors &&
                    props.validationErrors.map(validationError => {
                        return <li key={validationError}> {validationError} </li>;
                    })}
            </ul>
        </MessageBox>
    );
};

export default ValidationErrorComponent;
