import React, { useCallback } from 'react';
import CheckBox from '@eg/elements/Checkbox';

import { CheckboxProps } from './Checkbox.types';
import { useFieldValue } from '../../hooks';
import { Field } from 'components';
import './Checkbox.css';
import { offerHelper } from 'entities/offer';

export const Checkbox: React.FC<CheckboxProps> = ({
  corrupted,
  disabled,
  errors,
  mandatory,
  label,
  updateVariant,
  value,
  personId,
  variantId,
  field,
}: CheckboxProps) => {
  const fieldId = offerHelper.generateComponsedKeyForFrontendValidationByComposedId(
    personId,
    variantId,
    field
  );
  const { selectedValue, setSelectedValue } = useFieldValue(
    personId!,
    variantId!,
    field,
    value,
    corrupted
  );
  const checkboxChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateVariant(event.target.checked);
      setSelectedValue(event.target.checked);
    },
    [setSelectedValue, updateVariant]
  );

  return (
    <div className='checkbox'>
      <Field errors={errors} required={mandatory} id={fieldId}>
        <CheckBox
          disabled={disabled}
          checked={selectedValue}
          onChange={checkboxChangeHandler}
          label={label}
        />
      </Field>
    </div>
  );
};
