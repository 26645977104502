import React from 'react';

export interface TooltipWrapperProps {
  tooltipUuid: string;
  showTooltip: boolean;
  children: React.ReactNode;
  tooltipMessage: string;
  tooltipType: TooltipType;
  tooltipPlace?: TooltipPlace;
  effect?: TooltipEffect;
}

export enum TooltipPlace {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export enum TooltipType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

export enum TooltipEffect {
  FLOAT = 'float',
  SOLID = 'solid',
}
