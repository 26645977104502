import { connect } from 'react-redux';
import { offerSelectors } from 'entities/offer';
import { getInsuredPersons } from 'entities/persons';
import { RootState } from 'store/store.interface';
import { ComparisonTableComponent } from './ComparisonTable.component';
import { ComparisonTableStateProps } from './ComparisonTable.types';

const mapStateToProps = (state: RootState): ComparisonTableStateProps => ({
  persons: getInsuredPersons(state),
  hasPersonErrors: (personId: string) =>
    offerSelectors.hasPersonTableErrorMessages(state, personId),
});

export const ComparisonTable = connect(mapStateToProps)(ComparisonTableComponent);
