import React from 'react';
import { SelectedApprovalClausesProps } from './SelectedApprovalClauses.types';
import { DeletableChip } from '../DeletableChip';

export const SelectedApprovalClausesComponent = ({
  approvalClausesObject,
  removeApprovalClause,
  selectedApprovalOptions,
  isDeleting,
  currentlyDeletedApprovalClause,
}: SelectedApprovalClausesProps) => {
  const getLabelByApprovalClause = (approvalClause: string) =>
    approvalClausesObject?.get(approvalClause);

  return (
    <div>
      {selectedApprovalOptions &&
        selectedApprovalOptions.map((item) => (
          <DeletableChip
            onDelete={(approvalClause) => {
              removeApprovalClause(approvalClause);
            }}
            value={item}
            isBeingDeleted={isDeleting && currentlyDeletedApprovalClause === item}
            isDeleteActionBlocked={isDeleting}
            key={item}
          >
            {getLabelByApprovalClause(item)}
          </DeletableChip>
        ))}
    </div>
  );
};
