import React from 'react';

import { Table } from './Table';
import { TableItems, VariantProps } from './Table.types';

export const useTableLayout = (children: React.ReactNode) => {
  const tableChildren = React.Children.toArray(children);

  return tableChildren.reduce(
    (tableItems: TableItems, child) => {
      if (!React.isValidElement(child)) {
        return tableItems;
      }

      if (child.type === Table.Labels) {
        tableItems[0] = child;
      }

      if (child.type === Table.Variant) {
        const { personId, variantId, loading, label, children } = child.props as VariantProps & {
          children: React.ReactElement;
        };

        const variantItems = React.Children.map(children, (child) =>
          React.cloneElement(child, {
            personId,
            variantId,
            ...child.props,
            // Variant props that will overwrite Field specific ones
            ...(loading ? { disabled: true } : {}),
          })
        );

        tableItems[1].push({
          personId,
          variantId,
          label,
          elements: variantItems,
        });
      }

      return tableItems;
    },
    [null, []]
  );
};
