import React, { useState, useCallback, useEffect } from 'react';
import Checkbox from '@eg/elements/Checkbox';
import Input from '@eg/elements/Input';
import { Field } from 'components';
import { ReplacementRequestProps } from './ReplacementRequest.types';
import './ReplacementRequest.scss';

export const ReplacementRequest: React.FC<ReplacementRequestProps> = ({
  deleteReplacementRequest,
  hasData,
  isEnabled,
  isLoading,
  replacementRequestNumber,
  setEnabled,
  updateReplacementRequest,
}) => {
  const [isReplacementRequestNumberTouchedState, setIsReplacementRequestNumberTouchedState] =
    useState<boolean>(false);
  const [replacementRequestNumberState, setReplacementRequestNumberState] =
    useState<string>(replacementRequestNumber);

  useEffect(() => {
    setReplacementRequestNumberState(replacementRequestNumber);
  }, [replacementRequestNumber]);

  const onCheckboxChangeHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;
      setEnabled(isChecked);

      if (!isChecked && hasData) {
        setIsReplacementRequestNumberTouchedState(false);
        deleteReplacementRequest();
      }
    },
    [deleteReplacementRequest, hasData, setEnabled]
  );

  const onBlurHandler = useCallback(() => {
    if (!isReplacementRequestNumberTouchedState) {
      setIsReplacementRequestNumberTouchedState(true);
    }

    const isSubmitable =
      !!replacementRequestNumberState && replacementRequestNumberState !== replacementRequestNumber;

    if (isSubmitable) {
      updateReplacementRequest(replacementRequestNumberState);
    }
  }, [
    isReplacementRequestNumberTouchedState,
    replacementRequestNumber,
    replacementRequestNumberState,
    updateReplacementRequest,
  ]);

  const onChangeHandler = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setReplacementRequestNumberState(event.currentTarget.value);
    setIsReplacementRequestNumberTouchedState(false);
  }, []);

  return (
    <div className='replacement-request esc_grid__wrapper'>
      <div className='replacement-request__checkbox esc_col esc_col-4'>
        <Field>
          <Checkbox
            checked={isEnabled}
            disabled={isLoading}
            label='Ersatzantrag'
            onChange={onCheckboxChangeHandler}
          />
        </Field>
      </div>

      {isEnabled && (
        <div className='replacement-request__data esc_col esc_col-8'>
          <Field label='SV' required={isReplacementRequestNumberTouchedState}>
            <Input
              type='text'
              disabled={isLoading}
              required={isEnabled}
              value={replacementRequestNumberState}
              onBlur={onBlurHandler}
              onChange={onChangeHandler}
            />
          </Field>
          <Field>
            <div className='esc_col esc_col-4' />
          </Field>
        </div>
      )}
    </div>
  );
};
