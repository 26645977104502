import { AppActionsTypes, FetchInitSuccess } from '../app';
import { PaymentDataActions, PaymentDataTypes } from './payment.data.actions';
import { PaymentDataReducer } from './payment.data.types';

const defaultState: Readonly<PaymentDataReducer> = {
  id: '',
  iban: '',
  accountId: '',
  loading: false,
  error: null,
};

export const paymentDataReducer = (
  state: PaymentDataReducer = defaultState,
  action: PaymentDataActions | FetchInitSuccess
) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_INIT_SUCCESS:
      return {
        ...state,
        ...action.payload.init.paymentData,
      };

    case PaymentDataTypes.DELETE_PAYMENT_DATA_TRIGGER:
      return {
        ...state,
        loading: true,
      };

    case PaymentDataTypes.UPDATE_PAYMENT_DATA_TRIGGER:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case PaymentDataTypes.DELETE_PAYMENT_DATA_SUCCESS:
      return {
        ...defaultState,
      };

    case PaymentDataTypes.UPDATE_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: false,
      };

    case PaymentDataTypes.DELETE_PAYMENT_DATA_ERROR:
    case PaymentDataTypes.UPDATE_PAYMENT_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return { ...state };
  }
};
