import { combineEpics } from 'redux-observable';
import { paymentDataEpics } from 'entities/paymentData/payment.data.epic';
import { personManagementEpics } from 'features/PersonManagement/state/person.management.epic';
import { personsEpics } from 'entities/persons/persons.epic';
import appEpics from 'entities/app/app.epics';
import contractDetailsEpics from 'entities/contractDetails/contract.details.epics';
import offerEpics from 'entities/offer/offer.epics';
import orderEpics from 'entities/order/order.epics';
import { beneficiaryManagementEpics } from 'features/ComparisonTable/components/Beneficiary/BeneficairyManagement/state/beneficiary.management.epic';
import { beneficiaryEpics } from 'features/ComparisonTable/components/Beneficiary/state/beneficiary.epic';
import { unauthorizedModalEpics } from 'entities/unauthorizedModal/unauthorizedModal.epics';

export const rootEpic = combineEpics(
  appEpics,
  contractDetailsEpics,
  offerEpics,
  orderEpics,
  paymentDataEpics,
  personManagementEpics,
  beneficiaryManagementEpics,
  personsEpics,
  beneficiaryEpics,
  unauthorizedModalEpics
);
