import React, { useCallback, useEffect, useState } from 'react';
import { CurrencyInput } from './components';
import { CurrencyProps } from './Currency.types';
import { EmptyField, Field } from 'components';
import { useFormatCurrency } from '../../hooks';
import { offerHelper } from 'entities/offer';

const toTwoDecimalPlaces = (value: string): string => {
  const asFloat = parseFloat(value);
  return isNaN(asFloat) ? '' : asFloat.toFixed(2).replace('.', ',');
};

export const Currency: React.FC<CurrencyProps> = ({
  corrupted,
  disabled,
  errors,
  forceDecimals = false,
  mandatory,
  min,
  max,
  readOnly = false,
  setCorrupted,
  stepRange,
  updateVariant,
  value,
  fieldPackage,
  field,
  personId,
  variantId,
}: CurrencyProps) => {
  const fieldId = offerHelper.generateComponsedKeyForFrontendValidationByComposedId(
    personId,
    variantId,
    field
  );
  const [inputStateValue, setInputStateValue] = useState<string>(value);
  const formattedValue = useFormatCurrency(value);
  const isDecimalStep = !!stepRange && stepRange >= 0.01 && stepRange < 1;
  const stringValueWithValidNumberFormat = inputStateValue.replaceAll('.', '').replace(',', '.');
  const isRequired =
    (fieldPackage?.mandatory && mandatory) || (fieldPackage?.selected && mandatory);
  const allowEmptyValue = fieldPackage?.selected && !fieldPackage?.mandatory;

  const onBlurHandler = useCallback(() => {
    const parsedValue = inputStateValue.replace(',', '.');
    updateVariant(parsedValue);
  }, [inputStateValue, updateVariant]);

  const onValueChangeHandler = useCallback((value?: string) => {
    setInputStateValue(value || '');
  }, []);

  useEffect(() => {
    const updatedValue = isDecimalStep || forceDecimals ? toTwoDecimalPlaces(value) : value;
    setInputStateValue(updatedValue);
  }, [forceDecimals, isDecimalStep, value]);

  useEffect(() => {
    if (corrupted) {
      const updatedValue = isDecimalStep || forceDecimals ? toTwoDecimalPlaces(value) : value;
      setInputStateValue(updatedValue);
      setCorrupted(false);
    }
  }, [corrupted, forceDecimals, isDecimalStep, setCorrupted, value]);

  return (
    <Field
      errors={errors}
      {...(!readOnly && { min, max, stepRange, required: isRequired, allowEmptyValue })}
      id={fieldId}
    >
      {readOnly ? (
        <>{formattedValue || <EmptyField />}</>
      ) : (
        <CurrencyInput
          value={stringValueWithValidNumberFormat}
          inputStateValue={inputStateValue}
          disabled={disabled}
          forceDecimals={forceDecimals}
          isDecimalStep={isDecimalStep}
          onValueChange={onValueChangeHandler}
          onBlur={onBlurHandler}
          stepRange={stepRange}
        />
      )}
    </Field>
  );
};
