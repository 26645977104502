import React from 'react';
import ReactCurrencyInputField from 'react-currency-input-field';
import { CurrencyInputProps } from './CurrencyInput.type';
import './CurrencyInput.scss';

export const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
  const {
    disabled,
    forceDecimals,
    inputStateValue,
    isDecimalStep,
    onBlur,
    onValueChange,
    stepRange,
  } = props;

  return (
    <div className='currency-input'>
      <ReactCurrencyInputField
        className='currency-input__input'
        value={inputStateValue}
        disabled={disabled}
        allowDecimals={isDecimalStep || forceDecimals}
        onValueChange={onValueChange}
        allowNegativeValue={false}
        decimalSeparator={','}
        groupSeparator={'.'}
        onBlur={onBlur}
        disableAbbreviations={true}
        {...(stepRange && { step: stepRange })}
        {...((isDecimalStep || forceDecimals) && { decimalScale: 2 })}
      />
      <span className='currency-input__currency-symbol'>€</span>
    </div>
  );
};
