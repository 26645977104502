import React from 'react';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { ConfirmationModalProps } from './ConfirmationModal.type';
import styles from './ConfirmationModal.module.scss';

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onConfirm,
  onCancel,
  children,
}) => {
  return (
    <Modal dismissible backdropDismissesModal onDismiss={onCancel} open={open}>
      <div className={styles.ConfirmationModalWrapper}>
        <div className={styles.ConfirmationModalLabel}>{children}</div>
        <div className={styles.ConfirmationModalControls}>
          <Button onClick={onConfirm}>Ja</Button>
          <Button onClick={onCancel}>Nein</Button>
        </div>
      </div>
    </Modal>
  );
};
