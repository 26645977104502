import React, { useState, useEffect } from 'react';
import { DatePickerRow as ErgoDatePickerRow } from '@eg/elements/DatePickerRow';
import { format, parse } from 'date-fns';
import { DatePickerRowProps, DATE_FORMAT, DEFAULT_MIN_DATE } from './DatePickerRow.types';

export const DatePickerRow: React.FC<DatePickerRowProps> = ({
  name,
  label,
  placeholder,
  value = '',
  disabled = false,
  errors = [],
  minDate = '',
  maxDate = '',
  onChange,
}) => {
  const parseValueToDate = (value: string) =>
    value ? parse(value, DATE_FORMAT, new Date()) : undefined;
  const initMinDate = (date: string): Date => (date ? new Date(date) : new Date(DEFAULT_MIN_DATE));
  const initMaxDate = (date: string): Date | undefined => (date ? new Date(date) : undefined);

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(parseValueToDate(value));
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (value) {
      setSelectedDate(parseValueToDate(value));
    }
  }, [value]);

  useEffect(() => {
    if (errors?.length > 0) {
      setError(errors[0]);
    }
  }, [errors]);

  return (
    <ErgoDatePickerRow
      id={name}
      label={label}
      placeholder={placeholder}
      valueDate={selectedDate}
      minDate={initMinDate(minDate)}
      maxDate={initMaxDate(maxDate)}
      disabled={disabled}
      error={error}
      onChange={(evt, date) => {
        setError('');
        if (date && date instanceof Date) {
          setSelectedDate(date);
          onChange(format(date, DATE_FORMAT));
        }
      }}
    />
  );
};
