import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { offerActions, offerSelectors, UpdateFrontendValidation } from '../../entities/offer';
import { Field as FieldComponent } from './Field';
import { RootState } from '../../store/store.interface';
import { FieldDispatchProps, FieldStateProps } from './Fields.types';

const mapStateToProps = (state: RootState): FieldStateProps => ({
  frontendValidationsByComposedId: offerSelectors.getFrontendValidationsByComposedId(state),
});

const mapDispatchToProps = (dispatch: Dispatch): FieldDispatchProps => ({
  updateFrontendValidation: (frontendValidationsByComposedId: UpdateFrontendValidation) =>
    dispatch(offerActions.updateFrontendValidation(frontendValidationsByComposedId)),
});

export const Field = connect(mapStateToProps, mapDispatchToProps)(FieldComponent);
