export enum MessageCodes {
  AdresseFehlt = 'ADRESSE_FEHLT',
  AdresseUngueltig = 'ADRESSE_UNGUELTIG',
  CheckAlleDaHinweiseGelesen = 'error.KompositAngebotsVariante.checkAlleDaHinweiseGelesen',
  CheckBeratungsverzichtZugestimmt = 'error.KompositAngebot.checkBeratungsverzichtZugestimmt',
  CheckIddIdVorhandenBeiAbschluss = 'error.KompositAngebot.checkIddIdVorhandenBeiAbschluss',
  CheckMindestensEinmalRolleVaVorhanden = 'error.UnfallAngebot.checkMindestensEinmalRolleVaVorhanden',
  CheckPaymentStoreIdVorhandenBeiDa = 'error.KompositAngebot.checkPaymentStoreIdVorhandenBeiDA',
  CheckPdsDatenValide = 'error.KompositAngebot.checkPdsDatenValide',
  CheckVarianteGewaehlt = 'error.KompositAngebot.checkVarianteGewaehlt',
  KeineEmailVorhanden = 'KEINE_EMAIL_VORHANDEN',
}

export enum MessageSeverities {
  error = 'ERROR',
  warning = 'WARNING',
}

export interface MessageDto {
  code: MessageCodes;
  text: string;
  severity: MessageSeverities;
  personsId: string[];
  attributes: string[];
}

export type Message = MessageDto;
