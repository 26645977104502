import styles from '../Submit/Submit.module.scss';
import Button from '@eg/elements/Button';
import React from 'react';
import { LABEL } from './CancelButton.const';
import { CancelButtonProps } from './CancelButton.types';

export const CancelButton: React.FC<CancelButtonProps> = ({ selectedVariant, cancelRequest }) => {
  const onClick = () => cancelRequest(selectedVariant?.id);

  return (
    <div className={styles.Submit}>
      <div className={styles.SubmitFinalizeBtn}>
        <Button colorScheme='inverted' variant='secondary' size='large' onClick={onClick}>
          {LABEL}
        </Button>
      </div>
    </div>
  );
};
