export enum AppLoaderActionTypes {
  IS_LOADING = 'app-loader/IS_LOADING',
}

const isLoading = (isVisible: boolean) => ({
  type: AppLoaderActionTypes.IS_LOADING,
  payload: {
    isVisible,
  },
});

export const appLoaderActions = {
  isLoading,
};
