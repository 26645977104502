import { AnyAction } from 'redux';
import { ContractDetailsActionsTypes } from './contract.details.actions';
import { ContractDetailsReducer } from './contract.details.types';
import { AppActionsTypes } from '../app';

const defaultState: Readonly<ContractDetailsReducer> = {
  error: null,
  loading: false,
  associationMember: {
    enabled: false,
    association: {
      selectedNr: null,
      options: [],
    },
    membershipNumber: '',
  },
  replacementRequest: {
    enabled: false,
    replacementRequestNumber: '',
  },
  replacementContract: {
    enabled: false,
    policyNumber: '',
    currentContractPremium: '',
    paymentMethod: '',
  },
};

export const contractDetailsReducer = (
  state: ContractDetailsReducer = defaultState,
  action: AnyAction
) => {
  switch (action.type) {
    case ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_TRIGGER:
    case ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_TRIGGER:
    case ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_TRIGGER:
    case ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_TRIGGER:
      return {
        ...state,
        loading: true,
      };

    case ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_FAILURE:
    case ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_FAILURE:
    case ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_FAILURE:
    case ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case AppActionsTypes.FETCH_INIT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        associationMember: {
          enabled: false,
          ...action.payload.init.offer.contractDetails.associationMember,
        },
        replacementContract: {
          enabled: false,
          ...action.payload.init.offer.contractDetails.replacementContract,
        },
      };

    case ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        associationMember: {
          ...state.associationMember,
          association: {
            ...state.associationMember.association,
            ...action.payload.contractDetails.associationMember.association,
          },
          membershipNumber: action.payload.contractDetails.associationMember.membershipNumber,
        },
      };

    case ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        associationMember: {
          enabled: false,
          association: {
            selectedNr: null,
            options: state.associationMember.association.options,
          },
          membershipNumber: '',
        },
      };

    case ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        replacementContract: {
          ...state.replacementContract,
          policyNumber: action.payload.contractDetails.replacementContract.policyNumber,
          currentContractPremium:
            action.payload.contractDetails.replacementContract.currentContractPremium,
          paymentMethod: action.payload.contractDetails.replacementContract.paymentMethod,
        },
      };
    case ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
        replacementContract: {
          enabled: false,
          policyNumber: '',
          currentContractPremium: '',
          paymentMethod: '',
        },
      };

    case ContractDetailsActionsTypes.SET_ASSOCIATION_MEMBER_ENABLED:
      return {
        ...state,
        loading: false,
        associationMember: {
          ...state.associationMember,
          enabled: action.payload.enabled,
        },
      };

    case ContractDetailsActionsTypes.SET_REPLACEMENT_REQUEST_ENABLED:
      return {
        ...state,
        loading: false,
        replacementRequest: {
          ...state.replacementRequest,
          enabled: action.payload.enabled,
        },
      };

    case ContractDetailsActionsTypes.SET_REPLACEMENT_CONTRACT_ENABLED:
      return {
        ...state,
        loading: false,
        replacementContract: {
          ...state.replacementContract,
          enabled: action.payload.enabled,
        },
      };
    default:
      return state;
  }
};
