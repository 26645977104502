import { Person } from 'entities/persons';
import { ActionsUnion, createAction } from 'services';

export enum PersonManagementActionTypes {
  SET_EDITED_PERSON = '[ui][person.management] SET_EDITED_PERSON',
}

const setEditedPerson = (editedPerson: Person) =>
  createAction(PersonManagementActionTypes.SET_EDITED_PERSON, { editedPerson });

export const personManagementActions = {
  setEditedPerson,
};

export type PersonManagementActions = ActionsUnion<typeof personManagementActions>;
