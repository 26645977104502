import { OrderActionsTypes, OrderActions } from './order.actions';
import { OrderReducer } from './order.types';

const defaultState: Readonly<OrderReducer> = {
  error: null,
  finalized: false,
  loading: false,
  messages: [],
};

export const orderReducer = (state: OrderReducer = defaultState, action: OrderActions) => {
  switch (action.type) {
    case OrderActionsTypes.FINALIZE:
      return {
        ...state,
        loading: true,
      };

    case OrderActionsTypes.FINALIZE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        messages: action.payload.messages,
      };

    case OrderActionsTypes.FINALIZE_SUCCESS:
      return {
        ...state,
        error: '',
        finalized: action.payload.finalized,
        loading: false,
        messages: action.payload.messages,
      };

    default:
      return state;
  }
};
