import { AnyAction } from 'redux';
import { AgencyDto, AgencyReducer } from './agency.interface';
import { AppActionsTypes } from '../app';

export const defaultState: AgencyReducer = {
  oeNr: '',
  agentName: '',
};

export const agencyReducer = (state: AgencyReducer = defaultState, action: AnyAction) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_INIT_SUCCESS:
      const { oeNr, agentName = '' } = action.payload.init.agency as AgencyDto;
      return {
        ...state,
        oeNr,
        agentName,
      };
    default:
      return state;
  }
};
