import { Person, ValidationStatus } from 'entities/persons';

export enum PersonalDataEvent {
  ON_LOAD = 'onLoad',
  ON_UPDATE = 'onUpdate',
  ON_CHANGE = 'onChange',
  ON_ERROR = 'onError',
}

export interface PersonalDataValidationError {
  errorMessage: string;
}

export interface PersonalDataOnLoadEventDetail {
  person: Person;
  isValid: boolean;
}

export interface PersonalDataOnUpdateEventDetail {
  person: Person;
  errors: PersonalDataValidationError[];
  backendValidationErrors: string[];
  isValid: boolean;
  pdsValidationStatus: ValidationStatus;
}

export interface PersonalDataOnChangeEventDetail {
  person: Person;
  isValid: boolean;
}

export interface PersonalDataOnErrorEventDetail {
  error: Error;
  person: Person;
}
