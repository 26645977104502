import React from 'react';
import Button from '@eg/elements/Button';
import PlusIcon from '@eg/elements/components/Icons/PlusIcon';
import { CreatePersonProps } from './CreatePerson.types';

export const CreatePerson: React.FC<CreatePersonProps> = ({
  disabled,
  canAddMorePersons,
  createPerson,
}) => (
  <Button
    iconLeft={PlusIcon}
    variant='text-link'
    size='large'
    onClick={createPerson}
    disabled={!canAddMorePersons || disabled}
  >
    Person hinzufügen
  </Button>
);
