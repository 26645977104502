import { partial } from 'lodash';
import { connect } from 'react-redux';
import { offerSelectors, TableFields } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { mapDispatchToProps } from '../fields.helpers';
import { DatePickerComponent } from './DatePicker';
import {
  DatePickerDispatchProps,
  DatePickerMergeProps,
  DatePickerOwnProps,
  DatePickerStateProps,
} from './DatePicker.types';

const makeFieldMapState = (
  state: RootState,
  { personId, variantId, field }: DatePickerOwnProps<TableFields>
) => {
  const valueSelector = offerSelectors.getFieldValue(personId!, variantId!, field);
  const minSelector = offerSelectors.getFieldMin(personId!, variantId!, field);
  const maxSelector = offerSelectors.getFieldMax(personId!, variantId!, field);
  const errorsSelector = offerSelectors.getFieldErrors(personId!, variantId!, field);
  const disabledSelector = offerSelectors.getFieldDisabled(personId!, variantId!, field);
  const corruptedSelector = offerSelectors.getFieldCorrupted(personId!, variantId!, field);
  return (state: RootState): DatePickerStateProps => ({
    value: valueSelector(state),
    minDate: minSelector(state),
    maxDate: maxSelector(state),
    errors: errorsSelector(state),
    disabled: disabledSelector(state),
    corrupted: corruptedSelector(state),
  });
};

const mergeProps = (
  stateProps: DatePickerStateProps,
  dispatchProps: DatePickerDispatchProps,
  ownProps: DatePickerOwnProps
): DatePickerMergeProps => {
  const { setCorrupted, updateVariant, ...restDispatchProps } = dispatchProps;

  return {
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    setCorrupted: partial(
      setCorrupted,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
    updateVariant: partial(
      updateVariant,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
  };
};

export const DatePicker = connect(
  makeFieldMapState,
  mapDispatchToProps,
  mergeProps
)(DatePickerComponent);
