import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import Details from '@eg/elements/Details';
import Checkbox from '@eg/elements/Checkbox';
import Chip from '@eg/elements/Chip';
import parseISO from 'date-fns/parseISO';
import addYears from 'date-fns/addYears';
import isFuture from 'date-fns/isFuture';
import classNames from 'classnames';
import { BeneficiaryProps } from './Beneficiary.types';
import { BeneficiaryManagement } from './BeneficairyManagement';
import './Beneficiary.scss';
import { InheritShare } from './BeneficairyManagement/components/InheritShare/Inherit-share.container';
import { ShareChip } from './BeneficairyManagement/components/shareShip/shareChip';

const MAX_TOTAL_SHARE = 100;

const personIsFullAge = (date: string): boolean => {
  if (!date) return false;
  const parseDate = parseISO(date);
  const newDate = addYears(parseDate, 18);
  return !isFuture(newDate);
};

export const BeneficiaryComponent: React.FC<BeneficiaryProps> = ({
  personId,
  personBirthDate,
  isDirectOffer,
  hasDeathBenefit,
  hasPersonBeneficiaries,
  removeBeneficiaries,
  totalShare,
}) => {
  const [isCheckedValue, setIsCheckedValue] = useState<boolean>(false);

  const onCheckboxChangeHandler = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;
      if (!isChecked && hasPersonBeneficiaries) {
        removeBeneficiaries(personId);
      }
      setIsCheckedValue(isChecked);
    },
    [hasPersonBeneficiaries, personId, removeBeneficiaries]
  );

  useEffect(() => {
    setIsCheckedValue(hasPersonBeneficiaries);
  }, [hasPersonBeneficiaries]);

  if (!isDirectOffer || !personIsFullAge(personBirthDate) || !hasDeathBenefit) {
    return null;
  }

  return (
    <Details
      className='beneficiary'
      key={`beneficiary-${personId}`}
      summary='Bezugsberechtigte Person'
    >
      <Checkbox
        checked={isCheckedValue}
        onChange={onCheckboxChangeHandler}
        label='Jemand anderes soll die Todesfallleistung erhalten?'
      />
      {isCheckedValue && (
        <div className='beneficiary-management__wrapper'>
          <BeneficiaryManagement
            insuredPersonId={personId}
            additionalFormFields={<InheritShare insuredPersonId={personId} />}
            personHeaderInfo={(personShare: number) => <ShareChip share={personShare} />}
          />
          <div className='beneficiary-management__share-wrapper'>
            <Chip
              backgroundColor=''
              className={classNames('chip', {
                default: !hasPersonBeneficiaries,
                valid: totalShare < MAX_TOTAL_SHARE && hasPersonBeneficiaries,
                error: totalShare > MAX_TOTAL_SHARE,
                success: totalShare === MAX_TOTAL_SHARE,
              })}
            >
              {totalShare} von 100%
            </Chip>{' '}
            der Bezugsrechte verteilt.
          </div>
        </div>
      )}
    </Details>
  );
};
