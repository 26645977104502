import { connect } from 'react-redux';
import { Errors as ErrorsComponent } from './Errors';
import { ErrorsStateProps } from './Errors.types';
import { getAllErrorMessages, hasErrors } from './Errors.selectors';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState): ErrorsStateProps => ({
  errors: getAllErrorMessages(state),
  hasErrors: hasErrors(state),
});

export const Errors = connect(mapStateToProps)(ErrorsComponent);
