import { flow, get, isNumber, isString, partial } from 'lodash';
import { AnyAction } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { concat, EMPTY, from, of, throwError } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import {
  buffer,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { ActionByType, makeApiCall, buildEndpoint, ENDPOINTS } from 'services';

import { RootActions, RootState } from 'store/store.interface';
import { SalesProcessTypes, VariantsNames } from './offer.interface';
import {
  PersonRole,
  personsActions,
  PersonsActionTypes,
  personsSelectors,
  UpdatePerson,
} from '../persons';
import { OfferActions, offerActions, OfferActionsTypes, UpdateBirthdate } from './offer.actions';
import { offerHelper } from './offer.helper';
import { offerSelectors } from './offer.selectors';
import { offerService } from './offer.service';
import {
  GenericFieldValue,
  OfferDto,
  OfferFields,
  RoleAction,
  ShippingWayTypes,
  TableFields,
  UpdatePersonsFieldsMap,
} from './offer.types';

export const onUpdateOfferBasicDataRequest: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_OFFER_BASIC_DATA),
    mergeMap((action) => {
      const updateOfferPayload = [
        {
          fieldId: action.payload.fieldName,
          value: action.payload.value,
          personId: action.payload.personId,
        },
        ...extendByShippingEmailForWrittenOffer(
          action.payload.fieldName,
          action.payload.value,
          state$.value
        ),
      ];

      return from(
        makeApiCall({
          method: 'put',
          url: buildEndpoint(ENDPOINTS.updateBasicOfferFields),
          body: updateOfferPayload,
        })
      ).pipe(
        map((res: AjaxResponse) =>
          offerActions.updateOfferBasicDataSuccess(res.response, updateOfferPayload)
        ),
        catchError((error) =>
          of(offerActions.updateOfferBasicDataFailure(error, updateOfferPayload))
        )
      );
    })
  );

// TODO: Remove in favor of new setSalesProcess method
// payload should contain salesProcess and email
const extendByShippingEmailForWrittenOffer = (
  fieldName: string,
  value: string,
  state: RootState
) => {
  const email = personsSelectors.getInsuranceOwnerEmail(state);
  return fieldName === OfferFields.salesProcess && value === SalesProcessTypes.VOLLANGEBOT && email
    ? [
        {
          fieldId: 'shippingEmail',
          value: personsSelectors.getInsuranceOwnerEmail(state),
          personId: '',
        },
      ]
    : [];
};

export const onEmailUpdateOfVN: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(PersonsActionTypes.UPDATE_PERSON),
    filter(
      (action) =>
        personsSelectors.getInsuranceOwnerId(state$.value) === get(action.payload, 'personId')
    ),
    distinctUntilChanged(
      (previousAction, currentAction) =>
        get(previousAction.payload, 'personalEmail') === get(currentAction.payload, 'personalEmail')
    ),
    map((action) => {
      const email = get(action.payload, 'personalEmail');
      const emailToSend = !email ? null : email;
      const typeToSend = !email
        ? ShippingWayTypes.POST
        : offerSelectors.getShippingWay(state$.value) ?? null;

      return offerActions.updateShippingWay.trigger({
        type: typeToSend,
        email: emailToSend,
      });
    })
  );

const onUpdateSalutation: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(PersonsActionTypes.UPDATE_PERSON),
    mergeMap((action) =>
      offerService.onUpdatePersonSalutation(action as UpdatePerson, state$.value)
    )
  );

const onUpdatePersonAndUpdateOffer: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(PersonsActionTypes.UPDATE_PERSON_AND_UPDATE_OFFER),
    filter((action) => !!action.payload.birthDate),
    map((action) =>
      offerActions.updateBirthdate.trigger({
        personId: action.payload.personId,
        birthdate: action.payload.birthDate,
      })
    )
  );

const onUpdateBirthdateSuccess: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_BIRTHDATE_SUCCESS),
    mergeMap((action) =>
      offerService.onUpdateBirthdateSuccessSideEffects(action as UpdateBirthdate, state$.value)
    )
  );

const onUpdateBirthdateRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_BIRTHDATE_TRIGGER),
    mergeMap(({ payload }) =>
      makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateBirthdate, { personId: payload.personId }),
        body: {
          birthdate: payload.birthdate,
        },
      }).pipe(
        map((data) =>
          offerActions.updateBirthdate.success(data.response, {
            personId: payload.personId,
            birthdate: payload.birthdate,
          })
        ),
        catchError((error) => of(offerActions.updateBirthdate.failure(error)))
      )
    )
  );

export const onInsuranceOwnerChangeResetShippingWay: Epic<RootActions, RootActions, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(OfferActionsTypes.TOGGLE_PERSON_ROLE),
    filter(
      (action) =>
        get(action.payload, 'personId') !== personsSelectors.getInsuranceOwnerId(state$.value) &&
        get(action.payload, 'role') === PersonRole.INSURANCE_OWNER &&
        offerSelectors.isPaperOffer(state$.value)
    ),
    map(() =>
      offerActions.updateShippingWay.trigger({
        type: null,
        email: null,
      })
    )
  );

export const onFetchOfferRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.FETCH_OFFER),
    mergeMap(() =>
      makeApiCall({
        method: 'get',
        url: buildEndpoint(ENDPOINTS.fetchOffer),
      }).pipe(
        map(({ response: offer }) => offerActions.fetchOfferSuccess(offer as OfferDto)),
        catchError((error) => of(offerActions.fetchOfferFailure(error)))
      )
    )
  );

export const onSelectVariantRequest: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.SELECT_VARIANT),
    mergeMap((action) => {
      const { variantId, field: fieldId, value } = action.payload;
      const selectedVariantId = offerSelectors.getSelectedVariantId(state$.value);
      const updateVariantsPayload = [
        {
          personId: '',
          variantId,
          packageId: '',
          fieldId,
          value,
        },
      ];

      if (selectedVariantId) {
        updateVariantsPayload.push({
          personId: '',
          variantId: selectedVariantId,
          packageId: '',
          fieldId,
          value: false,
        });
      }

      return from(
        makeApiCall({
          method: 'put',
          url: buildEndpoint(ENDPOINTS.selectVariant),
          body: updateVariantsPayload,
        })
      ).pipe(
        map((res: AjaxResponse) => offerActions.selectVariantSuccess(res.response)),
        catchError((error) => of(offerActions.selectVariantFailure(error, updateVariantsPayload)))
      );
    })
  );

export const onUpdateVariantsRequest: Epic<any, any, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_VARIANTS),
    filter((action) => offerService.supressEmptyValuesMandatoryFields(action, state$.value)),
    filter((action) => offerService.supressInvalidRangeNotEmptyFields(action, state$.value)),
    filter((action) => offerService.supressInvalidStepRangeNotEmptyFields(action, state$.value)),
    buffer(action$.pipe(ofType(OfferActionsTypes.UPDATE_VARIANTS), debounceTime(1500))),
    switchMap((actions: RootActions[]) => {
      const fieldsToUpdateByPersonId = actions.reduce(
        (acc: UpdatePersonsFieldsMap, action: AnyAction) => {
          const { personId, variantId, fieldId, value } = action.payload.fieldsToUpdateByPersonId;

          const valueFromStore = offerSelectors.getFieldValue(
            personId,
            variantId,
            fieldId
          )(state$.value);
          const valueToSend =
            isNumber(valueFromStore) && isString(value) ? parseFloat(value) : value;
          const isSameValue = valueFromStore === valueToSend;
          const isExistingChange = get(acc, [personId, variantId], {}).hasOwnProperty(fieldId);

          if (isSameValue) {
            if (!isExistingChange) {
              return acc;
            }

            return flow(
              partial(
                offerHelper.removeVariantField,
                personId,
                VariantsNames.ERGO_UNFALLSCHUTZ,
                fieldId
              ),
              partial(
                offerHelper.removeVariantField,
                personId,
                VariantsNames.ERGO_UNFALLSCHUTZ_PREMIUM,
                fieldId
              ),
              partial(offerHelper.removeEmptyVariant, personId, VariantsNames.ERGO_UNFALLSCHUTZ),
              partial(
                offerHelper.removeEmptyVariant,
                personId,
                VariantsNames.ERGO_UNFALLSCHUTZ_PREMIUM
              ),
              partial(offerHelper.removeEmptyPerson, personId)
            )(acc);
          }

          if (!acc.hasOwnProperty(personId)) {
            acc[personId] = {};
          }

          const fields = {
            ...get(acc, [personId, variantId], {}),
            [fieldId]: valueToSend,
          };

          // Variant synchronisation
          acc[personId][VariantsNames.ERGO_UNFALLSCHUTZ] = fields;
          acc[personId][VariantsNames.ERGO_UNFALLSCHUTZ_PREMIUM] = fields;

          return acc;
        },
        {}
      );

      const fieldsToUpdateByPersonIdToDto = offerService.fieldsToUpdateByPersonIdToDto(
        state$.value,
        fieldsToUpdateByPersonId
      );

      if (!fieldsToUpdateByPersonIdToDto.length) {
        return EMPTY;
      }

      const extendedPayloadWithState = offerSelectors.getUpdateVariantsPayload(
        state$.value,
        fieldsToUpdateByPersonIdToDto
      );

      return concat(
        of(offerActions.setLoading(true)),
        from(
          makeApiCall({
            method: 'put',
            url: buildEndpoint(ENDPOINTS.updateVariants),
            body: extendedPayloadWithState,
          })
        ).pipe(
          map((res: AjaxResponse) => offerActions.updateVariantsSuccess(res.response)),
          catchError((error) =>
            of(offerActions.updateVariantsFailure(error, fieldsToUpdateByPersonIdToDto))
          )
        )
      );
    })
  );

export const onTogglePersonRole: Epic<OfferActions, OfferActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.TOGGLE_PERSON_ROLE),
    map((action) => {
      type UpdatePersonRoleActionType = ActionByType<
        OfferActions,
        OfferActionsTypes.TOGGLE_PERSON_ROLE
      >;
      const { personId, role, roleAction } = (action as UpdatePersonRoleActionType).payload;

      if (roleAction === RoleAction.SELECTION) {
        return offerActions.addRole.trigger({
          personId,
          role,
        });
      }

      return offerActions.deleteRole.trigger({
        personId,
        role,
      });
    })
  );

export const onCopyFieldsOfFirstInsuredPerson: Epic<RootActions, RootActions, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType(OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_TRIGGER),
    mergeMap((action) => {
      const personId = action.payload as string;
      const firstInsuredPersonId = personsSelectors.getFirstInsuredPersonId(state$.value);

      if (!firstInsuredPersonId) return throwError('The offer does not include the insured person');

      const fieldIds = offerSelectors.getCommonFieldsIdsForPackages(
        state$.value,
        firstInsuredPersonId
      );
      const variantIds = offerSelectors.getVariantsIds(state$.value);

      const getFieldsByVariantId = (variantId: string) =>
        fieldIds.reduce((acc: Record<TableFields, GenericFieldValue>, fieldId) => {
          return {
            ...acc,
            [fieldId]: offerSelectors.getFieldValue(
              firstInsuredPersonId,
              variantId,
              fieldId
            )(state$.value),
          };
        }, {} as Record<TableFields, GenericFieldValue>);

      const fieldsToUpdateByPersonId = variantIds.reduce(
        (acc: UpdatePersonsFieldsMap, variantId) => {
          return {
            ...acc,
            [personId]: {
              ...acc[personId],
              [variantId]: getFieldsByVariantId(variantId),
            },
          };
        },
        { [personId]: {} }
      );

      const fieldsToUpdateByPersonIdToDto = offerService.fieldsToUpdateByPersonIdToDto(
        state$.value,
        fieldsToUpdateByPersonId
      );

      if (!fieldsToUpdateByPersonIdToDto.length) {
        return EMPTY;
      }

      const extendedPayloadWithState = offerSelectors.getUpdateVariantsPayload(
        state$.value,
        fieldsToUpdateByPersonIdToDto
      );

      return makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateVariants),
        body: extendedPayloadWithState,
      }).pipe(
        map((data) => {
          return offerActions.copyFieldsOfFirstInsuredPerson.success(data.response);
        }),
        catchError((error: AjaxError) => {
          return of(offerActions.copyFieldsOfFirstInsuredPerson.failure(error));
        })
      );
    })
  );

export const onAddPersonRolesRequest: Epic<OfferActions, OfferActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.ADD_PERSON_ROLE_TRIGGER),
    switchMap((action) => {
      type AddPersonTriggerActionType = ActionByType<
        OfferActions,
        OfferActionsTypes.ADD_PERSON_ROLE_TRIGGER
      >;
      const { role, personId } = (action as AddPersonTriggerActionType).payload;

      return makeApiCall({
        method: 'post',
        url: buildEndpoint(ENDPOINTS.offerPersonRoles, { personId }),
        body: [role],
      }).pipe(
        mergeMap((data) => {
          const addRoleSuccess = offerActions.addRole.success(data.response, { addedRole: role });
          if (role === PersonRole.INSURED_PERSON) {
            return [addRoleSuccess, offerActions.fetchOffer()];
          }
          return [addRoleSuccess];
        }),
        catchError((error: AjaxError) => of(offerActions.addRole.failure(error)))
      );
    })
  );

export const onDeletePersonRolesRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.DELETE_PERSON_ROLE_TRIGGER),
    switchMap((action) => {
      type DeletePersonTriggerActionType = ActionByType<
        OfferActions,
        OfferActionsTypes.DELETE_PERSON_ROLE_TRIGGER
      >;
      const { role, personId } = (action as DeletePersonTriggerActionType).payload;

      return makeApiCall({
        method: 'delete',
        url: buildEndpoint(ENDPOINTS.offerPersonRoleDelete, {
          personId,
          roleType: role,
        }),
      }).pipe(
        mergeMap((data) => {
          // I THINK IT DOESNT' WORK PLEASE CHECK IT :(
          const deleteRoleSuccess = offerActions.deleteRole.success(
            Object.assign(data.response, { deletedRole: role })
          );
          if (role === PersonRole.INSURED_PERSON) {
            return [deleteRoleSuccess, offerActions.fetchOffer()];
          }
          return [deleteRoleSuccess];
        }),
        catchError((error: AjaxError) => of(offerActions.deleteRole.failure(error)))
      );
    })
  );

export const onDeletedInsuredPersonSuccess: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(PersonsActionTypes.REMOVE_PERSON_SUCCESS),
    filter((action) => action.payload.deletedRole === PersonRole.INSURED_PERSON),
    map(() => {
      return offerActions.fetchOffer();
    })
  );

export const onSetProfessionEpic: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(PersonsActionTypes.SET_PROFESSION_TRIGGER),
    mergeMap(({ payload }) =>
      makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateProfession, { personId: payload.personId }),
        body: { professionId: payload.professionId },
      }).pipe(
        map((data) => {
          return {
            type: PersonsActionTypes.SET_PROFESSION_SUCCESS,
            payload: {
              ...data.response,
            },
          };
        }),
        catchError((error) => {
          console.log(error);
          return of({
            type: PersonsActionTypes.SET_PROFESSION_FAILURE,
            payload: {
              error: true,
            },
          });
        })
      )
    )
  );

export const onSetSelfEmploymentEpic: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(PersonsActionTypes.SET_SELFEMPLOYMENT_TRIGGER),
    mergeMap(({ payload }) =>
      makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateProfession, { personId: payload.personId }),
        body: { selfEmployed: payload.selfEmployed },
      }).pipe(
        map((data) => {
          return {
            type: PersonsActionTypes.SET_SELFEMPLOYMENT_SUCCESS,
            payload: {
              ...data.response,
            },
          };
        }),
        catchError((error) => {
          console.log(error);
          return of({
            type: PersonsActionTypes.SET_SELFEMPLOYMENT_FAILURE,
            payload: {
              error: true,
            },
          });
        })
      )
    )
  );

export const onSetProfessionRiskGroupEpic: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(PersonsActionTypes.SET_PROFESSION_RISKGROUP_TRIGGER),
    mergeMap(({ payload }) =>
      makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateProfession, { personId: payload.personId }),
        body: {
          riskGroup: payload.riskGroup,
          professionId: payload.professionId,
        },
      }).pipe(
        map((data) => {
          return {
            type: PersonsActionTypes.SET_PROFESSION_RISKGROUP_SUCCESS,
            payload: {
              ...data.response,
            },
          };
        }),
        catchError((error) => {
          console.log(error);
          return of({
            type: PersonsActionTypes.SET_PROFESSION_RISKGROUP_FAILURE,
            payload: {
              error: true,
            },
          });
        })
      )
    )
  );

export const onShippingWayUpdate: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_SHIPPING_WAY_TRIGGER),
    mergeMap((action) => {
      return makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateShippingWay),
        body: action.payload,
      }).pipe(
        map((data) => offerActions.updateShippingWay.success(data.response)),
        catchError((error: AjaxError) => of(offerActions.updateShippingWay.failure(error)))
      );
    })
  );

export const onAgreementUpdate: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_AGREEMENTS_TRIGGER),
    mergeMap((action) => {
      return makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updateAgreements),
        body: action.payload,
      }).pipe(
        map((data) => offerActions.updateAgreement.success(data.response)),
        catchError((error: AjaxError) => of(offerActions.updateAgreement.failure(error)))
      );
    })
  );

export const onUpdateOrRemoveRoleSuccess: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS, OfferActionsTypes.DELETE_PERSON_ROLE_SUCCESS),
    map(() => personsActions.fetchAllPersons.trigger())
  );

export const onUpdateOfferBasicDataSuccess: Epic<RootActions, any, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_OFFER_BASIC_DATA_SUCCESS),
    filter((action) => offerSelectors.shouldFetchAllPersons(action.payload.offer)),
    map(() => personsActions.fetchAllPersons.trigger())
  );

const onAddApprovalClauseRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.ADD_APPROVAL_CLAUSE_TRIGGER),

    mergeMap((action) => {
      return makeApiCall({
        method: 'post',
        url: buildEndpoint(ENDPOINTS.addApprovalClause),
        body: { reasons: [action.payload] },
      }).pipe(
        map((data) => offerActions.addApprovalClause.success(data.response)),
        catchError((error: AjaxError) => of(offerActions.addApprovalClause.failure(error)))
      );
    })
  );

const onRemoveApprovalClauseRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_TRIGGER),

    mergeMap((action) => {
      return makeApiCall({
        method: 'delete',
        url: buildEndpoint(ENDPOINTS.removeApprovalClause, { reasonId: action.payload }),
      }).pipe(
        map((data) => offerActions.removeApprovalClause.success(data.response)),
        catchError((error: AjaxError) => of(offerActions.removeApprovalClause.failure(error)))
      );
    })
  );

const onCancelRequest: Epic<RootActions, RootActions, RootState> = (action$) =>
  action$.pipe(
    ofType(OfferActionsTypes.CANCEL_REQUEST_TRIGGER),
    mergeMap((action) => {
      return makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.cancelRequest),
        body: action.payload,
      }).pipe(
        map((data: AjaxResponse) => offerActions.cancelRequest.success(data.response)),
        catchError((error) => of(offerActions.cancelRequest.failure(error)))
      );
    })
  );

export default combineEpics(
  onUpdateOfferBasicDataRequest,
  onUpdateOfferBasicDataSuccess,
  onEmailUpdateOfVN,
  onInsuranceOwnerChangeResetShippingWay,
  onFetchOfferRequest,
  onSelectVariantRequest,
  onUpdateVariantsRequest,
  onTogglePersonRole,
  onAddPersonRolesRequest,
  onDeletedInsuredPersonSuccess,
  onDeletePersonRolesRequest,
  onSetProfessionEpic,
  onSetSelfEmploymentEpic,
  onSetProfessionRiskGroupEpic,
  onShippingWayUpdate,
  onAgreementUpdate,
  onUpdatePersonAndUpdateOffer,
  onUpdateBirthdateSuccess,
  onUpdateBirthdateRequest,
  onUpdateOrRemoveRoleSuccess,
  onUpdateSalutation,
  onAddApprovalClauseRequest,
  onRemoveApprovalClauseRequest,
  onCopyFieldsOfFirstInsuredPerson,
  onCancelRequest
);
