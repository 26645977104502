import { connect } from 'react-redux';
import { CancelButton as CancelButtonComponent } from './CancelButton';
import { RootState } from 'store/store.interface';
import { offerActions, offerSelectors } from 'entities/offer';
import { CancelButtonDispatchProps, CancelButtonStateProps } from './CancelButton.types';
import { Dispatch } from 'redux';
import { VariantsNames } from 'entities/offer/offer.interface';

const mapStateToProps = (state: RootState): CancelButtonStateProps => ({
  selectedVariant: offerSelectors.getSelectedVariant(state),
});

const mapDispatchToProps = (dispatch: Dispatch): CancelButtonDispatchProps => ({
  cancelRequest: (selectedVariant?: VariantsNames) => {
    if (selectedVariant) {
      return dispatch(
        offerActions.cancelRequest.trigger({
          selectedVariant: selectedVariant,
        })
      );
    }
  },
});
export const CancelButton = connect(mapStateToProps, mapDispatchToProps)(CancelButtonComponent);
