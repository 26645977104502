import React from 'react';
import YesNoInput from '@eg/elements/YesNoInput';
import { Agreement } from './components';
import { AgreementsProps } from './Agreements.types';
import { Details } from '@eg/elements/Details';

export const Agreements: React.FC<AgreementsProps> = ({
  agreements,
  changeAgreement,
  currentVariantId,
  hasAgreementsLoaded,
  isLoading,
  isUserInformedAboutTermination,
  isUserInformedAboutContractTermination,
}) => {
  if (!hasAgreementsLoaded || !agreements) {
    return null;
  }

  return (
    <Details className='comparison-table__details' summary='Ermöglichen DA' isInitiallyOpen>
      <div className='esc_grid'>
        <div className='esc_grid__wrapper'>
          <div className='esc_col esc_col-12'>
            <p>
              Sie haben nun die Möglichkeit den Vertrag direkt abzuschließen. Dafür sieht der
              Gesetzgeber umfangreiche Informationen vor, z. B. auch zum Thema Kündigung. Am Telefon
              dürfen wir uns auf wesentliche Informationen zum Vertrag und zur Leistung beschränken.
              Sind Sie damit einverstanden?
            </p>
            <YesNoInput
              value={isUserInformedAboutTermination}
              name='agreements__radio-input'
              onChange={isUserInformedAboutContractTermination}
            />
          </div>

          {isUserInformedAboutTermination && currentVariantId && (
            <div className='esc_col esc_col-12'>
              {agreements.map((agreement, index) => (
                <Agreement
                  key={`agreement.${index}`}
                  agreement={agreement}
                  currentVariantId={currentVariantId}
                  changeAgreement={changeAgreement}
                  isLoading={isLoading}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Details>
  );
};
