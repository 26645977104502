import * as React from 'react';
import Button from '@eg/elements/Button';

export interface SubmitButtonProps {
    disabled: boolean | undefined;
    onClick: () => void;
    submitButtonLabel?: string;
    kontext: string;
}
export default class SubmitButton extends React.Component<SubmitButtonProps> {
    public static defaultProps: Partial<SubmitButtonProps> = {
        submitButtonLabel: 'Beratungsprotokoll abschicken'
    };

    render() {
        const dataId = this.props.kontext + '__BUTTON_SUBMIT_IDD';

        return (
            <Button
                disabled={this.props.disabled}
                type="submit"
                data-test-id={dataId}
                onClick={this.props.onClick}
                variant="primary"
            >
                {this.props.submitButtonLabel}
            </Button>
        );
    }
}
