import * as React from 'react';
import { Checkbox, CheckboxProps } from '@eg/elements/Checkbox';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';
import { FormRow, FormRowProps } from '@eg/elements/FormRow';
import styled from 'styled-components';

export interface SectionBackgroundProps {
    className?: string;
    children?: React.ReactChild;
}

export interface TextblockBackgroundProps {
    className?: string;
    children?: Array<JSX.Element> | undefined;
}

export const StyledH4 = styled('h4')`
    margin-top: 0px;
    padding: 9px 0px 0px 16px;
    font-weight: 600;
    font-size: 18px;
`;

export const StyledFormRow: React.FunctionComponent<FormRowProps> = styled(FormRow)`
    display: flex;
    margin-bottom: 8px;
    flex-direction: column;
    .ee_form-row__container {
        max-width: 100%;
    }
    .ee_form-row__descriptor.ee_form-row__descriptor--wide {
        width: 100% !important;
        padding-left: 1rem !important;
        font-weight: 600;
    }
    .ee_form-row__container.ee_form-row__container--wide {
        padding-left: 1rem;
        flex-direction: row;
    }
    .ee_form-row__messages.ee_form-row__messages--wide {
        padding-left: 1rem !important;
    }
    .ee_form-row__hint {
        padding-left: 0 !important;
    }
    .ee_checkbox {
        align-items: flex-start !important;
        font-size: 13px !important;
        line-height: 18px !important;
    }
    .ee_form-row__hint {
        padding-left: 0 !important;
    }
`;

// mf 13012020: no proper type found
export const StyledRadioGroupRow = styled(RadioGroupRow)`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    .ee_form-row__container {
        max-width: 500px !important;
    }
    .ee_form-row__descriptor.ee_form-row__descriptor--wide {
        width: 100% !important;
        padding-left: 1rem !important;
        font-weight: 600;
    }
    .ee_form-row__item.ee_form-row__item--wide {
        padding-left: 1rem;
    }
    .ee_form-row__messages.ee_form-row__messages--wide {
        padding-left: 1rem !important;
    }
    .ee_form-row__hint {
        padding-left: 0 !important;
    }
`;

export const StyledConfirmationCheckbox: React.FunctionComponent<CheckboxProps> = styled(Checkbox)`
    padding-left: 1rem;
    font-weight: 600;
    .ee_checkbox__label {
        padding: 0 1rem 0 1rem !important;
    }
    .ee_form-row__hint {
        padding-left: 0 !important;
    }
`;

export const StyledConfirmationRowWrapper: React.FunctionComponent<FormRowProps> = styled(FormRow)`
    margin-bottom: 8px;
    .ee_form-row__descriptor.ee_form-row__descriptor--wide {
        width: 100% !important;
        padding-left: 1rem !important;
    }
    .ee_form-row__messages.ee_form-row__messages--wide {
        padding-left: 3.5rem !important;
    }
    .ee_checkbox__label {
        padding: 0 1rem 0 1rem !important;
    }
    .ee_form-row__hint {
        padding-left: 0 !important;
    }
`;

const BGUnstyled: React.FunctionComponent<SectionBackgroundProps> = (props: SectionBackgroundProps) => (
    <div className={props.className} id="idd-form-section">
        {props.children}
    </div>
);

export const SectionBackground: React.FunctionComponent<SectionBackgroundProps> = styled(BGUnstyled)`
    background-color: #f4f4f4;
`;

const BgTextblockUnstyled = (props: TextblockBackgroundProps) => (
    <div className={props.className} id="idd-form-textblock">
        {props.children}
    </div>
);

export const TextblockBackground: React.FunctionComponent<TextblockBackgroundProps> = styled(BgTextblockUnstyled)`
    background-color: #f4f4f4;
`;
