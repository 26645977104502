import React from 'react';
import { MinusIcon } from '@eg/elements/components/Icons';
import lb from './Statics.labels';
import { StaticsProps } from './Statics.types';
import './Statics.css';

export const Statics: React.FC<StaticsProps> = ({
  actionNumber,
  oeNr,
  employeeNr,
  salesProduct,
  salesModel,
  offerStatus,
  businessId,
  isProcessingFlow,
}: StaticsProps) => (
  <div className='statics'>
    <div className='statics__item'>
      {`${lb.actionNumber}: `}
      <span className='statics__item-data'>
        {actionNumber ? <b>{actionNumber}</b> : <MinusIcon />}
      </span>
    </div>

    <div className='statics__item'>
      {`${lb.salesProduct}: `}
      <span className='statics__item-data'>
        {salesProduct ? <b>{salesProduct}</b> : <MinusIcon />}
      </span>
    </div>

    <div className='statics__item'>
      {`${lb.oeNr}: `}
      <span className='statics__item-data'>{oeNr ? <b>{oeNr}</b> : <MinusIcon />}</span>
    </div>

    <div className='statics__item'>
      {`${lb.employeeNr}: `}
      <span className='statics__item-data'>{employeeNr ? <b>{employeeNr}</b> : <MinusIcon />}</span>
    </div>

    <div className='statics__item'>
      {`${lb.salesModel}: `}
      <span className='statics__item-data'>{salesModel ? <b>{salesModel}</b> : <MinusIcon />}</span>
    </div>

    <div className='statics__item'>
      {`${lb.offerStatus}: `}
      <span className='statics__item-data'>
        {offerStatus ? <b>{offerStatus}</b> : <MinusIcon />}
      </span>
    </div>

    <div className='statics__item'>
      {isProcessingFlow ? `${lb.agentDocumentId}: ` : `${lb.businessId}: `}
      <span className='statics__item-data'>{businessId ? <b>{businessId}</b> : <MinusIcon />}</span>
    </div>
  </div>
);
