import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import React, { useEffect, useState } from 'react';
import { InheritShareProps } from './inherit-share.types';

const MAX_VALUE = 100;
const MIN_VALUE = 0;

const inputValueToNumber = (value: string) => parseInt(value);

const convertToString = (value: number) => value.toString();

const preventValueOutOfRange = (value: string): string => {
  const val = parseInt(value, 10);

  switch (true) {
    case val > MAX_VALUE:
      return convertToString(MAX_VALUE);
    case val < MIN_VALUE:
      return convertToString(MIN_VALUE);
    default:
      return value;
  }
};

export const InheritShareComponent: React.FC<InheritShareProps> = ({
  isLoading,
  editedPerson,
  updateBeneficiaryShare,
}) => {
  const [inputShareValue, setInputShareValue] = useState<string>('');

  useEffect(() => {
    if (editedPerson.share?.toString()) {
      setInputShareValue(convertToString(editedPerson.share));
    }
  }, [editedPerson]);

  const handleOnChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setInputShareValue(preventValueOutOfRange(event.target.value));
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    inputValue
      ? updateBeneficiaryShare(editedPerson.personId, inputValueToNumber(inputValue))
      : setInputShareValue(convertToString(editedPerson.share!));
  };

  return (
    <FormRow label='Widerrufliches Bezugsrecht' boundary='narrow' className='beneficiary-share'>
      <Input
        value={inputShareValue}
        placeholder='prozentAnteil'
        disabled={isLoading}
        type='number'
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        adornmentRight='%'
      />
    </FormRow>
  );
};
