import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@eg/elements/Autocomplete';
import Select from '@eg/elements/Select';
import { debounce } from 'lodash';
import { fetchProfessions } from './Profession.model';
import { Field } from 'components';
import { PersonProfession } from 'entities/persons';
import { ProfessionComponentProps } from './Profession.types';
import { ProfessionInfo } from './components/ProfessionInfo/ProfessionInfo.component';
import { riskLabels } from './Professions.labels';
import './Profession.css';

export const ProfessionComponent: React.FC<ProfessionComponentProps> = ({
  businessId,
  isKid,
  isPersonPresentInAnyPackage,
  isSelfEmployable,
  person,
  setProfession,
  setRiskGroup,
  setSelfEmployment,
}) => {
  const riskGroup = person?.profession?.riskGroup ?? '';
  const riskLabel = riskLabels[riskGroup] || riskGroup;
  const professionFieldLabel = !!riskGroup ? riskLabel : 'Beruf';

  const [autocompleteValue, setAutocompleteValue] = useState<string>(
    person?.profession?.professionName ?? ''
  );

  const [suggestions, setSuggestions] = useState<PersonProfession[]>([]);

  const updateSuggestions = async (searchQuery: string) => {
    const suggestions = await fetchProfessions(searchQuery);
    const professionsList = suggestions?.length ? suggestions : [];

    setSuggestions(professionsList);
  };

  const delayedUpdateSuggestions = useCallback(
    debounce((searchQuery: string) => updateSuggestions(searchQuery), 500),
    []
  );

  const handleProfessionOnChange = (value: string) => {
    setAutocompleteValue(value);

    const suggestionMatch = suggestions.find((s) => s.professionName === value);

    if (suggestionMatch) {
      setProfession(person?.personId ?? '', businessId, suggestionMatch.professionId);
    } else if (value.length >= 3) {
      delayedUpdateSuggestions(value);
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const professionName = person?.profession?.professionName ?? '';
    setAutocompleteValue(professionName);
  }, [person]);

  const handleSelfEmployedOnChange = (newValue: string) => {
    setSelfEmployment(person?.personId ?? '', businessId, newValue === 'true');
  };

  const handleSetRiskGroup = (riskGroup: PersonProfession['riskGroup']) => {
    setRiskGroup(
      person?.personId ?? '',
      businessId,
      person?.profession?.professionId ?? '',
      riskGroup
    );
  };

  return isPersonPresentInAnyPackage && person ? (
    <div className='esc_grid profession'>
      <ProfessionInfo riskGroup={riskGroup} setRiskGroup={handleSetRiskGroup} />

      <div className='esc_grid__wrapper'>
        {/* one column should be empty to match table below */}
        <div className='esc_col esc_col-4' />
        <div className={`esc_col esc_col-8 profession__field`}>
          <Field label={professionFieldLabel}>
            <Autocomplete
              data-testId='Autocomplete'
              value={autocompleteValue}
              suggestions={suggestions.map((s) => s.professionName || '')}
              onChange={handleProfessionOnChange}
              disabled={isKid}
            />
          </Field>
        </div>
      </div>

      <div className='esc_grid__wrapper'>
        {/* one column should be empty to match table below */}
        <div className='esc_col esc_col-4' />
        <div className={`esc_col esc_col-8 profession__field`}>
          <Field label='Arbeitsverhältnis'>
            <Select
              value={person.profession?.selfEmployed ? 'true' : 'false'}
              disabled={!isSelfEmployable}
              onChange={(e) => handleSelfEmployedOnChange(e?.target?.value)}
            >
              <option value='true'>Selbstständig</option>
              <option value='false'>Angestellt</option>
            </Select>
          </Field>
        </div>
      </div>
    </div>
  ) : null;
};
