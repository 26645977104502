import { FetchClientOptions } from '../models/FormConfiguration';

export default class FetchClient {
    constructor(private _configruation: FetchClientOptions) {}

    // tslint:disable-next-line:no-any
    async fetch<T>(endpoint: string, options?: RequestInit): Promise<T> {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        const uri = this._configruation.url + endpoint;
        const opts = {
            ...options,
            headers,
            // tslint:disable-next-line:no-any
            credentials: 'include' as any
        };
        this._debugLog('REQUEST uri: ' + uri + ' opts: ', options);
        this._addAuthHeaders(opts.headers);

        return await this._fetch(uri, opts);
    }

    private _addAuthHeaders(headers: Headers) {
        if (!this._configruation.credentials) {
            return headers;
        }
        const credentials = this._configruation.credentials;
        headers.append('Authorization', 'Basic ' + btoa(credentials.username + ':' + credentials.password));
        this._debugLog('Adding authorization headers for user "' + credentials.username + '"');
    }

    private async _fetch(uri: string, opts?: RequestInit) {
        try {
            const response = await fetch(uri, opts);
            if (response.status === 200) {
                const json = await response.json();
                this._debugLog('RESPONSE ', json);
                return json;
            } else {
                throw new Error(
                    'Response did not return status code 200. Returned: ' +
                        response.status +
                        ' - ' +
                        response.body +
                        ' - ' +
                        response.statusText
                );
            }
        } catch (err) {
            this._debugLog('EXCEPTION: ', err);
            throw err;
        }
    }

    // tslint:disable-next-line:no-any
    private _debugLog(...msgs: Array<any>) {
        if (this._configruation.debugFetch) {
            // tslint:disable-next-line:no-console
            console.log('[IDD FetchClient]', ...msgs);
        }
    }
}
