import React, { useCallback } from 'react';
import Price from '@eg/elements/Price';
import Radio from '@eg/elements/Radio';
import { VariantRadioProps } from './VariantRadioSelect.types';
import './VariantRadioSelect.css';

export const VariantRadioSelect: React.FC<VariantRadioProps> = ({
  disabled,
  label,
  price,
  selectVariant,
  variantId,
  value,
}: VariantRadioProps) => {
  const handleVariantSelect = useCallback(() => {
    selectVariant();
  }, [selectVariant]);

  return (
    <div className='variant-radio-select'>
      {price ? <Price value={price} /> : <Price />}
      <label>{label}</label>
      <Radio
        label=''
        disabled={disabled}
        value={String(variantId)}
        checked={value}
        onChange={handleVariantSelect}
      />
    </div>
  );
};
