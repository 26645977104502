import { RootState } from 'store/store.interface';

const getError = (state: RootState) => state.entities.app.error;
const isLoading = (state: RootState) => state.entities.app.loading;
const getAppBusinessId = (state: RootState) => state.entities.app.businessId;

export const appSelectors = {
  getError,
  isLoading,
  getAppBusinessId,
};
