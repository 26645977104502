import { AppActions, AppActionsTypes } from './app.actions';
import { AppReducer } from './app.types';

const defaultState: AppReducer = {
  businessId: '',
  error: null,
  loading: false,
};

export const appReducer = (state: AppReducer = defaultState, action: AppActions) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_INIT:
      return {
        ...state,
        loading: true,
      };

    case AppActionsTypes.FETCH_INIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case AppActionsTypes.FETCH_INIT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case AppActionsTypes.SET_BUSINESS_ID:
      return {
        ...state,
        businessId: action.payload.businessId,
      };

    default:
      return state;
  }
};
