import { VariantFields } from './offer.types';

// this array takes cares of the sorting in the view
// IMPORTANT: if a field is not present in this array it will not be displayed
export const DEFAULT_ORDER_FOR_VARIANTS_FIELDS = [
  VariantFields.selected,
  VariantFields.tariff,
  VariantFields.insuranceStartDate,
  VariantFields.durationOfContract,
  VariantFields.insuranceEndDate,
  VariantFields.paymentMethod,
  VariantFields.debitDay,
  VariantFields.dynamicValue,
];
