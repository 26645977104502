import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { contractDetailsActions, contractDetailsSelectors } from 'entities/contractDetails';
import { ReplacementRequest as ReplacementRequestComponent } from './ReplacementRequest';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState) => ({
  hasData: contractDetailsSelectors.hasReplacementRequestData(state),
  isEnabled: contractDetailsSelectors.isReplacementRequestEnabled(state),
  isLoading: contractDetailsSelectors.isLoading(state),
  replacementRequestNumber: contractDetailsSelectors.getReplacementRequestNumber(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteReplacementRequest: () => console.log('deleteReplacementRequest'),
  setEnabled: (isEnabled: boolean) =>
    dispatch(contractDetailsActions.setReplacementRequestEnabled(isEnabled)),
  updateReplacementRequest: (replacementRequestNumber: string) =>
    console.log('replacementRequestNumber', replacementRequestNumber),
});

export const ReplacementRequest = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementRequestComponent);
