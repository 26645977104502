import './app-loader.component.css';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { AppLoaderProps } from './app-loader.interface';

export const AppLoaderComponent: FunctionComponent<AppLoaderProps> = ({
  showLoader,
}: AppLoaderProps) => (
  <>
    <div
      className={classNames({
        'app-loader': true,
        'app-loader__hide': !showLoader,
      })}
    >
      Warten auf Server...
    </div>
    <LoadingSpinner viewport='fixed' show={showLoader} />
  </>
);
