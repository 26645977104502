import { cloneDeep, get, isNumber, omit, set } from 'lodash';
import { SalesProcessTypes } from './offer.interface';
import {
  Field,
  Offer,
  OfferDto,
  OfferField,
  OfferFields,
  OfferReducer,
  Package,
  PackageDto,
  PackageField,
  PackageFields,
  ProcessingOfferFields,
  TableFields,
  UpdateOfferBasicDataDto,
  UpdatePersonsFieldsMap,
  UpdateVariantFieldDto,
  Variant,
  VariantDto,
  VariantField,
  VariantFields,
  SHORT_TERM_CONTRACT,
} from './offer.types';
import { Decimal } from 'decimal.js';

const generateComponsedKeyForPackagesByIds = (
  personId: string,
  variantId: string,
  fieldId: PackageFields
) => `${personId}.${variantId}.${fieldId}`;
const generateComponsedKeyForMapOfPackageIdByComposedId = (
  personId: string,
  variantId: string,
  packageId: string
) => `${personId}.${variantId}.${packageId}`;
const generateComponsedKeyForFrontendValidationByComposedId = (
  personId?: string,
  variantId?: string,
  packageId?: string
) => (personId && variantId && packageId ? `${personId}.${variantId}.${packageId}` : undefined);

const mapToVariantField = (field: Field<VariantFields>, variant: VariantDto): VariantField => {
  const baseField = {
    ...field,
    corrupted: false,
  };

  switch (field.id) {
    case VariantFields.insuranceEndDate:
      const durationOfContractField = variant.fields.find(
        (variantField: Field<VariantFields>) => variantField.id === VariantFields.durationOfContract
      );
      const hasShortTermContract = durationOfContractField?.value === SHORT_TERM_CONTRACT;

      if (hasShortTermContract) {
        const SHORT_TERM_CONTRACT_MESSAGE =
          'Bitte wählen Sie als Enddatum den nächsten Tag, wenn am letzten Tag voller Versicherungsschutz gelten soll.';
        baseField.errors = [SHORT_TERM_CONTRACT_MESSAGE, ...baseField.errors];
      }

      break;
    default:
      return baseField;
  }

  return baseField;
};

const mapToPackageField = (field: Field<PackageFields>, packageObj: PackageDto): PackageField => {
  const baseField = {
    ...field,
    corrupted: false,
  };

  switch (field.id) {
    case PackageFields['000500.model']:
      //Kapitalleistung 70% Invaliditä - EUNF-2609
      baseField.value = packageObj.specialMaxInsuranceSum ?? '';
      break;
    case PackageFields['000966.insuranceSum']:
      //Kids-Topschutz - EUNF-2310
      baseField.stepRange = 1;
      break;
    default:
      return baseField;
  }

  return baseField;
};

const mapVariantDtoToVariant = (variant: VariantDto): Variant => {
  const { fields, price, ...restVariant } = variant;
  return {
    ...restVariant,
    price: isNumber(price) ? price : null,
    fieldsIds: fields.map((field: Field<VariantFields>) => field.id),
    fieldsById: fields.reduce((acc: Record<string, VariantField>, field: Field<VariantFields>) => {
      acc[field.id] = mapToVariantField(field, variant);
      return acc;
    }, {}),
  };
};

const mapPackageDtoToPackage = (packageObj: PackageDto): Package => {
  const { fields, ...restPackage } = packageObj;
  return {
    ...restPackage,
    disabled: false,
    fieldsIds: fields.map((field: Field<PackageFields>) => field.id),
    fieldsById: fields.reduce((acc: Record<string, PackageField>, field: Field<PackageFields>) => {
      acc[field.id] = mapToPackageField(field, packageObj);
      return acc;
    }, {}),
  };
};

const processingFlowFields = (
  offerFields: Record<string, Field<ProcessingOfferFields> | undefined>
) =>
  Object.entries<Field<ProcessingOfferFields> | undefined>(offerFields).reduce(
    (acc: Record<string, Field<ProcessingOfferFields>>, [fieldName, field]) => ({
      ...acc,
      ...(field && { [fieldName]: field }),
    }),
    {}
  );

const mapOfferResponseToReducer = (offer: OfferDto): Offer => {
  const {
    acceptanceQuestions = {},
    actionNr = {},
    consultationProtocolId = {},
    creatorNr = {},
    number = '',
    offerStatus = {},
    packages = [],
    productName = {},
    salesModel = {},
    salesProcess = {},
    submissionReason = {},
    variants = [],
    shippingWay = null,
    approvalClause,
    approvalNote,
    messages,
  } = offer;

  return {
    messages,
    offerNumber: number,
    acceptanceQuestions: acceptanceQuestions as OfferField,
    actionNr: actionNr as OfferField,
    consultationProtocolId: consultationProtocolId as OfferField,
    creatorNr: creatorNr as OfferField,
    mapOfPackageIdByComposedId: packages.reduce(
      (acc: Record<string, string>, packageObj: PackageDto) => {
        packageObj.fields.forEach((field) => {
          const { personId, variantId } = packageObj;
          const composedId = generateComponsedKeyForPackagesByIds(personId, variantId, field.id);
          acc[composedId] = packageObj.id;
        });
        return acc;
      },
      {}
    ),
    offerStatus: offerStatus as OfferField,
    packagesIds: packages.map(
      (packageObj: PackageDto) => `${packageObj.personId}.${packageObj.variantId}.${packageObj.id}`
    ),
    packagesById: packages.reduce((acc: Record<string, Package>, packageObj: PackageDto) => {
      const { personId, variantId, id } = packageObj;
      const composedId = generateComponsedKeyForMapOfPackageIdByComposedId(personId, variantId, id);
      acc[composedId] = mapPackageDtoToPackage(packageObj);
      return acc;
    }, {}),
    productName: productName as OfferField,
    salesModel: salesModel as OfferField,
    salesProcess: salesProcess as OfferField,
    submissionReason: submissionReason as OfferField,
    shippingWay,
    variantsIds: variants.map((variant: VariantDto) => variant.id),
    variantsById: variants.reduce((acc: Record<string, Variant>, variant: VariantDto) => {
      acc[variant.id] = mapVariantDtoToVariant(variant);
      return acc;
    }, {}),

    ...processingFlowFields({
      approvalClause,
      approvalNote,
    }),
  };
};

const removeEmptyPerson = (personId: string, personFieldsMap: UpdatePersonsFieldsMap) =>
  Object.values(get(personFieldsMap, personId, {})).length
    ? personFieldsMap
    : omit(personFieldsMap, personId);

const removeEmptyVariant = (
  personId: string,
  variantId: string,
  personFieldsMap: UpdatePersonsFieldsMap
) =>
  Object.values(get(personFieldsMap, [personId, variantId], {})).length
    ? personFieldsMap
    : omit(personFieldsMap[personId], variantId);

const removeVariantField = (
  personId: string,
  variantId: string,
  field: string,
  personFieldsMap: UpdatePersonsFieldsMap
) => ({
  ...personFieldsMap,
  [personId]: {
    ...personFieldsMap[personId],
    [variantId]: omit(personFieldsMap[personId][variantId], field),
  },
});

const setFieldPropertyValue = (
  state: OfferReducer,
  personId: string,
  variantId: string,
  fieldId: TableFields,
  property: string,
  value: any
) => {
  if (!personId) {
    const newVariantsById = cloneDeep(state.variantsById);
    set(newVariantsById, [variantId, 'fieldsById', fieldId, property], value);

    return {
      variantsById: newVariantsById,
    };
  }

  const newPackagesById = cloneDeep(state.packagesById);
  const composedKeyForPackageForPackagesById = generateComponsedKeyForPackagesByIds(
    personId,
    variantId,
    fieldId as PackageFields
  );
  const packageId = get(state.mapOfPackageIdByComposedId, composedKeyForPackageForPackagesById, '');
  const composedKeyForMapOfPackageIdByComposedId =
    generateComponsedKeyForMapOfPackageIdByComposedId(personId, variantId, packageId);
  set(
    newPackagesById,
    [composedKeyForMapOfPackageIdByComposedId, 'fieldsById', fieldId, property],
    value
  );

  return {
    packagesById: newPackagesById,
  };
};

const updateFieldsToCorrupted = (state: OfferReducer, context: UpdateVariantFieldDto[]) => {
  const newVariantsById = cloneDeep(state.variantsById);
  const newPackagesById = cloneDeep(state.packagesById);

  context.forEach((fieldToUpdate: UpdateVariantFieldDto) => {
    const { personId, variantId, packageId, fieldId } = fieldToUpdate;

    if (!personId) {
      set(newVariantsById, [variantId, 'fieldsById', fieldId, 'corrupted'], true);
    } else {
      const extendedFieldId = `${packageId}.${fieldId}`;
      set(
        newPackagesById,
        [
          generateComponsedKeyForMapOfPackageIdByComposedId(personId, variantId, packageId),
          'fieldsById',
          extendedFieldId,
          'corrupted',
        ],
        true
      );
    }
  });

  return {
    variantsById: newVariantsById,
    packagesById: newPackagesById,
  };
};

const hasSalesProcessChanged = (context: UpdateOfferBasicDataDto[]) =>
  context.some(
    ({ fieldId, value }) =>
      fieldId === OfferFields.salesProcess && value === SalesProcessTypes.VOLLANGEBOT
  );

const hasValidStepRange = (value: string | number, stepRange: string | number) => {
  const zeroDecimal = new Decimal(0);
  const asFloatDecimal = new Decimal(value);
  const stepRangeDecimal = new Decimal(stepRange);
  return asFloatDecimal.mod(stepRangeDecimal).comparedTo(zeroDecimal) === 0;
};

export const offerHelper = {
  generateComponsedKeyForMapOfPackageIdByComposedId,
  generateComponsedKeyForPackagesByIds,
  generateComponsedKeyForFrontendValidationByComposedId,
  hasSalesProcessChanged,
  hasValidStepRange,
  mapOfferResponseToReducer,
  removeEmptyPerson,
  removeEmptyVariant,
  removeVariantField,
  setFieldPropertyValue,
  updateFieldsToCorrupted,
};
