import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store/store.interface';
import { PersonalDataComponent } from './personal-data.component';
import { Person, personsActions, personsSelectors } from 'entities/persons';

import { appLoaderActions } from 'components/appLoader/app-loader.actions';

import { PersonalDataDispatchProps, PersonalDataStateProps } from './personal-data.interface';
import { personManagementSelectors } from '../../state';
import { offerSelectors } from 'entities/offer';

const makeMapStatetoProps = () => {
  return (state: RootState): PersonalDataStateProps => ({
    businessId: offerSelectors.getBusinessId(state),
    editedPerson: personManagementSelectors.editedPerson(state),
    insuranceOwner: personsSelectors.getInsuranceOwner(state),
  });
};

const mapDispatchToProps = (dispatch: Dispatch): PersonalDataDispatchProps => ({
  addPerson: (person: Partial<Person>) => {
    dispatch(personsActions.addPerson({ ...person, showOnList: false, currentlyEditing: true }));
  },
  updatePerson: (person: Partial<Person>) => dispatch(personsActions.updatePerson(person)),
  updatePersonAndUpdateOffer: (person: Partial<Person>) =>
    dispatch(personsActions.updatePersonAndUpdateOffer(person)),
  personalDataError: (error: Error) => {
    console.log(error);
  },
  setIsLoading: (isVisible: boolean) => dispatch(appLoaderActions.isLoading(isVisible)),
});

export const PersonalData = connect(makeMapStatetoProps, mapDispatchToProps)(PersonalDataComponent);
