import React from 'react';
import classNames from 'classnames';
import { ErrorsProps } from './Errors.types';
import styles from './Errors.module.scss';

export const Errors: React.FC<ErrorsProps> = ({ errors, hasErrors }) => (
  <div className={classNames({ [styles.Errors]: hasErrors })}>
    {hasErrors && (
      <ul className={styles.ErrorsList}>
        {errors.map((message: string, index: number) => (
          <li className={styles.ErrorsListItem} key={`error.message.${index}`} title={message}>
            {message}
          </li>
        ))}
      </ul>
    )}
  </div>
);
