import React, { useEffect, useRef } from 'react';
import { CogwheelIcon } from '@eg/elements/components/Icons/CogwheelIcon';
import { personsHelpers, Person } from 'entities/persons';
import { PersonalData } from './components/PersonalData/personal-data.container';
import { PersonManagementProps } from './PersonManagement.types';
import { PersonBlock } from './components/PersonBlock';
import { ModalProvider } from 'components';
import './PersonManagement.scss';
import { CreatePerson } from './components/CreatePerson/CreatePerson';

export const PersonManagementComponent = ({
  insuredPersonId,
  persons,
  editedPerson,
  isPersonMgmtActionBlocked,
  personsLimitLabel = 'Anzahl der VP:',
  visibleFields,
  numberOfPersons,
  personsLimit,
  showRoleBadges = true,
  enablePDSValidation = true,
  enableCopyAddress = true,
  canAddMorePersons,
  canEditPerson,
  selectPerson,
  removePerson,
  createPerson,
  additionalFormFields,
  personHeaderInfo,
}: PersonManagementProps) => {
  const personListContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (persons.length && !editedPerson) {
      selectPerson(persons[0], insuredPersonId);
    }
  }, [persons, editedPerson, insuredPersonId, selectPerson]);

  useEffect(() => {
    const isLastPersonSelected = editedPerson?.personId === persons[persons.length - 1]?.personId;
    if (isLastPersonSelected) {
      personListContainer?.current?.scrollTo(0, personListContainer.current.scrollHeight);
    }
  }, [editedPerson, persons]);

  const selectEditedPerson = (personId: string) => {
    const person = persons.find((person) => person.personId === personId);
    if (person && person !== editedPerson) {
      selectPerson(person, insuredPersonId);
    }
  };

  return (
    <ModalProvider>
      <div className='person-management'>
        <div className='person-management__container'>
          <div
            className='person-management__list'
            id='person-management__list'
            ref={personListContainer}
          >
            {persons.map((person: Person) => (
              <div key={`PersonManagement.${person.personId}`}>
                {personHeaderInfo && (
                  <div className='person-management__header'>{personHeaderInfo(person.share!)}</div>
                )}
                <PersonBlock
                  key={`personManagement.${person.personId}`}
                  person={person}
                  isSelected={editedPerson?.personId === person.personId}
                  showRoleBadges={showRoleBadges}
                  showValidationIcons={enablePDSValidation}
                  onClickCallback={selectEditedPerson}
                  disableActions={
                    isPersonMgmtActionBlocked || personsHelpers.personIsInsuranceHolder(person)
                  }
                  onPersonRemove={(personId) => removePerson(personId, insuredPersonId)}
                />
              </div>
            ))}
          </div>
          <div className='person-management__details-form'>
            {editedPerson && (
              <>
                <PersonalData
                  personId={editedPerson?.personId}
                  enablePDSValidation={enablePDSValidation}
                  enableCopyAddress={enableCopyAddress}
                  visibleFields={visibleFields}
                  readOnly={!canEditPerson}
                />
                {additionalFormFields && (
                  <div className='person-management__additional-fields'>{additionalFormFields}</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='person-management__footer'>
          <div className='person-management__status'>
            <div className='person-management__status-content'>
              {personsLimitLabel}{' '}
              <b>
                {numberOfPersons}/{personsLimit}
              </b>
            </div>{' '}
            {isPersonMgmtActionBlocked && (
              <div className='person-management__cogwheel'>
                <CogwheelIcon spinning style={{ fill: '#8e0038' }} />
              </div>
            )}
          </div>
          <CreatePerson
            disabled={isPersonMgmtActionBlocked}
            createPerson={() => createPerson(insuredPersonId)}
            canAddMorePersons={canAddMorePersons}
          />
        </div>
      </div>
    </ModalProvider>
  );
};
