import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { orderActions, orderSelectors } from 'entities/order';
import { RootState } from 'store/store.interface';
import { Submit as SubmitComponent } from './Submit';
import { SubmitDispatchProps, SubmitStateProps } from './Submit.types';
import { offerSelectors } from 'entities/offer';

const mapStateToProps = (state: RootState): SubmitStateProps => ({
  isLoading: orderSelectors.isLoading(state),
  isReadOnly: offerSelectors.isReadOnly(state),
});

const mapDispatchToProps = (dispatch: Dispatch): SubmitDispatchProps => ({
  finalizeOrder: () => dispatch(orderActions.finalize()),
});

export const Submit = connect(mapStateToProps, mapDispatchToProps)(SubmitComponent);
