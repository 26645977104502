import Details from '@eg/elements/Details';
import classNames from 'classnames';
import React from 'react';
import { Person } from 'entities/persons';
import { ContractDetails } from '../ContractDetails';
import { ComparisonTableProps } from './ComparisonTable.types';
import { Beneficiary, Coverages } from './components';
import { PackagesTable } from './components/PackagesTable';
import { Profession } from './components/Profession';
import { VariantsTable } from './components/VariantsTable';
import './ComparisonTable.css';
import { OfferFlow } from 'components/OfferFlow';
import { FlowType } from 'entities/offer/offer.interface';
import { ProcessingFlow } from '../ProcessingFlow';

export const ComparisonTableComponent: React.FC<ComparisonTableProps> = ({
  persons,
  hasPersonErrors,
}) => (
  <div className='comparison-table'>
    <Details className='comparison-table__details' summary='Allgemein' isInitiallyOpen>
      <ContractDetails />
      <VariantsTable />
    </Details>

    {persons.map(({ personId, firstName, lastName, birthDate }: Person, index: number) => {
      const summary = `Versicherungsschutz ${index + 1}. Versicherte Person ${firstName || ''} ${
        lastName || ''
      }`;
      return (
        <Details
          key={`comparison.packages.table.${personId}`}
          className={classNames({
            'comparison-table__details': true,
            'comparison-table__details-with-errors': hasPersonErrors(personId),
          })}
          summary={summary}
        >
          <Profession personId={personId} />
          <PackagesTable personId={personId} />
          <Beneficiary personId={personId} personBirthDate={birthDate} />
        </Details>
      );
    })}

    <OfferFlow flowTypes={[FlowType.PROCESSING]}>
      <ProcessingFlow />
    </OfferFlow>
    <Details className='comparison-table__details' summary='Im Grundschutz mitversichert'>
      <Coverages />
    </Details>
  </div>
);
