import React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line
import { AssociationMember, ReplacementContract } from './components';
import { ContractDetailsProps } from './ContractDetails.types';
import { Loading } from 'components';
import styles from './ContractDetails.module.scss';

export const ContractDetails: React.FC<ContractDetailsProps> = ({
  hasEnabledAnyCheckbox,
  isLoading,
}) => {
  const sectionClassNames = classNames('esc_grid', {
    [styles.ContractDetails]: hasEnabledAnyCheckbox,
    [styles.ContractDetailsWithoutSelection]: !hasEnabledAnyCheckbox,
  });

  return (
    <Loading loading={isLoading}>
      <section className={sectionClassNames}>
        {/* Hidden base on ticket
        <ReplacementRequest />*/}
        {/*<ReplacementContract />*/}
        <AssociationMember />
      </section>
    </Loading>
  );
};
