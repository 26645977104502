import { connect } from 'react-redux';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { Payment as PaymentComponent } from './Payment';
import { PaymentStateProps } from './Payment.types';

const mapStateToProps = (state: RootState): PaymentStateProps => ({
  price: offerSelectors.getSelectedVariantPrice(state),
  paymentMethodValue: offerSelectors.getSelectedVariantPaymentMethod(state)?.value,
});

export const Payment = connect(mapStateToProps)(PaymentComponent);
