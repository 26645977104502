import { combineReducers } from 'redux';
import { appLoaderReducer } from 'components/appLoader/app-loader.reducer';
import { beneficiaryManagementReducer } from 'features/ComparisonTable/components/Beneficiary/BeneficairyManagement/state/beneficiary.management.reducer';
import { personManagementReducer } from 'features/PersonManagement/state';
import { processingFlowReducer } from 'features/ProcessingFlow/state';
import { unauthorizedModalReducer } from 'components/UnauthorizedModal';

export default combineReducers({
  appLoader: appLoaderReducer,
  personManagement: personManagementReducer,
  beneficiaryManagement: beneficiaryManagementReducer,
  processingFlow: processingFlowReducer,
  unauthorizedModal: unauthorizedModalReducer,
});
