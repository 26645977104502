import { Person, PersonProfession } from 'entities/persons';

export interface ProfessionStateProps {
  businessId: string;
  isKid: boolean;
  isSelfEmployable: boolean;
  person?: Person;
  isPersonPresentInAnyPackage: boolean;
}

export interface ProfessionDispatchProps {
  setProfession: (
    personId: Person['personId'],
    businessId: string,
    professionId: PersonProfession['professionId']
  ) => void;
  setSelfEmployment: (
    personId: Person['personId'],
    businessId: string,
    selfEmployed: boolean
  ) => void;
  setRiskGroup: (
    personId: Person['personId'],
    businessId: string,
    professionId: PersonProfession['professionId'],
    riskGroup: PersonProfession['riskGroup']
  ) => void;
}

export interface ProfessionOwnProps {
  personId: string;
}

export type ProfessionComponentProps = ProfessionStateProps &
  ProfessionDispatchProps &
  ProfessionOwnProps;

export enum ProfessionRiskTypes {
  GROUP_A = 'GEFAHRENGRUPPE_A',
  GROUP_B = 'GEFAHRENGRUPPE_B',
  GROUP_C = 'GEFAHRENGRUPPE_C',
  GROUP_D = 'GEFAHRENGRUPPE_D',
  GROUP_S = 'NICHT_VERSICHERBAR',
  GROUP_X = 'NICHT_EINGESTUFT',
}
