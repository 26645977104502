import React, { useEffect, useMemo } from 'react';
import ControlWithHint from '@eg/elements/ControlWithHint';
import { isEqual, template } from 'lodash';

import { useFieldTouched } from './hooks/useFieldTouched';
import { useFieldValidation } from './hooks/useFieldValidation';
import { useFieldRangeValidation } from './hooks/useFieldRangeValidation';
import { useFieldStepRangeValidation } from './hooks/useFieldStepRangeValidation';
import { FieldError } from './FieldError';
import { FieldProps } from './Fields.types';
import { formatNumberWithThousands } from 'services/number.service';
import lb from './labels';
import './Field.css';

export const Field: React.FC<FieldProps> = ({
  children,
  className = '',
  errors = [],
  label = '',
  required = false,
  min = -Infinity,
  max = Infinity,
  stepRange,
  allowEmptyValue = false,
  frontendValidationsByComposedId,
  updateFrontendValidation,
  id,
}: FieldProps) => {
  const { onBlurHandler } = useFieldTouched(children);
  const hasEmptyValue = !useFieldValidation(children);
  const hasInvalidStepRangeValue = !useFieldStepRangeValidation(children, stepRange);
  const hasInvalidRange = !useFieldRangeValidation(children, min, max);
  const labelMin = min === -Infinity ? 0 : formatNumberWithThousands(min);
  const labelMax =
    max === Infinity ? formatNumberWithThousands(999999999) : formatNumberWithThousands(max);

  const mandatoryFieldErrors = useMemo(
    () => (!allowEmptyValue && hasEmptyValue ? [lb.mandatoryField] : []),
    [allowEmptyValue, hasEmptyValue]
  );
  const invalidRangeErrors = useMemo(
    () => (hasInvalidRange ? [template(lb.invalidRange)({ min: labelMin, max: labelMax })] : []),
    [hasInvalidRange, labelMin, labelMax]
  );
  const invalidStepRangeErrors = useMemo(
    () =>
      hasInvalidStepRangeValue && !hasInvalidRange
        ? [template(lb.invalidStepRange)({ stepRange })]
        : [],
    [hasInvalidStepRangeValue, hasInvalidRange, stepRange]
  );

  const localErrors = useMemo(
    () => [...mandatoryFieldErrors, ...invalidRangeErrors, ...invalidStepRangeErrors],
    [mandatoryFieldErrors, invalidRangeErrors, invalidStepRangeErrors]
  );
  const extendedErrors = [...errors, ...localErrors];

  const hasValueAndOrIsRequired = !hasEmptyValue || required;

  const errorMsg =
    hasValueAndOrIsRequired && extendedErrors && extendedErrors.length ? (
      <FieldError errors={extendedErrors} />
    ) : (
      false
    );

  useEffect(() => {
    const errorsToUpdate = hasValueAndOrIsRequired && localErrors.length ? localErrors : undefined;

    if (!id || isEqual(frontendValidationsByComposedId?.[id], errorsToUpdate)) return;

    updateFrontendValidation({ [id]: errorsToUpdate });
  }, [
    localErrors,
    id,
    frontendValidationsByComposedId,
    hasValueAndOrIsRequired,
    updateFrontendValidation,
  ]);

  return (
    <div className={`field__wrapper ${className}`.trimEnd()}>
      <ControlWithHint error={errorMsg}>
        <>
          {React.isValidElement(children)
            ? React.cloneElement(children, {
                ...children.props,
                onBlur: onBlurHandler,
              } as React.HTMLProps<HTMLInputElement>)
            : children}
          {label && (
            <span title={label} className='field__label'>
              {label}
            </span>
          )}
        </>
      </ControlWithHint>
    </div>
  );
};
