import { AnyAction } from 'redux';
import { PersonsActionTypes } from 'entities/persons';
import { AppLoaderReducer } from './app-loader.interface';
import { AppLoaderActionTypes } from './app-loader.actions';
import { OfferActionsTypes } from 'entities/offer';

const defaultState: AppLoaderReducer = {
  isVisible: false,
};

const objectUpdate = (oldObject: Record<string, any>, updateObject: Record<string, any>) => ({
  ...oldObject,
  ...updateObject,
});

// TODO: Move tracking loading separately for each entity and use selectors to toggle app loader
export const appLoaderReducer = (state: AppLoaderReducer = defaultState, action: AnyAction) => {
  switch (action.type) {
    case AppLoaderActionTypes.IS_LOADING:
      return objectUpdate(state, { isVisible: action.payload.isVisible });
    case PersonsActionTypes.SET_PROFESSION_TRIGGER:
    case PersonsActionTypes.SET_PROFESSION_RISKGROUP_TRIGGER:
    case PersonsActionTypes.SET_SELFEMPLOYMENT_TRIGGER:
    case OfferActionsTypes.CANCEL_REQUEST_TRIGGER:
      return objectUpdate(state, { isVisible: true });
    case OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS:
    case OfferActionsTypes.ADD_PERSON_ROLE_FAILURE:
    case OfferActionsTypes.DELETE_PERSON_ROLE_SUCCESS:
    case OfferActionsTypes.DELETE_PERSON_ROLE_FAILURE:
    case PersonsActionTypes.SET_PROFESSION_SUCCESS:
    case PersonsActionTypes.SET_PROFESSION_FAILURE:
    case PersonsActionTypes.SET_SELFEMPLOYMENT_SUCCESS:
    case PersonsActionTypes.SET_SELFEMPLOYMENT_FAILURE:
    case PersonsActionTypes.SET_PROFESSION_RISKGROUP_SUCCESS:
    case PersonsActionTypes.SET_PROFESSION_RISKGROUP_FAILURE:
    case OfferActionsTypes.CANCEL_REQUEST_FAILURE:
    case OfferActionsTypes.CANCEL_REQUEST_SUCCESS:
      return objectUpdate(state, { isVisible: false });
    default:
      return {
        ...state,
      };
  }
};
