import React, { useContext } from 'react';
import parseISO from 'date-fns/parseISO';
import addYears from 'date-fns/addYears';
import isFuture from 'date-fns/isFuture';
import { ModalContext, ModalMode, TooltipType, TooltipWrapper } from 'components';
import { PersonRole } from 'entities/persons';
import { RoleAction } from 'entities/offer';
import { RoleBadge } from '../RoleBadge';
import { RoleBadgesProps, TransferablePersonRole } from './RoleBadges.types';

import T from './RoleBadges.labels';
import './RoleBadges.scss';

const personIsFullAge = (date: string): boolean => {
  if (!date) return false;
  const parseDate = parseISO(date);
  const newDate = addYears(parseDate, 18);
  return !isFuture(newDate);
};

const invalidInsuredOwnerBirthDate = (role: PersonRole, date: string): boolean =>
  role === PersonRole.INSURANCE_OWNER && !!date && !personIsFullAge(date);

const tooltipMessage = (role: PersonRole, date: string): string => {
  switch (true) {
    case !date:
      return T.tooltipMissingBirthDateMessage;
    case invalidInsuredOwnerBirthDate(role, date):
      return T.tooltipInvalidBirthDateMessage;
    default:
      return '';
  }
};

export const RoleBadges: React.FC<RoleBadgesProps> = ({
  personId,
  roles,
  toggleRole,
  isDisabled,
  maxInsuredPersonsNumberReached,
  personBirthDate,
  hasAssociationMemberData,
  insuranceOwnerIsEdwinInitialized,
}) => {
  const { openModal, setContent } = useContext(ModalContext);

  const roleBadges = TransferablePersonRole.map((role) => {
    const isRoleSelected = roles.includes(role);

    const maxNumberOfRoleReached =
      role === PersonRole.INSURED_PERSON && maxInsuredPersonsNumberReached && !isRoleSelected;

    const onRoleChangeHandler = async () => {
      const roleAction = isRoleSelected ? RoleAction.DESELECTION : RoleAction.SELECTION;
      if (
        role === PersonRole.INSURANCE_OWNER &&
        roleAction === RoleAction.SELECTION &&
        insuranceOwnerIsEdwinInitialized
      ) {
        setContent({
          content: T.edwinInsuranceOwnerChangePopupContent,
          mode: ModalMode.INFO,
        });
        await openModal();
      }

      if (role === PersonRole.INSURANCE_OWNER && hasAssociationMemberData) {
        setContent({
          content: T.associationMemberDataDeletePopupMessage,
        });
        await openModal(() =>
          toggleRole(personId, PersonRole.INSURANCE_OWNER, RoleAction.SELECTION)
        );
      } else {
        return toggleRole(personId, role, roleAction);
      }
    };

    const personBirthDayTooltipErrorMsg = tooltipMessage(role, personBirthDate);

    return {
      role,
      onRoleChangeHandler,
      personBirthDayTooltipErrorMsg,
      maxNumberOfRoleReached,
      isRoleSelected,
    };
  });

  return (
    <div className='role-badges'>
      {roleBadges.map(
        ({
          role,
          onRoleChangeHandler,
          personBirthDayTooltipErrorMsg,
          maxNumberOfRoleReached,
          isRoleSelected,
        }) => {
          return (
            <TooltipWrapper
              key={`TooltipWrapper.${personId}.${role}`}
              tooltipUuid={`${personId}-${role}`}
              showTooltip={!!personBirthDayTooltipErrorMsg}
              tooltipMessage={personBirthDayTooltipErrorMsg}
              tooltipType={TooltipType.ERROR}
            >
              <RoleBadge
                role={role}
                isSelected={isRoleSelected}
                onChangeRoleHandler={onRoleChangeHandler}
                isDisabled={isDisabled || maxNumberOfRoleReached || !!personBirthDayTooltipErrorMsg}
              />
            </TooltipWrapper>
          );
        }
      )}
    </div>
  );
};
