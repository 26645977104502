import React, { ChangeEvent, useEffect, useState } from 'react';
import { isValidIban } from '@eg/elements/utils/validation/iban';
import FormSection from '@eg/elements/FormSection';
import InputRow from '@eg/elements/InputRow';
import MessageBox from '@eg/elements/MessageBox';
import { Loading } from '../../components';
import { PaymentDataProps } from './PaymentData.types';
import styles from './PaymentData.module.scss';
import { Details } from '@eg/elements/Details';

export const PaymentData: React.FC<PaymentDataProps> = ({
  hasError,
  iban,
  isLoading,
  updateIban,
}) => {
  const [ibanInput, setIbanInput] = useState<string>(iban || '');
  const [showIbanError, setShowIbanError] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoading) {
      setIbanInput(iban);
    }
  }, [iban, isLoading]);

  const onChangeIbanHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const ibanInput = value.replace(/\s/g, '');
    setShowIbanError(false);
    setIbanInput(ibanInput);
  };

  const onBlurIbanHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: ibanInput } = event.currentTarget;
    if (isValidIban(ibanInput)) {
      setShowIbanError(false);
      updateIban(ibanInput);
    } else {
      setShowIbanError(true);
    }
  };

  return (
    <Details className='comparison-table__details' summary='Bankdaten' isInitiallyOpen>
      <FormSection className={styles.PaymentData}>
        <Loading loading={isLoading}>
          <InputRow
            label='IBAN'
            name='iban'
            maxLength={34}
            onChange={onChangeIbanHandler}
            onBlur={onBlurIbanHandler}
            value={ibanInput}
            className={styles.PaymentDataInput}
            error={
              showIbanError
                ? 'Diese IBAN ist nicht korrekt, bitte füllen Sie das Feld noch einmal aus.'
                : false
            }
            disabled={isLoading}
          />

          {hasError && (
            <MessageBox type='error'>
              Die Bankdaten wurden nicht gespeichert, etwas ist schlimm gegangen.
            </MessageBox>
          )}
        </Loading>
      </FormSection>
    </Details>
  );
};
