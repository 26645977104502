import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAction, createAsyncAction } from 'services';
import {
  OfferProfessionResponse,
  Person,
  SetProfessionPayload,
  SetProfessionRiskGroupPayload,
  SetSelfEmploymentPayload,
} from './persons.interface';

export enum PersonsActionTypes {
  ADD_PERSON = '[entities][persons] ADD_PERSON',
  UPDATE_PERSON = '[entities][persons] UPDATE_PERSON',
  UPDATE_PERSON_AND_UPDATE_OFFER = '[entities][persons] UPDATE_PERSON_AND_UPDATE_OFFER',
  CREATE_PERSON_TRIGGER = '[entities][persons] CREATE_PERSON_TRIGGER',
  CREATE_PERSON_SUCCESS = '[entities][persons] CREATE_PERSON_SUCCESS',
  CREATE_PERSON_FAILURE = '[entities][persons] CREATE_PERSON_FAILURE',
  FETCH_ALL_PERSONS_TRIGGER = '[entities][persons] FETCH_ALL_PERSONS_TRIGGER',
  FETCH_ALL_PERSONS_FAILURE = '[entities][persons] FETCH_ALL_PERSONS_FAILURE',
  FETCH_ALL_PERSONS_SUCCESS = '[entities][persons] FETCH_ALL_PERSONS_SUCCESS',
  SET_PROFESSION_TRIGGER = '[entities][persons] SET_PROFESSION_TRIGGER',
  SET_PROFESSION_FAILURE = '[entities][persons] SET_PROFESSION_FAILURE',
  SET_PROFESSION_SUCCESS = '[entities][persons] SET_PROFESSION_SUCCESS',
  SET_PROFESSION_RISKGROUP_TRIGGER = '[entities][persons] SET_PROFESSION_RISKGROUP_TRIGGER',
  SET_PROFESSION_RISKGROUP_FAILURE = '[entities][persons] SET_PROFESSION_RISKGROUP_FAILURE',
  SET_PROFESSION_RISKGROUP_SUCCESS = '[entities][persons] SET_PROFESSION_RISKGROUP_SUCCESS',
  SET_SELFEMPLOYMENT_TRIGGER = '[entities][persons] SET_SELFEMPLOYMENT_TRIGGER',
  SET_SELFEMPLOYMENT_FAILURE = '[entities][persons] SET_SELFEMPLOYMENT_FAILURE',
  SET_SELFEMPLOYMENT_SUCCESS = '[entities][persons] SET_SELFEMPLOYMENT_SUCCESS',
  REMOVE_PERSON_TRIGGER = '[entities][persons] REMOVE_PERSON_TRIGGER',
  REMOVE_PERSON_SUCCESS = '[entities][persons] REMOVE_PERSON_SUCCESS',
  REMOVE_PERSON_FAILURE = '[entities][persons] REMOVE_PERSON_FAILURE',
}

const setProfession = createAsyncAction(
  PersonsActionTypes.SET_PROFESSION_TRIGGER,
  PersonsActionTypes.SET_PROFESSION_SUCCESS,
  PersonsActionTypes.SET_PROFESSION_FAILURE
)<SetProfessionPayload, OfferProfessionResponse, AjaxError>();

const setProfessionRiskGroup = createAsyncAction(
  PersonsActionTypes.SET_PROFESSION_RISKGROUP_TRIGGER,
  PersonsActionTypes.SET_PROFESSION_RISKGROUP_SUCCESS,
  PersonsActionTypes.SET_PROFESSION_RISKGROUP_FAILURE
)<SetProfessionRiskGroupPayload, OfferProfessionResponse, AjaxError>();

const setSelfEmployment = createAsyncAction(
  PersonsActionTypes.SET_SELFEMPLOYMENT_TRIGGER,
  PersonsActionTypes.SET_SELFEMPLOYMENT_SUCCESS,
  PersonsActionTypes.SET_SELFEMPLOYMENT_FAILURE
)<SetSelfEmploymentPayload, OfferProfessionResponse, AjaxError>();

const createPerson = createAsyncAction(
  PersonsActionTypes.CREATE_PERSON_TRIGGER,
  PersonsActionTypes.CREATE_PERSON_SUCCESS,
  PersonsActionTypes.CREATE_PERSON_FAILURE
)<void, Person, AjaxError>();

const fetchAllPersons = createAsyncAction(
  PersonsActionTypes.FETCH_ALL_PERSONS_TRIGGER,
  PersonsActionTypes.FETCH_ALL_PERSONS_SUCCESS,
  PersonsActionTypes.FETCH_ALL_PERSONS_FAILURE
)<void, Person[], AjaxError>();

const removePerson = createAsyncAction(
  PersonsActionTypes.REMOVE_PERSON_TRIGGER,
  PersonsActionTypes.REMOVE_PERSON_SUCCESS,
  PersonsActionTypes.REMOVE_PERSON_FAILURE
)<{ personId: string }, { personId: string }, AjaxError>();

const addPerson = (person: Partial<Person>) => createAction(PersonsActionTypes.ADD_PERSON, person);

export type UpdatePerson = ReturnType<typeof updatePerson>;
const updatePerson = (person: Partial<Person>) =>
  createAction(PersonsActionTypes.UPDATE_PERSON, person);

export type UpdatePersonAndUpdateOffer = ReturnType<typeof updatePersonAndUpdateOffer>;
const updatePersonAndUpdateOffer = (person: Partial<Person>) =>
  createAction(PersonsActionTypes.UPDATE_PERSON_AND_UPDATE_OFFER, person);

const actions = {
  addPerson,
  updatePerson,
  updatePersonAndUpdateOffer,
};

const asyncActions = {
  setProfession,
  setProfessionRiskGroup,
  setSelfEmployment,
  createPerson,
  fetchAllPersons,
  removePerson,
};

export const personsActions = {
  ...actions,
  ...asyncActions,
};

export type PersonsActions = ActionsUnion<typeof actions, typeof asyncActions>;
