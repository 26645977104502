import React from 'react';
import { get } from 'lodash';
import { Table } from '../Table';
import { VariantFields } from 'entities/offer';
import { variantsConfig } from './VariantsTable.config';
import { VariantsTableProps } from './VariantsTable.types';
import './VariantsTable.css';

const renderLabelComponent = (fieldId: VariantFields) => {
  if (!variantsConfig.hasOwnProperty(fieldId)) {
    return null;
  }

  const labelProps = get(variantsConfig, [fieldId, 'label']);

  return <Table.Label key={`variants.table.label.${fieldId}`} {...labelProps} />;
};

const renderVariantFieldComponent = (
  variantId: string,
  fieldId: VariantFields,
  isReadOnly: boolean
) => {
  if (!variantsConfig.hasOwnProperty(fieldId)) {
    return null;
  }

  const { Component, props } = variantsConfig[fieldId].component;

  return (
    <Component
      key={`variants.table.component.${variantId}.${fieldId}`}
      disabled={isReadOnly}
      {...props}
    />
  );
};

export const VariantsTable: React.FC<VariantsTableProps> = ({
  fieldsIds,
  isOfferLoading,
  variantsIds,
  isReadOnly,
}: VariantsTableProps) => {
  if (!variantsIds.length || !fieldsIds.length) {
    return null;
  }

  return (
    <div className='variants-table'>
      <Table>
        <Table.Labels>
          {fieldsIds.map((fieldId: VariantFields) => renderLabelComponent(fieldId))}
        </Table.Labels>

        {variantsIds.map((variantId: string) => (
          <Table.Variant key={variantId} personId='' variantId={variantId} loading={isOfferLoading}>
            {fieldsIds.map((fieldId: VariantFields) =>
              renderVariantFieldComponent(variantId, fieldId, isReadOnly)
            )}
          </Table.Variant>
        ))}
      </Table>
    </div>
  );
};
