export const DEFAULT_HTTP_HEADERS = {
  'Content-Type': 'application/json',
};

export const REACT_APP_BACKEND_URL: string =
  (window as any).CONFIG_BACKEND_URL || process.env.REACT_APP_BACKEND_URL;

export const REACT_APP_PERSONAL_DATA_BUNDLE_URL: string =
  (window as any).CONFIG_PERSONAL_DATA_BUNDLE_URL || process.env.REACT_APP_PERSONAL_DATA_BUNDLE_URL;

export const REACT_APP_PERSONAL_DATA_BACKEND_URL: string =
  (window as any).CONFIG_PERSONAL_DATA_BACKEND_URL ||
  process.env.REACT_APP_PERSONAL_DATA_BACKEND_URL;

export const REACT_APP_USE_LOGGER: boolean =
  String((window as any).CONFIG_USE_LOGGER) === 'true' ||
  process.env.REACT_APP_USE_LOGGER === 'true';
