export enum ErrorMessages {
    DEFAULT = 'Es ist ein Fehler im IDD Formular aufgetreten',
    NO_IDD = 'Das Backend lieferte keine iddID',
    NO_UUID = 'Es gab einen Fehler bei der Kommunikation mit dem Backend, es wurde keine UUID zurückgegeben.',
    // tslint:disable-next-line:max-line-length
    NO_CONTEXT = 'Das Formular muss mit einem (Versicherungs-) Kontext aufgerufen werden. Nähere Informationen dazu finden sie in der README',
    SAVE_ERROR = 'Es gab einen Fehler bei der Kommunikation mit dem Backend beim versuch das Formular zu validieren',
    // tslint:disable-next-line:max-line-length
    DENIAL_ERROR = 'Es gab einen Fehler bei der Kommunikation mit dem Backend, der Beratungsverzicht konnte nicht ausgeführt werden.'
}
