import { uniq } from 'lodash';
import { createSelector } from 'reselect';
import { offerSelectors } from 'entities/offer';
import { orderSelectors } from 'entities/order';

export const getAllErrorMessages = createSelector(
  offerSelectors.getErrorMessagesText,
  orderSelectors.getErrorMessagesText,
  (offerEM, orderEM) => uniq([...offerEM, ...orderEM])
);

export const hasErrors = createSelector(
  getAllErrorMessages,
  (errorMessages) => !!errorMessages.length
);
