import { RoleAction } from 'entities/offer';
import { PersonRole } from 'entities/persons';

export interface RoleBadgesOwnProps {
  personId: string;
}

export interface RoleBadgesStateProps {
  roles: PersonRole[];
  insuranceOwnerIsEdwinInitialized: boolean;
  isDisabled: boolean;
  maxInsuredPersonsNumberReached: boolean;
  personBirthDate: string;
  hasAssociationMemberData: boolean;
}

export interface RoleBadgesDispatchProps {
  toggleRole(personId: string, roles: PersonRole, roleAction: RoleAction): void;
}

export type RoleBadgesProps = RoleBadgesOwnProps & RoleBadgesStateProps & RoleBadgesDispatchProps;

export const TransferablePersonRole = [PersonRole.INSURED_PERSON, PersonRole.INSURANCE_OWNER];
