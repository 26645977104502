import { createSelector } from 'reselect';
import { Message, MessageSeverities } from 'types';
import { RootState } from 'store/store.interface';

const getError = (state: RootState) => state.entities.order.error;

const hasError = createSelector(getError, (error) => !!error);

const isFinalized = (state: RootState) => state.entities.order.finalized;

const isLoading = (state: RootState) => state.entities.order.loading;

const getMessages = (state: RootState) => state.entities.order.messages;

const getAllMessages = createSelector(getMessages, (messages) =>
  messages.map((message: Message) => message.text)
);

const getErrorMessages = createSelector(getMessages, (messages) =>
  messages.filter((message: Message) => message.severity === MessageSeverities.error)
);

const getErrorMessagesText = createSelector(getErrorMessages, (messages) =>
  messages.map((message: Message) => message.text || message.code)
);

const getWarningMessages = createSelector(getMessages, (messages) =>
  messages.filter((message: Message) => message.severity === MessageSeverities.warning)
);

const getWarningMessagesText = createSelector(getWarningMessages, (messages) =>
  messages.map((message: Message) => message.text || message.code)
);

const hasMessages = createSelector(getMessages, (messages) => !!messages.length);

const hasErrorMessages = createSelector(getMessages, (messages) =>
  messages.some((message: Message) => message.severity === MessageSeverities.error)
);

const hasWarningMessages = createSelector(getMessages, (messages) =>
  messages.some((message: Message) => message.severity === MessageSeverities.warning)
);

export const orderSelectors = {
  getAllMessages,
  getError,
  getErrorMessages,
  getErrorMessagesText,
  getWarningMessages,
  getWarningMessagesText,
  getMessages,
  hasError,
  hasErrorMessages,
  hasMessages,
  hasWarningMessages,
  isFinalized,
  isLoading,
};
