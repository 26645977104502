import { combineReducers } from 'redux';
import { agencyReducer } from 'entities/agency/agency.reducer';
import { appReducer } from 'entities/app';
import { contractDetailsReducer } from 'entities/contractDetails';
import { offerReducer } from 'entities/offer';
import { orderReducer } from 'entities/order';
import { paymentDataReducer } from 'entities/paymentData';
import { personsReducer } from 'entities/persons';

export default combineReducers({
  app: appReducer,
  agency: agencyReducer,
  contractDetails: contractDetailsReducer,
  offer: offerReducer,
  order: orderReducer,
  persons: personsReducer,
  paymentData: paymentDataReducer,
});
