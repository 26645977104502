import React from 'react';
import { ValidationIconProps } from './ValidationIcon.types';
import './ValidationIcon.scss';
import RefreshIcon from '@eg/elements/Icons/RefreshIcon';
import CloseBorderIcon from '@eg/elements/Icons/CloseBorderIcon';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import { ValidationStatus } from 'entities/persons';

export const ValidationIcon: React.FC<ValidationIconProps> = ({ validationStatus }) => {
  switch (validationStatus) {
    case ValidationStatus.VALID:
      return <CheckIcon className={'validation-icon validation-icon--valid'} />;
    case ValidationStatus.INVALID:
      return <CloseBorderIcon className={'validation-icon validation-icon--invalid'} />;
    default:
      return <RefreshIcon className={'validation-icon validation-icon--unknown'} />;
  }
};
