import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AppComponent from './app.component';
import { appActions } from 'entities/app';
import { isLoading } from './app.selectors';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState) => ({
  isDirectOffer: offerSelectors.isDirectOffer(state),
  isFeeCalculated: offerSelectors.isSelectedVariantPriceNotNull(state),
  isLoading: isLoading(state),
  isPaperOffer: offerSelectors.isPaperOffer(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchInit: () => dispatch(appActions.fetchInit()),
  setBusinessId: (businessId: string) => dispatch(appActions.setBusinessId(businessId)),
});

export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
