import React from 'react';
import { Checkbox, Select, Static, DatePicker, VariantRadioSelect } from '../fields';
import { VariantFields } from 'entities/offer';
import { VariantFieldConfig } from './VariantsTable.types';

export const variantsConfig: Record<VariantFields, VariantFieldConfig> = {
  [VariantFields.selected]: {
    label: {
      text: 'Auswahl',
    },
    component: {
      Component: VariantRadioSelect,
      props: {
        field: VariantFields.selected,
      },
    },
  },
  [VariantFields.tariff]: {
    label: {
      text: 'Produktvariante',
    },
    component: {
      Component: Static,
      props: {
        field: VariantFields.tariff,
      },
    },
  },
  [VariantFields.insuranceStartDate]: {
    label: {
      text: 'Versicherungsbeginn',
      tooltipText: (
        <>
          <h1>Versicherungsbeginn</h1>
          <p>
            Versicherungsbeginn ist der von Ihnen <b>gewählte Tag</b> – frühestens der Tag nach der
            Beantragung.
          </p>
          <p>
            Sie können Ihren Unfallschutz <b>bis zu ein Jahr im Voraus</b> beantragen.
          </p>
        </>
      ),
    },
    component: {
      Component: DatePicker,
      props: {
        field: VariantFields.insuranceStartDate,
      },
    },
  },
  [VariantFields.insuranceEndDate]: {
    label: {
      text: 'Enddatum',
    },
    component: {
      Component: DatePicker,
      props: {
        field: VariantFields.insuranceEndDate,
        required: true,
      },
    },
  },
  [VariantFields.durationOfContract]: {
    label: {
      text: 'Versicherungsdauer',
      tooltipText: (
        <>
          <h1>Vertragsdauer</h1>
          <p>
            <b>1 Jahr:</b> Der Vertrag verlängert sich nach Ablauf der Vertragsdauer{' '}
            <b>stillschweigend</b> von Jahr zu Jahr. Ausnahme: Dem Vertragspartner ist spätestens 3
            Monate vor dem jeweiligen Ablauf eine Kündigung zugegangen.
          </p>
          <p>
            <b>3 Jahre:</b> Entscheiden Sie sich für eine{' '}
            <b>Vertragsdauer von mindestens 3 Jahren</b>, erhalten Sie <b>10 % Nachlass</b> auf
            Ihren Nettobeitrag und können den Vertrag zum Ende des 3. oder jedes folgenden Jahres
            kündigen. Vorausgesetzt, Sie halten eine Frist von 3 Monaten ein.
          </p>
        </>
      ),
    },
    component: {
      Component: Select,
      props: {
        field: VariantFields.durationOfContract,
      },
    },
  },
  [VariantFields.paymentMethod]: {
    label: {
      text: 'Zahlweise',
      tooltipText: (
        <>
          <h1>Zahlweise</h1>
          <p>Je nach Zahlungsweise enthält Ihr Beitrag diese Ratenzahlungszuschläge:</p>
          <ul>
            <li>Monatlich: 6 %</li>
            <li>Vierteljährlich: 5 %</li>
            <li>Halbjährlich: 3 %</li>
            <li>Jährlich: Kein Zuschlag</li>
          </ul>
        </>
      ),
    },
    component: {
      Component: Select,
      props: {
        field: VariantFields.paymentMethod,
      },
    },
  },
  [VariantFields.debitDay]: {
    label: {
      text: 'Abbuchung zum',
    },
    component: {
      Component: Select,
      props: {
        field: VariantFields.debitDay,
      },
    },
  },
  [VariantFields.dynamicValue]: {
    label: {
      text: 'Dynamik',
      tooltipText: (
        <>
          <h1>Dynamik</h1>
          <p>
            Dynamik bedeutet: Die Versicherungssummen für bestimmte Leistungsarten je versicherte
            Person in Ihrem Vertrag <b>erhöhen sich jedes Jahr um 3 %.</b>
            Damit erhöht sich auch der Beitrag. Und zwar immer zum Beginn des Versicherungsjahres
            Ihres Vertrages.
          </p>
          <p>
            ERGO informiert Sie jedes Jahr vorher über die Dynamik. Wenn Sie{' '}
            <b>keine Erhöhung möchten,</b> können Sie widersprechen oder widerrufen. In diesem Fall
            findet keine planmäßige Erhöhung der Leistungen und Beiträge mehr statt.
          </p>
          <p>Planmäßig erhöht werden:</p>
          <ul>
            <li>Kapitalleistung bei Invalidität</li>
            <li>Unfall-Rente</li>
            <li>Krankenhaus-Tagegeld</li>
            <li>Todesfallleistung</li>
          </ul>
          <p>
            In den Jahren einer altersbedingten Beitragsanpassung <b>entfällt</b> die dynamische
            Anpassung. Die Dynamik <b>endet</b> zum Ablauf des Versicherungsjahres, in dem die
            versicherte Person Ihren 55. Geburtstag hat.
          </p>
        </>
      ),
    },
    component: {
      Component: Checkbox,
      props: {
        field: VariantFields.dynamicValue,
      },
    },
  },
};
