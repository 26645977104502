import { AnyAction } from 'redux';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { AddRoleSuccess, OfferActionsTypes, offerHelper, offerSelectors } from '../offer';
import { makeApiCall, buildEndpoint, ENDPOINTS } from 'services';
import { paymentDataActions, PaymentDataTypes } from './payment.data.actions';
import { paymentDataSelectors } from './payment.data.selectors';
import { PersonRole } from '../persons';
import { RootState, RootActions } from 'store/store.interface';

export const onAddRoleSuccess: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS),
    filter(
      (action) =>
        (action as AddRoleSuccess)?.context?.addedRole === PersonRole.INSURANCE_OWNER &&
        paymentDataSelectors.hasPaymentId(state$.value)
    ),
    map(() => paymentDataActions.deletePaymentData.trigger())
  );

export const onChangeSalesProcess: Epic<AnyAction, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.UPDATE_OFFER_BASIC_DATA_SUCCESS),
    filter(
      (action) =>
        offerHelper.hasSalesProcessChanged(action.context.updatedFields) &&
        paymentDataSelectors.hasPaymentId(state$.value)
    ),
    map(() => paymentDataActions.deletePaymentData.trigger())
  );

export const onDeletePaymentData: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(PaymentDataTypes.DELETE_PAYMENT_DATA_TRIGGER),
    mergeMap(() =>
      makeApiCall({
        method: 'delete',
        url: buildEndpoint(ENDPOINTS.deletePaymentData, {
          businessId: offerSelectors.getBusinessId(state$.value),
        }),
      }).pipe(
        map((data) => paymentDataActions.deletePaymentData.success(data.response)),
        catchError((error) => of(paymentDataActions.deletePaymentData.failure(error)))
      )
    )
  );

export const onUpdatePaymentData: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(PaymentDataTypes.UPDATE_PAYMENT_DATA_TRIGGER),
    mergeMap((action) =>
      makeApiCall({
        method: 'put',
        url: buildEndpoint(ENDPOINTS.updatePaymentData),
        body: {
          ...paymentDataSelectors.getUpdatePaymentDataPayload(state$.value),
          iban: action.payload.iban,
        },
      }).pipe(
        map((data) =>
          paymentDataActions.updatePaymentData.success({
            id: data.response.id,
            accountId: data.response.accountId,
            iban: data.response.iban,
          })
        ),
        catchError((error) => of(paymentDataActions.updatePaymentData.failure(error)))
      )
    )
  );

export const paymentDataEpics = combineEpics(
  onAddRoleSuccess,
  onChangeSalesProcess,
  onDeletePaymentData,
  onUpdatePaymentData
);
