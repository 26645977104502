import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'store/store.interface';
import { BeneficiaryComponent } from './Beneficiary.component';
import {
  BeneficiaryStateProps,
  BeneficiaryOwnProps,
  BeneficiaryDispatchProps,
} from './Beneficiary.types';
import { offerSelectors } from 'entities/offer';
import { personsSelectors } from 'entities/persons';
import { beneficiaryActions } from './state/beneficiary.actions';

const mapStateToProps = (
  state: RootState,
  ownProps: BeneficiaryOwnProps
): BeneficiaryStateProps => ({
  isDirectOffer: offerSelectors.isDirectOffer(state),
  hasDeathBenefit: offerSelectors.hasDeathBenefitForSelectedVariant(state, ownProps.personId),
  hasPersonBeneficiaries: personsSelectors.hasInsuredPersonBeneficiaries(state, ownProps.personId),
  totalShare: personsSelectors.countInsuredPersonBeneficiariesShare(state, ownProps.personId),
});

const mapDispatchToProps = (dispatch: Dispatch): BeneficiaryDispatchProps => ({
  removeBeneficiaries: (personId: string) =>
    dispatch(beneficiaryActions.removeBeneficiaries.trigger({ personId })),
});

export const Beneficiary = connect(mapStateToProps, mapDispatchToProps)(BeneficiaryComponent);
