import { PaymentType } from '../../../StickySidebar/components/Payment';

export const TEXTS = {
  REPLACEMENT_CONTRACT: {
    CHECKBOX: {
      LABEL: 'Ersatzgeschäft',
    },
    POLICY_NUMBER: {
      LABEL: 'Versicherungsscheinnummer',
      ERROR_MESSAGE:
        'Bitte überprüfen Sie Ihre Eingabe. Die Vertragsnummer beginnt mit SV oder UV mit nachfolgend bis zu 9 Ziffern.',
    },
    CURRENT_CONTRACT_PREMIUM: {
      LABEL: 'Beitrag laufender Vertrag',
      ERROR_MESSAGE: 'Der Beitrag darf maximal 4-Stellen + 2 Kommastellen haben.',
    },
    PAYMENT_METHOD: {
      LABEL: 'Zahlweise laufender Vertrag',
    },
  },
  DEFAULT_SELECTED_OPTION: {
    LABEL: 'Bitte auswählen',
    KEY: 'EMPTY',
  },
};

export enum PaymentPeriodLabel {
  Monthly = 'monatlich',
  Quarterly = 'vierteljährlich',
  HalfYearly = 'halbjährlich',
  Yearly = 'jährlich',
  OneTimePayment = 'Einmalzahlung',
}

export type CommonSelectOptions<LabelType extends string, ValueType extends string> = {
  label: LabelType;
  value: ValueType;
}[];

export const PAYMENT_PERIOD_OPTIONS: CommonSelectOptions<PaymentPeriodLabel, PaymentType> = [
  {
    label: PaymentPeriodLabel.Monthly,
    value: PaymentType.Monthly,
  },
  {
    label: PaymentPeriodLabel.Quarterly,
    value: PaymentType.Quarterly,
  },
  {
    label: PaymentPeriodLabel.HalfYearly,
    value: PaymentType.HalfYearly,
  },
  {
    label: PaymentPeriodLabel.Yearly,
    value: PaymentType.Yearly,
  },
  {
    label: PaymentPeriodLabel.OneTimePayment,
    value: PaymentType.OneTimePayment,
  },
];

export const POLICY_NUMBER_REGEX = /^(UV|SV)\d{1,9}$/;
export const CURRENT_CONTRACT_PREMIUM_REGEX = /^([1-9]|[1-9]\d{1,3}),\d{1,2}$/;
