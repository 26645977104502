import { connect } from 'react-redux';
import { partial } from 'lodash';
import { Currency as CurrencyComponent } from './Currency';
import {
  CurrencyDispatchProps,
  CurrencyMergeProps,
  CurrencyOwnProps,
  CurrencyStateProps,
} from './Currency.types';
import { mapDispatchToProps } from '../fields.helpers';
import { offerSelectors, TableFields } from 'entities/offer';
import { RootState } from 'store/store.interface';

const makeFieldMapState = (state: RootState, ownProps: CurrencyOwnProps<TableFields>) => {
  const { personId, variantId, field } = ownProps;
  const fieldSelector = offerSelectors.getFieldOrEmptyPlaceholderForNumberAsString(
    personId!,
    variantId!,
    field
  );

  return (state: RootState): CurrencyStateProps => fieldSelector(state);
};

const mergeProps = (
  stateProps: CurrencyStateProps,
  dispatchProps: CurrencyDispatchProps,
  ownProps: CurrencyOwnProps
): CurrencyMergeProps => {
  const { setCorrupted, updateVariant, ...restDispatchProps } = dispatchProps;
  return {
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    setCorrupted: partial(
      setCorrupted,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
    updateVariant: partial(
      updateVariant,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
  };
};

export const Currency = connect(
  makeFieldMapState,
  mapDispatchToProps,
  mergeProps
)(CurrencyComponent);
