import React, { ReactNode } from 'react';
import ComparisonTable from '@eg/elements/ComparisonTable';
import { Label } from './components';
import { VariantProps } from './Table.types';
import { useTableLayout } from './useTableLayout';

export const Table = ({ children, personId = '' }: { children: ReactNode; personId?: string }) => {
  const [labels, variants] = useTableLayout(children);

  return (
    <ComparisonTable seperateRowsBy='border'>
      <thead>
        <tr>
          <th />
          {variants.map((variant) => (
            <th key={variant.variantId}>{variant.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {labels &&
          React.Children.map(labels.props.children, (label, index) => (
            <tr key={`${label}.${index}`}>
              <td>{label}</td>
              {variants.map((variant) => (
                <td key={`${personId}.${variant.variantId}.${index}`}>{variant.elements[index]}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </ComparisonTable>
  );
};

const Variant: React.FC<VariantProps> = ({ children }: { children: ReactNode }) => <>{children}</>;
const Labels = ({ children }: { children: ReactNode }) => <>{children}</>;

Table.Variant = Variant;
Table.Labels = Labels;
Table.Label = Label;
