import { connect } from 'react-redux';
import { partial } from 'lodash';
import { YearsInput as YearsInputComponent } from './YearsInput';
import {
  YearsInputOwnProps,
  YearsInputStateProps,
  YearsInputDispatchProps,
  YearsInputMergeProps,
} from './YearsInput.types';
import { mapDispatchToProps } from '../fields.helpers';
import { offerSelectors, TableFields } from 'entities/offer';
import { RootState } from 'store/store.interface';

const makeFieldMapState = (state: RootState, ownProps: YearsInputOwnProps<TableFields>) => {
  const { personId, variantId, field } = ownProps;
  const fieldSelector = offerSelectors.getFieldOrEmptyPlaceholderForNumberAsString(
    personId!,
    variantId!,
    field
  );

  return (state: RootState): YearsInputStateProps => fieldSelector(state);
};

const mergeProps = (
  stateProps: YearsInputStateProps,
  dispatchProps: YearsInputDispatchProps,
  ownProps: YearsInputOwnProps
): YearsInputMergeProps => {
  const { setCorrupted, updateVariant, ...restDispatchProps } = dispatchProps;
  return {
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    setCorrupted: partial(
      setCorrupted,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
    updateVariant: partial(
      updateVariant,
      ownProps.personId!,
      ownProps.variantId!,
      ownProps.field as TableFields
    ),
  };
};

export const YearsInput = connect(
  makeFieldMapState,
  mapDispatchToProps,
  mergeProps
)(YearsInputComponent);
