import { of } from 'rxjs';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { AjaxError } from 'rxjs/ajax';
import { catchError, mergeMap, switchMap, map } from 'rxjs/operators';
import { buildEndpoint, ENDPOINTS, ActionByType, makeApiCall } from 'services';
import { RootState } from 'store/store.interface';
import { PersonsActions, personsActions, PersonsActionTypes } from './persons.actions';
import { PersonManagementActions, personManagementActions } from 'features/PersonManagement/state';
import { OfferActions, offerActions } from '../offer';
import { Person, PersonRole } from './persons.interface';
import { personsSelectors } from './persons.selectors';

export const onCreatePersonRequest: Epic<
  PersonsActions | PersonManagementActions,
  PersonsActions | PersonManagementActions,
  RootState
> = (action$) =>
  action$.pipe(
    ofType(PersonsActionTypes.CREATE_PERSON_TRIGGER),
    mergeMap(() => {
      return makeApiCall({
        method: 'post',
        url: buildEndpoint(ENDPOINTS.createPerson),
      }).pipe(
        mergeMap((data) => {
          return [
            personsActions.createPerson.success(data.response),
            personManagementActions.setEditedPerson(data.response),
          ];
        }),
        catchError((error: AjaxError) => of(personsActions.createPerson.failure(error)))
      );
    })
  );

type RemovePersonTrigger = ActionByType<PersonsActions, PersonsActionTypes.REMOVE_PERSON_TRIGGER>;
export const onDeletePersonRequest: Epic<
  PersonsActions | PersonManagementActions | OfferActions,
  PersonsActions | PersonManagementActions | OfferActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(PersonsActionTypes.REMOVE_PERSON_TRIGGER),
    mergeMap((action) => {
      const personId = (action as RemovePersonTrigger).payload.personId;
      const deletedPerson = personsSelectors.getPersonById(state$.value, personId);

      return makeApiCall({
        method: 'delete',
        url: buildEndpoint(ENDPOINTS.deletePerson, { personId }),
      }).pipe(
        mergeMap(() => {
          const deletePersonSuccess = personsActions.removePerson.success({ personId });
          return deletedPerson.roles.includes(PersonRole.INSURED_PERSON)
            ? [deletePersonSuccess, offerActions.fetchOffer()]
            : [deletePersonSuccess];
        }),
        catchError((error: AjaxError) => of(personsActions.removePerson.failure(error)))
      );
    })
  );

export const onFetchAllPersonsRequest: Epic<
  PersonsActions | PersonManagementActions,
  PersonsActions | PersonManagementActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(PersonsActionTypes.FETCH_ALL_PERSONS_TRIGGER),
    switchMap(() => {
      return makeApiCall({
        method: 'get',
        url: buildEndpoint(ENDPOINTS.fetchAllPersons),
      }).pipe(
        map((data) => {
          const personsFilledWithValidationStatus = data.response.map((person: Person) => {
            const pdsValidationStatus = personsSelectors.getPersonById(
              state$.value,
              person.personId
            ).pdsValidationStatus;
            return { ...person, pdsValidationStatus };
          });
          return personsActions.fetchAllPersons.success(personsFilledWithValidationStatus);
        }),
        catchError((error: AjaxError) => of(personsActions.fetchAllPersons.failure(error)))
      );
    })
  );

export const personsEpics = combineEpics(
  onCreatePersonRequest,
  onDeletePersonRequest,
  onFetchAllPersonsRequest
);
