import {
    Context,
    DenialByKontextRequest,
    DenialByUuidRequest,
    ElementText,
    ElementTypes,
    InitAndReadResponse,
    InitRequest,
    InteractiveSectionComponent,
    Section,
    StoreRequest,
    ValuesMap,
    Variable
} from '../models';
import { Textblock } from '../models/Textblock';
import { TextblockTypes } from '../models/Types';

type Partial<T> = { [P in keyof T]?: T[P] };

type PartialSection = Partial<Section>;

export default class MapApi {
    contextToInitRequest(context: Context): InitRequest {
        return {
            kontext: this._getKontext(context),
            textVariablen: this._getVariablen(context)
        };
    }

    contextToStoreRequest(context: Context): StoreRequest {
        return {
            uuid: this._getUuid(context),
            variablen: this._getVariablen(context)
        };
    }

    contextToDenialByUuidRequest(context: Context): DenialByUuidRequest {
        return {
            kontext: null,
            uuid: this._getUuid(context)
        };
    }

    contextToDenialByKontextRequest(context: Context): DenialByKontextRequest {
        return {
            kontext: this._getKontext(context),
            uuid: null
        };
    }

    initOrReadToContext(response: InitAndReadResponse): Context {
        const textblocks = this._mapTextblocks(response);
        let values = this._mapValues(response);
        values = this._setDefaultValues(values, textblocks);

        return {
            uuid: response.uuid,
            values,
            textblocks: textblocks
        };
    }

    private _getKontext(context: Context): string {
        if (!context.kontext) {
            throw new Error('no "kontext" was given in context');
        }
        return context.kontext;
    }

    private _getUuid(context: Context): string {
        if (!context.uuid) {
            throw new Error('no "uuid" was given in context');
        }
        return context.uuid;
    }

    private _getVariablen(context: Context): Array<Variable> {
        if (!context.values) {
            return [];
        }
        let variablen: Array<Variable> = [];
        // map.prototype.forEach(value, key)
        context.values.forEach((wert, name) => {
            variablen.push({ name, wert });
        });
        return variablen;
    }

    private _mapTextblocks(response: InitAndReadResponse): Array<Textblock> | undefined {
        if (!response.texte) {
            return;
        }

        const textblocks: Array<Textblock> = [];

        response.texte.forEach(text => {
            let textblock: Textblock = {
                textblockType: undefined,
                sections: []
            };

            text.textblock.forEach(block => {
                textblock.sections.push({
                    ...this._mapSection(block)
                });
            });

            textblock.textblockType = this._mapTextblockType(textblock.sections);

            textblocks.push({
                ...textblock
            });
        });

        return textblocks;
    }

    private _mapTextblockType(sections: Array<Section>): TextblockTypes | undefined {
        if (sections.length === 0) {
            return;
        }

        if (sections.length === 1) {
            return TextblockTypes.SINGLESECTION;
        }

        if (sections.length > 1) {
            return TextblockTypes.MULTISECTION;
        }
    }

    private _mapSection(block: ElementText): Section {
        let section: Section = {
            heading: {
                display: ''
            },
            hint: undefined,
            elements: [],
            elementType: undefined,
            mandatoryReadingText: undefined
        };

        if (block.text) {
            section.heading.display = block.text;
        }

        if (block.tooltip) {
            section.hint = {
                display: block.tooltip
            };
        }

        section = {
            ...section,
            ...this._mapElementType(block)
        };

        return section;
    }

    private _mapElementType(block: ElementText): PartialSection {
        if (!block.variablen) {
            return {};
        }

        // TextblockHeader
        if (block.variablen.length === 0) {
            return { elementType: ElementTypes.TEXTBLOCKHEADER };
        }

        // Select and Confirmation
        if (block.variablen.length === 1) {
            const elements: Array<InteractiveSectionComponent> = [];
            const vars = block.variablen[0];
            // Select
            if (vars.variablentyp === 'SELECT') {
                if (vars.erlaubteauswahl) {
                    vars.erlaubteauswahl.forEach(allowedValue =>
                        elements.push({
                            backendName: vars.variablenname,
                            display: allowedValue.anzeigetext || '',
                            allowedValue: allowedValue.wert
                        })
                    );
                }
                return {
                    elementType: ElementTypes.SELECT,
                    elements
                };
            }
            // Confirmation
            if (vars.variablentyp === 'BESTAETIGUNG') {
                elements.push({
                    backendName: vars.variablenname,
                    display: vars.anzeigetext || ''
                });

                return {
                    elementType: ElementTypes.CONFIRMATION,
                    elements
                };
            }
        }

        // Multiselect
        if (block.variablen.length >= 1) {
            const elements: Array<InteractiveSectionComponent> = [];
            block.variablen.forEach(vars => {
                if (vars.variablentyp === 'BOOLEAN') {
                    elements.push({
                        backendName: vars.variablenname,
                        display: vars.anzeigetext || ''
                    });
                }
            });

            return {
                elementType: ElementTypes.MULTISELECT,
                elements
            };
        }
        return {};
    }

    private _mapValues(response: InitAndReadResponse): ValuesMap {
        let values: ValuesMap = new Map();
        if (response.textVariablen.length > 0) {
            response.textVariablen.forEach(entry => values.set(entry.name, String(entry.wert)));
        }

        return values;
    }

    /**
     * sets all "BOOLEAN"-like values to false by default,
     * it does not overwrite already existing values (from former mapValues())
     *
     * @param values
     * @param sections
     */
    private _setDefaultValues(values: ValuesMap, textblocks: Array<Textblock> | undefined) {
        if (!textblocks) {
            return values;
        }

        for (let textblock of textblocks) {
            if (!textblock.sections) {
                return values;
            }
            for (let section of textblock.sections) {
                switch (section.elementType) {
                    case ElementTypes.CONFIRMATION:
                    case ElementTypes.MULTISELECT:
                        for (let element of section.elements) {
                            if (!values.has(element.backendName)) {
                                values.set(element.backendName, 'false');
                            }
                        }
                        break;

                    default:
                }
            }
        }

        return values;
    }
}
