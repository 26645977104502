import React, { useEffect, useState } from 'react';
import FormRow from '@eg/elements/FormRow';
import Textarea from '@eg/elements/Textarea';
import lb from 'components/Field/labels';
import { template } from 'lodash';
import { ApprovalNoteProps } from './ApprovalNote.types';
import './ApprovalNote.scss';

const MAX_LENGTH = 7500;

export const ApprovalNote = ({
  label,
  placeholder,
  value = '',
  errors = [],
  onBlur,
}: ApprovalNoteProps) => {
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (errors?.length > 0) {
      setError(errors[0]);
    }
  }, [errors]);

  const onChangeHandler = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = evt.target;
    setInputValue(newValue);
  };

  const onBlurHandler = (evt: React.FocusEvent<HTMLTextAreaElement>) => {
    const { value } = evt.target;
    if (value.length > MAX_LENGTH) {
      setError(template(lb.invalidMaxLength)({ max: MAX_LENGTH }));
    } else {
      setError('');
      onBlur(evt);
    }
  };

  return (
    <FormRow error={error} label={label} boundary='narrow' className='approval-note'>
      <Textarea
        defaultValue={inputValue}
        placeholder={placeholder}
        rows={4}
        minRows={6}
        maxRows={10}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        error={!!error}
      />
    </FormRow>
  );
};
