import { RootState } from 'store/store.interface';

const isAdding = (state: RootState) => state.ui.processingFlow.isAdding;
const isDeleting = (state: RootState) => state.ui.processingFlow.isDeleting;
const getCurrentlyDeletedApprovalClause = (state: RootState) =>
  state.ui.processingFlow.currentlyDeletedApprovalClause;

export const processingFlowSelectors = {
  isAdding,
  isDeleting,
  getCurrentlyDeletedApprovalClause,
};
