import React from 'react';
import { Coverage } from './Coverages.types';

export const NonContributoryCoInsuredCoverages: Coverage = {
  coverages: [
    {
      label: 'Beratungsleistung über Unfall-Assistenten',
      tooltipContent:
        'Nach einem Unfall steht der Unfall-Assistent für Fragen zur Verfügung. Er unterstützt bei der individuellen Bedarfsanalyse und bei Fragen rund um den Unfall oder zur Diagnose, der Vermittlung von Hilfeleistungen, einer Reha- oder einer Pflege-Beratung.',
      availability: {
        smart: true,
        best: true,
      },
      content: {
        smart: null,
        best: null,
      },
    },
    {
      label: 'Kosten für Kosmetische Operationen',
      tooltipContent:
        'Um Unfall-bedingte Beeinträchtigungen des äußeren Erscheinungsbilds zu beheben: Zahnbehandlungen und Zahnersatz bei unfallbedingtem Verlust oder Beschädigung von Zähnen (auch Implantate, Kronen) gelten auch im Rahmen der Heilbehandlung als kosmetische Operationen.',
      availability: {
        smart: null,
        best: null,
      },
      content: {
        smart: (
          <>
            <p>
              bis zu <b>50.000 Euro</b> je versicherter Person
            </p>
          </>
        ),
        best: (
          <>
            <p>
              bis zu <b>50.000 Euro</b> je versicherter Person
            </p>
          </>
        ),
      },
    },
    {
      label: 'Rettungs- und Bergungskosten',
      tooltipContent: (
        <>
          <p>Bei Unfällen im Ausland ersetzen wir zusätzlich im Rahmen der Versicherungssumme:</p>
          <ul>
            <li>
              Dolmetscherkosten, die Heimfahrt- und Unterbringungskosten für mitreisende Partner und
              Kinder.
            </li>
            <li>
              bei unfallbedingtem Tod die Kosten für die Bestattung im Ausland oder die
              Überführungskosten zum letzten ständigen Wohnsitz im Inland.
            </li>
          </ul>
        </>
      ),
      availability: {
        smart: null,
        best: null,
      },
      content: {
        smart: (
          <p>
            bis zu <b>200.000 Euro</b> je versicherter Person
          </p>
        ),
        best: (
          <p>
            bis zu <b>200.000 Euro</b> je versicherter Person
          </p>
        ),
      },
    },
    {
      label: 'Differenzdeckung zusätzlicher Versicherungsschutz',
      tooltipContent: (
        <>
          <h3>Unter welchen Voraussetzungen gilt die Differenzdeckung?</h3>
          <p>
            Der Kunde beantragt eine ERGO Unfallversicherung und mindestens für eine VP die
            Assistenzleistung Unfall-Hilfe oder Unfall-Hilfe Plus mit einem vordatierten Beginn
            (max. 364 Tage in der Zukunft). Er ist zum Zeitpunkt der Beantragung noch bei einem
            anderen Versicherer (ausgenommen sind Unternehmen der ERGO Group) unfallversichert
            (Vorvertrag). Es muss ein 1, 2, 3, 4 oder 5 Jahresvertrag vorliegen.
          </p>
          <h3>Was leistet die Differenzdeckung?</h3>
          <p>
            In der Zeit zwischen Antragstellung und Beginn der Versicherung bieten wir beitragsfrei
            die mit uns vereinbarten Assistenzleistung Unfall-Hilfe bzw. Unfall-Hilfe Plus. Wir
            versichern jeweils die Differenz zwischen dem Umfang der Assistenzleistungen des
            Vorversicherers und der bei uns vereinbarten Unfall-Hilfe bzw. Unfall-Hilfe Plus. Für
            den Fall, dass dem Kunden aus der Unfallversicherung des Vorversicherers diese
            Leistungsinhalte nicht oder nicht in vollem Umfang zur Verfügung stehen, bieten wir ihm
            bereits eine entsprechende Absicherung. Für alle weiteren Leistungen (z.B.
            Kapitalleistung, Krankenhaus-Tagegeld, Business-Hilfe) besteht keine Differenzdeckung.
          </p>
        </>
      ),
      availability: {
        smart: true,
        best: true,
      },
      content: {
        smart: null,
        best: null,
      },
    },
    {
      label: 'Familien-Plus',
      tooltipContent: (
        <>
          <p>
            Heiratet der Antragsteller oder eine andere versicherte Person oder geht der
            Antragsteller oder eine andere versicherte Person eine Eingetragene Lebenspartnerschaft
            oder eine nichteheliche Lebensgemeinschaft ein, ist der Partner / die Partnerin ab
            diesem Tag sechs Monate lang mitversichert.
          </p>
          <p>
            Das gilt auch für minderjährige Kinder, die durch Geburt, Adoption, Heirat oder Gründung
            einer Eingetragenen Lebenspartnerschaft bzw. einer nichtehelichen Lebensgemeinschaft
            hinzugekommen sind. Wird uns die Geburt, Adoption, Heirat, Lebenspartnerschaft oder
            Lebensgemeinschaft innerhalb von sechs Monaten gemeldet, verlängert sich der
            Versicherungsschutz auf zwölf Monate.
          </p>
          <h3>Mitversicherung mitreisender minderjähriger Enkelkinder</h3>
          <p>
            Verreist der Antragsteller oder eine andere versicherte Person mit Ihrem minderjährigen
            Enkelkind (auch Urenkel), ist dieses während der Reise bis zu zwei Monate mitversichert.
            Voraussetzung ist, dass der Urlaubs- oder Ausflugsort mindestens 50 km sowohl vom
            Wohnort der versicherten Person, als auch vom Wohnort des minderjährigen Enkelkindes
            entfernt ist. Für das Familien-Plus und die Mitversicherung mitreisender minderjähriger
            Enkelkinder gelten jeweils die folgenden Versicherungssummen:
          </p>
          <ul>
            <li>
              Kapitalleistung bei Invalidität mit Progression 600% (300%) <b>20.000 Euro</b>.
            </li>
            <li>
              Kosten für kosmetische Operationen <b>50.000 Euro</b>.
            </li>
            <li>
              Rettungs- und Bergungskosten <b>200.000 Euro</b>.
            </li>
          </ul>
          <p>
            Mögliche Leistungserweiterungen, wie z.B. Gliedertaxe Plus, gelten hier nicht. Bestehen
            mehrere Verträge, so können das Familien-Plus sowie die Mitversicherung mitreisender
            minderjähriger Enkelkinder nur aus einem dieser Verträge beansprucht werden.
          </p>
        </>
      ),
      availability: {
        smart: true,
        best: true,
      },
      content: {
        smart: null,
        best: null,
      },
    },
    {
      label: 'Treue-Plus',
      tooltipContent: (
        <>
          <p>
            Das Treue-Plus ist eine Gewinnbeteiligung, die ab dem 3. Versicherungsjahr im
            Leistungsfall zusätzlich zur Kapitalleistung bei Invalidität, Unfall-Rente bzw.
            Unfall-Rente Plus bei Invalidität oder zur Todesfallleistung gezahlt wird. Die Höhe wird
            durch die Anzahl der Jahre bestimmt, die der Kunde bei uns unfallversichert ist. Es
            handelt sich allerdings nicht um eine Garantieleistung, so dass auch bereits erworbene
            Ansprüche wieder reduziert werden können.
          </p>
        </>
      ),
      availability: {
        smart: true,
        best: true,
      },
      content: {
        smart: null,
        best: null,
      },
    },
    {
      label: 'Rooming In-Leistung bei Kindern',
      tooltipContent: (
        <>
          <p>
            Neben einer versicherten minderjährigen Person wird während ihrer unfallbedingten
            vollstationären Behandlung eine betreuende Person stationär aufgenommen. Wir zahlen dann
            für diese für die Dauer der Aufnahme einen Tagessatz in Höhe von 25 Euro. Die Leistung
            wird je Unfall für höchstens 100 Tage erbracht.
          </p>
        </>
      ),
      availability: {
        smart: true,
        best: true,
      },
      content: {
        smart: null,
        best: null,
      },
    },
    {
      label: 'Beitragsbefreiung in der Kinder-Unfall bei Tod des Versicherungsnehmers',
      tooltipContent: (
        <>
          <p>
            Der Versicherungsbeginn muss vor dem 50. Geburtstag des Versicherungsnehmers liegen, der
            Unfallvertrag war nicht gekündigt und Tod nicht durch Suizid oder Kriegsereignisse
            verursacht. Zum Beginn des nächsten Versicherungsjahres nach dem 20. Geburtstag erlischt
            der Unfallschutz für das mitversicherte Kind automatisch.
          </p>
        </>
      ),
      availability: {
        smart: true,
        best: true,
      },
      content: {
        smart: null,
        best: null,
      },
    },
  ],
};
