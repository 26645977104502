import React from 'react';

interface InputFieldProps<Value> {
  value?: Value;
  min?: number;
  max?: number;
}

export const useFieldRangeValidation = <Value>(
  element: React.ReactNode,
  min = -Infinity,
  max = Infinity
): boolean => {
  if (React.isValidElement(element)) {
    const { value } = element.props as InputFieldProps<Value>;
    const isInfiniteRange = min === -Infinity && max === Infinity;
    const isStringValue = typeof value === 'string';

    if (isInfiniteRange) {
      return true;
    }

    if (!isStringValue) {
      return false;
    }

    const floatValue = parseFloat(value as unknown as string);
    return !isNaN(floatValue) && floatValue >= min && floatValue <= max;
  }

  return true;
};
