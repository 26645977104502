import React from 'react';
import { isNil } from 'lodash';
import { OfferFlowProps } from './OfferFlow.types';

export const OfferFlowComponent: React.FC<OfferFlowProps> = ({
  offerFlowType,
  all = false,
  flowTypes = [],
  displayWhen = true,
  forceOnFlow,
  children,
}) => {
  const showOnConditionAndFlowType = displayWhen && (all || flowTypes.includes(offerFlowType));
  const showOnForcedFlowType = !isNil(forceOnFlow) && forceOnFlow === offerFlowType;

  return showOnConditionAndFlowType || showOnForcedFlowType ? <>{children}</> : null;
};
