import { personsSelectors } from 'entities/persons';
import { RootState } from 'store/store.interface';

const editedPerson = (state: RootState, insuredPersonId: string) => {
  const editedPersonId = state.ui.beneficiaryManagement?.[insuredPersonId]?.editedPersonId;
  if (editedPersonId) {
    return personsSelectors.getPersonById(state, editedPersonId);
  }
};

const isPersonMgmtActionBlocked = (state: RootState, insuredPersonId: string) =>
  state.ui.beneficiaryManagement?.[insuredPersonId]?.isPersonMgmtActionBlocked;

const getInsuredPersonShare = (state: RootState, insuredPersonId: string): number =>
  editedPerson(state, insuredPersonId)?.share!;

export const beneficiaryManagementSelectors = {
  editedPerson,
  isPersonMgmtActionBlocked,
  getInsuredPersonShare,
};
