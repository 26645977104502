import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { contractDetailsActions, contractDetailsSelectors } from 'entities/contractDetails';
import { AssociationMember as AssociationMemberComponent } from './AssociationMember';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState) => ({
  associationOptions: contractDetailsSelectors.getAssociationOptions(state),
  associationSuggestions: contractDetailsSelectors.getAssociationSuggestions(state),
  hasData: contractDetailsSelectors.hasAssociationMemberData(state),
  isEnabled: contractDetailsSelectors.isAssociationMembershipEnabled(state),
  isLoading: contractDetailsSelectors.isLoading(state),
  membershipNumber: contractDetailsSelectors.getMembershipNumber(state),
  selectedAssociationNr: contractDetailsSelectors.getSelectedAssociationNr(state),
  selectedAssociationName: contractDetailsSelectors.getSelectedAssociationName(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteAssociationMembership: () =>
    dispatch(contractDetailsActions.deleteAssociationMembership.trigger()),
  setEnabled: (isEnabled: boolean) =>
    dispatch(contractDetailsActions.setAssociationMemberEnabled(isEnabled)),
  updateAssociationMembership: (associationNumber: number, membershipNumber: string) =>
    dispatch(
      contractDetailsActions.updateAssociationMembership.trigger({
        associationNumber,
        membershipNumber,
      })
    ),
});

export const AssociationMember = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociationMemberComponent);
