import { RootState } from 'store/store.interface';
import { connect } from 'react-redux';
import { FormDisablerComponent } from './FormDisabler.component';
import { offerSelectors } from 'entities/offer';

const mapStateToProps = (state: RootState) => {
  return {
    isReadOnly: offerSelectors.isReadOnly(state),
  };
};

export const FormDisabler = connect(mapStateToProps)(FormDisablerComponent);
