import './app.component.scss';
import '@eg/elements/styles/ergo-one/styles.css';
import '@eg/elements/styles/ergo-one/core.css';
import React from 'react';
import Provider from '@eg/elements/Provider';
import { REACT_APP_BACKEND_URL } from 'config';
import { PaymentDataContainer } from 'features/PaymentData';
import endpointBuilderFactory from 'services/endpoint.builder.service';
import { Agreements } from 'features/Agreements';
import { appHelper } from './app.helper';
import { AppLoader } from '../appLoader/app-loader.container';
import { AppProps } from './app.interface';
import { ComparisonTable } from 'features/ComparisonTable';
import { ConsultancyProtocol } from 'features/ConsultancyProtocol';
import { Loading } from '../Loading';
import { OfferFlow } from '../OfferFlow';
import { PersonManagement } from 'features/PersonManagement';
import { ShippingWayContainer } from 'features/ShippingWay';
import { StickySidebar } from 'features/StickySidebar';
import { FlowType } from 'entities/offer/offer.interface';
import { FormDisabler } from 'components/FormDisabler';
import { UnauthorizedModal } from 'components/UnauthorizedModal';
import { RefreshUserToken } from 'components/RefreshUserToken';

class AppComponent extends React.Component<AppProps> {
  componentDidMount() {
    const { fetchInit, setBusinessId } = this.props;
    const businessId = appHelper.getBusinessIdFromUrl();
    endpointBuilderFactory.setBackendUrl(REACT_APP_BACKEND_URL);

    if (businessId) {
      endpointBuilderFactory.setBusinessId(businessId);
      setBusinessId(businessId);
      fetchInit();
    }
  }

  render() {
    const { isDirectOffer, isFeeCalculated, isPaperOffer, isLoading } = this.props;

    return (
      <Provider theme='ergo-one'>
        <Loading viewport='fixed' loading={isLoading}>
          <div className='app'>
            <section className='app__main-info'>
              <FormDisabler>
                <AppLoader />
                <PersonManagement />
                <ComparisonTable />
                {isFeeCalculated && (
                  <>
                    <OfferFlow
                      flowTypes={[
                        FlowType.NEW_BUSINESS,
                        FlowType.FOLLOW_UP,
                        FlowType.PREMATURE,
                        FlowType.UPGRADING,
                      ]}
                    >
                      <ConsultancyProtocol />
                    </OfferFlow>

                    <OfferFlow all displayWhen={!isPaperOffer}>
                      <Agreements />
                    </OfferFlow>

                    <OfferFlow all forceOnFlow={FlowType.PROCESSING} displayWhen={!isPaperOffer}>
                      <PaymentDataContainer />
                    </OfferFlow>

                    <OfferFlow
                      flowTypes={[
                        FlowType.NEW_BUSINESS,
                        FlowType.FOLLOW_UP,
                        FlowType.PREMATURE,
                        FlowType.UPGRADING,
                      ]}
                      displayWhen={!isDirectOffer}
                    >
                      <ShippingWayContainer />
                    </OfferFlow>
                  </>
                )}
              </FormDisabler>
            </section>
            <StickySidebar />
            <UnauthorizedModal />
            {!isLoading && <RefreshUserToken />}
          </div>
        </Loading>
      </Provider>
    );
  }
}

export default AppComponent;
