import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual, curryRight, first, flatten, get, isNil, uniq } from 'lodash';

import { Person } from '../persons';
import { DEFAULT_ORDER_FOR_PACKAGE_FIELDS } from './PackagesFields.config';
import { DEFAULT_ORDER_FOR_VARIANTS_FIELDS } from './VariantsFields.config';
import {
  GenericFieldValue,
  Offer,
  OfferField,
  OfferFields,
  Package,
  PackageFields,
  TableFields,
  UpdatePersonsField,
  UpdateVariantFieldPayload,
  Variant,
  VariantFields,
} from './offer.types';
import { offerHelper } from './offer.helper';
import { RootState } from 'store/store.interface';
import { FlowType, SalesProcessTypes } from './offer.interface';
import { Message, MessageSeverities } from 'types';

const MAX_NUMBER_OF_APPROVAL_CLAUSES = 5;
export const PERSON_TABLE_ERRORS = ['versicherungssumme', 'gefahrengruppe'];

const getError = (state: RootState) => state.entities.offer.error;
const getOffer = (state: RootState) => state.entities.offer;
const getPackagesById = (state: RootState) => state.entities.offer.packagesById;
const getPackagesIds = (state: RootState) => state.entities.offer.packagesIds;
const getVariantsById = (state: RootState) => state.entities.offer.variantsById;
const getVariantsIds = (state: RootState) => state.entities.offer.variantsIds;
const isLoading = (state: RootState) => state.entities.offer.loading;
const getMapOfPackageIdByComposedId = (state: RootState) =>
  state.entities.offer.mapOfPackageIdByComposedId;
const getBusinessId = (state: RootState) => state.entities.offer.offerNumber;
const getFrontendValidationsByComponsedId = (state: RootState) =>
  state.entities.offer.frontendValidationsByComposedId;

const getActionNumber = (state: RootState) => getOffer(state).actionNr.value;
const getProductName = (state: RootState) => getOffer(state).productName.value;
const getSalesModel = (state: RootState) => getOffer(state).salesModel.value;
const getStatus = (state: RootState) => getOffer(state).offerStatus.value;
const getCreatorNumber = (state: RootState) => getOffer(state).creatorNr.value;
const getConsultationProtocolId = (state: RootState) => getOffer(state).consultationProtocolId;
const getConsultationProtocolChannelDirection = (state: RootState) =>
  getOffer(state).consultationProtocolChannelDirection.value;

const getSalesProcess = (state: RootState) => getOffer(state).salesProcess.value;
const getSalesProcessOptions = (state: RootState) => {
  const options = getOffer(state).salesProcess.options;
  return Array.isArray(options) ? options : [];
};

export const isDirectOffer = (state: RootState) =>
  getSalesProcess(state) === SalesProcessTypes.DIREKTABSCHLUSS;

export const isPaperOffer = (state: RootState) =>
  getSalesProcess(state) === SalesProcessTypes.VOLLANGEBOT;

export const getApprovalClausesOptions = (state: RootState) =>
  getOffer(state).approvalClause?.options;

export const isUserInformedAboutTermination = (state: RootState) =>
  getOffer(state).isUserInformedAboutContractTermination;

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getApprovalClausesObject = createDeepEqualSelector(
  getApprovalClausesOptions,
  (options = []) => {
    const result = new Map<string, string>();
    options.forEach((item) =>
      typeof item.value === 'string' ? result.set(item.value, item.label) : null
    );
    return result;
  }
);

export const getSortedApprovalClausesAvailableLabels = (state: RootState) => {
  return (
    getApprovalClausesOptions(state)
      ?.filter(
        (option) => !getSelectedApprovalClausesOptions(state).includes(option.value as string)
      )
      .map((option) => option.label)
      .sort() || []
  );
};

export const getAllApprovalClausesLabels = (state: RootState) => {
  return getApprovalClausesOptions(state)?.map((option) => option.label) || [];
};

export const isLimitOfSelectedApprovalClausesReached = (state: RootState) => {
  return getSelectedApprovalClausesOptions(state).length >= MAX_NUMBER_OF_APPROVAL_CLAUSES;
};

export const getSelectedApprovalClausesOptions = (state: RootState) =>
  (getOffer(state).approvalClause?.value as string[]) || [];

export const getApprovalNote = (state: RootState) => getOffer(state).approvalNote;

const getVariants = createSelector(getVariantsIds, getVariantsById, (variantsIds, variantsById) =>
  variantsIds.map((variantId: string) => get(variantsById, variantId))
);
const getPackages = createSelector(getPackagesIds, getPackagesById, (packagesIds, packagesById) =>
  packagesIds.map((packageId: string) => get(packagesById, packageId))
);

const getPersonsIds = createSelector(getPackagesIds, getPackagesById, (packagesIds, packagesById) =>
  uniq(packagesIds.map((packageId: string) => get(packagesById, packageId).personId))
);

const getPackageIdFromComposedId = (
  state: RootState,
  personId: string,
  variantId: string,
  fieldId: PackageFields
) =>
  get(
    getMapOfPackageIdByComposedId(state),
    offerHelper.generateComponsedKeyForMapOfPackageIdByComposedId(personId, variantId, fieldId),
    ''
  );

const getCommonFieldsIdsForVariants = createSelector(
  getVariantsIds,
  getVariantsById,
  (variantsIds, variantsById) => {
    const commonFieldIds = variantsIds
      .map((variantId: string) => get(variantsById, variantId))
      .reduce((acc: VariantFields[], variant: Variant) => {
        acc = uniq([...acc, ...variant.fieldsIds]);
        return acc;
      }, []);

    return DEFAULT_ORDER_FOR_VARIANTS_FIELDS.filter((fieldId: VariantFields) =>
      commonFieldIds.includes(fieldId)
    );
  }
);

const getCommonFieldsIdsForPackages = (state: RootState, personId: string) => {
  const packagesIds = getPackagesIds(state);
  const packagesById = getPackagesById(state);
  const variantsIds = getVariantsIds(state);
  const packages = packagesIds.map((packageId: string) => get(packagesById, packageId));
  const commonFieldIds = variantsIds.reduce((acc: PackageFields[], variantId: string) => {
    const fieldsIds = packages
      .filter(
        (packageObj: Package) =>
          packageObj.personId === personId && packageObj.variantId === variantId
      )
      .map((packageObj: Package) => packageObj.fieldsIds);
    acc = uniq([...acc, ...flatten(fieldsIds)]);
    return acc;
  }, []);

  return DEFAULT_ORDER_FOR_PACKAGE_FIELDS.filter((fieldId: PackageFields) =>
    commonFieldIds.includes(fieldId)
  );
};

const isAnyErrorInCommonFieldsForPackagesByPersonId = (state: RootState, personId: string) => {
  const variantIds = getVariantsIds(state);
  const fieldsIds = getCommonFieldsIdsForPackages(state, personId);

  return variantIds.some((variantId) =>
    fieldsIds.some((fieldId) => getFieldErrors(personId, variantId, fieldId)(state)?.length)
  );
};

const isAnyFrontendErrorByPersonId = (state: RootState, personId: string) => {
  const frontendValidationsByComposedId = getFrontendValidationsByComponsedId(state);

  return Object.keys(frontendValidationsByComposedId).some(
    (key) => key.split('.')[0] === personId && frontendValidationsByComposedId[key]?.length
  );
};

const getVariantField = (state: RootState, variantId: string, fieldId: TableFields) => {
  const variants = getVariants(state);
  const variant = variants.find((variantObj) => variantObj.id === variantId);

  return variant ? get(variant, ['fieldsById', fieldId]) : undefined;
};

const getField = (state: RootState, personId: string, variantId: string, fieldId: TableFields) => {
  if (!personId) {
    const variants = getVariants(state);
    const variant = variants.find((variantObj) => variantObj.id === variantId);

    if (!variant) {
      return undefined;
    }

    return get(variant, ['fieldsById', fieldId]);
  }

  const packages = getPackages(state);
  const packageId = get(
    getMapOfPackageIdByComposedId(state),
    offerHelper.generateComponsedKeyForMapOfPackageIdByComposedId(personId, variantId, fieldId),
    ''
  );
  const packageMatched = first(
    packages.filter(
      (packageObj: Package) =>
        packageObj.personId === personId &&
        packageObj.variantId === variantId &&
        packageObj.id === packageId
    )
  );
  if (!packageMatched) {
    return undefined;
  }

  return {
    ...get(packageMatched, ['fieldsById', fieldId]),
    fieldPackage: {
      selected: packageMatched.selected,
      mandatory: packageMatched.mandatory,
    },
  };
};

const getFieldValue = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) =>
    get(field, 'value')
  );

const getFieldErrors = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) =>
    get(field, 'errors')
  );

const getFieldLabel = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) =>
    get(field, 'label')
  );

const getFieldMin = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) => get(field, 'min'));

const getFieldMax = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) => get(field, 'max'));

const getFieldDisabled = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) =>
    get(field, 'disabled')
  );

const getFieldCorrupted = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) =>
    get(field, 'corrupted')
  );

const getFieldOrEmptyPlaceholder = (personId: string, variantId: string, fieldId: TableFields) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) => {
    if (!field) {
      return {
        corrupted: false,
        disabled: false,
        label: '',
        value: '',
        options: [],
        errors: [],
        mandatory: false,
      };
    }

    return {
      corrupted: field.corrupted,
      disabled: !!field.disabled,
      label: isNil(field.label) ? '' : String(field.label),
      value: isNil(field.value) ? '' : String(field.value),
      options: field.options,
      errors: field.errors || [],
      mandatory: field.mandatory,
    };
  });

const getFieldOrEmptyPlaceholderForNumberAsString = (
  personId: string,
  variantId: string,
  fieldId: TableFields
) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) => {
    if (!field) {
      return {
        corrupted: false,
        disabled: false,
        label: '',
        value: '',
        errors: [],
        mandatory: false,
        stepRange: undefined,
        min: -Infinity,
        max: Infinity,
        fieldPackage: undefined,
      };
    }

    return {
      corrupted: field.corrupted,
      disabled: field.disabled,
      label: isNil(field.label) ? '' : String(field.label),
      value: isNil(field.value) ? '' : String(field.value),
      errors: field.errors || [],
      stepRange: field.stepRange,
      min: isNil(field.min) ? -Infinity : field.min,
      max: isNil(field.max) ? Infinity : field.max,
      mandatory: field.mandatory,
      fieldPackage: {
        selected: field.fieldPackage.selected,
        mandatory: field.fieldPackage.mandatory,
      },
    };
  });

const getFieldOrEmptyPlaceholderForBoolean = (
  personId: string,
  variantId: string,
  fieldId: TableFields
) =>
  createSelector(curryRight(getField)(personId, variantId, fieldId), (field) => {
    if (!field) {
      return {
        corrupted: false,
        disabled: false,
        label: '',
        value: false,
        errors: [],
        mandatory: false,
      };
    }

    return {
      corrupted: field.corrupted,
      disabled: !!field.disabled,
      label: isNil(field.label) ? '' : String(field.label),
      value: isNil(field.value) ? false : Boolean(field.value),
      errors: field.errors || [],
      mandatory: field.mandatory,
    };
  });

const getOfferField = (state: RootState, field: OfferFields): OfferField =>
  get(getOffer(state), field);

const getVariantPrice = (state: RootState, variantId: string) =>
  get(getVariantsById(state), [variantId, 'price'], null);

const getSelectedVariant = createSelector(getVariantsById, (variantsById) =>
  Object.values(variantsById).find(
    (variant: Variant) => get(variant, 'fieldsById.selected.value') === true
  )
);

const getSelectedVariantId = createSelector(getSelectedVariant, (selectedVariant) =>
  selectedVariant ? selectedVariant.id : ''
);

const getSelectedVariantPrice = createSelector(getSelectedVariant, (variant) => {
  return get(variant, 'price', null);
});

const getSelectedVariantPaymentMethod = (state: RootState) =>
  getVariantField(state, getSelectedVariantId(state), VariantFields.paymentMethod);

const getSelectedVariantAgreements = createSelector(
  getSelectedVariant,
  (selectedVariant) => selectedVariant?.agreements
);

const hasSelectedVariantAgreements = createSelector(
  getSelectedVariant,
  (selectedVariant): boolean =>
    (selectedVariant &&
      Array.isArray(selectedVariant?.agreements) &&
      selectedVariant.agreements.length > 0) ||
    false
);

const getInsuranceStartDate = (state: RootState) =>
  getVariantField(state, getSelectedVariantId(state), VariantFields.insuranceStartDate);

const getShippingWay = (state: RootState) => getOffer(state).shippingWay;

const isSelectedVariantPriceNotNull = (state: RootState) => getSelectedVariantPrice(state) !== null;

const getUpdateVariantsPayload = (
  state: RootState,
  fields: UpdatePersonsField[]
): UpdateVariantFieldPayload[] => {
  const MAP_OF_FIELDS_TO_ADD_BY_FIELD_ID: Partial<Record<TableFields, TableFields[]>> = {
    [VariantFields.durationOfContract]: [
      VariantFields.insuranceStartDate,
      VariantFields.insuranceEndDate,
      VariantFields.paymentMethod,
    ],
  };

  return fields.map((field: UpdatePersonsField) => {
    const { fieldId, variantId, personId } = field;
    const config = MAP_OF_FIELDS_TO_ADD_BY_FIELD_ID?.[fieldId];
    const hasConfig = Array.isArray(config);
    const partialOfferState: Partial<Record<TableFields, GenericFieldValue>> = {};

    if (hasConfig) {
      config?.forEach((fieldId: TableFields) => {
        partialOfferState[fieldId] = getField(state, personId, variantId, fieldId)?.value;
      });
    }

    return {
      ...field,
      state: partialOfferState,
    };
  });
};

const isPersonPresentInAnyPackage = (state: RootState, personId: Person['personId']): boolean =>
  getPackages(state).some((offerPackage: Package) => offerPackage.personId === personId);

const hasDeathBenefitForSelectedVariant = (state: RootState, personId: string): boolean =>
  !!getFieldValue(
    personId,
    getSelectedVariantId(state),
    PackageFields['000501.insuranceSum']
  )(state);

const getMessages = (state: RootState) => state.entities.offer.messages;

const getErrorMessages = createSelector(getMessages, (messages) =>
  messages.filter((message: Message) => message.severity === MessageSeverities.error)
);

const getErrorMessagesText = createSelector(getErrorMessages, (messages) =>
  messages.map((message: Message) => message.text || message.code)
);

const hasPersonTableErrorMessages = (state: RootState, personId: string) =>
  getMessages(state)
    .filter(({ attributes }) =>
      attributes?.some((attribute) => PERSON_TABLE_ERRORS.includes(attribute))
    )
    .some(({ personsId }) => personsId?.includes(personId));

const shouldFetchAllPersons = (offer: Offer) =>
  offer.salesProcess.value === SalesProcessTypes.VOLLANGEBOT;

const isReadOnly = createSelector(getStatus, (status: string) => {
  return ![FlowType.NEW_BUSINESS, FlowType.PROCESSING].includes(status as FlowType);
});

const isProcessing = createSelector(getStatus, (status: string) => status === FlowType.PROCESSING);

const getFrontendValidationsByComposedId = (state: RootState) =>
  state.entities.offer.frontendValidationsByComposedId;

export const offerSelectors = {
  getActionNumber,
  getBusinessId,
  getCommonFieldsIdsForPackages,
  getCommonFieldsIdsForVariants,
  isAnyErrorInCommonFieldsForPackagesByPersonId,
  isAnyFrontendErrorByPersonId,
  getError,
  getField,
  getFieldErrors,
  getFieldLabel,
  getFieldOrEmptyPlaceholder,
  getFieldMin,
  getFieldMax,
  getFieldDisabled,
  getFieldCorrupted,
  getFieldOrEmptyPlaceholderForBoolean,
  getFieldOrEmptyPlaceholderForNumberAsString,
  getFieldValue,
  getInsuranceStartDate,
  getMapOfPackageIdByComposedId,
  getOffer,
  getOfferField,
  getPackageIdFromComposedId,
  getPackages,
  getPackagesById,
  getPackagesIds,
  getPersonsIds,
  getSelectedVariant,
  getSelectedVariantId,
  getVariantPrice,
  getSelectedVariantPrice,
  getSelectedVariantPaymentMethod,
  getVariants,
  getVariantsById,
  getVariantsIds,
  isLoading,
  getProductName,
  getSalesModel,
  getSalesProcess,
  isDirectOffer,
  isPaperOffer,
  getSalesProcessOptions,
  getStatus,
  getCreatorNumber,
  getConsultationProtocolId,
  getConsultationProtocolChannelDirection,
  hasSelectedVariantAgreements,
  getSelectedVariantAgreements,
  getShippingWay,
  isSelectedVariantPriceNotNull,
  getVariantField,
  getUpdateVariantsPayload,
  getApprovalClausesOptions,
  getSelectedApprovalClausesOptions,
  getApprovalClausesObject,
  getSortedApprovalClausesAvailableLabels,
  getAllApprovalClausesLabels,
  isLimitOfSelectedApprovalClausesReached,
  getApprovalNote,
  isPersonPresentInAnyPackage,
  hasDeathBenefitForSelectedVariant,
  getErrorMessagesText,
  hasPersonTableErrorMessages,
  shouldFetchAllPersons,
  isReadOnly,
  getFrontendValidationsByComposedId,
  isProcessing,
};
