import React from 'react';
import BootstrappedForm from 'idd-react-telefonie/dist/components/BootstrappedForm';
import FormSection from '@eg/elements/FormSection';
import { Details } from '@eg/elements/Details';
import { THEME } from '@eg/elements/Provider';
import { ConsultancyChannelDirection } from 'entities/offer/offer.interface';
import { ConsultancyProtocolProps } from './ConsultancyProtocol.type';
import { buildEndpoint, ENDPOINTS } from 'services';
import './ConsultancyProtocol.scss';

export const ConsultancyProtocol: React.FC<ConsultancyProtocolProps> = ({
  agentName,
  channelDirection,
  isPaperOffer,
  sendConsultancyProtocolId,
}) => {
  const contextOptions = new Map();
  contextOptions.set('eingangskanal', channelDirection || ConsultancyChannelDirection.INBOUND);

  if (!!agentName) {
    contextOptions.set('gespraechspartner', agentName);
  }

  const updateAndSendConsultancyId = (consultancyId: string | null) => {
    if (!!consultancyId) {
      sendConsultancyProtocolId(consultancyId);
    }
  };

  return (
    <section className='consultancy-protocol'>
      <Details className='comparison-table__details' summary='Beratungsprotokoll' isInitiallyOpen>
        <FormSection data-section-id='consultancy-protocol'>
          <BootstrappedForm
            submitForm={updateAndSendConsultancyId}
            configuration={{
              debugLog: false,
              debugFetch: false,
              useMock: false,
              allowDenial: isPaperOffer,
              theme: THEME.ergoone,
              submitButtonLabel: 'Beratungsprotokoll erstellen',
              url: buildEndpoint(ENDPOINTS.consultancyProtocol),
            }}
            context={{
              kontext: 'IDD_ERGO_UNFALL',
              values: contextOptions,
            }}
          />
        </FormSection>
      </Details>
    </section>
  );
};
