import React from 'react';
import { PaymentProps, paymentTypeLabel } from './Payment.types';
import { useFormatCurrency } from 'features/ComparisonTable';
import './Payment.css';

export const Payment: React.FC<PaymentProps> = ({ price, paymentMethodValue }: PaymentProps) => {
  const formattedPrice = useFormatCurrency(String(price));

  return (
    <div className='payment'>
      <span className='payment__label'>Beitrag Gesamt:</span>
      <span className='payment__price'>{formattedPrice}</span>
      {paymentMethodValue && (
        <span className='payment__method'>{`/ ${paymentTypeLabel[paymentMethodValue]}`}</span>
      )}
    </div>
  );
};
