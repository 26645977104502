import React from 'react';
import { connect } from 'react-redux';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';
import { RootState } from 'store/store.interface';
import { UnauthorizedModalHelper } from './UnauthorizedModal.helper';
import { UnauthorizedModalSelectors } from './UnauthorizedModal.selectors';
import styles from './UnauthorizedModal.module.scss';
import { UnauthorizedModalProps } from './UnauthorizedModal.types';

const UnauthorizedModalComponent: React.FC<UnauthorizedModalProps> = ({ isOpened }) => {
  const onClickHandler = () => UnauthorizedModalHelper.redirectToLoginPage();

  return (
    <Modal className={styles.root} open={isOpened} viewport='fixed' dismissible={false}>
      <div className={styles.wrapper}>
        <h1 data-testid='unauthenticated-modal-title'>Nicht berechtigt</h1>
        <p>
          Ihre Sitzung ist abgelaufen oder Sie sind nicht authentifiziert. Drücken Sie den
          Login-Button, um zur Login-Seite weitergeleitet zu werden.
        </p>
        <Button className={styles.button} variant='primary' onClick={onClickHandler}>
          Einloggen
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpened: UnauthorizedModalSelectors.isOpened(state),
});

export const UnauthorizedModal = connect(mapStateToProps)(UnauthorizedModalComponent);
