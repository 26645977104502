import { connect } from 'react-redux';
import { FlowType } from 'entities/offer/offer.interface';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { OfferFlowComponent } from './OfferFlow.component';

const mapStateToProps = (state: RootState) => ({
  offerFlowType: offerSelectors.getStatus(state) as FlowType,
});

export const OfferFlow = connect(mapStateToProps)(OfferFlowComponent);
