export interface CopyButtonStateProps {
  isDisabled: boolean;
  isHidden: boolean;
  errorMessage: string | null;
}

export interface CopyButtonDispatchProps {
  copyFieldsOfFirstInsuredPerson: (personId: string) => void;
}

export interface CopyButtonOwnProps {
  personId: string;
}

export enum CopyButtonErrorMessage {
  IVALID_FIRST_INSURED_PERSON_DATA = 'Die Angaben zur versicherten Person sind nicht korrekt.',
}

export type CopyButtonProps = CopyButtonStateProps & CopyButtonDispatchProps & CopyButtonOwnProps;
