import { AppActions, AppActionsTypes } from '../app';
import { ContractDetailsActions, ContractDetailsActionsTypes } from '../contractDetails';
import { OfferActions, OfferActionsTypes } from './offer.actions';
import { offerHelper } from './offer.helper';
import { OfferField, OfferReducer } from './offer.types';
import { PersonsActions, PersonsActionTypes } from '../persons';
import {
  BeneficiaryActions,
  BeneficiaryActionTypes,
} from 'features/ComparisonTable/components/Beneficiary/state/beneficiary.actions';

const defaultState: Readonly<OfferReducer> = {
  acceptanceQuestions: {} as OfferField,
  actionNr: {} as OfferField,
  consultationProtocolId: {} as OfferField,
  consultationProtocolChannelDirection: {} as OfferField,
  creatorNr: {} as OfferField,
  error: null,
  loading: false,
  mapOfPackageIdByComposedId: {},
  offerNumber: '',
  offerStatus: {} as OfferField,
  packagesById: {},
  packagesIds: [],
  productName: {} as OfferField,
  salesModel: {} as OfferField,
  salesProcess: {} as OfferField,
  submissionReason: {} as OfferField,
  isUserInformedAboutContractTermination: false,
  variantsById: {},
  variantsIds: [],
  shippingWay: null,
  messages: [],
  frontendValidationsByComposedId: {},
};

export const offerReducer = (
  state: OfferReducer = defaultState,
  action: OfferActions | AppActions | PersonsActions | ContractDetailsActions | BeneficiaryActions
) => {
  switch (action.type) {
    case AppActionsTypes.FETCH_INIT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        ...offerHelper.mapOfferResponseToReducer(action.payload.init.offer),
      };

    case AppActionsTypes.FETCH_INIT:
    case OfferActionsTypes.FETCH_OFFER:
    case OfferActionsTypes.SELECT_VARIANT:
    case OfferActionsTypes.UPDATE_OFFER_BASIC_DATA:
    case OfferActionsTypes.UPDATE_BIRTHDATE_TRIGGER:
    case OfferActionsTypes.UPDATE_SHIPPING_WAY_TRIGGER:
    case OfferActionsTypes.UPDATE_AGREEMENTS_TRIGGER:
    case BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_TRIGGER:
    case OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_TRIGGER:
    case OfferActionsTypes.CANCEL_REQUEST_TRIGGER:
      return {
        ...state,
        loading: true,
      };

    case AppActionsTypes.FETCH_INIT_FAILURE:
    case OfferActionsTypes.FETCH_OFFER_FAILURE:
    case OfferActionsTypes.UPDATE_OFFER_BASIC_DATA_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case OfferActionsTypes.UPDATE_BIRTHDATE_FAILURE:
    case OfferActionsTypes.UPDATE_SHIPPING_WAY_FAILURE:
    case OfferActionsTypes.UPDATE_AGREEMENTS_FAILURE:
    case OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_FAILURE:
    case OfferActionsTypes.CANCEL_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case OfferActionsTypes.FETCH_OFFER_SUCCESS:
    case OfferActionsTypes.SELECT_VARIANT_SUCCESS:
    case OfferActionsTypes.UPDATE_OFFER_BASIC_DATA_SUCCESS:
    case OfferActionsTypes.UPDATE_VARIANTS_SUCCESS:
    case BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_SUCCESS:
    case BeneficiaryActionTypes.CREATE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        ...offerHelper.mapOfferResponseToReducer(action.payload.offer),
      };

    case ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_SUCCESS:
    case ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_SUCCESS:
    case OfferActionsTypes.UPDATE_BIRTHDATE_SUCCESS:
    case OfferActionsTypes.UPDATE_SHIPPING_WAY_SUCCESS:
    case OfferActionsTypes.UPDATE_AGREEMENTS_SUCCESS:
    case OfferActionsTypes.ADD_APPROVAL_CLAUSE_SUCCESS:
    case OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_SUCCESS:
    case OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_SUCCESS:
    case OfferActionsTypes.CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        ...offerHelper.mapOfferResponseToReducer(action.payload),
      };

    case OfferActionsTypes.SET_FIELD_CORRUPTED:
      return {
        ...state,
        ...offerHelper.setFieldPropertyValue(
          state,
          action.payload.personId,
          action.payload.variantId,
          action.payload.fieldId,
          'corrupted',
          action.payload.corrupted
        ),
      };

    case OfferActionsTypes.SELECT_VARIANT_FAILURE:
    case OfferActionsTypes.UPDATE_VARIANTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        ...offerHelper.updateFieldsToCorrupted(state, action.context.updatedFields),
      };

    case OfferActionsTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case OfferActionsTypes.USER_INFORMED_ABOUT_CONTRACT:
      return {
        ...state,
        isUserInformedAboutContractTermination: action.payload.isInformed,
      };
    case PersonsActionTypes.SET_PROFESSION_SUCCESS:
    case PersonsActionTypes.SET_PROFESSION_RISKGROUP_SUCCESS:
    case PersonsActionTypes.SET_SELFEMPLOYMENT_SUCCESS:
      return {
        ...state,
        ...offerHelper.mapOfferResponseToReducer(action.payload.offer),
      };
    case BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case OfferActionsTypes.UPDATE_FRONTEND_VALIDATION:
      return {
        ...state,
        frontendValidationsByComposedId: {
          ...state.frontendValidationsByComposedId,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
