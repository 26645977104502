import React from 'react';
import Modal from '@eg/elements/Modal';
import { SuccessModalProps } from './SuccessModal.types';

export const SuccessModal: React.FC<SuccessModalProps> = ({ shouldOpenModal }) => {
  return (
    <Modal open={shouldOpenModal} viewport='fixed'>
      <h1>Herzlichen Glückwunsch!</h1>
      <p>
        Der Antrag wurde erfolgreich übermittelt es sind keine Änderungen mehr möglich. Sie können
        diese Seite jetzt schließen.
      </p>
      <p>Vielen Dank</p>
    </Modal>
  );
};
