import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Agreements as AgreementsComponent } from './Agreements';
import { AgreementsStateProps, AgreementsDispatchProps } from './Agreements.types';
import { offerActions, isUserInformedAboutTermination, offerSelectors } from 'entities/offer';

import { RootState } from 'store/store.interface';
import { UpdateAgreementPayload } from 'entities/offer/offer.interface';

const mapStateToProps = (state: RootState): AgreementsStateProps => ({
  agreements: offerSelectors.getSelectedVariantAgreements(state),
  currentVariantId: offerSelectors.getSelectedVariantId(state),
  hasAgreementsLoaded: offerSelectors.hasSelectedVariantAgreements(state),
  isLoading: offerSelectors.isLoading(state),
  isUserInformedAboutTermination: isUserInformedAboutTermination(state),
});

const mapDispatchToProps = (dispatch: Dispatch): AgreementsDispatchProps => ({
  changeAgreement: ({ checked, label, variantId }: UpdateAgreementPayload) =>
    dispatch(offerActions.updateAgreement.trigger({ checked, label, variantId })),
  isUserInformedAboutContractTermination: (yesNo: boolean) =>
    dispatch(offerActions.setUserIsInformedAboutContract(yesNo)),
});

export const Agreements = connect(mapStateToProps, mapDispatchToProps)(AgreementsComponent);
