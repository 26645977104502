import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import BaseSelect from '@eg/elements/Select';
import { SelectSalesProcessProps } from './SelectSalesProcess.types';
import styles from './SelectSalesProcess.module.scss';
import { FlowType } from 'entities/offer/offer.interface';

export const SelectSalesProcess: React.FC<SelectSalesProcessProps> = ({
  isLoading,
  offerStatus,
  salesProcess,
  salesProcessOptions,
  setSalesProcess,
}: SelectSalesProcessProps) => {
  const [selectedSalesProcess, setSelectedSalesProcess] = useState<string>(salesProcess);
  const onChangeSalesProcessHandler = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setSelectedSalesProcess(value);
      setSalesProcess(value);
    },
    [setSalesProcess]
  );

  const isSelectDisabled = isLoading || offerStatus !== FlowType.NEW_BUSINESS;

  useEffect(() => {
    if (!isLoading) {
      setSelectedSalesProcess(salesProcess);
    }
  }, [isLoading, salesProcess]);

  return (
    <div className={styles.SelectSalesProcess}>
      <BaseSelect
        value={selectedSalesProcess}
        disabled={isSelectDisabled}
        onChange={onChangeSalesProcessHandler}
      >
        {!selectedSalesProcess && <option disabled value='' />}
        {salesProcessOptions.map(({ label: optionLabel, value: optionValue }, index: number) => (
          <option
            key={`select.sales.process.${optionValue}.${index}`}
            value={optionValue as string}
          >
            {optionLabel}
          </option>
        ))}
      </BaseSelect>
    </div>
  );
};
