import React from 'react';
import Checkbox from '@eg/elements/Checkbox';
import DOMPurify from 'dompurify';
import { AgreementProps } from './Agreement.types';
import './Agreement.scss';

const SANITIZE_CONFIG = {
  ALLOWED_TAGS: ['b'],
};

export const Agreement: React.FC<AgreementProps> = ({
  agreement,
  changeAgreement,
  currentVariantId,
  isLoading,
}) => {
  const handleChangeAgreement = (elem: React.ChangeEvent<HTMLInputElement>) =>
    changeAgreement({
      variantId: currentVariantId,
      label: agreement.label,
      checked: elem.target.checked,
    });

  return (
    <div key={agreement.label} className='agreement'>
      <Checkbox
        label={
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(agreement.text, SANITIZE_CONFIG),
            }}
          />
        }
        disabled={isLoading}
        checked={agreement.checked}
        onChange={handleChangeAgreement}
      />
    </div>
  );
};
