import * as React from 'react';
import SectionComponent from './SectionComponent';
import { Context, FormConfiguration, Section, StoreAndDenialResponse, ValuesMap } from '../models';
import { ErrorComponent } from './ErrorComponent';
import { Denial, DenialComponent, DenialSelect } from './denial';
import SubmitButton from './SubmitButton';

import ValidationErrorComponents from './ValidationErrorComponent';
import FormWrapper, { FormHeadWrapper, FormLegendWrapper } from './FormWrapper';
import { default as apiMockFullResponse } from '../tests/IDD_ED_AUGE';
import { Api, ErrorMessages, MapApi } from '../utils';

import FetchClient from '../utils/FetchClient';
import ResetFormLink from './ResetFormLink';
import { LoadingComponent } from './LoadingComponent';
import Provider, { THEME } from '@eg/elements/Provider';
import { Textblock } from '../models/Textblock';
import { TextblockTypes } from '../models/Types';
import { TextblockBackground } from './CssOverrides';

export interface Props {
    submitForm: (iddId: string | null) => void;
    context: Context;
    configuration: FormConfiguration;
}

export default class Form extends React.Component<Props, Context> {
    private _api: Api;
    constructor(props: Props) {
        super(props);
        if (this.props && this.props.configuration) {
            this._debugLogConfig('Das Formular wurde mit folgender Konfiguration aufgerufen', this.props.configuration);
        }
        //  this.props.configuration.debugFetch
        const config = this.props.configuration;
        const fetchClient = new FetchClient({
            url: config.url,
            credentials: config.credentials,
            debugFetch: config.debugFetch
        });
        const mapApi = new MapApi();
        this._api = new Api(fetchClient, mapApi);
    }

    async componentDidMount() {
        if (this.props.configuration.useMock) {
            await this._setupMock();
            return;
        }
        if (this.props.context && this.props.context.kontext) {
            await this._initForm(this.props.context);
        } else {
            this._handleErrorState(true, new Error(ErrorMessages.NO_CONTEXT));
        }
    }

    // NON VALIDATION ERROR HANDLE
    componentDidCatch() {
        this._setError(true);
    }

    render() {
        if (this.state) {
            if (this._hasError()) {
                return <Provider theme={this._theme()}>{this._renderError()}</Provider>;
            } else {
                return <Provider theme={this._theme()}>{this._renderFormWrapper()}</Provider>;
            }
        } else {
            return <Provider theme={this._theme()}> {this._renderLoading()}</Provider>;
        }
    }

    private _theme(): THEME {
        return this.props.configuration.theme ? this.props.configuration.theme : THEME.ergoone;
    }

    /////////////////// RENDER FUNCTIONS
    private _renderFormWrapper() {
        return (
            <FormWrapper>
                <FormHeadWrapper>
                    <DenialSelect
                        denialLabel={this.props.configuration.denialLabel}
                        onChange={value => {
                            this._handleDenialSelectChange(value);
                            this._handleAllowDenialChange();
                        }}
                        disabled={this._handleDisabledState()}
                        allowDenial={this.props.configuration.allowDenial}
                    />
                </FormHeadWrapper>
                {this._renderValidationErrors()}
                {this._renderFormStates()}
                {this._renderSubmitButton()}
            </FormWrapper>
        );
    }

    private _handleDenialSelectChange(value: Denial) {
        this._resetValidationError();
        this._setDenial(value);
        if (value === Denial.WUNSCH) {
            const { kontext, values } = this.state;
            this._initForm({ kontext, values });
            this._updateIddId();
        }
    }

    private _handleAllowDenialChange() {
        if (!this.state) {
            return;
        }
        if (this._getDenial() && this.props.configuration.allowDenial) {
            this._setDenial(Denial.WUNSCH);
            this._setIddId(null);
            this.props.submitForm(null);
        }
    }

    private _renderError() {
        return <ErrorComponent errorMessage={this._getErrorMessage() || new Error(ErrorMessages.DEFAULT)} />;
    }

    private _renderLoading() {
        return <LoadingComponent />;
    }

    private _renderValidationErrors() {
        if (!this.state || !this.state.iddIdValidationErrors) {
            return;
        }
        return <ValidationErrorComponents validationErrors={this._getiddIdValidationErrors() || null} />;
    }

    private _renderFormStates() {
        if (this._getTextblocks() && !this._getDenial()) {
            return this._renderTextblocks(this._getTextblocks());
        }
        if (this._getSections() && !this._getTextblocks() && !this._getDenial()) {
            return this._renderSections(this._getSections());
        }
        if (this._getDenial()) {
            return (
                <DenialComponent
                    api={this._api}
                    context={this._getKontext()}
                    onDenialSubmit={iddId => this.props.submitForm(iddId)}
                />
            );
        }
    }

    private _renderSubmitButton() {
        if (!this._getDenial()) {
            return (
                <FormLegendWrapper>
                    <SubmitButton
                        onClick={() => this._handleFormSubmit()}
                        disabled={this._handleSubmitButtonDisabledState(this.props.configuration.submitButtonDisabled)}
                        submitButtonLabel={this.props.configuration.submitButtonLabel}
                        kontext={this.props.context.kontext ? this.props.context.kontext : '?'}
                    />
                    {this._getIddId() ? (
                        <ResetFormLink
                            resetForm={() => this._handleFormReset()}
                            resetButtonLabel={this.props.configuration.resetButtonLabel}
                        />
                    ) : (
                        undefined
                    )}
                </FormLegendWrapper>
            );
        }
    }

    private _handleDisabledState() {
        const iddId = this._getIddId();
        if (iddId && !this._getLoading()) {
            return true;
        } else {
            return false;
        }
    }

    private _handleSubmitButtonDisabledState(submitButtonDisabled?: boolean): boolean {
        if (submitButtonDisabled === true) {
            return true;
        }
        return this._handleDisabledState();
    }

    private async _handleFormReset() {
        await this._resetState();
        await this._updateIddId();
    }

    private _renderTextblocks(textblocks?: Array<Textblock>) {
        if (!this.state) {
            return;
        }

        if (!textblocks) {
            return;
        }

        return (
            textblocks &&
            textblocks.map((textblock: Textblock, index: number) => {
                if (textblock.textblockType === TextblockTypes.MULTISECTION) {
                    return (
                        <TextblockBackground key={index}>
                            {this._renderSections(textblock.sections)}
                        </TextblockBackground>
                    );
                }
                return this._renderSections(textblock.sections);
            })
        );
    }

    private _renderSections(sections?: Array<Section>) {
        if (!this.state) {
            return;
        }

        if (!sections) {
            return;
        }

        return (
            sections &&
            sections.map(section => (
                <SectionComponent
                    values={this.state.values}
                    key={`${section.elements && section.elements.map(e => e.backendName + e.display)}-${
                        section.elementType
                    }`}
                    section={section}
                    disabled={this._handleDisabledState()}
                    onValueChangedHandler={(key, value) => this._handleOnChange(key, value)}
                />
            ))
        );
    }

    /////////////////// HANDLERS
    private async _initForm(context: Context) {
        try {
            const response: Context = await this._api.init(context);
            this._setupFormState(response);
        } catch (error) {
            this._handleErrorState(true, error);
        }
    }

    private _handleErrorState(hasError: boolean, errorMessage: Error) {
        this.setState({
            hasError: hasError,
            errorMessage: errorMessage
        });
    }

    private _setupFormState(context: Context) {
        if (!this.state && !context) {
            return;
        }
        this._debugLog('CONTEXT: ', context);
        this.setState({
            ...context,
            iddId: null,
            kontext: this.props.context.kontext
        });
    }

    private async _setupMock() {
        const mapper = new MapApi();
        this.setState({
            ...mapper.initOrReadToContext(apiMockFullResponse)
        });
    }

    private _handleOnChange(key: string, value: string) {
        let values = this.state.values ? this.state.values : new Map();
        values.set(key, typeof value === 'boolean' ? String(value) : value);
        this.setState({ values });
        this._debugLogMap('Set key: ' + key + ' Value: ' + value + ' ; state.values now ', values);
    }

    private async _validateForm() {
        this._resetValidationError();
        if (this.props.configuration.useMock) {
            this._mockLoading();
            this._setValidationResponse({
                iddId: '109387-68',
                fehlermeldung: null
            });
        }
        try {
            const result = await this._api.store({
                uuid: this.state.uuid,
                values: this.state.values
            });
            this._setValidationResponse(result);
        } catch (error) {
            this._handleErrorState(true, error);
        }
    }

    private _setValidationResponse(result: StoreAndDenialResponse) {
        if (result.iddId) {
            this._setIddId(result.iddId);
        }

        if (result.fehlermeldung) {
            this._setiddIdValidationErrors(result.fehlermeldung);
        }
    }

    private _handleFormSubmit = async () => {
        await this._validateForm();
        await this._updateIddId();
    };

    private _updateIddId = async () => {
        const iddId = this._getIddId() || null;
        this._debugLog('returned iddId:', iddId);
        this.props.submitForm(iddId);
    };

    private _resetValidationError() {
        if (!this.state) {
            return;
        }
        this.setState({ iddIdValidationErrors: undefined });
    }

    private async _resetState() {
        const formState: Context = {
            ...this.state,
            iddId: null,
            uuid: undefined
        };
        this.setState(formState);
        await this._initForm(formState);
    }

    // tslint:disable-next-line:no-any
    private _mockLoading = (event?: any) => {
        if (!this.state) {
            return;
        }
        this._setLoading(true);
        setTimeout(() => {
            this._setLoading(false);
            // tslint:disable-next-line:align
        }, 2000);
    };

    // State getter and setter
    private _getKontext(): Context | undefined {
        if (!this.state) {
            return;
        }
        return {
            ...this.state
        };
    }

    private _getLoading() {
        if (!this.state) {
            return;
        }
        return this.state.loading;
    }

    private _setLoading(loading: boolean) {
        if (!this.state) {
            return;
        }
        this.setState({ loading });
    }

    private _hasError() {
        if (!this.state) {
            return;
        }
        return this.state.hasError;
    }

    private _getErrorMessage() {
        if (!this.state) {
            return;
        }
        return this.state.errorMessage;
    }

    private _setError(hasError: boolean) {
        if (!this.state) {
            this.setState({ hasError: true });
            return;
        }
        this.setState({ hasError });
    }

    private _getiddIdValidationErrors() {
        if (!this.state) {
            return;
        }
        return this.state.iddIdValidationErrors;
    }

    private _setiddIdValidationErrors(iddIdValidationErrors: Array<string>) {
        if (!this.state) {
            return;
        }
        this.setState({ iddIdValidationErrors });
    }

    private _getIddId() {
        if (!this.state) {
            return;
        }
        return this.state.iddId || null;
    }

    private _setIddId(iddId: string | null) {
        if (!this.state) {
            return;
        }
        this.setState({ iddId });
    }

    private _getSections() {
        if (!this.state) {
            return;
        }
        return this.state.sections;
    }

    private _getTextblocks() {
        if (!this.state) {
            return;
        }
        return this.state.textblocks;
    }

    private _getDenial() {
        if (!this.state) {
            return;
        }
        return this.state.denial;
    }

    private _setDenial(denial: Denial) {
        if (!this.state) {
            return;
        }
        if (denial === Denial.VERZICHT) {
            this.setState({ denial: true });
        } else {
            this.setState({ denial: false });
        }
    }

    ////////// DEBUG FUNCTIONS
    // tslint:disable-next-line:no-any
    private _debugLog(...msgs: Array<any>) {
        if (!this.props.configuration.debugLog) {
            return;
        }
        // tslint:disable-next-line:no-console
        console.debug('[IDD Bootstrap]', ...msgs);
    }

    // Whitelist of printed config, avoid printing password anywhere
    // (Off course: you can get it via console!)
    private _debugLogConfig(msg: string, config: FormConfiguration) {
        const displayedConfig: FormConfiguration = {
            url: config.url,
            credentials: config.credentials
                ? {
                      username: config.credentials.username,
                      password: ''
                  }
                : undefined,
            debugFetch: config.debugFetch,
            debugLog: config.debugLog,
            useMock: config.useMock
        };
        this._debugLog(msg, displayedConfig);
    }

    private _debugLogMap(msg: string, map: ValuesMap) {
        if (!this.props.configuration.debugLog) {
            return;
        }
        let output = '';
        map.forEach((v, k) => {
            output += '{' + k + ':' + v + '}';
        });
        // tslint:disable-next-line:no-console
        this._debugLog(msg + ' [' + output + ']');
    }
}
