import { ProfessionRiskTypes } from './Profession.types';

export const riskLabels: Record<string, string> = {
  [ProfessionRiskTypes.GROUP_A]: 'Gefahrengruppe A',
  [ProfessionRiskTypes.GROUP_B]: 'Gefahrengruppe B',
  [ProfessionRiskTypes.GROUP_C]: 'Gefahrengruppe C',
  [ProfessionRiskTypes.GROUP_D]: 'Gefahrengruppe D',
  [ProfessionRiskTypes.GROUP_S]: 'Gefahrengruppe S - NICHT VERSICHERBAR',
  [ProfessionRiskTypes.GROUP_X]: 'Berufstätigkeit ist anfragepflichtig',
};
