import React from 'react';
import { FormDisablerProps } from './FormDisabler.types';
import styles from './FormDisabler.module.scss';

export const FormDisablerComponent: React.FC<FormDisablerProps> = ({ isReadOnly, children }) => {
  return (
    <fieldset disabled={isReadOnly} className={styles.FormDisablerFieldset}>
      {children}
    </fieldset>
  );
};
