import * as React from 'react';
import Button from '@eg/elements/Button';

export interface ResetFormLinkProps {
    resetForm: () => void;
    resetButtonLabel?: string;
}
export default class ResetFormLink extends React.Component<ResetFormLinkProps> {
    public static defaultProps: Partial<ResetFormLinkProps> = {
        resetButtonLabel: 'Zurücksetzen'
    };

    render() {
        return (
            <Button variant="secondary" onClick={() => this.props.resetForm()} data-test-id="BUTTON_RESET_IDD">
                {this.props.resetButtonLabel}
            </Button>
        );
    }
}
