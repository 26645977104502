export enum ElementTypes {
    SELECT = 'SELECT',
    CONFIRMATION = 'BESTAETIGUNG',
    MULTISELECT = 'MULTISELECT',
    TEXTBLOCKHEADER = 'TEXTBLOCKHEADER'
}

export enum TextblockTypes {
    SINGLESECTION = 'SINGLESECTION',
    MULTISECTION = 'MULTISECTION'
}
