import * as React from 'react';
import { Api, ErrorMessages } from '../../utils';
import { Context, StoreAndDenialResponse } from '../../models';
import { ErrorComponent } from '../ErrorComponent';
import MessageBox from '@eg/elements/MessageBox';

export interface DenialComponentState {
    hasError?: boolean;
    errorMessage?: Error;
}

export interface DenialComponentProps {
    api: Api;
    context?: Context;
    onDenialSubmit: (iddId: string) => void;
}

export class DenialComponent extends React.Component<DenialComponentProps, DenialComponentState> {
    state = {
        hasError: false,
        errorMessage: new Error(ErrorMessages.DEFAULT)
    };
    constructor(props: DenialComponentProps) {
        super(props);
    }

    async componentDidMount() {
        await this._handleDenial();
    }

    render() {
        return !this.state.hasError ? (
            <MessageBox type="error">
                <strong>ACHTUNG !!!</strong> Der Beratungsverzicht ist nur in absoluten Ausnahmefällen
                (Freundschaftswerbung, Vollmacht, explizitem Kundenwunsch) bzw. bei schriftlicher Bearbeitung zulässig.
            </MessageBox>
        ) : (
            this._renderError()
        );
    }

    /////////////////// RENDERER
    private _renderError() {
        if (!this.state.errorMessage) {
            return;
        }
        return <ErrorComponent errorMessage={this.state.errorMessage} />;
    }

    /////////////////// HANDLERS
    private _handleDenial = async () => {
        if (!this.props.context) {
            return;
        }
        const result = await this._denialSubmit(this.props.context);

        return this.props.onDenialSubmit(result.iddId);
    };

    private async _denialSubmit(context: Context): Promise<StoreAndDenialResponse> {
        try {
            return await this.props.api.denialByUuid(context);
        } catch (error) {
            this._handleErrorState(true, error);
            return Promise.reject(error);
        }
    }

    private _handleErrorState(hasError: boolean, errorMessage: Error) {
        this.setState({
            hasError: hasError,
            errorMessage: errorMessage
        });
    }
}
