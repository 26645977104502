import { connect } from 'react-redux';
import { orderSelectors } from 'entities/order';
import { RootState } from 'store/store.interface';
import { SuccessModal as SuccessModalComponent } from './SuccessModal';
import { SuccessModalStateProps } from './SuccessModal.types';

const mapStateToProps = (state: RootState): SuccessModalStateProps => ({
  shouldOpenModal: orderSelectors.isFinalized(state),
});

export const SuccessModal = connect(mapStateToProps)(SuccessModalComponent);
