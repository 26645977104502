// tslint:disable:max-len
import { InitAndReadResponse } from '../models';

const response: InitAndReadResponse = {
    uuid: '3d3f9d51-09e8-4f24-a8df-96aa65942716',
    textVariablen: [],
    texte: [
        {
            textblock: [
                {
                    tooltip: null,
                    text: 'Gesprächsanlass?',
                    variablen: [
                        {
                            variablentyp: 'SELECT',
                            variablenname: 'eingangskanal',
                            anzeigetext: null,
                            erlaubterwert: ['Inbound', 'Outbound'],
                            erlaubteauswahl: [
                                {
                                    wert: 'Inbound',
                                    anzeigetext: 'Inbound'
                                },
                                {
                                    wert: 'Outbound',
                                    anzeigetext: 'Outbound'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            textblock: [
                {
                    tooltip: null,
                    text: 'Bestehender Versicherungsschutz?',
                    variablen: [
                        {
                            variablentyp: 'BOOLEAN',
                            variablenname: 'azb',
                            anzeigetext: 'AZB',
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        },
                        {
                            variablentyp: 'BOOLEAN',
                            variablenname: 'aza',
                            anzeigetext: 'AZA',
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        },
                        {
                            variablentyp: 'BOOLEAN',
                            variablenname: 'aze',
                            anzeigetext: 'AZE',
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        },
                        {
                            variablentyp: 'BOOLEAN',
                            variablenname: 'ge',
                            anzeigetext: 'GE',
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        },
                        {
                            variablentyp: 'BOOLEAN',
                            variablenname: 'keineAugenversicherung',
                            anzeigetext: 'keine',
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        }
                    ]
                }
            ]
        },
        {
            textblock: [
                {
                    tooltip: 'Brillenträger, Apollokunde, Vorsorgeuntersuchungen ',
                    text: 'Bedarfsermittlung erfolgt?',
                    variablen: [
                        {
                            variablentyp: 'BESTAETIGUNG',
                            variablenname: 'bedarfsermittlungAuge',
                            anzeigetext: null,
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        }
                    ]
                }
            ]
        },
        {
            textblock: [
                {
                    tooltip: 'Auf Premiumvariante AZB /AZE  hinweisen, bei Apollokunden Hinweis auf AZA\n',
                    text: 'Beratung zu  Augentarifen erfolgt: ',
                    variablen: [
                        {
                            variablentyp: 'BESTAETIGUNG',
                            variablenname: 'beratungAugeErfolgt',
                            anzeigetext: null,
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        }
                    ]
                }
            ]
        },
        {
            textblock: [
                {
                    tooltip: null,
                    text: 'Alle nahen Familienangehörige sind mit abgesichert / Partnercross\n',
                    variablen: [
                        {
                            variablentyp: 'SELECT',
                            variablenname: 'weitereFamilie',
                            anzeigetext: null,
                            erlaubterwert: ['true', 'false', 'keine'],
                            erlaubteauswahl: [
                                {
                                    wert: 'true',
                                    anzeigetext: 'Ja'
                                },
                                {
                                    wert: 'false',
                                    anzeigetext: 'Nein'
                                },
                                {
                                    wert: 'keine',
                                    anzeigetext: 'keine'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            textblock: [
                {
                    tooltip:
                        // tslint:disable-next-line:max-line-length
                        'persönlichen Angaben / Kostensenkung für zukünftige augenärztliche Vorsorgeuntersuchungen, Sehhilfen und Sehschärfenkorrekturen / finanzielle Situation / Hinweis Risikolücke / Hinweis auf nicht versicherte Familienangehörige\n',
                    text: 'Abschlussempfehlung / Risikolücke',
                    variablen: [
                        {
                            variablentyp: 'BESTAETIGUNG',
                            variablenname: 'abschlussempfehlungAuge',
                            anzeigetext: null,
                            erlaubterwert: null,
                            erlaubteauswahl: null
                        }
                    ]
                }
            ]
        }
    ],
    fehlermeldung: null
};

export default response;
