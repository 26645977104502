import { Person } from 'entities/persons';

// TODO it would be nice if uPDS would have npm package on nexus with its typings like there below
export enum PersonFieldName {
  SALUTATION = 'salutation',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  BIRTH_DATE = 'birthDate',
  PHONE_NUMBER_WITH_PREFIX = 'phoneNumberWithPrefix',
  PHONE_INTERNATIONAL_PREFIX = 'phoneInternationalPrefix',
  PHONE_AREA_PREFIX = 'phoneAreaPrefix',
  PHONE_NUMBER = 'phoneNumber',
  PERSONAL_EMAIL = 'personalEmail',
  PERSON_ID = 'personId',
  BIRTHPLACE = 'birthPlace',
  NATIONALITY = 'nationality',
  TITLE = 'title',
}

export enum AddressFieldName {
  ADDRESS = 'address',
  STREET_NAME = 'streetName',
  BUILDING_NUMBER = 'buildingNumber',
  POSTCODE = 'postCode',
  CITY = 'city',
  BUILDING_ADDITIONAL_INFO = 'buildingAdditionalInfo',
  CITY_ADDITIONAL_INFO = 'cityAdditionalInfo',
  ADDRESS_ADDITIONAL_INFO = 'addressAdditionalInfo',
}

export type FormFields = (PersonFieldName | AddressFieldName)[];

export interface PersonalDataOwnProps {
  personId?: string;
  isAdditionalPersonEdit?: boolean;
  enablePDSValidation?: boolean;
  enableCopyAddress?: boolean;
  visibleFields?: FormFields;
  readOnly?: boolean;
}

export interface PersonalDataStateProps {
  businessId: string;
  editedPerson?: Person;
  insuranceOwner: Person;
}

export interface PersonalDataDispatchProps {
  addPerson: (person: Partial<Person>) => void;
  updatePerson: (person: Partial<Person>) => void;
  updatePersonAndUpdateOffer: (person: Partial<Person>) => void;
  personalDataError: (error: Error) => void;
  setIsLoading: (isVisible: boolean) => void;
}
export interface PersonalDataProps
  extends PersonalDataOwnProps,
    PersonalDataStateProps,
    PersonalDataDispatchProps {}

export interface PersonalDataState {
  isPersonalDataFormValid: boolean;
  appId: string;
}
