import React from 'react';
import Details from '@eg/elements/Details';
import { ProcessingFlowProps } from './ProcessingFlow.types';
import { InputRow, DatePickerRow, ApprovalNote } from './components';
import { OfferFields } from 'entities/offer';
import { ApprovalClause } from './components/ApprovalClause';

export const ProcessingFlowComponent: React.FC<ProcessingFlowProps> = ({
  consultationProtocolId,
  applicationDate,
  approvalNote,
  updateApplicationDate,
  updateConsultationProtocolId,
  updateApprovalNote,
}) => {
  const onBlurInputRowHandler = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    updateConsultationProtocolId(value);
  };

  const onChangeApplicationDateHandler = (value: string) => {
    updateApplicationDate(value);
  };

  const onBlurApproveNoteHandler = (evt: React.FocusEvent<HTMLTextAreaElement>) => {
    const { value } = evt.target;
    updateApprovalNote(value);
  };

  return (
    <div className='comparison-table'>
      <Details className='comparison-table__details' summary='Veredelung' isInitiallyOpen>
        <DatePickerRow
          name={applicationDate.id}
          placeholder='TT.MM.JJJJ'
          label='Antragsdatum'
          value={applicationDate.value}
          errors={applicationDate.errors}
          maxDate={applicationDate.max}
          minDate={applicationDate.min}
          disabled={applicationDate.disabled}
          onChange={onChangeApplicationDateHandler}
        />
        <InputRow
          name={OfferFields.consultationProtocolId}
          label='Beratung'
          required={consultationProtocolId.mandatory}
          value={consultationProtocolId.value}
          errors={consultationProtocolId.errors}
          onBlur={onBlurInputRowHandler}
        />
        <ApprovalClause />
        <ApprovalNote
          label='Notizfeld'
          placeholder='Notizen'
          value={approvalNote?.value}
          errors={approvalNote?.errors}
          onBlur={onBlurApproveNoteHandler}
        />
      </Details>
    </div>
  );
};
