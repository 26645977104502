import { openUnauthorizedModal } from 'components/UnauthorizedModal/UnauthorizedModal.actions';
import { UnauthorizedModalHelper } from 'components/UnauthorizedModal/UnauthorizedModal.helper';
import { isAnythingLoading } from 'components/appLoader/app-loader.selectors';
import { AppActionsTypes } from 'entities/app';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { filter, first, ignoreElements, map, tap } from 'rxjs/operators';

export const SESSION_STORAGE_KEY = 'unfall-tof-failure';

const AUTHENTICATION_ERROR = 401;

export const onAppInit: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(...[AppActionsTypes.FETCH_INIT_FAILURE]),
    filter((action) => {
      // flag to prevent redirect loop
      const hasRedirectLoopProtectionFlag = sessionStorage.getItem(SESSION_STORAGE_KEY) !== null;

      return (
        action.payload.error?.response.statusCode === AUTHENTICATION_ERROR &&
        !isAnythingLoading(state$.value) &&
        hasRedirectLoopProtectionFlag
      );
    }),
    first(),
    map(() => openUnauthorizedModal())
  );
};

export const onUnauthorizedInitResponse: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(...[AppActionsTypes.FETCH_INIT_FAILURE]),
    filter((action) => {
      // flag to prevent redirect loop
      const hasRedirectLoopProtectionFlag = sessionStorage.getItem(SESSION_STORAGE_KEY) !== null;
      return (
        action.payload.error?.response.statusCode === AUTHENTICATION_ERROR &&
        !isAnythingLoading(state$.value) &&
        !hasRedirectLoopProtectionFlag
      );
    }),
    tap(() => {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true');
      UnauthorizedModalHelper.redirectToLoginPage();
    }),
    ignoreElements()
  );
};

export const onInitSuccessOrWithoutAuthError: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(AppActionsTypes.FETCH_INIT_SUCCESS),
    filter((action) => {
      return isAnythingLoading(state$.value) || action.payload.error?.response.statusCode !== 401;
    }),
    tap(() => sessionStorage.removeItem(SESSION_STORAGE_KEY)),
    ignoreElements()
  );
};

export const onApiCallError: Epic = (action$, state$) =>
  action$.pipe(
    filter((action) => {
      const isFailedAction =
        action.type !== AppActionsTypes.FETCH_INIT_FAILURE && action.type.includes('FAILURE');

      return isFailedAction && !isAnythingLoading(state$.value);
    }),
    first(),
    map((action) => {
      const isUnauthorizedError = action.payload.error
        ? action.payload.error?.status === AUTHENTICATION_ERROR
        : action.payload?.status === AUTHENTICATION_ERROR;
      return isUnauthorizedError && openUnauthorizedModal();
    })
  );

export const unauthorizedModalEpics = combineEpics(
  onAppInit,
  onUnauthorizedInitResponse,
  onInitSuccessOrWithoutAuthError,
  onApiCallError
);
