import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions, offerSelectors } from '../../../../../../entities/offer';
import { personsSelectors } from '../../../../../../entities/persons';
import { RootState } from '../../../../../../store/store.interface';
import { CopyButton as CopyButtonComponent } from './CopyButton';
import {
  CopyButtonDispatchProps,
  CopyButtonErrorMessage,
  CopyButtonOwnProps,
  CopyButtonStateProps,
} from './CopyButton.types';

const mapStateToProps = (state: RootState, ownProps: CopyButtonOwnProps): CopyButtonStateProps => {
  const firstInsuredPersonId = personsSelectors.getFirstInsuredPersonId(state);
  const isAnyErrorInCommonFieldsForFirstInsuredPerson =
    !!firstInsuredPersonId &&
    offerSelectors.isAnyErrorInCommonFieldsForPackagesByPersonId(state, firstInsuredPersonId);
  const isAnyFrontendErrorByPersonId =
    !!firstInsuredPersonId &&
    offerSelectors.isAnyFrontendErrorByPersonId(state, firstInsuredPersonId);
  const isLoading = offerSelectors.isLoading(state);

  const getErrorMessage = () => {
    if (isAnyErrorInCommonFieldsForFirstInsuredPerson || isAnyFrontendErrorByPersonId)
      return CopyButtonErrorMessage.IVALID_FIRST_INSURED_PERSON_DATA;

    return null;
  };

  return {
    isDisabled:
      isAnyErrorInCommonFieldsForFirstInsuredPerson || isAnyFrontendErrorByPersonId || isLoading,
    isHidden: ownProps.personId === firstInsuredPersonId,
    errorMessage: getErrorMessage(),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): CopyButtonDispatchProps => ({
  copyFieldsOfFirstInsuredPerson: (personId: string) =>
    dispatch(offerActions.copyFieldsOfFirstInsuredPerson.trigger(personId)),
});

export const CopyButton = connect(mapStateToProps, mapDispatchToProps)(CopyButtonComponent);
