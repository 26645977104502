import React, { useContext } from 'react';
import { PersonBlockProps } from './PersonBlock.types';
import TrashbinIcon from '@eg/elements/Icons/TrashbinIcon';
import { RoleBadges } from '../RoleBadges';
import { ModalContext } from 'components';
import './PersonBlock.scss';
import { ValidationIcon } from '../ValidationIcon';

function formatName(firstName: string, lastName: string) {
  if (!firstName && !lastName) {
    return '-';
  }
  return `${lastName}, ${firstName}`;
}

export const PersonBlock: React.FC<PersonBlockProps> = ({
  person,
  isSelected,
  disableActions,
  showRoleBadges = true,
  showValidationIcons = true,
  onClickCallback,
  onPersonRemove,
}) => {
  const { openModal, setContent } = useContext(ModalContext);

  return (
    <div
      className={`person-block ${isSelected ? 'person-block--selected' : ''}`}
      onClick={() => onClickCallback(person.personId)}
    >
      <div className='person-block__label'>{formatName(person.firstName, person.lastName)}</div>
      <div className='person-block__badges'>
        {showRoleBadges && <RoleBadges personId={person.personId} />}
      </div>
      <div className='person-block__label'>{isSelected}</div>
      <TrashbinIcon
        width={32}
        className={`person-block__trashbin-icon ${
          disableActions && 'person-block__trashbin-icon--disabled'
        }`}
        onClick={(evt) => {
          evt.stopPropagation();
          setContent({ content: <>Sind Sie sicher, dass sie diese Person löschen möchten?</> });
          !disableActions && openModal(() => onPersonRemove(person.personId));
        }}
      />
      {showValidationIcons && <ValidationIcon validationStatus={person.pdsValidationStatus} />}
    </div>
  );
};
