import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SelectedApprovalClausesComponent } from './SelectedApprovalClauses.component';
import {
  SelectedApprovalClausesDispatchProps,
  SelectedApprovalClausesStateProps,
} from './SelectedApprovalClauses.types';
import {
  getApprovalClausesObject,
  getSelectedApprovalClausesOptions,
  offerActions,
} from 'entities/offer';
import { RootState } from 'store/store.interface';
import { processingFlowSelectors } from '../../state';

const mapStateToProps = (state: RootState): SelectedApprovalClausesStateProps => ({
  approvalClausesObject: getApprovalClausesObject(state),
  selectedApprovalOptions: getSelectedApprovalClausesOptions(state),
  isDeleting: processingFlowSelectors.isDeleting(state),
  currentlyDeletedApprovalClause: processingFlowSelectors.getCurrentlyDeletedApprovalClause(state),
});

const mapDispatchToProps = (dispatch: Dispatch): SelectedApprovalClausesDispatchProps => ({
  removeApprovalClause: (approvalClause: string) =>
    dispatch(offerActions.removeApprovalClause.trigger(approvalClause)),
});

export const SelectedApprovalClauses = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedApprovalClausesComponent);
