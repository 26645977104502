import { defaultsDeep, omit } from 'lodash';
import {
  BeneficiaryActions,
  BeneficiaryActionTypes,
} from 'features/ComparisonTable/components/Beneficiary/state/beneficiary.actions';
import { AppActions, AppActionsTypes } from '../app';
import { OfferActions, OfferActionsTypes } from '../offer';
import { PersonsActions, PersonsActionTypes } from './persons.actions';
import { Person, PersonSalutation, PersonsState, ValidationStatus } from './persons.interface';

const defaultPerson: Person = {
  salutation: PersonSalutation.UNKNOWN,
  firstName: '',
  lastName: '',
  personId: '',
  birthDate: '',
  personalEmail: '',
  workEmail: '',

  roles: [],
  profession: undefined,
  errors: [],

  address: {
    addressAdditionalInfo: '',
    streetName: '',
    buildingNumber: '',
    buildingAdditionalInfo: '',
    postCode: '',
    city: '',
    cityAdditionalInfo: '',
    country: '',
  },
  showOnList: true,
  currentlyEditing: false,
  title: '',
  birthPlace: '',
  nationality: '',
  phoneInternationalPrefix: '',
  pdsValidationStatus: ValidationStatus.UNKNOWN,
};

function getNewPerson(personCandidiate: Partial<Person>) {
  return defaultsDeep({}, personCandidiate, defaultPerson);
}

const defaultState: PersonsState = {
  personsId: [],
  personById: {},
  personsLimit: Infinity,
  insuredPersonsLimit: Infinity,
  beneficiariesLimit: Infinity,
};

export const personsReducer = (
  state: PersonsState = defaultState,
  action: PersonsActions | AppActions | OfferActions | BeneficiaryActions
): PersonsState => {
  switch (action.type) {
    case AppActionsTypes.FETCH_INIT_SUCCESS: {
      const { offer, persons } = action.payload.init;

      return persons.reduce(
        ({ personsId, personById, ...restState }: PersonsState, personCandidiate: Person) => {
          return {
            ...restState,
            personsId: [...personsId, personCandidiate.personId],
            personById: {
              ...personById,
              [personCandidiate.personId]: getNewPerson(personCandidiate),
            },
          };
        },
        {
          ...state,
          personsLimit: offer.personsLimit,
          insuredPersonsLimit: offer.insuredPersonsLimit,
          beneficiariesLimit: offer.beneficiariesLimit,
        }
      );
    }

    case PersonsActionTypes.ADD_PERSON:
    case PersonsActionTypes.CREATE_PERSON_SUCCESS:
      const personId = action.payload.personId;

      if (!personId) {
        return { ...state };
      }

      return {
        ...state,
        personsId: [...state.personsId, personId],
        personById: {
          ...state.personById,
          [personId]: getNewPerson(action.payload),
        },
      };

    case PersonsActionTypes.UPDATE_PERSON:
    case BeneficiaryActionTypes.UPDATE_BENEFICIARY:
    case PersonsActionTypes.UPDATE_PERSON_AND_UPDATE_OFFER: {
      const personId = action.payload.personId;

      if (!personId) {
        return { ...state };
      }

      return {
        ...state,
        personsId: [...state.personsId],
        personById: {
          ...state.personById,
          [personId]: {
            ...state.personById[personId],
            ...action.payload,
          },
        },
      };
    }

    case PersonsActionTypes.FETCH_ALL_PERSONS_SUCCESS:
      return {
        ...state,
        personsId: action.payload.map((person: Person) => person.personId),
        personById: action.payload.reduce((acc: Record<string, Person>, person: Person) => {
          acc[person.personId] = getNewPerson(person);
          return acc;
        }, {}),
      };

    case PersonsActionTypes.SET_SELFEMPLOYMENT_SUCCESS:
    case PersonsActionTypes.SET_PROFESSION_RISKGROUP_SUCCESS:
    case PersonsActionTypes.SET_PROFESSION_SUCCESS: {
      const { person, errors } = action.payload;
      const { personId, ...profession } = person;

      return {
        ...state,
        personById: {
          ...state.personById,
          [personId]: {
            ...state.personById[personId],
            profession,
            errors,
          },
        },
      };
    }

    case PersonsActionTypes.REMOVE_PERSON_SUCCESS: {
      const personId = action.payload.personId;

      return {
        ...state,
        personsId: state.personsId.filter((id) => id !== personId),
        personById: omit(state.personById, personId),
      };
    }
    case OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS:
    case OfferActionsTypes.DELETE_PERSON_ROLE_SUCCESS: {
      return {
        ...state,
        personById: state.personsId.reduce((personsBydId, personId) => {
          const newRoles =
            action.payload.find((rolePerson) => rolePerson.personId === personId)?.roles || [];
          return {
            ...personsBydId,
            [personId]: {
              ...state.personById[personId],
              roles: newRoles,
            },
          };
        }, {}),
      };
    }

    default:
      return { ...state };
  }
};
