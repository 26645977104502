import React, { useEffect, useState } from 'react';
import useInterval from 'use-interval';
import { REFRESH_INTERVAL_IN_MILISECS } from './RefreshUserToken.const';
import styles from './RefreshUserToken.module.scss';
import { ENDPOINTS, buildEndpoint } from 'services';

export const RefreshUserToken: React.FC = () => {
  const [intervalTime, setIntervalTime] = useState<number | null>(REFRESH_INTERVAL_IN_MILISECS);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    return () => setIntervalTime(null);
  }, []);

  useInterval(
    () => {
      setKey((key) => {
        const nextKey = key + 1;

        return Number.isSafeInteger(nextKey) ? nextKey : 0;
      });
    },
    intervalTime,
    false
  );

  return (
    <iframe
      data-testid={`refresh-user-token-${key}`}
      className={styles.root}
      title='refresh-user-token'
      src={buildEndpoint(ENDPOINTS.refreshUserToken)}
      key={key}
    />
  );
};
