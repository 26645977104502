import React from 'react';
import classNames from 'classnames';
import './RoleBadge.scss';
import { PersonRole } from 'entities/persons';
import { RoleBadgeProps } from './RoleBadge.types';

const getAbbreviationByByRole = (role: PersonRole): string | undefined => {
  switch (role) {
    case PersonRole.INSURED_PERSON:
      return 'VP';
    case PersonRole.INSURANCE_OWNER:
      return 'VN';
    default:
      return;
  }
};

export const RoleBadge: React.FC<RoleBadgeProps> = ({
  role,
  isSelected,
  onChangeRoleHandler,
  isDisabled,
}) => {
  function getClassNames() {
    return classNames({
      'role-badges__badge--selected-vn': role === PersonRole.INSURANCE_OWNER && isSelected,
      'role-badges__badge--selected': role !== PersonRole.INSURANCE_OWNER && isSelected,
      'role-badges__badge--disabled': isDisabled,
    });
  }

  const isVnAndIsSelected = role === PersonRole.INSURANCE_OWNER && isSelected;
  return (
    <div
      className={`role-badges__badge ${getClassNames()}`}
      onClick={(event) => {
        event.stopPropagation();
        if (isDisabled || isVnAndIsSelected) {
          return;
        }
        onChangeRoleHandler();
      }}
    >
      {getAbbreviationByByRole(role)}
    </div>
  );
};
