import { Button } from '@eg/elements/Button';
import React from 'react';
import { ProfessionRiskTypes } from '../../Profession.types';
import { ProfessionInfoProps } from './ProfessionInfo.types';

export const ProfessionInfo: React.FC<ProfessionInfoProps> = ({ riskGroup, setRiskGroup }) => {
  switch (riskGroup) {
    case ProfessionRiskTypes.GROUP_C:
    case ProfessionRiskTypes.GROUP_D:
      return (
        <div className='profession__info'>
          <div className='profession__info-header'>
            Sind Sie ausschließlich kaufmännisch/verwaltend tätig?
          </div>
          <div className='profession__info-buttons'>
            <Button variant='primary' onClick={() => setRiskGroup(ProfessionRiskTypes.GROUP_A)}>
              Ja
            </Button>
            <Button variant='tertiary' onClick={() => setRiskGroup(ProfessionRiskTypes.GROUP_B)}>
              Nein
            </Button>
          </div>
        </div>
      );

    case ProfessionRiskTypes.GROUP_S:
      return (
        <div className='profession__info'>
          <div className='profession__info-header'>Berufstätigkeit nicht versicherbar</div>
        </div>
      );

    case ProfessionRiskTypes.GROUP_X:
      return (
        <div className='profession__info'>
          <div className='profession__info-header'>Berufstätigkeit ist anfragepflichtig</div>
          <div className='profession__info-text'>
            Dieser Beruf ist ein spezielles Risiko und kann nicht telefonisch berechnet werden.
            Bitte leiten Sie den Kunden an den Außendienst weiter mit dem GEVO 550.
          </div>
        </div>
      );

    default:
      return null;
  }
};
