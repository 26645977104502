import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { partial } from 'lodash';
import { VariantRadioSelect as VariantRadioSelectComponent } from './VariantRadioSelect';
import {
  VariantRadioSelectStateProps,
  VariantRadioSelectDispatchProps,
  VariantRadioSelectOwnProps,
  VariantRadioSelectMergeProps,
} from './VariantRadioSelect.types';
import { offerActions, offerSelectors, TableFields } from 'entities/offer';
import { RootState } from 'store/store.interface';

const makeFieldMapState = (state: RootState, ownProps: VariantRadioSelectOwnProps<TableFields>) => {
  const { personId, variantId, field } = ownProps;
  const fieldSelector = offerSelectors.getFieldOrEmptyPlaceholderForBoolean(
    personId!,
    variantId!,
    field
  );

  return (state: RootState): VariantRadioSelectStateProps => ({
    ...fieldSelector(state),
    price: offerSelectors.getVariantPrice(state, variantId!),
  });
};

const mapDispatchToProps = (dispatch: Dispatch): VariantRadioSelectDispatchProps => ({
  selectVariant: (variantId: string, isSelected: boolean) =>
    dispatch(offerActions.selectVariant(variantId, isSelected)),
});

const mergeProps = (
  stateProps: VariantRadioSelectStateProps,
  dispatchProps: VariantRadioSelectDispatchProps,
  ownProps: VariantRadioSelectOwnProps
): VariantRadioSelectMergeProps => {
  const { selectVariant, ...restDispatchProps } = dispatchProps;
  return {
    ...stateProps,
    ...restDispatchProps,
    ...ownProps,
    selectVariant: partial(selectVariant, ownProps.variantId!, true),
  };
};

export const VariantRadioSelect = connect(
  makeFieldMapState,
  mapDispatchToProps,
  mergeProps
)(VariantRadioSelectComponent);
