const getQueryParam = (source: string, param: string) => {
  const urlQueryParams = new URLSearchParams(source);
  return urlQueryParams.get(param);
};

const getBusinessIdFromUrl = (url = document.location.search): string | undefined => {
  const businessId = getQueryParam(url, 'businessId');
  const businessIdInUrlRegex = /^([a-z0-9-]+)?($|#|\?)/gi;

  if (businessId?.match(businessIdInUrlRegex)) {
    return businessId;
  }

  return undefined;
};

export const appHelper = {
  getQueryParam,
  getBusinessIdFromUrl,
};
