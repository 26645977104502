import React, { useCallback, useEffect, useState } from 'react';
import TextInput from '@eg/elements/Input';
import { YearsInputProps } from './YearsInput.types';
import { Field } from 'components';
import { offerHelper } from 'entities/offer';

export const YearsInput: React.FC<YearsInputProps> = ({
  corrupted,
  disabled,
  errors,
  mandatory,
  min,
  max,
  setCorrupted,
  stepRange,
  updateVariant,
  value,
  withSuffix,
  field,
  personId,
  variantId,
}: YearsInputProps) => {
  const fieldId = offerHelper.generateComponsedKeyForFrontendValidationByComposedId(
    personId,
    variantId,
    field
  );
  const [inputStateValue, setInputStateValue] = useState(value);
  const onBlurHandler = useCallback(
    () => updateVariant(inputStateValue),
    [inputStateValue, updateVariant]
  );
  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const yearsRegex = /^[0-9]{0,2}$/;
      const inputValue = event.target.value;

      if (!yearsRegex.test(inputValue)) {
        event.nativeEvent.preventDefault();
        event.preventDefault();
        return;
      }

      setInputStateValue(inputValue);
    },
    [setInputStateValue]
  );

  const onKeyDownHandler = useCallback(
    (event: React.KeyboardEvent) => {
      const originalValue = parseInt(inputStateValue, 10);
      let valueToUpdate = parseInt(inputStateValue, 10);

      if (event.which === 38 || event.keyCode === 38) {
        valueToUpdate += stepRange!;
      }

      if (event.which === 40 || event.keyCode === 40) {
        valueToUpdate -= stepRange!;
      }

      if (originalValue !== valueToUpdate && valueToUpdate >= min! && valueToUpdate <= max!) {
        setInputStateValue(valueToUpdate.toString());
      }
    },
    [inputStateValue, setInputStateValue, max, min, stepRange]
  );

  useEffect(() => setInputStateValue(value), [value]);

  useEffect(() => {
    if (corrupted) {
      setInputStateValue(value);
      setCorrupted(false);
    }
  }, [corrupted, setCorrupted, value]);

  return (
    <Field errors={errors} required={mandatory} min={min} max={max} id={fieldId}>
      <TextInput
        {...(withSuffix && { adornmentRight: 'Jahre' })}
        disabled={disabled}
        value={inputStateValue}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
      />
    </Field>
  );
};
