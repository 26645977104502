import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'store/store.interface';
import { personsSelectors, Person } from 'entities/persons';
import { offerSelectors } from 'entities/offer';

import { PersonManagementComponent } from 'features/PersonManagement/PersonManagement.component';
import { beneficiaryManagementActions } from './state/beneficiary.management.actions';
import { beneficiaryManagementSelectors } from './state/beneficiary.management.selectors';
import { beneficiaryActions } from '../state/beneficiary.actions';
import {
  BeneficiaryManagementOwnProps,
  BeneficiaryManagementStateProps,
  BeneficiaryManagementDispatchProps,
} from './BeneficiaryManagement.types';

const mapStateToProps = (
  state: RootState,
  { insuredPersonId }: BeneficiaryManagementOwnProps
): BeneficiaryManagementStateProps => ({
  persons: personsSelectors.getBeneficiariesByPersonId(state, insuredPersonId),
  editedPerson: beneficiaryManagementSelectors.editedPerson(state, insuredPersonId),
  isPersonMgmtActionBlocked: beneficiaryManagementSelectors.isPersonMgmtActionBlocked(
    state,
    insuredPersonId
  ),
  numberOfPersons: personsSelectors.getBeneficiariesCountByPersonId(state, insuredPersonId),
  personsLimit: personsSelectors.getBeneficiariesLimit(state),
  canAddMorePersons:
    personsSelectors.isBeneficiaryLimitReached(state, insuredPersonId) &&
    !offerSelectors.isReadOnly(state),
  canEditPerson: !offerSelectors.isReadOnly(state),
});

const mapDispatchToProps = (dispatch: Dispatch): BeneficiaryManagementDispatchProps => ({
  selectPerson: (person: Person, insuredPersonId: string) =>
    dispatch(beneficiaryManagementActions.setEditedPerson(insuredPersonId, person)),
  removePerson: (personId: string, insuredPersonId: string) =>
    dispatch(beneficiaryManagementActions.removeBeneficiary.trigger({ personId, insuredPersonId })),
  createPerson: (insuredPersonId: string) =>
    dispatch(beneficiaryActions.createBeneficiary.trigger({ insuredPersonId })),
});

export const BeneficiaryManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonManagementComponent);
