import { connect } from 'react-redux';
import { VariantsTable as VariantsTableComponent } from './VariantsTable';
import { VariantsTableStateProps } from './VariantsTable.types';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState): VariantsTableStateProps => ({
  fieldsIds: offerSelectors.getCommonFieldsIdsForVariants(state),
  isOfferLoading: offerSelectors.isLoading(state),
  variantsIds: offerSelectors.getVariantsIds(state),
  isReadOnly: offerSelectors.isReadOnly(state),
});

export const VariantsTable = connect(mapStateToProps)(VariantsTableComponent);
