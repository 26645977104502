export interface PaymentStateProps {
  price: number | null;
  paymentMethodValue?: string;
}

export enum PaymentType {
  Monthly = 'MONATLICH',
  Quarterly = 'VIERTELJAEHRLICH',
  HalfYearly = 'HALBJAEHRLICH',
  Yearly = 'JAEHRLICH',
  OneTimePayment = 'EINMALZAHLUNG',
}

export const paymentTypeLabel: Record<string, string> = {
  [PaymentType.Monthly]: 'mtl.',
  [PaymentType.Quarterly]: 'viertelj.',
  [PaymentType.HalfYearly]: 'halbj.',
  [PaymentType.Yearly]: 'jährl.',
  [PaymentType.OneTimePayment]: 'Einmalbtr.',
};

export type PaymentProps = PaymentStateProps;
