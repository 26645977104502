import React from 'react';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { LoadingProps } from './Loading.types';
import styles from './Loading.module.scss';

export const Loading: React.FC<LoadingProps> = ({ children, loading, viewport = 'relative' }) => {
  return (
    <div className={styles.Loading}>
      <LoadingSpinner viewport={viewport} show={loading} />
      {children}
    </div>
  );
};
