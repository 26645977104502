import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import { createEpicMiddleware } from 'redux-observable';
import { REACT_APP_USE_LOGGER } from 'config';
import { rootReducer } from './root.reducer';
import { rootEpic } from './root.epic';
import { RootActions, RootState } from './store.interface';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const epicMiddleware = createEpicMiddleware<RootActions, RootActions, RootState>();

let middlewares: Middleware[] = [epicMiddleware];

if (REACT_APP_USE_LOGGER) {
  const logger = createLogger({ collapsed: true });

  middlewares = [logger, ...middlewares];
}

const store = createStore(rootReducer(), composeEnhancers(applyMiddleware(...middlewares)));

epicMiddleware.run(rootEpic);

export default store;
