import { PersonRole } from '../persons';

export interface OfferReducer {
  // TODO: Do we need this error atrr ?
  error: string | null;
  data: OfferFormData;
}

export enum VariantsNames {
  ERGO_UNFALLSCHUTZ = 'SMART',
  ERGO_UNFALLSCHUTZ_PREMIUM = 'BEST',
}

export type FieldPerVariant = {
  [value in VariantsNames]: Field;
};

export const isFieldPerVariant = (obj: Record<string, any>): obj is FieldPerVariant =>
  obj[VariantsNames.ERGO_UNFALLSCHUTZ] !== undefined &&
  obj[VariantsNames.ERGO_UNFALLSCHUTZ_PREMIUM] !== undefined;

export interface OfferAgreement {
  label: string;
  checked: boolean;
  text: string;
}

export type OfferAgreements = {
  [value in VariantsNames]?: OfferAgreement[];
};

export enum ConsultancyChannelDirection {
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound',
}

export interface ConsultancyProtocol {
  id?: string;
  channelDirection?: ConsultancyChannelDirection;
}

export enum ProfessionRiskGroup {
  A = 'A',
  B = 'B',
}

export type OfferProfession = {
  mainGroup?: ProfessionRiskGroup;
  occupation: string;
  group: string;
  id: string;
};

export interface OfferFormData {
  adsId: string;
  actionNr: string;
  businessId: string;
  consultancyProtocol: ConsultancyProtocol;
  productName: string;
  isUserInformedAboutContractTermination: boolean;
  agreements: OfferAgreements;
  formData: FormData;
  fee: OfferFee;
  // TODO: add proper error typ
  errors: any[];
}

export interface OfferFee {
  [key: string]: Fee;
}

export interface Fee {
  brutto?: number;
  netto?: number;
}

export interface FormData {
  paymentMethod: Field;
  insuranceBegin: Field;
  durationOfContract: Field;
  salesProcess: Field<SalesProcessTypes>;
  disabilityBenefit: FieldPerVariant;
  pensionBenefit: FieldPerVariant;
  profession: Field;
  // @TODO RENAME TO SELECTEDVARIANT
  selected: FieldPerVariant;
}

export interface Field<T = string> {
  value: T;
  defaultValue: string;
  mandatory: boolean;
  options: string[];
}

export interface UpdateVariantValue {
  fieldName: string;
  value: string;
  variantName?: VariantsNames;
}

export interface UpdateAgreementPayload {
  variantId: string;
  label: string;
  checked: boolean;
}

export enum SalesProcessTypes {
  DIREKTABSCHLUSS = 'DIREKTABSCHLUSS',
  VOLLANGEBOT = 'VOLLANGEBOT',
}

export enum RoleType {
  CONTRIBUTION_PAYER = 'BEITRAGSZAHLER',
  INSURANCE_OWNER = 'VERSICHERUNGSNEHMER',
  INSURED_PERSON = 'VERSICHERTE_PERSON',
  SHIPPING_ADDRESS = 'VERSANDANSCHRIFT',
}

export interface UnfallPersonRolesResponse {
  personId: string;
  roles: PersonRole[];
  affectedRoles: PersonRole[];
}

export interface AddRoleSuccessContext {
  addedRole: PersonRole;
}

export interface UpdateBirthdateContext {
  personId: string;
  birthdate: string;
}

export enum FlowType {
  NEW_BUSINESS = 'ANGELEGT',
  PREMATURE = 'VORFASS',
  FOLLOW_UP = 'NACHFASS',
  PROCESSING = 'VEREDELUNG_AKTIV',
  UPGRADING = 'UPGRADING_AKTIV',
  REQUEST_CANCELLED = 'ANTRAG_STORNIERT',
}
