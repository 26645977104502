import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerSelectors } from 'entities/offer';
import { Person, PersonProfession, personsActions, personsSelectors } from 'entities/persons';
import { RootState } from 'store/store.interface';
import { ProfessionComponent } from './Profession.component';
import { professionsSelectors } from './Profession.selectors';
import { ProfessionOwnProps } from './Profession.types';

const mapStateToProps = (state: RootState, ownProps: ProfessionOwnProps) => ({
  businessId: offerSelectors.getBusinessId(state),
  isKid: professionsSelectors.isKid(state, ownProps.personId),
  isSelfEmployable: professionsSelectors.isSelfEmployable(state, ownProps.personId),
  person: personsSelectors.getPersonById(state, ownProps.personId),
  isPersonPresentInAnyPackage: offerSelectors.isPersonPresentInAnyPackage(state, ownProps.personId),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setProfession: (
    personId: Person['personId'],
    businessId: string,
    professionId: PersonProfession['professionId']
  ) =>
    dispatch(
      personsActions.setProfession.trigger({
        personId,
        businessId,
        professionId,
      })
    ),
  setSelfEmployment: (personId: Person['personId'], businessId: string, selfEmployed: boolean) =>
    dispatch(
      personsActions.setSelfEmployment.trigger({
        personId,
        businessId,
        selfEmployed,
      })
    ),
  setRiskGroup: (
    personId: Person['personId'],
    businessId: string,
    professionId: PersonProfession['professionId'],
    riskGroup: PersonProfession['riskGroup']
  ) =>
    dispatch(
      personsActions.setProfessionRiskGroup.trigger({
        personId,
        businessId,
        professionId,
        riskGroup,
      })
    ),
});

export const Profession = connect(mapStateToProps, mapDispatchToProps)(ProfessionComponent);
