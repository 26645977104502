import React, { ReactNode } from 'react';

export enum ModalMode {
  CONFIRM,
  INFO,
}

export interface ModalProps {
  open: boolean;
  onConfirm(): void;
  onCancel(): void;
  mode?: ModalMode;
  children: ReactNode;
}

export interface Content {
  content: React.ReactNode;
  mode?: ModalMode;
}

export interface ModalContextProps {
  openModal(onConfirm?: () => void): Promise<unknown>;
  setContent(modalContent: Content): void;
}
