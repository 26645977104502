import React from 'react';
import { TooltipIcon } from '@eg/elements/components/TooltipIcon';
import { LabelProps } from './Label.types';
import './Label.css';

export const Label: React.FC<LabelProps> = ({ text, tooltipText }) => {
  if (!tooltipText) {
    return <span>{text}</span>;
  }

  return (
    <div className='label'>
      <span>{text}</span>
      <span className='label__tooltip'>
        <TooltipIcon>{tooltipText}</TooltipIcon>
      </span>
    </div>
  );
};
