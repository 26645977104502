import { OfferDto } from '../offer';

export interface PersonAddress {
  addressAdditionalInfo?: string;
  streetName: string;
  buildingNumber: string;
  buildingAdditionalInfo?: string;
  postCode: string;
  city: string;
  cityAdditionalInfo?: string;
  country: string;
}

export enum PersonRole {
  CONTRIBUTION_PAYER = 'CONTRIBUTION_PAYER',
  INSURANCE_HOLDER = 'INSURANCE_HOLDER',
  INSURANCE_OWNER = 'INSURANCE_OWNER',
  INSURED_PERSON = 'INSURED_PERSON',
  SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
  BENEFICIARY = 'BENEFICIARY',
}

export enum PersonRoleDE {
  CONTRIBUTION_PAYER = 'Beitragszahler',

  // todo posprzątac tu
  INSURANCE_HOLDER = 'Halter',
  INSURANCE_OWNER = 'Versicherungsnehmer',
  INSURED_PERSON = 'Versicherte Person',
}

export enum PersonSalutation {
  MISTRESS = 'Frau',
  MISTER = 'Herr',
  UNKNOWN = '',
}

export interface PersonProfession {
  riskGroup: string | null;
  professionId: string | null;
  professionName: string | null;
  selfEmployed: boolean | null;
}

export interface PersonError {
  code: string | null;
  text: string | null;
  personId: string | null;
  variantName: string | null;
}

export interface OfferProfessionResponse {
  person: PersonProfession & { personId: string };
  errors: PersonError[];
  offer: OfferDto;
}

export interface Person {
  firstName: string;
  lastName: string;
  personId: string;
  salutation: PersonSalutation;
  birthDate: string;
  personalEmail: string;
  workEmail: string;

  roles: PersonRole[];
  profession?: PersonProfession;
  errors: PersonError[];

  address: PersonAddress;
  showOnList?: boolean;
  currentlyEditing: boolean;
  title: string;
  birthPlace: string;
  nationality: string;
  phoneInternationalPrefix: string;

  pdsValidationStatus: ValidationStatus;
  insuredPersonId?: string;
  share?: number;
  edwinInitialized?: boolean;
}

export interface PersonsState {
  personsId: string[];
  personById: Record<string, Person>;
  personsLimit: number;
  insuredPersonsLimit: number;
  beneficiariesLimit: number;
}

export interface SetProfessionPayload {
  personId: Person['personId'];
  businessId: string;
  professionId: PersonProfession['professionId'];
}

export interface SetProfessionRiskGroupPayload {
  personId: Person['personId'];
  businessId: string;
  professionId: PersonProfession['professionId'];
  riskGroup: PersonProfession['riskGroup'];
}

export interface SetSelfEmploymentPayload {
  personId: Person['personId'];
  businessId: string;
  selfEmployed: boolean;
}

export enum ValidationStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  UNKNOWN = 'UNKNOWN',
}
