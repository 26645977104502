import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ConsultancyProtocol as ConsultancyProtocolComponent } from './ConsultancyProtocol';
import { agencySelectors } from 'entities/agency/agency.selectors';
import { offerActions, OfferFields, offerSelectors } from 'entities/offer';

import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState) => ({
  agentName: agencySelectors.getAgentName(state),
  channelDirection: offerSelectors.getConsultationProtocolChannelDirection(state),
  isPaperOffer: offerSelectors.isPaperOffer(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendConsultancyProtocolId: (newIdd: string) =>
    dispatch(offerActions.updateOfferBasicData(newIdd, OfferFields.consultationProtocolId)),
});

export const ConsultancyProtocol = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultancyProtocolComponent);
