import React from 'react';
import { Errors } from './components/Errors';
import { Payment } from './components/Payment';
import { SelectSalesProcess } from './components/SelectSalesProcess';
import { Statics } from './components/Statics';
import { StickySidebarProps } from './StickySidebar.types';
import { Submit } from './components/Submit';
import { SuccessModal } from './components/SuccessModal';
import './StickySidebar.css';
import { CancelButton } from './components/CancelButton';

export const StickySidebar: React.FC<StickySidebarProps> = ({ isProcessing }) => (
  <section className='sticky-sidebar'>
    <SelectSalesProcess />
    <Statics />
    <Errors />
    <Payment />
    <Submit />
    {isProcessing && <CancelButton />}
    <SuccessModal />
  </section>
);
