import { connect } from 'react-redux';
import { ContractDetails as ContractDetailsComponent } from './ContractDetails';
import { contractDetailsSelectors } from 'entities/contractDetails';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState) => ({
  hasEnabledAnyCheckbox: contractDetailsSelectors.hasEnabledAnyCheckbox(state),
  isLoading: contractDetailsSelectors.isLoading(state),
});

export const ContractDetails = connect(mapStateToProps)(ContractDetailsComponent);
