import { AjaxError } from 'rxjs/ajax';
import { Person } from 'entities/persons';
import { createAction, ActionsUnion } from 'services';

export enum BeneficiaryManagementActionTypes {
  SET_EDITED_PERSON = '[ui][beneficiaries.management] SET_EDITED_PERSON',
  LAST_BENEFICIARY_REMOVED = '[ui][beneficiaries.management] LAST_BENEFICIARY_REMOVED',
  REMOVE_BENEFICIARY_TRIGGER = '[ui][beneficiaries.management] REMOVE_BENEFICIARY_TRIGGER',
  REMOVE_BENEFICIARY_SUCCESS = '[ui][beneficiaries.management] REMOVE_BENEFICIARY_SUCCESS',
  REMOVE_BENEFICIARY_FAILURE = '[ui][beneficiaries.management] REMOVE_BENEFICIARY_FAILURE',
}

type BeneficiaryManagementPayload = { insuredPersonId: string; personId: string };

const setEditedPerson = (insuredPersonId: string, editedPerson: Person) =>
  createAction(BeneficiaryManagementActionTypes.SET_EDITED_PERSON, {
    insuredPersonId,
    editedPerson,
  });

const removeBeneficiary = {
  trigger: (payload: BeneficiaryManagementPayload) =>
    createAction(BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_TRIGGER, payload),
  success: (success: BeneficiaryManagementPayload) =>
    createAction(BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_SUCCESS, success),
  failure: (error: AjaxError, context: BeneficiaryManagementPayload) =>
    createAction(BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_FAILURE, error, context),
};

const lastBeneficiaryRemoved = (insuredPersonId: string, personId: string) =>
  createAction(BeneficiaryManagementActionTypes.LAST_BENEFICIARY_REMOVED, {
    insuredPersonId,
    personId,
  });

const actions = {
  setEditedPerson,
  lastBeneficiaryRemoved,
};

const asyncActions = {
  removeBeneficiary,
};

export const beneficiaryManagementActions = {
  ...actions,
  ...asyncActions,
};

export type BeneficiaryManagementActions = ActionsUnion<typeof actions, typeof asyncActions>;
