import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { offerActions, TableFields } from 'entities/offer';

export const useFieldValue = <Value>(
  personId: string,
  variantId: string,
  fieldId: TableFields,
  value: Value,
  corrupted: boolean
) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedValue(value);

    if (corrupted) {
      dispatch(offerActions.setFieldCorrupted(personId, variantId, fieldId, false));
    }
  }, [corrupted, dispatch, fieldId, personId, value, variantId]);

  return { selectedValue, setSelectedValue };
};
