import React from 'react';
import Button from '@eg/elements/Button';
import labels from './Submit.labels';
import { SubmitProps } from './Submit.types';
import styles from './Submit.module.scss';

export const Submit: React.FC<SubmitProps> = ({ finalizeOrder, isLoading, isReadOnly }) => (
  <div className={styles.Submit}>
    <div className={styles.SubmitFinalizeBtn}>
      <Button
        disabled={isLoading || isReadOnly}
        colorScheme='inverted'
        variant='secondary'
        size='large'
        onClick={finalizeOrder}
      >
        {labels.finalizeBtn}
      </Button>
    </div>
  </div>
);
