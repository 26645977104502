import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  personsSelectors,
  Person,
  getPersonsWithoutBeneficiaries,
  personsActions,
} from 'entities/persons';
import { RootState } from 'store/store.interface';
import {
  PersonManagementStateProps,
  PersonManagementDispatchProps,
} from './PersonManagement.types';
import { PersonManagementComponent } from './PersonManagement.component';

import { personManagementActions, personManagementSelectors } from './state';

import WithinDetails from './WithinDetails/WithinDetails.hoc';
import { offerSelectors } from 'entities/offer';

const mapStateToProps = (state: RootState): PersonManagementStateProps => {
  const canEditPerson = !offerSelectors.isReadOnly(state);

  return {
    persons: getPersonsWithoutBeneficiaries(state),
    editedPerson: personManagementSelectors.editedPerson(state),
    isPersonMgmtActionBlocked: personManagementSelectors.isPersonMgmtActionBlocked(state),
    numberOfPersons: personsSelectors.getInsuredPersonsCount(state),
    personsLimit: personsSelectors.getInsuredPersonsLimit(state),
    canAddMorePersons: personsSelectors.getRemainingPersonsNumber(state) > 0 && canEditPerson,
    canEditPerson,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PersonManagementDispatchProps => ({
  selectPerson: (person: Person) => dispatch(personManagementActions.setEditedPerson(person)),
  removePerson: (personId: string) => dispatch(personsActions.removePerson.trigger({ personId })),
  createPerson: () => dispatch(personsActions.createPerson.trigger()),
});

export const PersonManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithinDetails(PersonManagementComponent));
