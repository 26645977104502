import React from 'react';
import classNames from 'classnames';
import './ValidatePersonButton.scss';
import Button from '@eg/elements/Button';
import { ValidatePersonButtonProps } from './ValidatePersonButton.types';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import RefreshIcon from '@eg/elements/Icons/RefreshIcon';
import CloseBorderIcon from '@eg/elements/Icons/CloseBorderIcon';
import { ValidationStatus } from 'entities/persons';

function getValidationButtonLabel(validationStatus: ValidationStatus) {
  switch (validationStatus) {
    case ValidationStatus.VALID:
      return 'Personendaten sind gültig';
    case ValidationStatus.INVALID:
      return 'Personendaten sind ungültig';
    case ValidationStatus.UNKNOWN:
      return 'Personendaten überprüfen';
  }
}

function getClassNames(validationStatus: ValidationStatus) {
  return classNames({
    'validate-person-button--valid': validationStatus === ValidationStatus.VALID,
    'validate-person-button--invalid': validationStatus === ValidationStatus.INVALID,
    'validate-person-button--unknown': validationStatus === ValidationStatus.UNKNOWN,
  });
}

function getValidationIcon(validationStatus: ValidationStatus) {
  switch (validationStatus) {
    case ValidationStatus.VALID:
      return CheckIcon;
    case ValidationStatus.INVALID:
      return CloseBorderIcon;
    case ValidationStatus.UNKNOWN:
      return RefreshIcon;
  }
}

export const ValidatePersonButton: React.FC<ValidatePersonButtonProps> = ({
  onClick,
  validationStatus,
  ...buttonProps
}) => {
  return (
    <div className='validate-person-button__container'>
      <Button
        onClick={onClick}
        className={getClassNames(validationStatus)}
        iconLeft={getValidationIcon(validationStatus)}
        {...buttonProps}
      >
        {getValidationButtonLabel(validationStatus)}
      </Button>
    </div>
  );
};
