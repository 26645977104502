import { DEFAULT_HTTP_HEADERS } from 'config';
import { getEndpointBusinessId } from './endpoint.builder.service';
import { AjaxRequest, ajax } from 'rxjs/ajax';

export const X_CORRELATION_ID = 'X-Correlation-Id';

export const makeApiCall = (request: AjaxRequest) =>
  ajax({
    ...request,
    headers: {
      ...(request.headers ? { ...request.headers } : DEFAULT_HTTP_HEADERS),
      [X_CORRELATION_ID]: getEndpointBusinessId(),
    },
    withCredentials: true,
  });
