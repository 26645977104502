import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAction, createAsyncAction } from 'services';
import { OfferDto } from 'entities/offer';
import { Person } from 'entities/persons';

export enum BeneficiaryActionTypes {
  CREATE_BENEFICIARY_TRIGGER = '[entities][beneficiary] CREATE_BENEFICIARY_TRIGGER',
  CREATE_BENEFICIARY_SUCCESS = '[entities][beneficiary] CREATE_BENEFICIARY_SUCCESS',
  CREATE_BENEFICIARY_FAILURE = '[entities][beneficiary] CREATE_BENEFICIARY_FAILURE',
  REMOVE_BENEFICIARIES_TRIGGER = '[entities][beneficiaries] REMOVE_BENEFICIARIES_TRIGGER',
  REMOVE_BENEFICIARIES_SUCCESS = '[entities][beneficiaries] REMOVE_BENEFICIARIES_SUCCESS',
  REMOVE_BENEFICIARIES_FAILURE = '[entities][beneficiaries] REMOVE_BENEFICIARIES_FAILURE',
  UPDATE_BENEFICIARY = '[entities][beneficiary] UPDATE_BENEFICIARY',
  UPDATE_BENEFICIARY_SHARE_TRIGGER = '[entities][beneficiary] UPDATE_BENEFICIARY_SHARE_TRIGGER',
  UPDATE_BENEFICIARY_SHARE_SUCCESS = '[entities][beneficiary] UPDATE_BENEFICIARY_SHARE_SUCCESS',
  UPDATE_BENEFICIARY_SHARE_FAILURE = '[entities][beneficiary] UPDATE_BENEFICIARY_SHARE_FAILURE',
}

type BeneficiaryPayload = { insuredPersonId: string };
const createBeneficiary = {
  trigger: (payload: BeneficiaryPayload) =>
    createAction(BeneficiaryActionTypes.CREATE_BENEFICIARY_TRIGGER, payload),
  success: (success: BeneficiaryPayload & { offer: OfferDto }) =>
    createAction(BeneficiaryActionTypes.CREATE_BENEFICIARY_SUCCESS, success),
  failure: (error: AjaxError, context: BeneficiaryPayload) =>
    createAction(BeneficiaryActionTypes.CREATE_BENEFICIARY_FAILURE, error, context),
};

const removeBeneficiaries = createAsyncAction(
  BeneficiaryActionTypes.REMOVE_BENEFICIARIES_TRIGGER,
  BeneficiaryActionTypes.REMOVE_BENEFICIARIES_SUCCESS,
  BeneficiaryActionTypes.REMOVE_BENEFICIARIES_FAILURE
)<{ personId: string }, { personId: string }, AjaxError>();

const updateBeneficiaryShare = createAsyncAction(
  BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_TRIGGER,
  BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_SUCCESS,
  BeneficiaryActionTypes.UPDATE_BENEFICIARY_SHARE_FAILURE
)<{ personId: string; share: number }, { offer: OfferDto; person: Person }, AjaxError>();

export type updatePersonRoleSuccess = ReturnType<typeof updateBeneficiarySuccess>;
const updateBeneficiarySuccess = (person: Person) =>
  createAction(BeneficiaryActionTypes.UPDATE_BENEFICIARY, person);

const actions = {
  updateBeneficiarySuccess,
};

const asyncActions = {
  createBeneficiary,
  removeBeneficiaries,
  updateBeneficiaryShare,
};

export const beneficiaryActions = {
  ...actions,
  ...asyncActions,
};

export type BeneficiaryActions = ActionsUnion<typeof actions, typeof asyncActions>;
