import React from 'react';
import Details from '@eg/elements/Details';
import './WithinDetails.scss';

const WithinDetails =
  <P extends Record<string, any>>(WrappedComponent: React.ComponentType<P>): React.FC<P> =>
  (props: P) =>
    (
      <Details className='within-details' summary='Personendaten' isInitiallyOpen>
        <WrappedComponent {...props} />
      </Details>
    );
WithinDetails.displayName = 'WithinDetails';

export default WithinDetails;
