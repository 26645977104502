import { isString, template } from 'lodash';
import { REACT_APP_BACKEND_URL } from 'config';

export enum ENDPOINTS {
  fetchInit = 'fetchInit',
  fetchOffer = 'fetchOffer',
  selectVariant = 'selectVariant',
  updateBasicOfferFields = 'updateBasicOfferFields',
  updateVariants = 'updateVariants',
  deleteAssociationMembership = 'deleteAssociationMembership',
  updateAssociationMembership = 'updateAssociationMembership',
  deleteReplacementContract = 'deleteReplacementContract',
  updateReplacementContract = 'updateReplacementContract',
  finalizeOrder = 'finalizeOrder',
  offerPersonRoles = 'offerPersonRoles',
  offerPersonRoleDelete = 'offerPersonRoleDelete',
  createPerson = 'createPerson',
  deletePerson = 'deletePerson',
  updateShippingWay = 'updateShippingWay',
  updateAgreements = 'updateAgreements',
  updateProfession = 'updateProfession',
  fetchAllPersons = 'fetchAllPersons',
  updateBirthdate = 'updateBirthdate',
  updatePaymentData = 'updatePaymentData',
  deletePaymentData = 'deletePaymentData',
  addApprovalClause = 'addApprovalClause',
  removeApprovalClause = 'removeApprovalClause',
  addBeneficiary = 'addBeneficiary',
  deleteBeneficiaries = 'deleteBeneficiaries',
  updateBeneficiaryShare = 'updateBeneficiaryShare',
  searchProfession = 'searchProfession',
  consultancyProtocol = 'consultancy-protocol',
  cancelRequest = 'cancelRequest',
  refreshUserToken = 'refreshUserToken',
  login = 'login',
}

class EndpointBuilderFactory {
  private ENDPOINT_TEMPLATES_URL: Record<ENDPOINTS, string> = {
    [ENDPOINTS.fetchInit]: '<%= backendUrl %>/api/offer/<%= businessId %>/init',
    [ENDPOINTS.fetchOffer]: '<%= backendUrl %>/api/offer/<%= businessId %>',
    [ENDPOINTS.selectVariant]: '<%= backendUrl %>/api/offer/<%= businessId %>',
    [ENDPOINTS.updateBasicOfferFields]: '<%= backendUrl %>/api/offer/<%= businessId %>',
    [ENDPOINTS.updateVariants]: '<%= backendUrl %>/api/offer/<%= businessId %>',
    [ENDPOINTS.deleteAssociationMembership]:
      '<%= backendUrl %>/api/contract-details/association/<%= businessId %>',
    [ENDPOINTS.updateAssociationMembership]:
      '<%= backendUrl %>/api/contract-details/association/<%= businessId %>',
    [ENDPOINTS.deleteReplacementContract]:
      '<%= backendUrl %>/api/contract-details/replacement-contract/<%= businessId %>',
    [ENDPOINTS.updateReplacementContract]:
      '<%= backendUrl %>/api/contract-details/replacement-contract/<%= businessId %>',
    [ENDPOINTS.finalizeOrder]: '<%= backendUrl %>/api/order/<%= businessId %>',
    [ENDPOINTS.offerPersonRoles]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/roles/<%= personId %>',
    [ENDPOINTS.offerPersonRoleDelete]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/roles/<%= personId %>/<%= roleType %>',
    [ENDPOINTS.createPerson]: '<%= backendUrl %>/api/persons/<%= businessId %>',
    [ENDPOINTS.deletePerson]: '<%= backendUrl %>/api/persons/<%= businessId %>/<%= personId %>',
    [ENDPOINTS.updateShippingWay]: '<%= backendUrl %>/api/offer/<%= businessId %>/shipping-way',
    [ENDPOINTS.updateAgreements]: '<%= backendUrl %>/api/offer/<%= businessId %>/agreements',
    [ENDPOINTS.updateProfession]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/profession/<%= personId %>',
    [ENDPOINTS.fetchAllPersons]: '<%= backendUrl %>/api/persons/<%= businessId %>/all',
    [ENDPOINTS.updateBirthdate]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/birthdate/<%= personId %>',
    [ENDPOINTS.updatePaymentData]: '<%= backendUrl %>/api/payment-data',
    [ENDPOINTS.deletePaymentData]: '<%= backendUrl %>/api/payment-data/<%= businessId %>',
    [ENDPOINTS.addApprovalClause]: '<%= backendUrl %>/api/offer/<%= businessId %>/approval',
    [ENDPOINTS.removeApprovalClause]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/approval/<%= reasonId %>',
    [ENDPOINTS.addBeneficiary]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/beneficiary/<%= insuredPersonId %>',
    [ENDPOINTS.deleteBeneficiaries]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/beneficiary/<%= personId %>',
    [ENDPOINTS.updateBeneficiaryShare]:
      '<%= backendUrl %>/api/offer/<%= businessId %>/beneficiary/<%= personId %>/share',
    [ENDPOINTS.searchProfession]:
      '<%= backendUrl %>/api/professions/search?searchProfession=<%= searchProfession %>',
    [ENDPOINTS.consultancyProtocol]: '<%= backendUrl %>/api/consultancy-protocol',
    [ENDPOINTS.cancelRequest]: '<%= backendUrl %>/api/offer/<%= businessId %>/cancel-request',
    [ENDPOINTS.refreshUserToken]: '<%= backendUrl %>/api/auth/refresh/user-token',
    [ENDPOINTS.login]: '<%= backendUrl %>/api/auth/login?RelayState=<%= RelayState %>',
  };

  private backendUrl: string = REACT_APP_BACKEND_URL;

  private businessId = '';

  setBackendUrl(backendUrl?: string) {
    if (isString(backendUrl)) {
      this.backendUrl = backendUrl;
    }
  }

  setBusinessId(businessId: string) {
    this.businessId = businessId;
  }

  getBusinessId() {
    return this.businessId;
  }

  build(templateKey: ENDPOINTS, templateParams = {}): string {
    const params = {
      backendUrl: this.backendUrl,
      businessId: this.businessId,
      ...templateParams,
    };

    const templateUrl = this.ENDPOINT_TEMPLATES_URL[templateKey];
    return template(templateUrl)(params);
  }
}

const endpointBuilderFactory = new EndpointBuilderFactory();

export const buildEndpoint = endpointBuilderFactory.build.bind(endpointBuilderFactory);
export const getEndpointBusinessId =
  endpointBuilderFactory.getBusinessId.bind(endpointBuilderFactory);
export default endpointBuilderFactory;
