import { catchError, map, mergeMap } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { get } from 'lodash';
import { makeApiCall, buildEndpoint, ENDPOINTS } from 'services';
import { orderActions, OrderActionsTypes } from './order.actions';
import { RootState } from 'store/store.interface';

export const onFinalizeRequest: Epic<any, any, RootState> = (action$) =>
  action$.pipe(
    ofType(OrderActionsTypes.FINALIZE),
    mergeMap(() =>
      from(
        makeApiCall({
          method: 'post',
          url: buildEndpoint(ENDPOINTS.finalizeOrder),
          body: null,
        })
      ).pipe(
        map(() => orderActions.finalizeSuccess()),
        catchError((error) => {
          const messages = get(error, 'response.messages', []);
          return get(error, 'status') === 422
            ? of(orderActions.finalizeFailure(null, messages))
            : of(orderActions.finalizeFailure(error, []));
        })
      )
    )
  );

export default combineEpics(onFinalizeRequest);
