import React from 'react';
import EgModal from '@eg/elements/Modal';
import Button from '@eg/elements/Button';
import styles from './Modal.module.scss';
import { ModalProps, ModalMode } from './Modal.types';
import T from './Modal.labels';

export const Modal: React.FC<ModalProps> = ({
  open,
  onConfirm,
  onCancel,
  mode = ModalMode.CONFIRM,
  children,
}) => {
  const onModalButtonConfirm = () => {
    onConfirm();
    onCancel();
  };

  return (
    <EgModal dismissible backdropDismissesModal onDismiss={onCancel} open={open}>
      <div className={styles.ModalWrapper}>
        <div className={styles.ModalLabel}>{children}</div>
        <div className={styles.ModalControls}>
          <Button onClick={onModalButtonConfirm}>
            {mode === ModalMode.CONFIRM ? T.confirm : T.ok}
          </Button>
          {mode === ModalMode.CONFIRM && <Button onClick={onCancel}>{T.cancel}</Button>}
        </div>
      </div>
    </EgModal>
  );
};
