import { EMPTY, of } from 'rxjs';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { buildEndpoint, ENDPOINTS, ActionByType, makeApiCall } from 'services';
import { RootState } from 'store/store.interface';
import { PersonsActions, personsActions, personsSelectors } from 'entities/persons';
import { AjaxError } from 'rxjs/ajax';
import {
  beneficiaryManagementActions,
  BeneficiaryManagementActions,
  BeneficiaryManagementActionTypes,
} from './beneficiary.management.actions';

type RemoveBeneficiaryTrigger = ActionByType<
  BeneficiaryManagementActions,
  BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_TRIGGER
>;
export const onDeleteBeneficiaryRequest: Epic<
  PersonsActions | BeneficiaryManagementActions,
  PersonsActions | BeneficiaryManagementActions,
  RootState
> = (action$) =>
  action$.pipe(
    ofType(BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_TRIGGER),
    mergeMap((action) => {
      const { personId, insuredPersonId } = (action as RemoveBeneficiaryTrigger).payload;

      return makeApiCall({
        method: 'delete',
        url: buildEndpoint(ENDPOINTS.deletePerson, { personId }),
      }).pipe(
        mergeMap(() => {
          return [
            personsActions.removePerson.success({ personId }),
            beneficiaryManagementActions.removeBeneficiary.success({ personId, insuredPersonId }),
          ];
        }),
        catchError((error: AjaxError) =>
          of(
            beneficiaryManagementActions.removeBeneficiary.failure(error, {
              personId,
              insuredPersonId,
            })
          )
        )
      );
    })
  );

export const onDeleteBeneficiarySuccess: Epic<
  PersonsActions | BeneficiaryManagementActions,
  PersonsActions | BeneficiaryManagementActions,
  RootState
> = (action$, state$) =>
  action$.pipe(
    ofType(BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_SUCCESS),
    mergeMap((action) => {
      const { personId, insuredPersonId } = (action as RemoveBeneficiaryTrigger).payload;
      const hasPersonBeneficiaries = personsSelectors.hasInsuredPersonBeneficiaries(
        state$.value,
        insuredPersonId
      );

      return hasPersonBeneficiaries
        ? EMPTY
        : [beneficiaryManagementActions.lastBeneficiaryRemoved(insuredPersonId, personId)];
    })
  );

export const beneficiaryManagementEpics = combineEpics(
  onDeleteBeneficiaryRequest,
  onDeleteBeneficiarySuccess
);
