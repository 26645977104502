import { isMatch } from 'date-fns';
import { get, isEmpty, isNil } from 'lodash';
import { AnyAction } from 'redux';
import { RootState } from 'store/store.interface';
import { offerHelper } from './offer.helper';
import { offerSelectors } from './offer.selectors';
import {
  FieldWithPackage,
  PackageFields,
  TableFields,
  UpdatePersonsFieldsMap,
  UpdateVariantFieldDto,
  UpdateVariantFieldsMap,
} from './offer.types';
import { personsActions, PersonSalutation, personsSelectors, UpdatePerson } from '../persons';
import { ProfessionRiskTypes } from 'features/ComparisonTable/components/Profession/Profession.types';
import { professionsSelectors } from 'features/ComparisonTable/components/Profession/Profession.selectors';
import { UpdateBirthdate } from './offer.actions';

const STUDENT_GIRL_PROFESSION_ID = '0998997W';
const STUDENT_BOY_PROFESSION_ID = '0998997M';

const fieldsToUpdateByVariantIdToDto = (
  state: RootState,
  fieldsToUpdateByVariantId: UpdateVariantFieldsMap,
  personId: string
) =>
  Object.keys(fieldsToUpdateByVariantId).reduce(
    (acc: UpdateVariantFieldDto[], variantId: string) => {
      acc.push(
        ...Object.keys(fieldsToUpdateByVariantId[variantId]).map((fieldId: string) => {
          const parsedFieldId = (
            fieldId.includes('.') ? fieldId.split('.')[1] : fieldId
          ) as TableFields;
          return {
            fieldId: parsedFieldId,
            packageId: offerSelectors.getPackageIdFromComposedId(
              state,
              personId,
              variantId,
              fieldId as PackageFields
            ),
            personId,
            value: get(fieldsToUpdateByVariantId, [variantId, fieldId]),
            variantId,
          };
        })
      );
      return acc;
    },
    []
  );

const fieldsToUpdateByPersonIdToDto = (
  state: RootState,
  fieldsToUpdateByPersonId: UpdatePersonsFieldsMap
) =>
  Object.keys(fieldsToUpdateByPersonId).reduce((acc: UpdateVariantFieldDto[], personId: string) => {
    acc.push(
      ...fieldsToUpdateByVariantIdToDto(state, fieldsToUpdateByPersonId[personId], personId)
    );
    return acc;
  }, []);

const supressEmptyValuesMandatoryFields = (action: AnyAction, state: RootState) => {
  const { personId, variantId, fieldId, value } = action.payload.fieldsToUpdateByPersonId;
  const valueFromStore = offerSelectors.getField(
    state,
    personId,
    variantId,
    fieldId
  ) as FieldWithPackage;

  if (valueFromStore && valueFromStore?.fieldPackage?.mandatory) {
    return !!value || value === false;
  }

  return true;
};

const supressInvalidRangeNotEmptyFields = (action: AnyAction, state: RootState) => {
  const { personId, variantId, fieldId, value } = action.payload.fieldsToUpdateByPersonId;
  const { min, max } = offerSelectors.getField(state, personId, variantId, fieldId) || {
    min: undefined,
    max: undefined,
  };

  if (isEmpty(value)) {
    return true;
  }

  // check that Field has min/max defined and is not a string
  const hasMinMax = !isNil(min) && !isNil(max);
  const notAString = typeof value !== 'string';
  if (!hasMinMax || notAString) {
    return true;
  }

  // check that string is matching date format
  const isDateString = isMatch(value, 'yyyy-MM-dd');
  if (isDateString) {
    return true;
  }

  // try to parse string as float and check that it's within min/max range
  const floatValue = parseFloat(value);
  return !isNaN(floatValue) && floatValue >= min! && floatValue <= max!;
};

const supressInvalidStepRangeNotEmptyFields = (action: AnyAction, state: RootState) => {
  const { personId, variantId, fieldId, value } = action.payload.fieldsToUpdateByPersonId;
  const { stepRange } = offerSelectors.getField(state, personId, variantId, fieldId) || {
    stepRange: undefined,
  };

  if (isEmpty(value)) {
    return true;
  }

  if (!stepRange) {
    return true;
  }

  return offerHelper.hasValidStepRange(value, stepRange);
};

const kidProfessionSideEffect = (
  personId: string,
  birthDate: string,
  salutation: string,
  state: RootState
) => {
  const businessId = offerSelectors.getBusinessId(state);
  const { professionId } = personsSelectors.getProfession(state, personId) || {};
  const hasSalutation = !!salutation;
  const hasBirthdate = !!birthDate;
  const isKid = professionsSelectors.isKid(state, personId);
  const isStudent = professionsSelectors.isStudent(state, personId);

  if (!hasSalutation && isKid) {
    return [
      personsActions.setProfessionRiskGroup.trigger({
        personId,
        businessId,
        professionId: STUDENT_BOY_PROFESSION_ID,
        riskGroup: ProfessionRiskTypes.GROUP_A,
      }),
    ];
  }

  const isSalutationMatchingStudentProfession =
    (salutation === PersonSalutation.MISTER && professionId === STUDENT_BOY_PROFESSION_ID) ||
    (salutation === PersonSalutation.MISTRESS && professionId === STUDENT_GIRL_PROFESSION_ID);
  const isKidNotStudent = hasSalutation && hasBirthdate && isKid && !isStudent;
  const isKidThatChangedSalutation =
    hasSalutation && hasBirthdate && isKid && isStudent && !isSalutationMatchingStudentProfession;

  if (isKidNotStudent || isKidThatChangedSalutation) {
    const computedProfessionId =
      salutation === PersonSalutation.MISTER
        ? STUDENT_BOY_PROFESSION_ID
        : STUDENT_GIRL_PROFESSION_ID;

    return [
      personsActions.setProfessionRiskGroup.trigger({
        personId,
        businessId,
        professionId: computedProfessionId,
        riskGroup: ProfessionRiskTypes.GROUP_A,
      }),
    ];
  }

  return [];
};

const selfEmployableSideEffect = (personId: string, state: RootState) => {
  const businessId = offerSelectors.getBusinessId(state);
  const profession = personsSelectors.getProfession(state, personId);
  const isSelfEmployed = profession?.selfEmployed;
  const isSelfEmployable = professionsSelectors.isSelfEmployable(state, personId);

  if (!isSelfEmployable && isSelfEmployed) {
    return [
      personsActions.setSelfEmployment.trigger({
        personId,
        businessId,
        selfEmployed: false,
      }),
    ];
  }

  return [];
};

const professionsSideEffects = (
  personId: string,
  birthdate: string,
  salutation: string,
  state: RootState
) => {
  return [
    ...kidProfessionSideEffect(personId, birthdate, salutation, state),
    ...selfEmployableSideEffect(personId, state),
  ];
};

const onUpdateBirthdateSuccessSideEffects = (action: UpdateBirthdate, state: RootState) => {
  const personId = action.context?.personId || '';
  const birthdate = action.context?.birthdate || '';
  const salutation = personsSelectors.getSalutation(state, personId) || '';

  return professionsSideEffects(personId, birthdate, salutation, state);
};

const onUpdatePersonSalutation = (action: UpdatePerson, state: RootState) => {
  const { personId = '', birthDate = '', salutation = '' } = action.payload;
  return kidProfessionSideEffect(personId, birthDate, salutation, state);
};

export const offerService = {
  fieldsToUpdateByPersonIdToDto,
  supressEmptyValuesMandatoryFields,
  supressInvalidRangeNotEmptyFields,
  supressInvalidStepRangeNotEmptyFields,
  onUpdateBirthdateSuccessSideEffects,
  onUpdatePersonSalutation,
};
