import { createSelector } from 'reselect';
import { RootState } from 'store/store.interface';

const getAgencyData = (state: RootState) => state.entities.agency;

const getAgentName = createSelector(getAgencyData, (agencyData) => agencyData.agentName);

const getOenr = createSelector(getAgencyData, (agencyData) => agencyData.oeNr);

export const agencySelectors = {
  getAgencyData,
  getAgentName,
  getOenr,
};
