import Button from '@eg/elements/Button';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { REACT_APP_PERSONAL_DATA_BACKEND_URL, REACT_APP_PERSONAL_DATA_BUNDLE_URL } from 'config';
import {
  PersonalDataEvent,
  PersonalDataOnChangeEventDetail,
  PersonalDataOnErrorEventDetail,
  PersonalDataOnLoadEventDetail,
  PersonalDataOnUpdateEventDetail,
} from './personal-data.events';
import { PersonalDataProps, PersonalDataState } from './personal-data.interface';
import { BundleParcelElement } from 'components/bundle-loader/bundle-loader.interface';
import { BundleLoader } from 'components/bundle-loader/bundle-loader.component';
import './personal-data.scss';
import { ValidatePersonButton } from '../ValidatePersonButton';

const bundleUrl = REACT_APP_PERSONAL_DATA_BUNDLE_URL || '';
const backendUrl = REACT_APP_PERSONAL_DATA_BACKEND_URL || '';

export class PersonalDataComponent extends React.PureComponent<
  PersonalDataProps,
  PersonalDataState
> {
  ref: React.RefObject<BundleParcelElement>;

  constructor(props: PersonalDataProps) {
    super(props);
    this.ref = React.createRef();

    this.state = {
      isPersonalDataFormValid: false,
      appId: uuid(),
    };
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  private onPersonalDataFormLoad = (customEvent: CustomEvent<PersonalDataOnLoadEventDetail>) => {
    const { addPerson, personId } = this.props || {};
    const { isValid, person } = customEvent.detail;

    const isUserCreated = !personId && person.personId;

    if (isUserCreated) {
      addPerson({ ...person });
    }

    this.setState({
      isPersonalDataFormValid: isValid,
    });
  };

  private onPersonalDataFormUpdate = (
    customEvent: CustomEvent<PersonalDataOnUpdateEventDetail>
  ) => {
    const { updatePerson, setIsLoading, editedPerson, updatePersonAndUpdateOffer } = this.props;
    const { person, pdsValidationStatus } = customEvent.detail;
    const personWithValidationStatus = { ...person, pdsValidationStatus: pdsValidationStatus };

    setIsLoading(false);
    if (person.birthDate === editedPerson?.birthDate) {
      updatePerson(personWithValidationStatus);
    } else {
      updatePersonAndUpdateOffer(personWithValidationStatus);
    }
  };

  private onPersonalDataFormChange = (
    customEvent: CustomEvent<PersonalDataOnChangeEventDetail>
  ) => {
    const { isValid } = customEvent.detail;

    this.setState({
      isPersonalDataFormValid: isValid,
    });
  };

  private onPersonalDataFormError = (customEvent: CustomEvent<PersonalDataOnErrorEventDetail>) => {
    const { personalDataError } = this.props;
    const { error } = customEvent.detail;

    personalDataError(error);
  };

  private addEvents = () => {
    const el = this.ref.current?.el;
    const { appId } = this.state;

    el?.addEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_LOAD}`,
      this.onPersonalDataFormLoad as EventListener
    );

    el?.addEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_UPDATE}`,
      this.onPersonalDataFormUpdate as EventListener
    );

    el?.addEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_CHANGE}`,
      this.onPersonalDataFormChange as EventListener
    );

    el?.addEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_ERROR}`,
      this.onPersonalDataFormError as EventListener
    );
  };

  private removeEvents = () => {
    const el = this.ref.current?.el;
    const { appId } = this.state;

    el?.removeEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_LOAD}`,
      this.onPersonalDataFormLoad as EventListener
    );

    el?.removeEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_UPDATE}`,
      this.onPersonalDataFormUpdate as EventListener
    );

    el?.removeEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_CHANGE}`,
      this.onPersonalDataFormChange as EventListener
    );

    el?.removeEventListener(
      `upds:${appId}:${PersonalDataEvent.ON_ERROR}`,
      this.onPersonalDataFormError as EventListener
    );
  };

  private parcelDidMount = (ref: React.RefObject<BundleParcelElement>) => {
    this.ref = ref;
    this.addEvents();
  };

  private validatePerson = () => {
    const { appId } = this.state;
    this.ref.current?.el.dispatchEvent(new CustomEvent(`upds:${appId}:submit`) as Event);
  };

  private copyAddress = () => {
    const { appId } = this.state;
    const {
      insuranceOwner: { address },
    } = this.props;

    this.ref.current?.el.dispatchEvent(
      new CustomEvent(`upds:${appId}:change`, { detail: { address } }) as Event
    );
  };

  render() {
    const {
      personId,
      businessId,
      editedPerson,
      enablePDSValidation,
      enableCopyAddress,
      isAdditionalPersonEdit,
      visibleFields,
      readOnly,
    } = this.props;

    const { appId } = this.state;

    const customProps = {
      appId,
      backendUrl,
      businessId,
      personId,
      visibleFields,
      readOnly,
    };

    if (isAdditionalPersonEdit) {
      // TODO: hide some fields for editing additional person
    }

    return (
      <div>
        <BundleLoader
          id='personal-data'
          bundleUrl={bundleUrl}
          customProps={customProps}
          parcelDidMount={this.parcelDidMount}
        />
        <div className='person-form-action-buttons'>
          {enableCopyAddress && (
            <Button onClick={this.copyAddress} disabled={readOnly}>
              Adresse des Versicherungsnehmers übernehmen
            </Button>
          )}
          {enablePDSValidation && editedPerson && (
            <ValidatePersonButton
              onClick={this.validatePerson}
              validationStatus={editedPerson?.pdsValidationStatus}
              disabled={readOnly}
            />
          )}
        </div>
      </div>
    );
  }
}
