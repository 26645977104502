import { AjaxError } from 'rxjs/ajax';
import { createAction } from 'services';
import { InitDto } from './app.types';

export enum AppActionsTypes {
  FETCH_INIT = '[entities][app] FETCH_INIT',
  FETCH_INIT_FAILURE = '[entities][app] FETCH_INIT_FAILURE',
  FETCH_INIT_SUCCESS = '[entities][app] FETCH_INIT_SUCCESS',
  SET_BUSINESS_ID = '[entities][app] SET_BUSINESS_ID',
}

export type FetchInit = ReturnType<typeof fetchInit>;
const fetchInit = () => createAction(AppActionsTypes.FETCH_INIT, {});

export type FetchInitFailure = ReturnType<typeof fetchInitFailure>;
const fetchInitFailure = (error: AjaxError) =>
  createAction(AppActionsTypes.FETCH_INIT_FAILURE, { error });

export type FetchInitSuccess = ReturnType<typeof fetchInitSuccess>;
const fetchInitSuccess = (init: InitDto) =>
  createAction(AppActionsTypes.FETCH_INIT_SUCCESS, { init });

export type SetBusinessId = ReturnType<typeof setBusinessId>;
const setBusinessId = (businessId: string) =>
  createAction(AppActionsTypes.SET_BUSINESS_ID, { businessId });

export const appActions = {
  fetchInit,
  fetchInitFailure,
  fetchInitSuccess,
  setBusinessId,
};

export type AppActions = FetchInit | FetchInitFailure | FetchInitSuccess | SetBusinessId;
