import React from 'react';

export default {
  edwinInsuranceOwnerChangePopupContent: `Wenn Sie den Kunden nicht schon identifiziert haben, müssen sie das nun bitte nachholen, um der Person die Rolle Versicherungsnehmer zuzuweisen.`,
  associationMemberDataDeletePopupMessage: (
    <>
      <p>Die Auswahl Verbandsunfall wird gelöscht, da sich der Versicherungsnehmer ändert.</p>
      <p>Sind Sie sicher, dass sie den Versicherungsnehmer ändern möchten?</p>
    </>
  ),
  tooltipMissingBirthDateMessage:
    'Um eine Rolle einer Person zugeschrieben bitte geben Sie das Geburtsdatum ein.',
  tooltipInvalidBirthDateMessage:
    'Der Versicherungsnehmer muss bei Vertragsabschluss mindestens 18 Jahre alt sein.',
};
