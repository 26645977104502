import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { contractDetailsActions, contractDetailsSelectors } from 'entities/contractDetails';
import { ReplacementContract as ReplacementContractComponent } from './ReplacementContract';
import { RootState } from 'store/store.interface';

const mapStateToProps = (state: RootState) => ({
  hasData: contractDetailsSelectors.hasReplacementContractData(state),
  isEnabled: contractDetailsSelectors.isReplacementContractEnabled(state),
  isLoading: contractDetailsSelectors.isLoading(state),
  policyNumber: contractDetailsSelectors.getSelectedPolicyNumber(state),
  currentContractPremium: contractDetailsSelectors.getSelectedCurrentContractPremium(state),
  paymentMethod: contractDetailsSelectors.getSelectedPaymentMethod(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteReplacementContract: () =>
    dispatch(contractDetailsActions.deleteReplacementContract.trigger()),
  setEnabled: (isEnabled: boolean) =>
    dispatch(contractDetailsActions.setReplacementContractEnabled(isEnabled)),
  updateReplacementContract: (
    policyNumber: string,
    currentContractPremium: string,
    paymentMethod: string
  ) =>
    dispatch(
      contractDetailsActions.updateReplacementContract.trigger({
        policyNumber,
        currentContractPremium,
        paymentMethod,
      })
    ),
});

export const ReplacementContract = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplacementContractComponent);
