import { ENDPOINTS, buildEndpoint } from 'services';

const getRelayState = (): string => {
  const context = 'angebot';
  const businessId = new URLSearchParams(document.location.search).get('businessId');
  return encodeURIComponent(`context=${context}&businessId=${businessId}`);
};

const redirectToLoginPage = (): void => {
  window.location.href = buildEndpoint(ENDPOINTS.login, {
    RelayState: getRelayState(),
  });
};

export const UnauthorizedModalHelper = {
  redirectToLoginPage,
};
