import { connect } from 'react-redux';
import { partial } from 'lodash';

import { CheckboxOwnProps, CheckboxStateProps, CheckboxDispatchProps } from './Checkbox.types';
import { Checkbox as CheckboxComponent } from './Checkbox';
import { makeFieldMapState, mapDispatchToProps } from '../fields.helpers';
import { offerSelectors, TableFields } from 'entities/offer';

const makeCheckboxFieldMapState = makeFieldMapState(
  offerSelectors.getFieldOrEmptyPlaceholderForBoolean
)((field): CheckboxStateProps => field);

const mergeProps = (
  stateProps: CheckboxStateProps,
  dispatchProps: CheckboxDispatchProps,
  ownProps: CheckboxOwnProps<TableFields>
) => ({
  ...stateProps,
  ...ownProps,
  setCorrupted: partial(
    dispatchProps.setCorrupted,
    ownProps.personId!,
    ownProps.variantId!,
    ownProps.field
  ),
  updateVariant: partial(
    dispatchProps.updateVariant,
    ownProps.personId!,
    ownProps.variantId!,
    ownProps.field
  ),
});

export const Checkbox = connect(
  makeCheckboxFieldMapState,
  mapDispatchToProps,
  mergeProps
)(CheckboxComponent);
