import { connect } from 'react-redux';
import { AppLoaderComponent } from './app-loader.component';
import { RootState } from 'store/store.interface';
import { isAnythingLoading } from './app-loader.selectors';

const mapStateToProps = (state: RootState) => ({
  showLoader: isAnythingLoading(state),
});

export const AppLoader = connect(mapStateToProps)(AppLoaderComponent);
