export const DATE_FORMAT = 'yyyy-MM-dd';

export const DEFAULT_MIN_DATE = '1900-01-01';

export interface DatePickerRowProps {
  name: string;
  value: string;
  placeholder: string;
  label: string;
  minDate?: string;
  maxDate?: string;
  disabled: boolean;
  errors: string[];
  onChange: (val: string) => void;
}
