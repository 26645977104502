import React, { createContext, ReactNode, useState } from 'react';
import { noop } from 'lodash';
import { Modal } from './Modal';
import { ModalMode, ModalContextProps, Content } from './Modal.types';

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => Promise.resolve(),
  setContent: noop,
});

export const ModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [modalOpen, setOpen] = useState(false);
  const [{ content, mode = ModalMode.CONFIRM }, setContent] = useState<Content>({
    content: null,
    mode: ModalMode.CONFIRM,
  });
  const [onConfirm, setOnConfirm] = useState(() => noop);
  const [onClose, setOnClose] = useState(() => noop);

  const openModal = async (onConfirm: () => void = noop) => {
    setOpen(true);
    return new Promise((resolve) => {
      setOnConfirm(() => () => onConfirm());
      setOnClose(() => () => resolve(void 0));
    });
  };
  const closeModal = () => {
    setOpen(false);
    onClose();
  };

  return (
    <ModalContext.Provider value={{ openModal, setContent }}>
      <Modal mode={mode} open={modalOpen} onCancel={closeModal} onConfirm={onConfirm}>
        {content}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
