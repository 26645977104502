import { PersonManagementActions, PersonManagementActionTypes } from './person.management.actions';
import { PersonManagementState } from './person.management.types';
import { OfferActions, OfferActionsTypes } from 'entities/offer';
import { PersonsActions, PersonsActionTypes } from 'entities/persons';

const initialState: PersonManagementState = {
  editedPersonId: undefined,
  isPersonMgmtActionBlocked: false,
};

export const personManagementReducer = (
  state: PersonManagementState = initialState,
  action: PersonManagementActions | OfferActions | PersonsActions
): PersonManagementState => {
  switch (action.type) {
    case OfferActionsTypes.ADD_PERSON_ROLE_TRIGGER:
    case OfferActionsTypes.DELETE_PERSON_ROLE_TRIGGER:
    case PersonsActionTypes.CREATE_PERSON_TRIGGER:
    case PersonsActionTypes.REMOVE_PERSON_TRIGGER:
      return {
        ...state,
        isPersonMgmtActionBlocked: true,
      };

    case PersonsActionTypes.CREATE_PERSON_SUCCESS:
    case PersonsActionTypes.CREATE_PERSON_FAILURE:
    case OfferActionsTypes.ADD_PERSON_ROLE_FAILURE:
    case OfferActionsTypes.DELETE_PERSON_ROLE_FAILURE:
    case OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS:
    case OfferActionsTypes.DELETE_PERSON_ROLE_SUCCESS:
    case PersonsActionTypes.REMOVE_PERSON_SUCCESS:
    case PersonsActionTypes.REMOVE_PERSON_FAILURE:
      return {
        ...state,
        isPersonMgmtActionBlocked: false,
      };
    case PersonManagementActionTypes.SET_EDITED_PERSON:
      return {
        ...state,
        editedPersonId: action.payload.editedPerson.personId,
      };

    default:
      return { ...state };
  }
};
