import { PackageFields } from './offer.types';

// this array takes cares of the sorting in the view
// IMPORTANT: if a field is not present in this array it will not be displayed
export const DEFAULT_ORDER_FOR_PACKAGE_FIELDS = [
  PackageFields['000500.insuranceSum'],
  PackageFields['000500.model'],
  PackageFields['000961.insuranceSum'],
  PackageFields['000961.model'],
  PackageFields['000962.insuranceSum'],
  PackageFields['000962.model'],
  PackageFields['000583.insuranceSum'],
  PackageFields['000583.model'],
  PackageFields['000565.insuranceSum'],
  PackageFields['000565.model'],
  PackageFields['person-info.member-tax'],
  PackageFields['000501.insuranceSum'],
  PackageFields['injury-benefit.group'],
  PackageFields['000750.insuranceSum'],
  PackageFields['000567.insuranceSum'],
  PackageFields['000567.model'],
  PackageFields['000966.insuranceSum'],
  PackageFields['accident-assistance.group'],
  PackageFields['000969.selected'],
  PackageFields['000963.model'],
  PackageFields['000963.insuranceSum'],
];
