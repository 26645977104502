import React from 'react';
import { get } from 'lodash';
import { CheckIcon } from '@eg/elements/components/Icons/CheckIcon';
import { ComparisonTable } from '@eg/elements/ComparisonTable';
import { MinusIcon } from '@eg/elements/components/Icons/MinusIcon';
import { TooltipIcon } from '@eg/elements/TooltipIcon';
import { CoverageItem, CoveragesProps } from './Coverages.types';
import { Variant } from 'entities/offer';
import './Coverages.css';

export const Coverages: React.FC<CoveragesProps> = ({ coverages, variants }) => {
  return (
    <div className='coverages'>
      <ComparisonTable seperateRowsBy='border'>
        <thead>
          <tr>
            <th />
            {variants.map((variant: Variant) => (
              <th key={`variant.label.${variant.id}`} />
            ))}
          </tr>
        </thead>
        <tbody>
          {coverages.map((coverage: CoverageItem, index: number) => (
            <tr key={`coverage.item.${index}`}>
              <th className='coverages__header-label'>
                {coverage.label}
                {coverage.tooltipContent && (
                  <div className='coverages__table-header-tooltip'>
                    <TooltipIcon>{coverage.tooltipContent}</TooltipIcon>
                  </div>
                )}
              </th>
              {variants.map((variant: Variant) => {
                const tariff = variant.id.toLowerCase();
                const availability = get(coverage.availability, tariff);
                const hasAvailability = availability !== null;
                const content = get(coverage.content, tariff);
                const hasContent = content !== null;
                return (
                  <td key={`coverage.content.${variant.id}.${index}`}>
                    {hasAvailability && (availability ? <CheckIcon /> : <MinusIcon />)}
                    {hasContent && content}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </ComparisonTable>
    </div>
  );
};
