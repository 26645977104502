import React, { useState, useCallback, useEffect } from 'react';
import Autocomplete from '@eg/elements/Autocomplete';
import Checkbox from '@eg/elements/Checkbox';
import Input from '@eg/elements/Input';
import { isNumber } from 'lodash';
import { AssociationMemberProps } from './AssociationMember.types';
import { ConfirmationModal, Field } from 'components';
import './AssociationMember.scss';

export const AssociationMember: React.FC<AssociationMemberProps> = ({
  associationOptions,
  associationSuggestions,
  deleteAssociationMembership,
  hasData,
  isEnabled,
  isLoading,
  membershipNumber,
  selectedAssociationName,
  selectedAssociationNr,
  setEnabled,
  updateAssociationMembership,
}) => {
  const [openConfirmationModalState, setOpenConfirmationModalState] = useState<boolean>(false);
  const [isAssociationNameTouchedState, setIsAssociationNameTouchedState] =
    useState<boolean>(false);
  const [isMembershipNumberTouchedState, setIsMembershipNumberTouchedState] =
    useState<boolean>(false);
  const [membershipNumberState, setMembershipNumberState] = useState<string>(
    membershipNumber || ''
  );
  const [associationNameState, setAssociationNameState] = useState<string>(selectedAssociationName);
  const [associationNrState, setAssociationNrState] = useState<number | null>(
    selectedAssociationNr
  );
  const [associationSuggestionState, setAssociationSuggestionState] =
    useState<string[]>(associationSuggestions);
  const autosugestionFieldErrors =
    isAssociationNameTouchedState && !!associationNameState && !isNumber(associationNrState)
      ? ['Der Verbandsname ist in der Liste nicht vorhanden']
      : [];

  useEffect(() => {
    setAssociationNameState(selectedAssociationName);
  }, [selectedAssociationName]);

  useEffect(() => {
    setAssociationNrState(selectedAssociationNr);
    setIsAssociationNameTouchedState(false);
    setAssociationSuggestionState(associationSuggestions);
  }, [selectedAssociationNr, associationSuggestions]);

  useEffect(() => {
    setMembershipNumberState(membershipNumber || '');
    setIsMembershipNumberTouchedState(false);
  }, [membershipNumber]);

  const onCheckboxChangeHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const isChecked = event.currentTarget.checked;

      if (!isChecked && hasData) {
        setOpenConfirmationModalState(true);
      } else {
        setEnabled(isChecked);
      }
    },
    [hasData, setEnabled]
  );

  const onConfirmationDeleteHandler = () => {
    deleteAssociationMembership();
    setOpenConfirmationModalState(false);
  };

  const onCancelDeleteHandler = () => setOpenConfirmationModalState(false);

  const onAutocompleteChangeHandler = useCallback(
    (value: string) => {
      const filterSuggestions = associationSuggestions.filter((suggestion: string) =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );

      setIsAssociationNameTouchedState(false);
      setAssociationSuggestionState(filterSuggestions);
      setAssociationNameState(value);
    },
    [associationSuggestions]
  );

  const onAutocompleteBlurHandler = useCallback(() => {
    if (!isAssociationNameTouchedState) {
      setIsAssociationNameTouchedState(true);
    }

    const associationNr =
      associationOptions.find((option) => option.name === associationNameState)?.nr ?? null;

    setAssociationNrState(associationNr);

    const hasSelectedNewAssociationNr = associationNr !== selectedAssociationNr;
    const hasNewMembershipNumber = membershipNumberState !== membershipNumber;
    const isSubmitable =
      !!associationNameState &&
      isNumber(associationNr) &&
      !!membershipNumberState &&
      (hasSelectedNewAssociationNr || hasNewMembershipNumber);

    if (isSubmitable) {
      updateAssociationMembership(associationNr as number, membershipNumberState);
    }
  }, [
    associationNameState,
    associationOptions,
    isAssociationNameTouchedState,
    membershipNumber,
    membershipNumberState,
    selectedAssociationNr,
    updateAssociationMembership,
  ]);

  const onMembershipBlurHandler = useCallback(() => {
    if (!isMembershipNumberTouchedState) {
      setIsMembershipNumberTouchedState(true);
    }

    const hasSelectedNewAssociationNr = associationNrState !== selectedAssociationNr;
    const hasNewMembershipNumber = membershipNumberState !== membershipNumber;
    const isSubmitable =
      !!associationNameState &&
      isNumber(associationNrState) &&
      !!membershipNumberState &&
      (hasSelectedNewAssociationNr || hasNewMembershipNumber);

    if (isSubmitable) {
      updateAssociationMembership(associationNrState as number, membershipNumberState);
    }
  }, [
    associationNameState,
    associationNrState,
    isMembershipNumberTouchedState,
    membershipNumber,
    membershipNumberState,
    selectedAssociationNr,
    updateAssociationMembership,
  ]);

  const onMembershipChangeHandler = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setMembershipNumberState(event.currentTarget.value);
    setIsMembershipNumberTouchedState(false);
  }, []);

  return (
    <div className='association-member esc_grid__wrapper'>
      <ConfirmationModal
        open={openConfirmationModalState}
        onConfirm={onConfirmationDeleteHandler}
        onCancel={onCancelDeleteHandler}
      >
        <p>Sind Sie sicher, dass die Option Verbandsmitglied löschen wollen?</p>
      </ConfirmationModal>
      <div className='association-member__checkbox esc_col esc_col-4'>
        <Field>
          <Checkbox
            onChange={onCheckboxChangeHandler}
            checked={isEnabled}
            disabled={isLoading}
            label='Verbandsmitglied'
          />
        </Field>
      </div>

      {isEnabled && (
        <div className='association-member__data esc_col esc_col-8'>
          <Field
            label='Verbandsname'
            required={isAssociationNameTouchedState}
            errors={autosugestionFieldErrors}
          >
            <Autocomplete
              disabled={isLoading}
              suggestions={associationSuggestionState}
              value={associationNameState}
              onBlur={onAutocompleteBlurHandler}
              onChange={onAutocompleteChangeHandler}
            />
          </Field>

          <Field label='Mitgliedsnummer' required={isMembershipNumberTouchedState}>
            <Input
              type='text'
              disabled={isLoading}
              required={isEnabled}
              value={membershipNumberState}
              onChange={onMembershipChangeHandler}
              onBlur={onMembershipBlurHandler}
            />
          </Field>
        </div>
      )}
    </div>
  );
};
