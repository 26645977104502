import { AnyAction } from 'redux';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { appActions, AppActionsTypes } from './app.actions';
import { makeApiCall, buildEndpoint, ENDPOINTS } from 'services';
import { InitDto } from './app.types';
import { RootState } from 'store/store.interface';

export const onFetchInitRequest: Epic<AnyAction, AnyAction, RootState> = (action$) =>
  action$.pipe(
    ofType(AppActionsTypes.FETCH_INIT),
    mergeMap(() =>
      makeApiCall({
        method: 'get',
        url: buildEndpoint(ENDPOINTS.fetchInit),
        responseType: 'json',
      }).pipe(
        map(({ response }) => appActions.fetchInitSuccess(response as InitDto)),
        catchError((error) => of(appActions.fetchInitFailure(error)))
      )
    )
  );

export default combineEpics(onFetchInitRequest);
