import { connect } from 'react-redux';
import { agencySelectors } from 'entities/agency/agency.selectors';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { Statics as StaticsComponent } from './Statics';
import { StaticsStateProps } from './Statics.types';

export const Statics = connect((state: RootState): StaticsStateProps => {
  const offerStatus = offerSelectors.getStatus(state);
  const isProcessingFlow = offerSelectors.isProcessing(state);

  return {
    actionNumber: offerSelectors.getActionNumber(state),
    salesProduct: offerSelectors.getProductName(state),
    oeNr: agencySelectors.getOenr(state),
    employeeNr: offerSelectors.getCreatorNumber(state),
    salesModel: offerSelectors.getSalesModel(state),
    businessId: offerSelectors.getBusinessId(state),
    offerStatus,
    isProcessingFlow,
  };
})(StaticsComponent);
