import { getInsuranceOwner, getPayerName } from '../persons';
import { RootState } from 'store/store.interface';
import { UpdatePaymentDataDto } from './payment.data.types';
import { appSelectors } from '../app';

const getPaymentData = (state: RootState) => state.entities.paymentData;

const getIban = (state: RootState) => getPaymentData(state)?.iban;

const getPaymentId = (state: RootState) => getPaymentData(state)?.id;

const getError = (state: RootState) => getPaymentData(state)?.error;

const getUpdatePaymentDataPayload = (state: RootState): Omit<UpdatePaymentDataDto, 'iban'> => {
  const { id, accountId } = getPaymentData(state);
  return {
    id,
    accountId,
    accountOwner: getPayerName(state),
    businessId: appSelectors.getAppBusinessId(state),
    personId: getInsuranceOwner(state).personId,
  };
};

const isLoading = (state: RootState) => getPaymentData(state).loading;

const hasError = (state: RootState) => !!getError(state);

const hasPaymentId = (state: RootState) => !!getPaymentId(state);

export const paymentDataSelectors = {
  getError,
  getIban,
  getPaymentData,
  getPaymentId,
  getUpdatePaymentDataPayload,
  hasError,
  hasPaymentId,
  isLoading,
};
