import { differenceInYears, startOfDay } from 'date-fns';
import { offerSelectors } from 'entities/offer';
import { personsSelectors } from 'entities/persons';
import { RootState } from 'store/store.interface';

const KID_AGE_LIMIT_IN_YEARS = 10;
const SELF_EMPLOYABLE_MINIMUM_AGE_IN_YEARS = 21;
const STUDENT_PROFESSION_IDS = ['0998997M', '0998997W'];
const PENSIONER_PROFESSION_IDS = ['0997999M', '0997999W', '0997998M', '0997998W'];
const UNEMPLOYED_PROFESSION_IDS = ['0983001W', '0983002M', '0983002W'];
const HOUSEWIFE_PROFESSION_IDS = [
  '0011025W',
  '0793009W',
  '0991064W',
  '0999999W',
  '0011025M',
  '0793009M',
  '0793052N',
  '0991064M',
  '0999999M',
];

const calculatePersonAgeInYearsFromInsuranceStartDate = (state: RootState, personId: string) => {
  const personBirthdate = personsSelectors.getBirthdate(state, personId);
  const birthdate = !!personBirthdate ? new Date(personBirthdate) : new Date();
  const { value: insuranceStartDate } = offerSelectors.getInsuranceStartDate(state) || {};
  const baseDate = !!insuranceStartDate ? new Date(insuranceStartDate) : new Date();

  return differenceInYears(startOfDay(baseDate), startOfDay(birthdate));
};

const isKid = (state: RootState, personId: string) => {
  const personAge = calculatePersonAgeInYearsFromInsuranceStartDate(state, personId);
  return personAge < KID_AGE_LIMIT_IN_YEARS;
};

const isStudent = (state: RootState, personId: string) => {
  const { professionId } = personsSelectors.getProfession(state, personId) || {};
  return STUDENT_PROFESSION_IDS.includes(professionId || '');
};

const isUnder21YO = (state: RootState, personId: string) => {
  const personAge = calculatePersonAgeInYearsFromInsuranceStartDate(state, personId);
  return personAge < SELF_EMPLOYABLE_MINIMUM_AGE_IN_YEARS;
};

const isPensioner = (state: RootState, personId: string) => {
  const { professionId } = personsSelectors.getProfession(state, personId) || {};
  return PENSIONER_PROFESSION_IDS.includes(professionId || '');
};

const isHousewife = (state: RootState, personId: string) => {
  const { professionId } = personsSelectors.getProfession(state, personId) || {};
  return HOUSEWIFE_PROFESSION_IDS.includes(professionId || '');
};

const isUnemployed = (state: RootState, personId: string) => {
  const { professionId } = personsSelectors.getProfession(state, personId) || {};
  return UNEMPLOYED_PROFESSION_IDS.includes(professionId || '');
};

const isSelfEmployable = (state: RootState, personId: string) => {
  const isUnderAgedPerson = isUnder21YO(state, personId);
  const hasPensionerProfession = isPensioner(state, personId);
  const hasStudentProfession = isStudent(state, personId);
  const hasHousewifeProfession = isHousewife(state, personId);
  const hasUnemployedProfession = isUnemployed(state, personId);

  return (
    !isUnderAgedPerson &&
    !hasPensionerProfession &&
    !hasStudentProfession &&
    !hasHousewifeProfession &&
    !hasUnemployedProfession
  );
};

export const professionsSelectors = {
  isKid,
  isSelfEmployable,
  isStudent,
};
