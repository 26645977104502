import { connect } from 'react-redux';
import { offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { Coverages as CoveragesComponent } from './Coverages';
import { NonContributoryCoInsuredCoverages } from './Coverages.config';

const mapStateToProps = (state: RootState) => ({
  coverages: NonContributoryCoInsuredCoverages.coverages,
  variants: offerSelectors.getVariants(state),
});

export const Coverages = connect(mapStateToProps)(CoveragesComponent);
