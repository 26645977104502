import { AjaxError } from 'rxjs/ajax';
import { createAction } from 'services';
import { MessageDto } from 'types';

export enum OrderActionsTypes {
  FINALIZE = '[entities][order] FINALIZE',
  FINALIZE_FAILURE = '[entities][order] FINALIZE_FAILURE',
  FINALIZE_SUCCESS = '[entities][offer] FINALIZE_SUCCESS',
}

export type Finalize = ReturnType<typeof finalize>;
const finalize = () => createAction(OrderActionsTypes.FINALIZE, {});

export type FinalizeFailure = ReturnType<typeof finalizeFailure>;
const finalizeFailure = (error: AjaxError | null, messages: MessageDto[]) =>
  createAction(OrderActionsTypes.FINALIZE_FAILURE, {
    error,
    messages,
  });

export type FinalizeSuccess = ReturnType<typeof finalizeSuccess>;
const finalizeSuccess = () =>
  createAction(OrderActionsTypes.FINALIZE_SUCCESS, {
    finalized: true,
    messages: [],
  });

export const orderActions = {
  finalize,
  finalizeFailure,
  finalizeSuccess,
};

export type OrderActions = Finalize | FinalizeFailure | FinalizeSuccess;
