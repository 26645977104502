import FetchClient from '../utils/FetchClient';
import MapApi from '../utils/MapApi';
import { Context, InitAndReadResponse, StoreAndDenialResponse } from '../models';
import { ErrorMessages } from './ErrorMessages';

export interface ApiInterface {
    init(context: Context): Promise<Context>;
    store(context: Context): Promise<StoreAndDenialResponse>;
    denialByUuid(context: Context): Promise<StoreAndDenialResponse>;
}

export class Api implements ApiInterface {
    constructor(private _fetchClient: FetchClient, private _mapper: MapApi) {}

    public async init(context: Context): Promise<Context> {
        const response = await this._fetchClient.fetch<InitAndReadResponse>('/IDD/init', {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(this._mapper.contextToInitRequest(context))
        });

        if (!response.uuid) {
            throw new Error(ErrorMessages.NO_UUID);
        }

        return this._mapper.initOrReadToContext(response);
    }

    public async store(context: Context): Promise<StoreAndDenialResponse> {
        const response = await this._fetchClient.fetch<StoreAndDenialResponse>('/IDD/speichern', {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(this._mapper.contextToStoreRequest(context))
        });

        if ((!response.iddId && !response.fehlermeldung) || !response) {
            throw new Error(ErrorMessages.SAVE_ERROR);
        }

        return response;
    }

    public async denialByUuid(context: Context): Promise<StoreAndDenialResponse> {
        if (!context.uuid) {
            throw new Error(ErrorMessages.DENIAL_ERROR);
        }

        const response = await this._fetchClient.fetch<StoreAndDenialResponse>('/IDD/verzicht', {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(this._mapper.contextToDenialByUuidRequest(context))
        });

        if (!response.iddId || response.fehlermeldung) {
            throw new Error(ErrorMessages.DENIAL_ERROR);
        }
        return response;
    }
}
