import { connect } from 'react-redux';
import { offerSelectors, TableFields } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { Static as StaticComponent } from './Static';
import { StaticOwnProps, StaticStateProps } from './Static.types';

const makeFieldMapState = (
  state: RootState,
  { personId, variantId, field }: StaticOwnProps<TableFields>
) => {
  const valueSelector = offerSelectors.getFieldValue(personId!, variantId!, field);
  const errorsSelector = offerSelectors.getFieldErrors(personId!, variantId!, field);
  const labelSelector = offerSelectors.getFieldLabel(personId!, variantId!, field);

  return (state: RootState): StaticStateProps => ({
    value: valueSelector(state),
    errors: errorsSelector(state),
    label: labelSelector(state),
  });
};

export const Static = connect(makeFieldMapState)(StaticComponent);
