import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TooltipWrapperProps, TooltipEffect, TooltipPlace } from './TooltipWrapper.types';
import './TooltipWrapper.scss';

export const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  tooltipUuid,
  children,
  showTooltip,
  tooltipMessage,
  tooltipType,
  tooltipPlace = TooltipPlace.RIGHT,
  effect = TooltipEffect.SOLID,
}) => {
  return (
    <div className='tooltip-wrapper'>
      <div data-tip='' data-for={`tooltip-${tooltipUuid}`}>
        {children}
      </div>
      {showTooltip && (
        <ReactTooltip
          className={`tooltip__${tooltipType}`}
          id={`tooltip-${tooltipUuid}`}
          type={tooltipType}
          effect={effect}
          place={tooltipPlace}
        >
          {tooltipMessage}
        </ReactTooltip>
      )}
    </div>
  );
};
