import { AjaxError } from 'rxjs/ajax';
import { createAsyncAction, ActionsUnion, createAction } from 'services';
import { OfferDto } from '../offer';
import { UpdateAssociationMembership, UpdateReplacementContract } from './contract.details.types';

export enum ContractDetailsActionsTypes {
  SET_REPLACEMENT_CONTRACT_ENABLED = '[entities][contract.details] SET_REPLACEMENT_CONTRACT_ENABLED',
  SET_ASSOCIATION_MEMBER_ENABLED = '[entities][contract.details] SET_ASSOCIATION_MEMBER_ENABLED',
  SET_REPLACEMENT_REQUEST_ENABLED = '[entities][contract.details] SET_REPLACEMENT_REQUEST_ENABLED',
  UPDATE_REPLACEMENT_CONTRACT_TRIGGER = '[entities][contract.details] UPDATE_REPLACEMENT_CONTRACT_TRIGGER',
  UPDATE_REPLACEMENT_CONTRACT_FAILURE = '[entities][contract.details] UPDATE_REPLACEMENT_CONTRACT_FAILURE',
  UPDATE_REPLACEMENT_CONTRACT_SUCCESS = '[entities][contract.details] UPDATE_REPLACEMENT_CONTRACT_SUCCESS',
  DELETE_REPLACEMENT_CONTRACT_TRIGGER = '[entities][contract.details] DELETE_REPLACEMENT_CONTRACT_TRIGGER',
  DELETE_REPLACEMENT_CONTRACT_FAILURE = '[entities][contract.details] DELETE_REPLACEMENT_CONTRACT_FAILURE',
  DELETE_REPLACEMENT_CONTRACT_SUCCESS = '[entities][contract.details] DELETE_REPLACEMENT_CONTRACT_SUCCESS',
  UPDATE_ASSOCIATION_MEMBERSHIP_TRIGGER = '[entities][contract.details] UPDATE_ASSOCIATION_MEMBERSHIP_TRIGGER',
  UPDATE_ASSOCIATION_MEMBERSHIP_FAILURE = '[entities][contract.details] UPDATE_ASSOCIATION_MEMBERSHIP_FAILURE',
  UPDATE_ASSOCIATION_MEMBERSHIP_SUCCESS = '[entities][contract.details] UPDATE_ASSOCIATION_MEMBERSHIP_SUCCESS',
  DELETE_ASSOCIATION_MEMBERSHIP_TRIGGER = '[entities][contract.details] DELETE_ASSOCIATION_MEMBERSHIP_TRIGGER',
  DELETE_ASSOCIATION_MEMBERSHIP_FAILURE = '[entities][contract.details] DELETE_ASSOCIATION_MEMBERSHIP_FAILURE',
  DELETE_ASSOCIATION_MEMBERSHIP_SUCCESS = '[entities][contract.details] DELETE_ASSOCIATION_MEMBERSHIP_SUCCESS',
}

const setReplacementContractEnabled = (enabled: boolean) =>
  createAction(ContractDetailsActionsTypes.SET_REPLACEMENT_CONTRACT_ENABLED, {
    enabled,
  });

const setAssociationMemberEnabled = (enabled: boolean) =>
  createAction(ContractDetailsActionsTypes.SET_ASSOCIATION_MEMBER_ENABLED, {
    enabled,
  });

const setReplacementRequestEnabled = (enabled: boolean) =>
  createAction(ContractDetailsActionsTypes.SET_REPLACEMENT_REQUEST_ENABLED, {
    enabled,
  });

const updateReplacementContract = createAsyncAction(
  ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_TRIGGER,
  ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_SUCCESS,
  ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_FAILURE
)<UpdateReplacementContract, OfferDto, AjaxError>();

const deleteReplacementContract = createAsyncAction(
  ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_TRIGGER,
  ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_SUCCESS,
  ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_FAILURE
)<void, OfferDto, AjaxError>();

const deleteAssociationMembership = createAsyncAction(
  ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_TRIGGER,
  ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_SUCCESS,
  ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_FAILURE
)<void, OfferDto, AjaxError>();

const updateAssociationMembership = createAsyncAction(
  ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_TRIGGER,
  ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_SUCCESS,
  ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_FAILURE
)<UpdateAssociationMembership, OfferDto, AjaxError>();

const actions = {
  setReplacementContractEnabled,
  setAssociationMemberEnabled,
  setReplacementRequestEnabled,
};

const asyncActions = {
  deleteReplacementContract,
  updateReplacementContract,
  deleteAssociationMembership,
  updateAssociationMembership,
};

export const contractDetailsActions = {
  ...actions,
  ...asyncActions,
};

export type ContractDetailsActions = ActionsUnion<typeof actions, typeof asyncActions>;
