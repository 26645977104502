import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAsyncAction } from 'services';
import { UpdatePaymentDataSuccess, UpdatePaymentDataDto } from './payment.data.types';

export enum PaymentDataTypes {
  DELETE_PAYMENT_DATA_TRIGGER = '[entities][paymentData] DELETE_PAYMENT_DATA_TRIGGER',
  DELETE_PAYMENT_DATA_SUCCESS = '[entities][paymentData] DELETE_PAYMENT_DATA_SUCCESS',
  DELETE_PAYMENT_DATA_ERROR = '[entities][paymentData] DELETE_PAYMENT_DATA_ERROR',
  UPDATE_PAYMENT_DATA_TRIGGER = '[entities][paymentData] UPDATE_PAYMENT_DATA_TRIGGER',
  UPDATE_PAYMENT_DATA_SUCCESS = '[entities][paymentData] UPDATE_PAYMENT_DATA_SUCCESS',
  UPDATE_PAYMENT_DATA_ERROR = '[entities][paymentData] UPDATE_PAYMENT_DATA_ERROR',
}

const deletePaymentData = createAsyncAction(
  PaymentDataTypes.DELETE_PAYMENT_DATA_TRIGGER,
  PaymentDataTypes.DELETE_PAYMENT_DATA_SUCCESS,
  PaymentDataTypes.DELETE_PAYMENT_DATA_ERROR
)<void, void, AjaxError>();

const updatePaymentData = createAsyncAction(
  PaymentDataTypes.UPDATE_PAYMENT_DATA_TRIGGER,
  PaymentDataTypes.UPDATE_PAYMENT_DATA_SUCCESS,
  PaymentDataTypes.UPDATE_PAYMENT_DATA_ERROR
)<UpdatePaymentDataDto, UpdatePaymentDataSuccess, AjaxError>();

const actions = {};

const asyncActions = {
  deletePaymentData,
  updatePaymentData,
};

export const paymentDataActions = {
  ...actions,
  ...asyncActions,
};

export type PaymentDataActions = ActionsUnion<typeof actions, typeof asyncActions>;
