import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { offerActions, OfferFields, offerSelectors } from 'entities/offer';
import { RootState } from 'store/store.interface';
import { SelectSalesProcess as SelectSalesProcessComponent } from './SelectSalesProcess';
import {
  SelectSalesProcessStateProps,
  SelectSalesProcessDispatchProps,
} from './SelectSalesProcess.types';

const mapStateToProps = (state: RootState): SelectSalesProcessStateProps => ({
  isLoading: offerSelectors.isLoading(state),
  salesProcess: offerSelectors.getSalesProcess(state),
  salesProcessOptions: offerSelectors.getSalesProcessOptions(state),
  offerStatus: offerSelectors.getStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch): SelectSalesProcessDispatchProps => ({
  setSalesProcess: (selectedSalesProcess: string) =>
    dispatch(offerActions.updateOfferBasicData(selectedSalesProcess, OfferFields.salesProcess)),
});

export const SelectSalesProcess = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSalesProcessComponent);
