import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { InputRow as ErgoInputRow } from '@eg/elements/InputRow';
import lb from 'components/Field/labels';
import { InputRowProps } from './InputRow.types';

export const InputRow: React.FC<InputRowProps> = ({
  name,
  label,
  description = '',
  value = '',
  required = true,
  disabled = false,
  type = 'text',
  errors,
  onBlur,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (errors?.length > 0) {
      setError(errors[0]);
    }
  }, [errors]);

  const validate = (val: string): string[] => [
    ...(required && isEmpty(val) ? [lb.mandatoryField] : []),
  ];

  const onFocusHandler = () => setTouched(true);

  const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = evt.target;
    setInputValue(newValue);
  };

  const onBlurHandler = (evt: React.FocusEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    const errors = validate(value);
    const error = !isEmpty(errors) && touched ? errors[0] : '';
    setError(error);

    if (!error) {
      onBlur(evt);
    }
  };

  return (
    <ErgoInputRow
      name={name}
      required={required}
      label={label}
      value={inputValue || ''}
      type={type}
      disabled={disabled}
      error={error}
      onFocus={onFocusHandler}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      description={description}
    />
  );
};
