import * as React from 'react';
import { StyledRadioGroupRow } from '../CssOverrides';
import Radio from '@eg/elements/Radio';

export enum Denial {
    WUNSCH = 'wunsch',
    VERZICHT = 'verzicht'
}

export interface DenialSelectProps {
    disabled?: boolean;
    onChange: (denial: Denial) => void;
    denialLabel?: JSX.Element;
    allowDenial?: boolean;
}

export interface DenialSelectState {
    selected: Denial;
}

export class DenialSelect extends React.Component<DenialSelectProps, DenialSelectState> {
    state = {
        selected: Denial.WUNSCH
    };

    render() {
        const disallowDenial = !this.props.allowDenial && true; // default is on
        return (
            <StyledRadioGroupRow
                label={this.props.denialLabel || ''}
                defaultValue={this._getSelected()}
                name="beratungsToggle"
                // tslint:disable-next-line:no-any
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    this.props.onChange(event.target.value as Denial)
                }
            >
                <Radio
                    label="Beratungswunsch"
                    disabled={this.props.disabled}
                    value={Denial.WUNSCH}
                    data-id={'RADIO_' + Denial.WUNSCH + '_IDD'}
                    onClick={() => this._updateSelected(Denial.WUNSCH)}
                />
                <Radio
                    label="Beratungsverzicht"
                    disabled={this.props.disabled || disallowDenial}
                    data-id={'RADIO_' + Denial.VERZICHT + '_IDD'}
                    value={Denial.VERZICHT}
                    onClick={() => this._updateSelected(Denial.VERZICHT)}
                />
            </StyledRadioGroupRow>
        );
    }

    private _updateSelected = (denial: Denial) => {
        if (!this.state) {
            return;
        }
        // tslint:disable-next-line:switch-default
        switch (denial) {
            case Denial.WUNSCH: {
                this.setState({
                    selected: denial
                });
                break;
            }

            case Denial.VERZICHT: {
                this.setState({
                    selected: denial
                });
                break;
            }
        }
    };

    private _getSelected() {
        if (!this.state) {
            return;
        }
        return this.state.selected;
    }
}
