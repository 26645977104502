import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, concatMap, filter } from 'rxjs/operators';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { get } from 'lodash';
import { of, from } from 'rxjs';
import { AppActionsTypes } from '../app';
import { makeApiCall, buildEndpoint, ENDPOINTS } from 'services';
import { ContractDetailsActionsTypes, contractDetailsActions } from './contract.details.actions';
import { contractDetailsSelectors } from './contract.details.selectors';
import { RootActions, RootState } from 'store/store.interface';
import { OfferActionsTypes } from '../offer';
import { PersonRole } from '../persons';

export const onFetchInitSuccess: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(AppActionsTypes.FETCH_INIT_SUCCESS),
    concatMap(() => {
      const associationMemberAction = contractDetailsSelectors.hasAssociationMemberData(
        state$.value
      )
        ? [contractDetailsActions.setAssociationMemberEnabled(true)]
        : [];

      const replacementRequestAction = contractDetailsSelectors.hasReplacementRequestData(
        state$.value
      )
        ? [contractDetailsActions.setReplacementRequestEnabled(true)]
        : [];

      const replacementContractAction = contractDetailsSelectors.hasReplacementContractData(
        state$.value
      )
        ? [contractDetailsActions.setReplacementContractEnabled(true)]
        : [];

      return [
        ...associationMemberAction,
        ...replacementRequestAction,
        ...replacementContractAction,
      ];
    })
  );

export const onDeleteAssociationMembershipRequest: Epic<RootActions, RootActions, RootState> = (
  action$
) =>
  action$.pipe(
    ofType(ContractDetailsActionsTypes.DELETE_ASSOCIATION_MEMBERSHIP_TRIGGER),
    switchMap(() => {
      return from(
        makeApiCall({
          method: 'delete',
          url: buildEndpoint(ENDPOINTS.deleteAssociationMembership),
        })
      ).pipe(
        map((res) => contractDetailsActions.deleteAssociationMembership.success(res.response)),
        catchError((error) => of(contractDetailsActions.deleteAssociationMembership.failure(error)))
      );
    })
  );

export const onUpdateAssociationMembershipRequest: Epic<RootActions, RootActions, RootState> = (
  action$
) =>
  action$.pipe(
    ofType(ContractDetailsActionsTypes.UPDATE_ASSOCIATION_MEMBERSHIP_TRIGGER),
    switchMap((action) => {
      return from(
        makeApiCall({
          method: 'put',
          url: buildEndpoint(ENDPOINTS.updateAssociationMembership),
          body: action.payload,
        })
      ).pipe(
        map((res: AjaxResponse) =>
          contractDetailsActions.updateAssociationMembership.success(res.response)
        ),
        catchError((error) => of(contractDetailsActions.updateAssociationMembership.failure(error)))
      );
    })
  );

export const onDeleteReplacementContractRequest: Epic<RootActions, RootActions, RootState> = (
  action$
) =>
  action$.pipe(
    ofType(ContractDetailsActionsTypes.DELETE_REPLACEMENT_CONTRACT_TRIGGER),
    switchMap(() => {
      return from(
        makeApiCall({
          method: 'delete',
          url: buildEndpoint(ENDPOINTS.deleteReplacementContract),
        })
      ).pipe(
        map((res) => contractDetailsActions.deleteReplacementContract.success(res.response)),
        catchError((error) => of(contractDetailsActions.deleteReplacementContract.failure(error)))
      );
    })
  );

export const onUpdateReplacementContractRequest: Epic<RootActions, RootActions, RootState> = (
  action$
) =>
  action$.pipe(
    ofType(ContractDetailsActionsTypes.UPDATE_REPLACEMENT_CONTRACT_TRIGGER),
    switchMap((action) => {
      return from(
        makeApiCall({
          method: 'put',
          url: buildEndpoint(ENDPOINTS.updateReplacementContract),
          body: action.payload,
        })
      ).pipe(
        map((res: AjaxResponse) =>
          contractDetailsActions.updateReplacementContract.success(res.response)
        ),
        catchError((error) => of(contractDetailsActions.updateReplacementContract.failure(error)))
      );
    })
  );

export const onAddRoleSuccess: Epic<RootActions, RootActions, RootState> = (action$, state$) =>
  action$.pipe(
    ofType(OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS),
    filter(
      (action) =>
        contractDetailsSelectors.hasAssociationMemberData(state$.value) &&
        get(action, 'context.addedRole') === PersonRole.INSURANCE_OWNER
    ),
    map(() => contractDetailsActions.deleteAssociationMembership.trigger())
  );

export default combineEpics(
  onFetchInitSuccess,
  onAddRoleSuccess,
  onDeleteAssociationMembershipRequest,
  onUpdateAssociationMembershipRequest,
  onDeleteReplacementContractRequest,
  onUpdateReplacementContractRequest
);
