import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApprovalClauseComponent } from './ApprovalClause.component';
import { ApprovalClauseDispatchProps, ApprovalClauseStateProps } from './ApprovalClause.types';
import {
  getApprovalClausesOptions,
  getSortedApprovalClausesAvailableLabels,
  isLimitOfSelectedApprovalClausesReached,
  offerActions,
} from 'entities/offer';

import { RootState } from 'store/store.interface';
import { processingFlowSelectors } from '../../state';

const mapStateToProps = (state: RootState): ApprovalClauseStateProps => ({
  approvalClausesOptions: getApprovalClausesOptions(state),
  isLimitReached: isLimitOfSelectedApprovalClausesReached(state),
  sortedApprovalClausesAvailableLabels: getSortedApprovalClausesAvailableLabels(state),
  isAdding: processingFlowSelectors.isAdding(state),
});

const mapDispatchToProps = (dispatch: Dispatch): ApprovalClauseDispatchProps => ({
  addApprovalClause: (approvalClause: string) =>
    dispatch(offerActions.addApprovalClause.trigger(approvalClause)),
});

export const ApprovalClause = connect(mapStateToProps, mapDispatchToProps)(ApprovalClauseComponent);
