import { createAction } from 'services';

export enum UnauthorizedModalActionsTypes {
  OPEN_UNAUTHORIZED_MODAL = '[entities][unauthorizedModal] OPEN_UNAUTHORIZED_MODAL',
  CLOSE_UNAUTHORIZED_MODAL = '[entities][unauthorizedModal] CLOSE_UNAUTHORIZED_MODAL',
}

export type OpenUnauthorizedModal = ReturnType<typeof openUnauthorizedModal>;
export const openUnauthorizedModal = () =>
  createAction(UnauthorizedModalActionsTypes.OPEN_UNAUTHORIZED_MODAL);

export type CloseUnauthorizedModal = ReturnType<typeof closeUnauthorizedModal>;
export const closeUnauthorizedModal = () =>
  createAction(UnauthorizedModalActionsTypes.CLOSE_UNAUTHORIZED_MODAL);

export const unauthorizedModalActions = {
  openUnauthorizedModal,
  closeUnauthorizedModal,
};

export type UnauthorizedModalActions = OpenUnauthorizedModal | CloseUnauthorizedModal;
