import { AjaxError } from 'rxjs/ajax';
import { ContractDetailsDto } from '../contractDetails';
import { Message } from 'types';
import { PersonRole } from '../persons';
import { VariantsNames } from './offer.interface';

export type FieldOption = {
  label: string;
  value: string | number | null;
};

export type OfferFieldValue = string;
export type GenericFieldValue = string | boolean | number | string[];

export type Field<FieldIdType, FieldValue = GenericFieldValue> = {
  id: FieldIdType;
  label?: string;
  value: FieldValue;
  defaultValue: FieldValue;
  disabled: boolean;
  mandatory: boolean;
  min?: number;
  max?: number;
  stepRange?: number;
  options?: FieldOption[];
  errors: string[];
  visible?: boolean;
};

export enum OfferFields {
  actionNr = 'actionNr',
  creatorNr = 'creatorNr',
  offerStatus = 'offerStatus',
  productName = 'productName',
  salesModel = 'salesModel',
  salesProcess = 'salesProcess', // 'verkaufsprozessart',
  acceptanceQuestions = 'acceptanceQuestions',
  submissionReason = 'submissionReason', // 'vorlagegrund',
  consultationProtocolId = 'consultationProtocolId', // 'beratungsprotokollId',
}

export enum ProcessingOfferFields {
  approvalClause = 'approvalClause',
  approvalNote = 'approvalNote',
  applicationDate = 'applicationDate',
}

export enum VariantFields {
  selected = 'selected', // selected
  tariff = 'tariff', // 'tarif'
  insuranceStartDate = 'insuranceStartDate', // 'versicherungsbeginn',
  insuranceEndDate = 'insuranceEndDate',
  durationOfContract = 'durationOfContract',
  paymentMethod = 'paymentMethod', // 'zahlweise',
  debitDay = 'debitDay', // 'abbuchungstag',
  dynamicValue = 'dynamicValue', // 'dynamikHoehe',
}

export enum PackageFields {
  '000500.insuranceSum' = '000500.insuranceSum',
  '000500.model' = '000500.model',
  '000961.insuranceSum' = '000961.insuranceSum',
  '000961.model' = '000961.model',
  '000962.insuranceSum' = '000962.insuranceSum',
  '000962.model' = '000962.model',
  '000583.insuranceSum' = '000583.insuranceSum',
  '000583.model' = '000583.model',
  '000565.insuranceSum' = '000565.insuranceSum',
  '000565.model' = '000565.model',
  'person-info.member-tax' = 'person-info.member-tax',
  '000501.insuranceSum' = '000501.insuranceSum',
  'injury-benefit.group' = 'injury-benefit.group',
  '000750.insuranceSum' = '000750.insuranceSum',
  '000567.insuranceSum' = '000567.insuranceSum',
  '000567.model' = '000567.model',
  '000966.insuranceSum' = '000966.insuranceSum',
  'accident-assistance.group' = 'accident-assistance.group',
  '000969.selected' = '000969.selected',
  '000963.model' = '000963.model',
  '000963.insuranceSum' = '000963.insuranceSum',
}

export type TableFields = VariantFields | PackageFields | ProcessingOfferFields;

export type VariantDto = {
  id: string;
  name: string;
  number: number;
  disabled: boolean;
  price?: number;
  fields: Field<VariantFields>[];
  agreements: VariantAgreement[];
};

export type PackageDto = {
  id: string;
  variantId: string;
  personId: string;
  selected: boolean;
  mandatory: boolean;
  specialMaxInsuranceSum: number | null;
  fields: Field<PackageFields>[];
};

export type FieldWithPackage = {
  fieldPackage?: Pick<Package, 'selected' | 'mandatory'>;
} & FieldUI &
  Pick<
    Field<PackageFields>,
    'disabled' | 'label' | 'value' | 'errors' | 'mandatory' | 'stepRange' | 'min' | 'max'
  >;

export type OfferDto = {
  variants: VariantDto[];
  packages: PackageDto[];
  number: string;
  shippingWay: ShippingWayTypes | null;
  personsLimit: number;
  insuredPersonsLimit: number;
  beneficiariesLimit: number;
  approvalClause?: OfferField;
  approvalNote?: OfferField;
  contractDetails: ContractDetailsDto;
  messages: Message[];
} & {
  [key in OfferFields]: OfferFields;
};

type FieldUI = {
  corrupted: boolean;
};

export type OfferField = Field<OfferFields & ProcessingOfferFields, OfferFieldValue>;
export type VariantField = Field<VariantFields> & FieldUI;
export type PackageField = Field<PackageFields> & FieldUI;

export interface VariantAgreement {
  label: string;
  checked: boolean;
  text: string;
}

export type Variant = {
  id: string;
  name: string;
  number: number;
  price: number | null;
  disabled: boolean;
  agreements: VariantAgreement[];
  fieldsIds: VariantFields[];
  fieldsById: Partial<Record<VariantFields, VariantField>>;
};

export type Package = {
  id: string;
  disabled: boolean;
  variantId: string;
  personId: string;
  selected: boolean;
  mandatory: boolean;
  fieldsIds: PackageFields[];
  fieldsById: Partial<Record<PackageFields, PackageField>>;
};

export type Offer = {
  offerNumber: string;
  mapOfPackageIdByComposedId: Record<string, string>;
  variantsIds: string[];
  variantsById: Record<string, Variant>;
  packagesIds: string[];
  packagesById: Record<string, Package>;
  shippingWay: ShippingWayTypes | null;
  approvalClause?: OfferField;
  approvalNote?: OfferField;
  messages: Message[];
} & {
  [key in OfferFields]: OfferField;
};

export type FrontendValidationsByComposedId = Record<string, string[] | undefined>;

export enum ShippingWayTypes {
  EMAIL = 'EMAIL',
  POST = 'POST',
}

export type OfferReducer = {
  acceptanceQuestions: OfferField;
  actionNr: OfferField;
  consultationProtocolId: OfferField;
  consultationProtocolChannelDirection: OfferField;
  creatorNr: OfferField;
  offerStatus: OfferField;
  productName: OfferField;
  salesModel: OfferField;
  salesProcess: OfferField;
  submissionReason: OfferField;

  mapOfPackageIdByComposedId: Record<string, string>;
  packagesById: Record<string, Package>;
  variantsById: Record<string, Variant>;
  isUserInformedAboutContractTermination: boolean;
  error: AjaxError | null;
  loading: boolean;
  offerNumber: string;
  packagesIds: string[];
  variantsIds: string[];
  shippingWay: ShippingWayTypes | null;
  approvalNote?: OfferField;
  approvalClause?: Field<string[]>;
  messages: Message[];
  frontendValidationsByComposedId: FrontendValidationsByComposedId;
};

export type UpdatePersonsState = {
  [VariantFields.insuranceStartDate]?: string;
  [VariantFields.insuranceEndDate]?: string;
  [VariantFields.paymentMethod]?: string;
};

export type UpdatePersonsField = {
  fieldId: TableFields;
  value: GenericFieldValue;
  variantId: string;
  personId: string;
};

export type UpdateVariantFieldsMap = Record<
  string,
  Partial<Record<TableFields, GenericFieldValue>>
>;
export type UpdatePersonsFieldsMap = Record<
  string,
  Record<string, Partial<Record<TableFields, GenericFieldValue>>>
>;

export interface UpdateOfferBasicDataDto {
  fieldId: OfferFields;
  value: OfferFieldValue;
}
export interface UpdateVariantFieldDto {
  fieldId: TableFields;
  packageId: string;
  personId: string;
  value: GenericFieldValue;
  variantId: string;
}

export interface UpdateRoleDto {
  personId: string;
  role: PersonRole;
}

export interface UpdateBirthdateDto {
  personId: string;
  birthdate: string;
}

export interface UpdateShippingWayDto {
  type: ShippingWayTypes | null;
  email: string | null;
}

export type UpdateFrontendValidation = Record<string, string[] | undefined>;

export type UpdateAgreementDto = Pick<VariantAgreement, 'label' | 'checked'> & {
  variantId: string;
};

export enum RoleAction {
  SELECTION,
  DESELECTION,
}

export const SHORT_TERM_CONTRACT = 'F';

export type UpdateVariantFieldPayload = UpdatePersonsField & {
  state: Partial<Record<TableFields, GenericFieldValue>>;
};

export interface CancelRequestDto {
  selectedVariant: VariantsNames;
}
