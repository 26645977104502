import React, { useEffect, useState } from 'react';
import { ApprovalClauseProps } from './ApprovalClause.types';
import FormRow from '@eg/elements/FormRow';
import Autocomplete from '@eg/elements/Autocomplete';
import { SelectedApprovalClauses } from '../SelectedApprovalClauses';
import { CogwheelIcon } from '@eg/elements/components/Icons/CogwheelIcon';
import './ApprovalClause.scss';

export const ApprovalClauseComponent = ({
  approvalClausesOptions,
  addApprovalClause,
  isLimitReached,
  isAdding,
  sortedApprovalClausesAvailableLabels,
}: ApprovalClauseProps) => {
  const [currentInput, setCurrentInput] = useState<string>('');
  const [filteredLabels, setFilteredLabels] = useState<string[]>(
    sortedApprovalClausesAvailableLabels
  );

  //handle selecting value from autocomplete
  useEffect(() => {
    const foundMatchingOption = approvalClausesOptions?.find(
      (option) => option.label.trim().toLowerCase() === currentInput.toLowerCase().trim()
    );

    if (foundMatchingOption && typeof foundMatchingOption?.value === 'string' && !isAdding) {
      addApprovalClause(foundMatchingOption?.value);
    }
  }, [currentInput]); // eslint-disable-line

  //on empty input restore all available options
  useEffect(() => {
    if (!currentInput.trim()) {
      setFilteredLabels(sortedApprovalClausesAvailableLabels);
    }
  }, [currentInput, sortedApprovalClausesAvailableLabels]);

  //clean input after adding finished
  useEffect(() => {
    if (!isAdding) {
      setCurrentInput('');
    }
  }, [isAdding]);

  const renderAddingCoghwheelIcon = () => {
    return (
      <div className='approval-clause__cogwheel'>
        <CogwheelIcon spinning style={{ fill: '#8e0038' }} />
      </div>
    );
  };

  return (
    <div>
      <div>
        <FormRow
          label={
            <>
              Billigung / Rötelung
              {isAdding && renderAddingCoghwheelIcon()}
            </>
          }
          description={
            isLimitReached ? 'Es dürfen maximal 5 Billigungsgründe ausgewählt werden.' : ''
          }
        >
          <Autocomplete
            disabled={isLimitReached || isAdding}
            suggestions={filteredLabels}
            value={currentInput}
            onChange={(value: string) => {
              setCurrentInput(value);
              setFilteredLabels(
                sortedApprovalClausesAvailableLabels.filter(
                  (item) => item.trim().toLowerCase().indexOf(value.trim().toLowerCase()) >= 0
                )
              );
            }}
          />
        </FormRow>
      </div>

      <SelectedApprovalClauses />
    </div>
  );
};
