import { curryRight, get } from 'lodash';
import { createSelector } from 'reselect';
import { Person, PersonRole } from './persons.interface';
import { RootState } from 'store/store.interface';

export const personsState = (state: RootState) => state.entities.persons;

export const getPersons = (state: RootState) =>
  state.entities.persons.personsId.map((id) => state.entities.persons.personById[id]);

export const getPersonsWithoutBeneficiaries = (state: RootState) =>
  getPersons(state).filter(({ roles }: Person) => !roles.includes(PersonRole.BENEFICIARY));

const getInsuredPersonsCount = (state: RootState) =>
  getPersons(state).filter((person) => person.roles.includes(PersonRole.INSURED_PERSON)).length;

const getBeneficiariesCountByPersonId = (state: RootState, personId: string) =>
  getBeneficiariesByPersonId(state, personId).length;

const hasInsuredPersonBeneficiaries = (state: RootState, personId: string) =>
  getBeneficiariesByPersonId(state, personId).length > 0;

export const getInsuredPersons = (state: RootState) =>
  Object.values(personsState(state).personById).filter((person) =>
    person.roles.includes(PersonRole.INSURED_PERSON)
  );

const getBeneficiariesByPersonId = (state: RootState, personId: string) =>
  getPersons(state).filter(
    ({ roles, insuredPersonId }: Person) =>
      insuredPersonId === personId && roles.includes(PersonRole.BENEFICIARY)
  );

const getPersonById = (state: RootState, personId: string) =>
  state.entities.persons.personById[personId];

const getPersonIds = (state: RootState) => state.entities.persons.personsId;

const getPersonsLimit = (state: RootState) => personsState(state).personsLimit;

const isMaxInsuredPersonsNumberReached = (state: RootState) =>
  getInsuredPersonsCount(state) === getInsuredPersonsLimit(state);

const getInsuredPersonsLimit = (state: RootState) => personsState(state).insuredPersonsLimit;

const getBeneficiariesLimit = (state: RootState) => personsState(state).beneficiariesLimit;

const getPersonRoles = (personId: string) =>
  createSelector(curryRight(getPersonById)(personId), (person) => person?.roles || []);

export const getPersonsByRole = (state: RootState, role: PersonRole): Person[] =>
  getPersons(state).filter((person: Person) => person.roles?.includes(role));

// there can be only one person that is INSURANCE OWNER
// and this statement should be verified on backends OfferEngine Service
export const getInsuranceOwner = (state: RootState) =>
  get(getPersonsByRole(state, PersonRole.INSURANCE_OWNER), [0]);

const getInsuranceOwnerId = createSelector(getInsuranceOwner, (insuranceOwner) =>
  get(insuranceOwner, 'personId')
);

const getInsuranceOwnerEmail = createSelector(getInsuranceOwner, (insuranceOwner) =>
  get(insuranceOwner, 'personalEmail')
);

export const getInsuredPersonsAndPersonsWithoutRoles = (state: RootState): Person[] =>
  getPersons(state).filter(
    (person: Person) =>
      (person.roles?.includes(PersonRole.INSURED_PERSON) || person.roles?.length === 0) &&
      person.showOnList === true
  );

export const getPayerName = (state: RootState) => {
  const payer = getPersonsByRole(state, PersonRole.CONTRIBUTION_PAYER)[0];
  return `${payer?.firstName} ${payer?.lastName}`;
};

export const getRemainingPersonsNumber = createSelector(
  getPersons,
  getPersonsLimit,
  (persons, personsLimit) => personsLimit - persons.length
);

export const getRemainingInsuredPersonsNumber = createSelector(
  getInsuredPersons,
  getInsuredPersonsLimit,
  (persons, personsLimit) => personsLimit - persons.length
);

const isBeneficiaryLimitReached = createSelector(
  getBeneficiariesCountByPersonId,
  getBeneficiariesLimit,
  (personsCount, personsLimit) => personsLimit - personsCount > 0
);

const getBirthdate = (state: RootState, personId: string) =>
  get(getPersonById(state, personId), 'birthDate');

const getProfession = (state: RootState, personId: string) =>
  get(getPersonById(state, personId), 'profession');

const getSalutation = (state: RootState, personId: string) =>
  get(getPersonById(state, personId), 'salutation');

const getEdwinInitialized = (state: RootState, personId: string) =>
  get(getPersonById(state, personId), 'edwinInitialized');

const insuranceOwnerIsEdwinInitialized = createSelector(
  getInsuranceOwner,
  (insuranceOwner) => !!insuranceOwner?.edwinInitialized
);

const countInsuredPersonBeneficiariesShare = (state: RootState, personId: string): number => {
  const persons = getBeneficiariesByPersonId(state, personId);
  return persons.reduce((acc: number, p: Person) => (acc += p.share!), 0);
};

export const getFirstInsuredPersonId = createSelector(
  getInsuredPersons,
  (insuredPersons) => insuredPersons[0]?.personId
);

export const personsSelectors = {
  getBirthdate,
  getInsuranceOwner,
  getInsuranceOwnerEmail,
  getInsuranceOwnerId,
  getPersonById,
  getPersonIds,
  getPersonRoles,
  getPersonsByRole,
  getPersonsLimit,
  getInsuredPersonsLimit,
  getInsuredPersonsCount,
  getBeneficiariesByPersonId,
  getBeneficiariesCountByPersonId,
  getBeneficiariesLimit,
  getRemainingPersonsNumber,
  getRemainingInsuredPersonsNumber,
  isBeneficiaryLimitReached,
  hasInsuredPersonBeneficiaries,
  isMaxInsuredPersonsNumberReached,
  getProfession,
  getSalutation,
  getEdwinInitialized,
  insuranceOwnerIsEdwinInitialized,
  countInsuredPersonBeneficiariesShare,
  getFirstInsuredPersonId,
};
