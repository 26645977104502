import { BeneficiaryActions, BeneficiaryActionTypes } from '../../state/beneficiary.actions';
import {
  BeneficiaryManagementActions,
  BeneficiaryManagementActionTypes,
} from './beneficiary.management.actions';

export interface BeneficiaryManagementState {
  [insuredPersonId: string]: {
    editedPersonId?: string;
    isPersonMgmtActionBlocked: boolean;
  };
}

type BeneficiaryManagementInitialState = Record<string, any>;
const initialState: BeneficiaryManagementInitialState = {};

export const beneficiaryManagementReducer = (
  state: BeneficiaryManagementState = initialState,
  action: BeneficiaryManagementActions | BeneficiaryActions
): BeneficiaryManagementState | BeneficiaryManagementInitialState => {
  switch (action.type) {
    case BeneficiaryActionTypes.CREATE_BENEFICIARY_TRIGGER:
    case BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_TRIGGER:
      return {
        ...state,
        [action.payload.insuredPersonId]: {
          ...state[action.payload.insuredPersonId],
          isPersonMgmtActionBlocked: true,
        },
      };
    case BeneficiaryActionTypes.CREATE_BENEFICIARY_SUCCESS:
    case BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        [action.payload.insuredPersonId]: {
          ...state[action.payload.insuredPersonId],
          isPersonMgmtActionBlocked: false,
        },
      };
    case BeneficiaryActionTypes.CREATE_BENEFICIARY_FAILURE:
    case BeneficiaryManagementActionTypes.REMOVE_BENEFICIARY_FAILURE:
      return {
        ...state,
        [action.context.insuredPersonId]: {
          isPersonMgmtActionBlocked: false,
        },
      };
    case BeneficiaryManagementActionTypes.SET_EDITED_PERSON:
      return {
        ...state,
        [action.payload.insuredPersonId]: {
          ...state[action.payload.insuredPersonId],
          editedPersonId: action.payload.editedPerson.personId,
        },
      };
    case BeneficiaryManagementActionTypes.LAST_BENEFICIARY_REMOVED:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload.insuredPersonId]: insuredPersonKey, ...rest } = state;
      return {
        ...rest,
      };
    default:
      return { ...state };
  }
};
