import * as React from 'react';
import MessageBox from '@eg/elements/MessageBox';

export interface ErrorComponentProps {
    errorMessage: Error;
}
export class ErrorComponent extends React.Component<ErrorComponentProps> {
    render() {
        return (
            <MessageBox type="error">
                <strong>Achtung</strong>:
                <MessageBox type="error">
                    <p> {this.props.errorMessage.stack} </p>
                </MessageBox>
            </MessageBox>
        );
    }
}
