import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAction, createAsyncAction } from 'services';
import {
  AddRoleSuccessContext,
  UnfallPersonRolesResponse,
  UpdateBirthdateContext,
} from './offer.interface';
import { PersonRole } from '../persons';
import {
  OfferDto,
  OfferFields,
  ProcessingOfferFields,
  OfferFieldValue,
  RoleAction,
  TableFields,
  UpdateAgreementDto,
  UpdateBirthdateDto,
  UpdateOfferBasicDataDto,
  UpdatePersonsField,
  UpdateRoleDto,
  UpdateShippingWayDto,
  UpdateVariantFieldDto,
  VariantFields,
  UpdateFrontendValidation,
  CancelRequestDto,
} from './offer.types';

export enum OfferActionsTypes {
  FETCH_OFFER = '[entities][offer] FETCH_OFFER',
  FETCH_OFFER_FAILURE = '[entities][offer] FETCH_OFFER_FAILURE',
  FETCH_OFFER_SUCCESS = '[entities][offer] FETCH_OFFER_SUCCESS',
  SELECT_VARIANT = '[entities][offer] SELECT_VARIANT',
  SELECT_VARIANT_FAILURE = '[entities][offer] SELECT_VARIANT_FAILURE',
  SELECT_VARIANT_SUCCESS = '[entities][offer] SELECT_VARIANT_SUCCESS',
  SET_FIELD_CORRUPTED = '[entities][offer] SET_FIELD_CORRUPTED',
  SET_LOADING = '[entities][offer] SET_LOADING',
  UPDATE_OFFER_BASIC_DATA = '[entities][offer] UPDATE_OFFER_BASIC_DATA',
  UPDATE_OFFER_BASIC_DATA_SUCCESS = '[entities][offer] UPDATE_OFFER_BASIC_DATA_SUCCESS',
  UPDATE_OFFER_BASIC_DATA_FAILURE = '[entities][offer] UPDATE_OFFER_BASIC_DATA_FAILURE',
  UPDATE_VARIANTS = '[entities][offer] UPDATE_VARIANTS',
  UPDATE_VARIANTS_FAILURE = '[entities][offer] UPDATE_VARIANTS_FAILURE',
  UPDATE_VARIANTS_SUCCESS = '[entities][offer] UPDATE_VARIANTS_SUCCESS',
  TOGGLE_PERSON_ROLE = '[entities][offer] TOGGLE_PERSON_ROLE',
  COPY_FIELDS_OF_FIRST_INSURED_PERSON_TRIGGER = '[entities][offer] COPY_FIELDS_OF_FIRST_INSURED_PERSON_TRIGGER',
  COPY_FIELDS_OF_FIRST_INSURED_PERSON_FAILURE = '[entities][offer] COPY_FIELDS_OF_FIRST_INSURED_PERSON_FAILURE',
  COPY_FIELDS_OF_FIRST_INSURED_PERSON_SUCCESS = '[entities][offer] COPY_FIELDS_OF_FIRST_INSURED_PERSON_SUCCESS',
  UPDATE_FRONTEND_VALIDATION = '[entities][offer] UPDATE_FRONTEND_VALIDATION',
  USER_INFORMED_ABOUT_CONTRACT = '[entities][offer] USER_INFORMED_ABOUT_CONTRACT',

  ADD_PERSON_ROLE_TRIGGER = '[entities][offer] ADD_PERSON_ROLE_TRIGGER',
  ADD_PERSON_ROLE_SUCCESS = '[entities][offer] ADD_PERSON_ROLE_SUCCESS',
  ADD_PERSON_ROLE_FAILURE = '[entities][offer] ADD_PERSON_ROLE_FAILURE',
  DELETE_PERSON_ROLE_TRIGGER = '[entities][offer] DELETE_PERSON_ROLE_TRIGGER',
  DELETE_PERSON_ROLE_SUCCESS = '[entities][offer] DELETE_PERSON_ROLE_SUCCESS',
  DELETE_PERSON_ROLE_FAILURE = '[entities][offer] DELETE_PERSON_ROLE_FAILURE',
  UPDATE_SHIPPING_WAY_TRIGGER = '[entities][offer] UPDATE_SHIPPING_WAY_TRIGGER',
  UPDATE_SHIPPING_WAY_SUCCESS = '[entities][offer] UPDATE_SHIPPING_WAY_SUCCESS',
  UPDATE_SHIPPING_WAY_FAILURE = '[entities][offer] UPDATE_SHIPPING_WAY_FAILURE',
  UPDATE_AGREEMENTS_TRIGGER = '[entities][offer] UPDATE_AGREEMENTS_TRIGGER',
  UPDATE_AGREEMENTS_SUCCESS = '[entities][offer] UPDATE_AGREEMENTS_SUCCESS',
  UPDATE_AGREEMENTS_FAILURE = '[entities][offer] UPDATE_AGREEMENTS_FAILURE',
  UPDATE_BIRTHDATE_TRIGGER = '[entities][offer] UPDATE_BIRTHDATE_TRIGGER',
  UPDATE_BIRTHDATE_SUCCESS = '[entities][offer] UPDATE_BIRTHDATE_SUCCESS',
  UPDATE_BIRTHDATE_FAILURE = '[entities][offer] UPDATE_BIRTHDATE_FAILURE',

  ADD_APPROVAL_CLAUSE_TRIGGER = '[entities][offer] ADD_APPROVAL_CLAUSE_TRIGGER',
  ADD_APPROVAL_CLAUSE_SUCCESS = '[entities][offer] ADD_APPROVAL_CLAUSE_SUCCESS',
  ADD_APPROVAL_CLAUSE_FAILURE = '[entities][offer] ADD_APPROVAL_CLAUSE_FAILURE',

  REMOVE_APPROVAL_CLAUSE_TRIGGER = '[entities][offer] REMOVE_APPROVAL_CLAUSE_TRIGGER',
  REMOVE_APPROVAL_CLAUSE_SUCCESS = '[entities][offer] REMOVE_APPROVAL_CLAUSE_SUCCESS',
  REMOVE_APPROVAL_CLAUSE_FAILURE = '[entities][offer] REMOVE_APPROVAL_CLAUSE_FAILURE',

  CANCEL_REQUEST_TRIGGER = '[entities][offer] CANCEL_REQUEST_TRIGGER',
  CANCEL_REQUEST_SUCCESS = '[entities][offer] CANCEL_REQUEST_SUCCESS',
  CANCEL_REQUEST_FAILURE = '[entities][offer] CANCEL_REQUEST_FAILURE',
}

export type FetchOffer = ReturnType<typeof fetchOffer>;
const fetchOffer = () => createAction(OfferActionsTypes.FETCH_OFFER, {});

export type FetchOfferFailure = ReturnType<typeof fetchOfferFailure>;
const fetchOfferFailure = (error: AjaxError) =>
  createAction(OfferActionsTypes.FETCH_OFFER_FAILURE, {
    error,
  });

export type FetchOfferSuccess = ReturnType<typeof fetchOfferSuccess>;
const fetchOfferSuccess = (offer: OfferDto) =>
  createAction(OfferActionsTypes.FETCH_OFFER_SUCCESS, {
    offer,
  });

export type SelectVariant = ReturnType<typeof selectVariant>;
const selectVariant = (variantId: string, isSelected: boolean) =>
  createAction(OfferActionsTypes.SELECT_VARIANT, {
    variantId,
    field: VariantFields.selected,
    value: isSelected,
  });

export type SelectVariantFailure = ReturnType<typeof selectVariantFailure>;
const selectVariantFailure = (error: AjaxError, updatedFields: UpdateVariantFieldDto[]) =>
  createAction(OfferActionsTypes.SELECT_VARIANT_FAILURE, { error }, { updatedFields });

export type SelectVariantSuccess = ReturnType<typeof selectVariantSuccess>;
const selectVariantSuccess = (offer: OfferDto) =>
  createAction(OfferActionsTypes.SELECT_VARIANT_SUCCESS, { offer });

export type SetFieldCorrupted = ReturnType<typeof setFieldCorrupted>;
const setFieldCorrupted = (
  personId: string,
  variantId: string,
  fieldId: TableFields,
  isCorrupted: boolean
) =>
  createAction(OfferActionsTypes.SET_FIELD_CORRUPTED, {
    personId,
    variantId,
    fieldId,
    corrupted: isCorrupted,
  });

export type UpdateOfferBasicData = ReturnType<typeof updateOfferBasicData>;
const updateOfferBasicData = (
  value: OfferFieldValue,
  fieldName: OfferFields | ProcessingOfferFields
) =>
  createAction(OfferActionsTypes.UPDATE_OFFER_BASIC_DATA, {
    fieldName,
    value,
  });

export type UpdateOfferBasicDataFailure = ReturnType<typeof updateOfferBasicDataFailure>;
const updateOfferBasicDataFailure = (error: AjaxError, updatedFields: UpdateOfferBasicDataDto[]) =>
  createAction(OfferActionsTypes.UPDATE_OFFER_BASIC_DATA_FAILURE, { error }, { updatedFields });

export type UpdateOfferBasicDataSuccess = ReturnType<typeof updateOfferBasicDataSuccess>;
const updateOfferBasicDataSuccess = (offer: OfferDto, updatedFields: UpdateOfferBasicDataDto[]) =>
  createAction(OfferActionsTypes.UPDATE_OFFER_BASIC_DATA_SUCCESS, { offer }, { updatedFields });

export type SetLoading = ReturnType<typeof setLoading>;
const setLoading = (isLoading: boolean) =>
  createAction(OfferActionsTypes.SET_LOADING, {
    loading: isLoading,
  });

export type UpdateVariants = ReturnType<typeof updateVariants>;
const updateVariants = (fieldsToUpdateByPersonId: UpdatePersonsField) =>
  createAction(OfferActionsTypes.UPDATE_VARIANTS, {
    fieldsToUpdateByPersonId,
  });

export type UpdateVariantsFailure = ReturnType<typeof updateVariantsFailure>;
const updateVariantsFailure = (error: AjaxError, updatedFields: UpdateVariantFieldDto[]) =>
  createAction(OfferActionsTypes.UPDATE_VARIANTS_FAILURE, { error }, { updatedFields });

export type UpdateVariantsSuccess = ReturnType<typeof updateVariantsSuccess>;
const updateVariantsSuccess = (offer: OfferDto) =>
  createAction(OfferActionsTypes.UPDATE_VARIANTS_SUCCESS, { offer });

const toggleRole = (personId: string, role: PersonRole, roleAction: RoleAction) =>
  ({
    type: OfferActionsTypes.TOGGLE_PERSON_ROLE,
    payload: {
      personId,
      role,
      roleAction,
    },
  } as const);

export type AddRoleSuccess = ReturnType<typeof addRole.success>;
const addRole = {
  trigger: (payload: UpdateRoleDto) =>
    createAction(OfferActionsTypes.ADD_PERSON_ROLE_TRIGGER, payload),
  success: (success: UnfallPersonRolesResponse[], context?: AddRoleSuccessContext) =>
    createAction(OfferActionsTypes.ADD_PERSON_ROLE_SUCCESS, success, context),
  failure: (error: AjaxError) => createAction(OfferActionsTypes.ADD_PERSON_ROLE_FAILURE, error),
};

export type UpdateBirthdate = ReturnType<typeof updateBirthdate.success>;
const updateBirthdate = {
  trigger: (payload: UpdateBirthdateDto) =>
    createAction(OfferActionsTypes.UPDATE_BIRTHDATE_TRIGGER, payload),
  success: (success: OfferDto, context?: UpdateBirthdateContext) =>
    createAction(OfferActionsTypes.UPDATE_BIRTHDATE_SUCCESS, success, context),
  failure: (error: AjaxError) => createAction(OfferActionsTypes.UPDATE_BIRTHDATE_FAILURE, error),
};

const setUserIsInformedAboutContract = (yesNo: boolean) =>
  createAction(OfferActionsTypes.USER_INFORMED_ABOUT_CONTRACT, {
    isInformed: yesNo,
  });

const updateFrontendValidation = (frontendValidationsByComposedId: UpdateFrontendValidation) =>
  createAction(OfferActionsTypes.UPDATE_FRONTEND_VALIDATION, frontendValidationsByComposedId);

const cancelRequest = createAsyncAction(
  OfferActionsTypes.CANCEL_REQUEST_TRIGGER,
  OfferActionsTypes.CANCEL_REQUEST_SUCCESS,
  OfferActionsTypes.CANCEL_REQUEST_FAILURE
)<CancelRequestDto, OfferDto, AjaxError>();

const copyFieldsOfFirstInsuredPerson = createAsyncAction(
  OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_TRIGGER,
  OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_SUCCESS,
  OfferActionsTypes.COPY_FIELDS_OF_FIRST_INSURED_PERSON_FAILURE
)<string, OfferDto, AjaxError>();

const deleteRole = createAsyncAction(
  OfferActionsTypes.DELETE_PERSON_ROLE_TRIGGER,
  OfferActionsTypes.DELETE_PERSON_ROLE_SUCCESS,
  OfferActionsTypes.DELETE_PERSON_ROLE_FAILURE
)<UpdateRoleDto, UnfallPersonRolesResponse[], AjaxError>();

const updateShippingWay = createAsyncAction(
  OfferActionsTypes.UPDATE_SHIPPING_WAY_TRIGGER,
  OfferActionsTypes.UPDATE_SHIPPING_WAY_SUCCESS,
  OfferActionsTypes.UPDATE_SHIPPING_WAY_FAILURE
)<UpdateShippingWayDto, OfferDto, AjaxError>();

const updateAgreement = createAsyncAction(
  OfferActionsTypes.UPDATE_AGREEMENTS_TRIGGER,
  OfferActionsTypes.UPDATE_AGREEMENTS_SUCCESS,
  OfferActionsTypes.UPDATE_AGREEMENTS_FAILURE
)<UpdateAgreementDto, OfferDto, AjaxError>();

const addApprovalClause = createAsyncAction(
  OfferActionsTypes.ADD_APPROVAL_CLAUSE_TRIGGER,
  OfferActionsTypes.ADD_APPROVAL_CLAUSE_SUCCESS,
  OfferActionsTypes.ADD_APPROVAL_CLAUSE_FAILURE
)<string, OfferDto, AjaxError>();

const removeApprovalClause = createAsyncAction(
  OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_TRIGGER,
  OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_SUCCESS,
  OfferActionsTypes.REMOVE_APPROVAL_CLAUSE_FAILURE
)<string, OfferDto, AjaxError>();

const actions = {
  fetchOffer,
  fetchOfferFailure,
  fetchOfferSuccess,
  selectVariant,
  selectVariantFailure,
  selectVariantSuccess,
  setFieldCorrupted,
  setLoading,
  updateOfferBasicData,
  updateOfferBasicDataFailure,
  updateOfferBasicDataSuccess,
  updateVariants,
  updateVariantsFailure,
  updateVariantsSuccess,
  setUserIsInformedAboutContract,
  toggleRole,
  updateFrontendValidation,
};

const asyncActions = {
  addRole,
  deleteRole,
  updateShippingWay,
  updateAgreement,
  updateBirthdate,
  addApprovalClause,
  removeApprovalClause,
  copyFieldsOfFirstInsuredPerson,
  cancelRequest,
};

export const offerActions = {
  ...actions,
  ...asyncActions,
};

export type OfferActions = ActionsUnion<typeof actions, typeof asyncActions>;
