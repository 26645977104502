import {
  UnauthorizedModalActions,
  UnauthorizedModalActionsTypes,
} from './UnauthorizedModal.actions';
import { UnauthorizedModalState } from './UnauthorizedModal.types';

const defaultState: UnauthorizedModalState = {
  opened: false,
};

export const unauthorizedModalReducer = (
  state = defaultState,
  action: UnauthorizedModalActions
) => {
  switch (action.type) {
    case UnauthorizedModalActionsTypes.OPEN_UNAUTHORIZED_MODAL:
      return {
        ...state,
        opened: true,
      };
    case UnauthorizedModalActionsTypes.CLOSE_UNAUTHORIZED_MODAL:
      return {
        ...state,
        opened: false,
      };
    default:
      return state;
  }
};
